import { TreeType } from '@platform-ui-kit/components-library'
import { WppButton, WppInput, WppTypography } from '@platform-ui-kit/components-library-react'
import { t } from 'i18next'
import { ComponentProps, useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'

import { useCreateGroupApi } from 'api/groups/mutations/useCreateGroupApi'
import { useEditGroupApi } from 'api/groups/mutations/useEditGroupApi'
import { useUpdateGroupsRolesApi } from 'api/groups/mutations/useUpdateGroupsRolesApi'
import { useUpdateGroupsUsersApi } from 'api/groups/mutations/useUpdateGroupsUsersApi'
import { useGroupApi } from 'api/groups/queries/useGroupApi'
import { useGroupUsersApi } from 'api/groups/queries/useGroupUsersApi'
import { is403Error } from 'api/utils'
import { Flex } from 'components/common/flex/Flex'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { FormTree, updateTreeCloseNotSelected } from 'components/form/formTree/FormTree'
import { NoFoundSearchResult } from 'components/form/formTree/noFoundSearchResult/NoFoundSearchResult'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Delay } from 'constants/delay'
import { useForm } from 'hooks/form/useForm'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useStableCallback } from 'hooks/useStableCallback'
import { useHierarchyAccountData } from 'pages/admin/hierarchy/hierarchySettings/utils'
import { Member } from 'pages/admin/members/addMembersToGroupSideModal/utils'
import { AddGroupMembersSection } from 'pages/admin/members/groups/manageGroupSideModal/AddGroupMembersSection/AddGroupMembersSection'
import { CreateGroupRolesSection } from 'pages/admin/members/groups/manageGroupSideModal/createGroupRolesSection/CreateGroupRolesSection'
import { EditGroupMembersSection } from 'pages/admin/members/groups/manageGroupSideModal/editGroupMembersSection/EditGroupMembersSection'
import { EditGroupRolesSection } from 'pages/admin/members/groups/manageGroupSideModal/editGroupRolesSection/EditGroupRolesSection'
import styles from 'pages/admin/members/groups/manageGroupSideModal/ManageGroupSideModal.module.scss'
import {
  DESCRIPTION_MAX_CHARACTERS,
  getGroupScheme,
  handleReloadGroupRolesEditTable,
  mapGroupFormToCreateDTO,
  mapGroupFormToEditDTO,
  mapGroupToForm,
  mapMembersToGroupUsersDTO,
} from 'pages/admin/members/groups/manageGroupSideModal/utils'
import { handleReloadGroupsListTable } from 'pages/admin/members/groups/utils'
import { ExecutionContext, useViewGroupModals } from 'pages/admin/members/groups/viewGroupSideModal/useViewGroupModals'
import { handleReloadGroupRolesTable } from 'pages/admin/members/groups/viewGroupSideModal/utils'
import { handleReloadMembersTable } from 'pages/admin/members/membersList/membersTable/utils'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { GroupForm, GroupUser } from 'types/groups/groups'
import { TenantRole } from 'types/roles/tenantRole'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import {
  mapGroupRolesPayloadToCreate,
  sortNavigationTree,
  useApplyPermissionsOnNavigationTree,
  useMapNavigationTree,
} from 'utils/roles'

const defaultValues: GroupForm = {
  account_uid: '',
  name: '',
  description: '',
  members: [],
}

interface Props extends NiceModalWrappedProps {
  groupId?: string
}

const ManageGroupSideModal = ({ groupId, isOpen, onClose, onCloseComplete, id }: Props) => {
  const { enqueueToast } = useToast()
  const { currentTenant } = useCurrentTenantData()
  const { mapNavigationTree } = useMapNavigationTree()
  const { getHierarchyAccountData } = useHierarchyAccountData()

  const [roles, setRoles] = useState<TenantRole[]>([])
  const [users, setUsers] = useState<(GroupUser | Member)[]>([])
  const [membersSearch, setMembersSearch] = useState('')
  const [hierarchySearch, hierarchySetSearch] = useState('')

  const { mutateAsync: handleEditGroup } = useEditGroupApi()
  const { mutateAsync: handleCreateGroup } = useCreateGroupApi()
  const { mutateAsync: handleUpdateGroupsUsers } = useUpdateGroupsUsersApi()
  const { mutateAsync: handleUpdateGroupsRoles } = useUpdateGroupsRolesApi()
  const { applyPermissions, isLoading: isNavigationPermissionsLoading } = useApplyPermissionsOnNavigationTree()

  const defaultTree = mapNavigationTree().map(sortNavigationTree).map(applyPermissions())
  const [treeData, setTreeData] = useState<TreeType[]>(defaultTree)

  useEffect(() => {
    !isNavigationPermissionsLoading && setTreeData(defaultTree)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNavigationPermissionsLoading])

  const { data: group } = useGroupApi({
    params: { groupId: groupId! },
    enabled: isOpen && !!groupId,
  })
  const { onEdit, onViewRole } = useViewGroupModals({
    executionContext: ExecutionContext.EDIT,
    groupId: groupId!,
    group: group!,
  })

  const { data: groupUsers } = useGroupUsersApi({
    params: {
      groupId: groupId!,
    },
    enabled: isOpen && !!groupId,
  })

  const onDeleteUserFromGroup = async () => {
    /* Invalidate user groups when delete performed in members table context */
    if (!onEdit) {
      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AZ_USER_BY_EMAIL] })
      // Default view
      await handleReloadMembersTable()
    }
  }

  const form = useForm({
    defaultValues: group
      ? mapGroupToForm(group, groupUsers)
      : { ...defaultValues, account_uid: currentTenant.azMeta.organizationsId },
    validationSchema: getGroupScheme(t),
  })

  const {
    reset,
    trigger,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = form

  const onCreateSubmit = async (values: GroupForm) => {
    try {
      const { data } = await handleCreateGroup({
        group: mapGroupFormToCreateDTO(values),
      })

      await handleUpdateGroupsUsers(mapMembersToGroupUsersDTO(values.members || [], data.uid))
      await handleUpdateGroupsRoles({
        create: mapGroupRolesPayloadToCreate([data.uid], roles),
        delete: [],
      })

      await handleReloadGroupsListTable()

      enqueueToast({
        message: t('os.common.toasts.create', { entity: t('os.entities.group') }),
        type: 'success',
      })

      onClose()
    } catch (e) {
      if (is403Error(e)) {
        enqueueToast({
          message: t('os.groups.manage_group.members_table.errors.no_permissions'),
          type: 'error',
        })
      }
      enqueueToast({
        message: t('os.common.errors.error'),
        type: 'error',
      })
    }
  }

  const onEditSubmit = async (values: GroupForm) => {
    try {
      const { group: groupUpdate } = mapGroupFormToEditDTO(values, groupUsers, groupId!)

      await Promise.all([
        handleEditGroup({ id: groupId!, group: groupUpdate }),
        handleUpdateGroupsUsers({
          create: users.map(({ email }) => ({ userId: email, groupId: groupId! })),
          delete: [],
        }),
        handleUpdateGroupsRoles({
          create: mapGroupRolesPayloadToCreate([group!.uid], roles),
          delete: [],
        }),
      ])

      await handleReloadGroupsListTable()
      await handleReloadGroupRolesTable()
      await handleReloadGroupRolesEditTable()
      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.GROUP_USERS_LIST] })

      enqueueToast({
        message: t('os.common.toasts.update', { entity: t('os.entities.group') }),
        type: 'success',
      })

      onClose()
    } catch (e) {
      if (is403Error(e)) {
        enqueueToast({
          message: t('os.groups.manage_group.members_table.errors.no_permissions'),
          type: 'error',
        })
      }
      enqueueToast({
        message: t('os.common.errors.general'),
        type: 'error',
      })
    }
  }

  const onSearchChange = useDebounceFn<NonNullable<ComponentProps<typeof WppInput>['onWppChange']>>(
    ({ detail }) => hierarchySetSearch(detail.value || ''),
    Delay.Search,
  )

  const onFormTreeChange: NonNullable<ComponentProps<typeof FormTree>['onWppChange']> = useStableCallback(
    ({ detail }) => {
      const { reason, treeState, currentItem } = detail
      setTreeData(reason === 'search' && !hierarchySearch ? updateTreeCloseNotSelected(treeState) : treeState)

      if (reason === 'select' && currentItem) {
        setValue('account_uid', currentItem.id.toString())
        trigger('account_uid')
      }
    },
  )

  return (
    <>
      <FormProvider {...form}>
        <SideModal
          formConfig={{
            onSubmit: handleSubmit(group ? onEditSubmit : onCreateSubmit),
          }}
          open={isOpen}
          size="l"
          disableOutsideClick
          onWppSideModalClose={() => {
            setRoles([])
            onClose()
          }}
          onWppSideModalCloseComplete={() => {
            setRoles([])
            reset()
            onCloseComplete()
          }}
          data-testid={id}
        >
          <WppTypography slot="header" type="2xl-heading">
            {t(group ? 'os.groups.manage_group.header.edit' : 'os.groups.manage_group.header.add')}
          </WppTypography>

          <Flex slot="body" direction="column" gap={24} className={styles.modalBody}>
            <FormTextInput
              name="name"
              labelConfig={{
                text: t('os.groups.manage_group.name.label'),
              }}
              required
            />

            <FormTextareaInput
              name="description"
              labelConfig={{
                text: t('os.groups.manage_group.description.label'),
              }}
              required
              charactersLimit={DESCRIPTION_MAX_CHARACTERS}
              warningThreshold={DESCRIPTION_MAX_CHARACTERS}
            />

            {!group ? (
              <Flex direction="column" gap={16}>
                <WppTypography type="m-strong"> {t('os.groups.manage_group.hierarchy')}</WppTypography>
                {!!treeData.length && !!treeData[0].children?.length && (
                  <WppInput
                    size="s"
                    type="search"
                    disabled={isSubmitting}
                    onWppChange={onSearchChange}
                    placeholder={t('os.roles.manage_roles.fields.hierarchy.search')}
                    data-testid="hierarchy-search-input"
                  />
                )}
                <FormTree
                  name="organizationUnitId"
                  data={treeData}
                  search={hierarchySearch}
                  onWppChange={onFormTreeChange}
                  locales={{ nothingFound: '' }}
                />
                {treeData[0]?.hidden && <NoFoundSearchResult search={hierarchySearch} />}
              </Flex>
            ) : (
              <WppTypography type="s-strong" data-testid="group-hierarchy">
                {`${t('os.groups.manage_group.hierarchy')}: ${getHierarchyAccountData(group.account_uid).title}`}
              </WppTypography>
            )}

            {groupId && group ? (
              <EditGroupRolesSection group={group} setRoles={setRoles} onOpenViewRoleModal={onViewRole} />
            ) : (
              <CreateGroupRolesSection setRoles={setRoles} onOpenViewRoleModal={onViewRole} />
            )}

            <Flex direction="column" gap={12}>
              {groupId && group ? (
                <EditGroupMembersSection
                  className={styles.tableSection}
                  group={group!}
                  membersSearch={membersSearch}
                  onDeleteMember={onDeleteUserFromGroup}
                  setMembersSearch={setMembersSearch}
                  setUsers={setUsers}
                />
              ) : (
                <AddGroupMembersSection className={styles.tableSection} />
              )}
            </Flex>
          </Flex>

          <Flex slot="actions" gap={12} justify="end">
            <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
              {t('os.common.cancel')}
            </WppButton>
            <WppButton variant="primary" type="submit" loading={isSubmitting} disabled={!isValid} data-testid="apply">
              {t('os.common.save')}
            </WppButton>
          </Flex>
        </SideModal>
      </FormProvider>
    </>
  )
}

export const { showModal: showManageGroupSideModal, hideModal: hideManageGroupSideModal } = createNiceModal(
  ManageGroupSideModal,
  'manage-group-side-modal',
)
