import { WppButton, WppIconWarning, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  onSubmit: () => void
}

const ClearSelectionModal = ({ isOpen, onClose, onSubmit, onCloseComplete, id }: Props) => {
  const { t } = useTranslation()

  return (
    <Modal open={isOpen} onWppModalClose={onClose} data-testid={id} onWppModalCloseComplete={onCloseComplete}>
      <WppTypography slot="header" type="xl-heading">
        <Flex gap={8} align="center">
          <WppIconWarning />
          {t('os.roles.clear_selection.title')}
        </Flex>
      </WppTypography>

      <WppTypography slot="body" type="s-body">
        {t('os.roles.clear_selection.message')}
      </WppTypography>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} variant="secondary" size="s">
          {t('os.common.cancel')}
        </WppButton>

        <WppButton
          onClick={() => {
            onSubmit()
            onClose()
          }}
          size="s"
          variant="destructive"
        >
          {t('os.roles.clear_selection.unselect')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showClearSelectionModal } = createNiceModal(ClearSelectionModal, 'clear-selection-modal')
