import { useMemo } from 'react'

import { useUserProfileCountriesList } from 'api/users/queries/useUserProfileCountriesList'
import { PageSize } from 'constants/pageSize'
import { normalize, sortBy } from 'utils/common'

export const useCountries = () => {
  const { data, isLoading } = useUserProfileCountriesList({
    params: {
      page: 1,
      itemsPerPage: PageSize.All,
    },
  })

  const normalizedCountries = useMemo(() => {
    return normalize(data, ({ isoAlpha2 }) => isoAlpha2)
  }, [data])

  const countriesOptions = useMemo(
    () =>
      sortBy(
        data.map(({ isoAlpha2, name, logoThumbnail }) => ({
          value: isoAlpha2,
          label: name,
          logoThumbnail,
        })),
        ({ label }) => label,
      ),
    [data],
  )

  return { data, normalizedCountries, countriesOptions, isLoading }
}
