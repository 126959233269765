import {
  WppActionButton,
  WppIconRemoveCircle,
  WppTypography,
  WppAvatar,
  WppTooltip,
  WppIconUserAdd,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { EmailCell } from 'components/cellRenderers/email/EmailCell'
import { ClipboardText } from 'components/common/clipboardText/ClipboardText'
import { Flex } from 'components/common/flex/Flex'
import { MeTag } from 'components/common/meTag/MeTag'
import { ColDef, Table } from 'components/common/table'
import { SvgNewNoRecords } from 'components/svg/network/SvgNewNoRecords'
import { TableKey } from 'constants/table'
import { Member } from 'pages/admin/members/addMembersToGroupSideModal/utils'
import { showAddMembersSideModal } from 'pages/admin/members/groups/manageGroupSideModal/AddGroupMembersSection/AddMembersSideModal'
import styles from 'pages/admin/members/groups/viewGroupSideModal/viewGroupMembersSection/ViewGroupMembersSection.module.scss'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { join } from 'utils/common'

interface Props {
  className?: string
}

export const AddGroupMembersSection = ({ className }: Props) => {
  const { t } = useTranslation()
  const { userDetails } = useOtherTenantsAndUserData()
  const { setValue, watch } = useFormContext()

  const members: Member[] = watch('members')

  const handleAddMemberClick = useCallback(
    () =>
      showAddMembersSideModal({
        onSubmit: values => setValue('members', [...members, ...values.users]),
      }),
    [members, setValue],
  )

  const handelRemoveMember = useCallback(
    (removedEmail: string) => {
      setValue(
        'members',
        members.filter(({ email }) => email !== removedEmail),
      )
    },
    [members, setValue],
  )

  const columnDefs = useMemo<ColDef<Member>[]>(
    () => [
      {
        flex: 1,
        headerName: t('os.groups.manage_group.members_table.columns.name'),
        colId: 'name',
        cellRenderer: ({ data }) => {
          const { firstname, lastname, email } = data!
          const fullName = join([firstname, lastname], ' ')

          return (
            <ClipboardText
              textClassName={styles.clipboard}
              text={fullName}
              tooltipMessage={t('os.common.copy_entity_to_clipboard', {
                entity: t('os.members_list.table.tooltips.member_name'),
              })}
            >
              <Flex>
                <WppAvatar name={fullName} size="s" className={styles.avatar} />
              </Flex>
              <Flex gap={4} align="center" className={styles.fullNameWrapper}>
                <Flex className={styles.fullName}>{fullName}</Flex>
                {email === userDetails.email && <MeTag />}
              </Flex>
            </ClipboardText>
          )
        },
      },
      {
        flex: 1,
        headerName: t('os.groups.manage_group.members_table.columns.email'),
        colId: 'email',
        cellRenderer: ({ data }) => (
          <EmailCell
            email={data!.email}
            isCopiable
            copyTooltip={t('os.common.copy_entity_to_clipboard', {
              entity: t('os.members_list.table.tooltips.member_email'),
            })}
          />
        ),
        tooltipValueGetter: ({ data }) => data!.email,
      },
      {
        width: 52,
        colId: 'delete',
        cellRenderer: ({ data }) => (
          <WppTooltip text={t('os.groups.manage_group.members_table.delete_modal.title')}>
            <WppIconRemoveCircle className={styles.removeMember} onClick={() => handelRemoveMember(data?.email!)} />
          </WppTooltip>
        ),
      },
    ],
    [t, userDetails.email, handelRemoveMember],
  )

  return (
    <>
      <Flex align="center" gap={8} direction="row">
        <WppTypography type="m-strong">{t('os.groups.manage_group.members_table.header')}</WppTypography>
        <WppTypography type="s-body" className={styles.membersCount}>
          ({members.length})
        </WppTypography>
      </Flex>
      <Flex direction="column" gap={8} className={className}>
        <Flex data-testid="members-header-section" justify="between" align="end">
          <div />
          <Flex gap={12}>
            <WppActionButton onClick={handleAddMemberClick} data-testid="member-add-empty">
              <WppIconUserAdd slot="icon-start" />
              {t('os.groups.manage_group.members_table.add_members')}
            </WppActionButton>
          </Flex>
        </Flex>

        <Table
          rowData={members}
          tableKey={TableKey.VIEW_GROUP_MEMBERS}
          className={clsx('edit-group-members', styles.table, 'members-list')}
          columnDefs={columnDefs}
          getRowId={({ data }) => data.email}
          noRowsOverlayComponent={() => (
            <Flex direction="column" align="center" gap={2} data-testid="member-section-empty-state">
              <SvgNewNoRecords />
              <WppTypography type="m-strong">{t('os.roles.table.placeholder.no_members_found')}</WppTypography>
            </Flex>
          )}
        />
      </Flex>
    </>
  )
}
