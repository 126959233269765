import { WppActionButton, WppIconChevron, WppMenuContext, WppSkeleton } from '@platform-ui-kit/components-library-react'
import { AppInstanceAssignmentType, AppInstanceShort, AppInstanceStatus } from '@wpp-open/core'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppInstancesApi } from 'api/apps/queries/useAppInstancesApi'
import { Flex } from 'components/common/flex/Flex'
import { AppInstancesDefaults } from 'constants/appInstances'
import { PageSize } from 'constants/pageSize'
import { BreadcrumbsContainer } from 'layout/header/breadcrumbs/common/container/BreadcrumbsContainer'
import { BreadcrumbItem } from 'layout/header/breadcrumbs/common/item/BreadcrumbItem'
import styles from 'layout/header/breadcrumbs/hierarchy/HierarchyBreadcrumbs.module.scss'
import { HierarchyBreadcrumbsMenu } from 'layout/header/breadcrumbs/hierarchy/menu/HierarchyBreadcrumbsMenu'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { BreadcrumbItemHierarchy, BreadcrumbItemHierarchyType } from 'types/osState/breadcrumbs'
import { AppInstanceApp, mapAppInstanceToApp } from 'utils/appInstances'

interface Props {
  items: BreadcrumbItemHierarchy[]
}

export interface BreadcrumbAppData {
  app: AppInstanceApp
  appInstance: AppInstanceShort
}

export const HierarchyBreadcrumbs = ({ items }: Props) => {
  const navigate = useNavigate()
  const { navigationTree } = useCurrentTenantData()
  const { openNavigation, appData } = useOsState()
  const [isOpen, setIsOpen] = useState(false)

  const { activeWorkspaceId, appInstance: activeAppInstance } = appData
  const { rootId } = navigationTree

  const { data: appInstances, isLoading } = useAppInstancesApi({
    params: {
      assignmentId: activeWorkspaceId || rootId,
      assignmentType: appData.isCustomNavigation
        ? AppInstanceAssignmentType.Custom
        : AppInstanceAssignmentType.Workspace,
      status: [AppInstanceStatus.Active],
      itemsPerPage: PageSize.All,
    },
    staleTime: AppInstancesDefaults.BreadcrumbsStaleTime,
    enabled: isOpen,
  })

  const apps: BreadcrumbAppData[] = useMemo(() => {
    let filteredAppInstances = appInstances

    // Apps in custom menu are filtered by active group
    if (appData.isCustomNavigation) {
      const activeGroupId = activeAppInstance?.group?.id

      filteredAppInstances = appInstances.filter(appInstance => {
        const groupId = appInstance.group?.id

        return (!activeGroupId && !groupId) || activeGroupId === groupId
      })
    }

    const visibleFilteredAppInstances = filteredAppInstances.filter(app => app.flags.visible)

    const noGroup = visibleFilteredAppInstances
      .filter(item => !item.group && !item.subgroup)
      .sort((a, b) => {
        if (a?.position && b?.position && a.position !== b.position) {
          return a.position - b.position
        }
        return 0
      })
    const withGroup = visibleFilteredAppInstances
      .filter(item => item.group)
      .sort((a, b) => {
        if (a?.group?.order && b?.group?.order && a.group.order !== b.group.order) {
          return a.group.order - b.group.order
        }
        return 0
      })
      .sort((a, b) => {
        if (
          a.group?.order === b.group?.order &&
          a.subgroup?.order &&
          b.subgroup?.order &&
          a.subgroup.order !== b.subgroup.order
        ) {
          return a.subgroup.order - b.subgroup.order
        }
        return 0
      })
      .sort((a, b) => {
        if (a.group?.order === b.group?.order && a.subgroup?.order === b.subgroup?.order) {
          return a.position - b.position
        }
        return 0
      })

    const sortedData = [...noGroup, ...withGroup]

    return sortedData.map(appInstance => ({ app: mapAppInstanceToApp(appInstance), appInstance }))
  }, [activeAppInstance?.group?.id, appData.isCustomNavigation, appInstances])

  const collapsibleItems = useMemo(() => items.slice(0, -1), [items])
  const lastItem = items.at(-1)

  const handleItemClick = ({ type, id, link }: BreadcrumbItemHierarchy) => {
    if (link) {
      navigate(link)
    } else if (type === BreadcrumbItemHierarchyType.Hierarchy) {
      openNavigation(id)
    }
  }

  return (
    <BreadcrumbsContainer items={collapsibleItems} onItemClick={handleItemClick}>
      {!!lastItem && (
        <WppMenuContext
          className={styles.appsMenuWrapper}
          externalClass={styles.appsMenu}
          dropdownConfig={{
            offset: [-18, 0], // Align with apps column
            onShow: () => setIsOpen(true),
            onHidden: () => setIsOpen(false),
          }}
          data-testid="breadcrumb-dropdown"
        >
          <BreadcrumbItem slot="trigger-element" label={lastItem.label}>
            <WppActionButton variant="secondary">
              <WppIconChevron slot="icon-start" direction="down" />
            </WppActionButton>
          </BreadcrumbItem>

          <Flex direction="column">
            {isLoading ? (
              <Flex className={styles.skeletonsWrapper} direction="column" gap={16}>
                <WppSkeleton height={20} />
                <WppSkeleton height={20} />
                <WppSkeleton height={20} />
              </Flex>
            ) : (
              <>{isOpen ? <HierarchyBreadcrumbsMenu apps={apps} /> : null}</>
            )}
          </Flex>
        </WppMenuContext>
      )}
    </BreadcrumbsContainer>
  )
}
