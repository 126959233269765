import { MayBeNull } from '@wpp-open/content-builder'

import { facadeApi } from 'api'
import { PaginatedResponseOld } from 'api/common/types'
import { UserDetailsShort } from 'types/users/users'

interface UserExternalStatus {
  id: string
  email: string
  isExternal: boolean
}

export interface Params {
  emails: MayBeNull<string[]>
}

export type UsersListResponse = PaginatedResponseOld<UserDetailsShort>

export const fetchUsersExternalStatusApi = ({ emails }: Params) =>
  facadeApi.post<{ data: UserExternalStatus[] }>('/users/check-external', {
    emails,
  })
