import { facadeApi } from 'api'

interface Params {
  rejectReason: string
  requestIds: string[]
  tenantId: string
}

export const rejectHubAccessRequestApi = ({ rejectReason, requestIds, tenantId }: Params) =>
  facadeApi.patch(
    '/hub-access-requests/reject/bulk',
    { ids: requestIds, rejectReason },
    { headers: { 'X-Tenant-Id': tenantId } },
  )
