import { WppActionButton, WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'components/common/emptyStateWithAction/EmptyStateWithAction.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { SvgNoRecordsPlaceholder } from 'components/svg/network/SvgNoRecordsPlaceholder'

interface EmptyStateWithActionProps {
  label: string
  actionLabel?: string | boolean
  onAction?: () => void
}

export const EmptyStateWithAction = ({ label, actionLabel, onAction }: EmptyStateWithActionProps) => {
  return (
    <Flex direction="column" align="center">
      <Flex direction="column" align="center" className={styles.wrapper} gap={12}>
        <SvgNoRecordsPlaceholder />
        <WppTypography type="m-strong" data-testid="empty-state-label">
          {label}
        </WppTypography>
        {actionLabel && onAction && (
          <WppActionButton role="button" onClick={onAction} data-testid="empty-state-btn">
            {actionLabel}
          </WppActionButton>
        )}
      </Flex>
    </Flex>
  )
}
