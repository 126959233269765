import { HierarchyContainerNode, HierarchyNode, ContainerNodeType } from './common'
import { MayBeNull } from '../common'
import { TenantType } from '../tenant'

export const ProjectNodeType = 'PROJECT'
export type ProjectNodeType = typeof ProjectNodeType

export const ProjectsContainerNodeId = 'project-container-node'
export type ProjectsContainerNodeId = typeof ProjectsContainerNodeId

export interface ProjectNode {
  azId: null
  type: ProjectNodeType
  customTypeName: null
  name: string
  logoOriginal: null
  logoThumbnail: null
  children: string[]
  apps: readonly []
}

export interface ProjectsContainerNode {
  azId: null
  type: ContainerNodeType
  customTypeName: null
  name: ProjectsContainerNodeId
  logoOriginal: null
  logoThumbnail: null
  children: string[]
  apps: readonly []
}

export type NavigationTreeNode = HierarchyNode | ProjectNode | ProjectsContainerNode | HierarchyContainerNode

export type NavigationTreeMapping = Record<string, NavigationTreeNode>

export interface ProjectTenant {
  id: string
  name: string
  homeUrl: string
  tenantType: MayBeNull<TenantType>
}

export interface ProjectsMappingNode {
  contextWorkspace: MayBeNull<string>
  description: MayBeNull<string>
  endDate: MayBeNull<string>
  id: string
  name: string
  ownerEmail: string
  startDate: MayBeNull<string>
  status: string
  type: string
  tenant: ProjectTenant
}

export type ProjectsMapping = Record<string, ProjectsMappingNode>

export interface NavigationTree {
  rootId: string
  mapping: NavigationTreeMapping
  projectsMapping: ProjectsMapping
}

export interface NavigationNode {
  id: string
  children: string[]
}
