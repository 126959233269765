import { facadeApi } from 'api'

interface Props {
  ids: string[]
  nodeAzIds: string[]
}

export const approveHierarchyAccessRequestApi = ({ ids, nodeAzIds }: Props) =>
  facadeApi.patch('/hierarchy-requests/approve/bulk', {
    ids,
    nodeAzIds,
  })
