import {
  WppTypography,
  WppActionButton,
  WppIconOwner,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppIconRemoveCircle,
  WppTooltip,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import { is5xxError } from 'api/utils'
import { TextWithTooltipCell } from 'components/cellRenderers/textWithTooltip/TextWithTooltipCell'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, Table, TableInfinite } from 'components/common/table'
import { RenderErrorBoundary, RenderErrorType, CriticalError } from 'components/renderError'
import { SvgNoRecords } from 'components/svg/network/SvgNoRecords'
import { Delay } from 'constants/delay'
import { NONE_SELECTED_VALUE } from 'constants/select'
import { TableKey } from 'constants/table'
import { useNumberFormat } from 'hooks/useNumberFormat'
import styles from 'pages/admin/members/editMemberSideModal/EditMemberSideModal.module.scss'
import { RolesSegment } from 'pages/admin/members/editMemberSideModal/utils'
import { showSelectRoleSideModal } from 'pages/admin/members/selectRoleSideModal/SelectRoleSideModal'
import { useMemberRolesListLoader } from 'pages/admin/members/viewMemberSideModal/utils'
import { showDeleteRoleMemberModal } from 'pages/admin/roles/deleteRoleMemberModal/DeleteRoleMemberModal'
import { GroupUser } from 'types/groups/groups'
import { MemberRole } from 'types/roles/memberRole'
import { TenantRole } from 'types/roles/tenantRole'
import { AZUser, UserDetailsShort } from 'types/users/users'
import { isRoleCreatedOnRoot } from 'utils/roles'

interface Props {
  member: UserDetailsShort | GroupUser | { email: string }
  rolesAssignments: TenantRole[]
  updateRolesAssignment: (rolesAssignments: TenantRole[]) => void
  azUser: AZUser
  isLayerAdminPage: boolean
}

interface State {
  currentSegment: RolesSegment
  isCriticalError: boolean
}

export const EditMemberSideModalRoleSection = ({
  member,
  rolesAssignments,
  updateRolesAssignment,
  azUser,
  isLayerAdminPage,
}: Props) => {
  const { formatNumber } = useNumberFormat()
  const { t } = useTranslation()
  const [totalRowsCount, setTotalRowsCount] = useState<number>(0)

  const [{ currentSegment, isCriticalError }, setState] = useSetState<State>({
    currentSegment: RolesSegment.Assigned,
    isCriticalError: false,
  })

  const handleDeleteAssignedRole = useCallback(
    (roleId: string) => {
      const filteredAssignments = rolesAssignments.filter(role => role.id !== roleId)
      updateRolesAssignment(filteredAssignments)
      if (!filteredAssignments.length) {
        setState({
          currentSegment: RolesSegment.Assigned,
        })
      }
    },
    [rolesAssignments, updateRolesAssignment, setState],
  )

  const { loader } = useMemberRolesListLoader({ memberEmail: member.email })

  const noRowsOverlayComponent = useCallback(
    () => (
      <Flex direction="column" align="center" gap={12} className={styles.notFound}>
        <SvgNoRecords />
        <WppTypography type="m-strong" data-testid="no-member-roles-found-text">
          {t('os.roles.table.placeholder.no_roles_found')}
        </WppTypography>
      </Flex>
    ),
    [t],
  )

  const columnDefs = useMemo<ColDef<TenantRole>[]>(
    () => [
      {
        flex: 1,
        headerName: t('os.roles.table.columns.account_name'),
        colId: 'account_name',
        sort: 'asc',
        valueGetter: ({ data }) =>
          isRoleCreatedOnRoot(data?.createdOnAccountName) ? t('os.common.navigation.os') : data!.createdOnAccountName,
        tooltipValueGetter: ({ data }) =>
          isRoleCreatedOnRoot(data?.createdOnAccountName) ? t('os.common.navigation.os') : data!.createdOnAccountName,
      },
      {
        flex: 1,
        colId: 'name',
        headerName: t('os.entities.role'),
        valueGetter: ({ data }) => data!.name,
        tooltipValueGetter: ({ data }) => data!.name,
      },
      {
        width: 120,
        colId: 'hierarchy',
        headerName: t('os.roles.table.columns.hierarchy'),
        cellRenderer: ({ data }) => {
          return (
            <TextWithTooltipCell
              tooltipProps={{
                text: data!.uniqueAccounts.map(acc => acc.name).join(', '),
                config: { placement: 'left', delay: [Delay.Tooltip, null] },
              }}
              cellText={t('os.groups.view_group.roles_table.columns.hierarchy', { count: data!.uniqueAccounts.length })}
            />
          )
        },
      },
      {
        width: 115,
        cellRenderer: ({ data }) =>
          isLayerAdminPage ? (
            <></>
          ) : (
            <Flex align="center" justify="center">
              <WppActionButton variant="secondary" onClick={() => handleDeleteAssignedRole(data!.id)}>
                <WppIconRemoveCircle slot="icon-start" />
                {t('os.common.delete')}
              </WppActionButton>
            </Flex>
          ),
        tooltipValueGetter: () => t('os.roles.table.rows.delete'),
      },
    ],
    [handleDeleteAssignedRole, t, isLayerAdminPage],
  )

  const memberRolesColumnDefs = useMemo<ColDef<MemberRole>[]>(
    () => [
      {
        flex: 1,
        headerName: t('os.common.entity'),
        colId: 'application',
        valueGetter: ({ data }) =>
          isRoleCreatedOnRoot(data!.createdOnAccountName) ? t('os.common.navigation.os') : data!.createdOnAccountName,
        tooltipValueGetter: ({ data }) => data!.createdOnAccountName,
      },
      {
        flex: 1,
        headerName: t('os.entities.role'),
        colId: 'role',
        cellRenderer: ({ data }) => {
          return (
            <Flex direction="column" justify="center" className={styles.zeroMinWidth}>
              <WppTypography type="s-body">{data!.name}</WppTypography>
              {!!data!.description && (
                <WppTooltip text={data!.description} className={styles.tooltipWidth}>
                  <WppTypography type="xs-body" className={clsx(styles.descriptionColor, styles.zeroMinWidth)}>
                    {data!.description}
                  </WppTypography>
                </WppTooltip>
              )}
            </Flex>
          )
        },
      },
      {
        colId: 'hierarchy',
        width: 120,
        headerName: t('os.roles.table.columns.hierarchy'),
        cellRenderer: ({ data }) => {
          return (
            <TextWithTooltipCell
              tooltipProps={{
                text: data!.uniqueAccounts.map(acc => acc.name).join(', '),
                config: { placement: 'left', delay: [Delay.Tooltip, null] },
              }}
              cellText={t('os.roles.table.rows.hierarchy', { count: data!.uniqueAccounts.length })}
            />
          )
        },
      },
      {
        width: 60,
        colId: 'remove',
        cellRenderer: ({ data }) =>
          isLayerAdminPage ? (
            <></>
          ) : (
            <WppTooltip
              text={t('os.members_list.edit_member.actions_column.remove_role')}
              config={{ placement: 'left' }}
            >
              <WppActionButton
                variant="secondary"
                disabled={!data?.userAssignedDirect.length}
                onClick={() =>
                  showDeleteRoleMemberModal({
                    member: azUser!,
                    role: data!,
                  })
                }
              >
                <WppIconRemoveCircle />
              </WppActionButton>
            </WppTooltip>
          ),
      },
    ],
    [azUser, t, isLayerAdminPage],
  )

  const handleOpenSelectRoleSideModal = () => {
    showSelectRoleSideModal({
      cancelButtonText: t('os.common.back_to', { entity: t('os.entities.member').toLocaleLowerCase() }),
      rolesAssignments,
      onSubmit: ({ role, navigation }) => {
        if (!role || role === NONE_SELECTED_VALUE) {
          return
        }
        const filteredRolesAssignments = rolesAssignments.filter(roleAssignments => roleAssignments.id !== role.id)

        const resultRoles = [...filteredRolesAssignments, { ...role, uniqueAccounts: navigation! }]

        updateRolesAssignment(resultRoles)

        setState({
          currentSegment: resultRoles.length ? RolesSegment.New : RolesSegment.Assigned,
        })
      },
    })
  }

  return (
    <>
      {isCriticalError && <CriticalError />}

      <Flex direction="column" gap={12}>
        <Flex justify="between">
          <WppTypography type="m-strong">
            {t('os.members_list.view_member.roles.header', {
              countRoles: formatNumber(
                currentSegment === RolesSegment.Assigned ? totalRowsCount : rolesAssignments.length,
              ),
            })}
          </WppTypography>

          {!isLayerAdminPage && (
            <WppActionButton onClick={handleOpenSelectRoleSideModal}>
              <WppIconOwner slot="icon-start" /> {t('os.roles.header.assign_role')}
            </WppActionButton>
          )}
        </Flex>

        {!!rolesAssignments.length && (
          <WppSegmentedControl
            size="s"
            value={currentSegment}
            onWppChange={({ detail }) => {
              setState({
                currentSegment: detail.value as RolesSegment,
              })
            }}
          >
            <WppSegmentedControlItem value={RolesSegment.Assigned}>
              {t('os.roles.table.columns.assigned')}
            </WppSegmentedControlItem>
            <WppSegmentedControlItem value={RolesSegment.New}>
              {t('os.roles.table.columns.new')}
            </WppSegmentedControlItem>
          </WppSegmentedControl>
        )}
        {currentSegment === RolesSegment.New && (
          <Table
            columnDefs={columnDefs}
            noRowsOverlayComponent={noRowsOverlayComponent}
            rowData={rolesAssignments}
            className={styles.table}
          />
        )}
        {currentSegment === RolesSegment.Assigned && (
          <RenderErrorBoundary catchErrors={[RenderErrorType.DataIsNotAvailable]}>
            <TableInfinite
              tableKey={TableKey.EDIT_MEMBER_ROLES}
              className={clsx('member-roles-edit', styles.table)}
              columnDefs={memberRolesColumnDefs}
              noRowsOverlayComponent={noRowsOverlayComponent}
              loader={loader}
              onLoadSuccess={({ totalRowsCount }) => setTotalRowsCount(totalRowsCount)}
              onLoadError={error => {
                if (is5xxError(error)) {
                  setState({ isCriticalError: true })
                }
              }}
            />
          </RenderErrorBoundary>
        )}
      </Flex>
    </>
  )
}
