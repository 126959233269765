import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { skipEmptyParams } from 'api/common/utils'
import { HubType, UserHub } from 'types/hubs/hubs'

export interface HubsFilters {
  isActive?: boolean
  type?: HubType
  canManage?: boolean
  accessRequestable?: boolean
  organizationUnitIds?: string[]
}

export type Params = PaginationParams<{
  tenantId: string
  email: string
  sort?: string
  filters?: HubsFilters
  searchName?: string
}>

export type UserHubsResponse = PaginatedResponse<UserHub>

export const fetchUserHubsApi = ({ page, itemsPerPage, tenantId, email, sort, filters, searchName }: Params) =>
  facadeApi.get<UserHubsResponse>('/user/hubs', {
    params: skipEmptyParams({
      page,
      itemsPerPage,
      email,
      sort,
      filter: { searchName, ...filters },
    }),
    headers: {
      'X-Tenant-Id': tenantId,
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
