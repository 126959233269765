import {
  WppActionButton,
  WppIconRemoveCircle,
  WppTypography,
  WppInput,
  WppIconChevron,
  WppTooltip,
  WppIconOwner,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { RolesListSortBy } from 'api/roles/fetchers/fetchRolesListApi'
import { EmptyStateWithAction } from 'components/common/emptyStateWithAction/EmptyStateWithAction'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, TableInfinite } from 'components/common/table'
import { Delay } from 'constants/delay'
import { TableKey } from 'constants/table'
import { useRolesListLoader } from 'hooks/loaders/useRolesListLoader'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { showDeleteGroupRoleModal } from 'pages/admin/members/deleteGroupRoleModal/DeleteGroupRoleModal'
import { deselectGroupMembers } from 'pages/admin/members/groups/viewGroupSideModal/utils'
import styles from 'pages/admin/members/groups/viewGroupSideModal/viewGroupMembersSection/ViewGroupMembersSection.module.scss'
import { AzGroup } from 'types/groups/groups'
import { TenantRole } from 'types/roles/tenantRole'
import { isRoleCreatedOnRoot } from 'utils/roles'

interface Props {
  className?: string
  group: AzGroup
  onOpenViewRoleModal: (id: string) => void
  onAddRole: () => void
  setMembersSearch: (arg: string) => void
  rolesSearch?: string
  setRolesSearch: (arg: string) => void
  rolesCount?: number
  onRolesLoad: (count: number) => void
}

export const ViewGroupRolesSection = ({
  className,
  group,
  onOpenViewRoleModal,
  onAddRole,
  setMembersSearch,
  rolesSearch,
  setRolesSearch,
  onRolesLoad,
}: Props) => {
  const { t } = useTranslation()

  const [rolesCount, setRolesCount] = useState(-1)

  const handleAssignRoleClick = useCallback(() => {
    deselectGroupMembers()
    setMembersSearch('')
    setRolesSearch('')
    onAddRole()
  }, [onAddRole, setMembersSearch, setRolesSearch])

  const columnDefs = useMemo<ColDef<TenantRole>[]>(
    () => [
      {
        flex: 1,
        colId: RolesListSortBy.account_name,
        sortable: true,
        headerName: t('os.roles.table.columns.account_name'),
        valueGetter: ({ data }) =>
          isRoleCreatedOnRoot(data!.createdOnAccountName) ? t('os.common.navigation.os') : data!.createdOnAccountName,
      },
      {
        flex: 1,
        colId: RolesListSortBy.name,
        sortable: true,
        sort: 'asc',
        headerName: t('os.entities.role'),
        cellRenderer: ({ data }) => (
          <Flex direction="column" className={styles.roleAndDescription}>
            <WppTypography type="s-body">{data!.name}</WppTypography>
            <WppTooltip text={data!.description} className={styles.roleDescriptionTooltip}>
              <WppTypography type="xs-body" className={styles.roleDescription}>
                {data!.description}
              </WppTypography>
            </WppTooltip>
          </Flex>
        ),
      },
      {
        width: 52,
        colId: 'delete',
        cellRenderer: ({ data }) => (
          <WppTooltip text={t('os.groups.view_group.roles_table.delete_modal.title')}>
            <WppIconRemoveCircle
              className={styles.removeMember}
              onClick={() => {
                const { id, name } = data!

                showDeleteGroupRoleModal({
                  group,
                  roleName: name,
                  roleId: id,
                })
              }}
            />
          </WppTooltip>
        ),
      },
      {
        width: 52,
        colId: 'view',
        cellRenderer: ({ data }) => (
          <WppTooltip text={t('os.groups.view_group.roles_table.actions_column.view_role')}>
            <WppIconChevron
              className={styles.viewMember}
              direction="right"
              onClick={() => onOpenViewRoleModal(data!.id)}
            />
          </WppTooltip>
        ),
      },
    ],
    [t, onOpenViewRoleModal, group],
  )

  const setSearchDebounced = useDebounceFn((search?: string) => {
    setRolesSearch(search?.trim() || '')
    setRolesCount(-1)
  }, Delay.Search)

  const { loader } = useRolesListLoader({ search: rolesSearch, groupId: [group.uid] })

  return (
    <Flex direction="column" gap={8} className={className}>
      <Flex data-testid="roles-header-section" justify="between" align="end" gap={4}>
        <Flex direction="column">
          {(rolesCount > 1 || !!rolesSearch) && (
            <WppInput
              size="s"
              type="search"
              value={rolesSearch}
              className={styles.searchInput}
              onWppChange={({ detail }) => setSearchDebounced(detail.value)}
              placeholder={t('os.groups.view_group.search_role_placeholder')}
              data-testid="search-roles"
            />
          )}
        </Flex>
        {rolesCount > 0 && (
          <WppActionButton onClick={handleAssignRoleClick}>
            <WppIconOwner slot="icon-start" />
            {t('os.groups.view_group.roles_table.assign_role')}
          </WppActionButton>
        )}
      </Flex>

      {rolesCount === 0 ? (
        <EmptyStateWithAction
          label={t('os.roles.table.placeholder.no_added_roles')}
          actionLabel={t('os.groups.view_group.roles_table.add_role')}
          onAction={handleAssignRoleClick}
        />
      ) : (
        <TableInfinite
          tableKey={TableKey.VIEW_GROUP_ROLES}
          className={clsx(styles.table, 'roles-list')}
          columnDefs={columnDefs}
          loader={loader}
          onLoadSuccess={({ totalRowsCount }) => {
            onRolesLoad(totalRowsCount)
            setRolesCount(totalRowsCount)
          }}
        />
      )}
    </Flex>
  )
}
