import { WppButton, WppIconChevron, WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useAzUserByEmailApi } from 'api/alphaZulu/queries/useAzUserByEmailApi'
import { useGenerateUserDetailsDownloadUrlsApi } from 'api/attachments/queries/useGenerateUserDetailsDownloadUrlsApi'
import { useUserByEmailApi } from 'api/users/queries/useUserByEmailApi'
import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useAddGroupToMemberSideModal } from 'pages/admin/members/addGroupsToMemberSideModal/AddGroupsToMemberSideModal'
import { isMemberWithAvatar } from 'pages/admin/members/membersList/utils'
import { useSelectRoleSideModal } from 'pages/admin/members/selectRoleSideModal/SelectRoleSideModal'
import { useViewMemberModals } from 'pages/admin/members/viewMemberSideModal/utils'
import styles from 'pages/admin/members/viewMemberSideModal/ViewMemberSideModal.module.scss'
import { ViewMemberSideModalContent } from 'pages/admin/members/viewMemberSideModal/ViewMemberSideModalContent'
import { GroupUser } from 'types/groups/groups'
import { RoleMember } from 'types/roles/roleMember'
import { UserDetailsShort } from 'types/users/users'
import { join } from 'utils/common'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  backButtonText?: string
  member: UserDetailsShort | GroupUser | RoleMember
  onBack?: () => void
  isLayerAdminPage?: boolean
}

const ViewMemberSideModal = ({
  isOpen,
  backButtonText,
  isLayerAdminPage = false,
  member,
  onClose,
  onCloseComplete,
  id,
  onBack,
}: Props) => {
  const { t } = useTranslation()

  const { data: azUser, isLoading: isAzUserLoading } = useAzUserByEmailApi({
    params: member.email,
    enabled: isOpen,
  })

  const isAvatarAvailable = isMemberWithAvatar(member)

  const { data: user } = useUserByEmailApi({
    params: { email: member.email },
    enabled: isOpen && !isAvatarAvailable,
  })

  const avatarThumbnail = isAvatarAvailable ? member.avatarThumbnail : user?.avatarThumbnail

  const {
    data: [avatar],
    isLoading: isAvatarLoading,
  } = useGenerateUserDetailsDownloadUrlsApi({
    params: { keys: [avatarThumbnail?.key!] },
    enabled: isOpen && !!avatarThumbnail,
  })

  const { onAddRole, onEdit, onAddGroup, onViewGroup, onViewRole } = useViewMemberModals({
    backButtonText,
    isLayerAdminPage,
    onBack,
    member,
    fullName: join([azUser?.first_name, azUser?.last_name], ' '),
  })

  return (
    <SideModal
      open={isOpen}
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={onCloseComplete}
      size="xl"
      data-testid={id}
    >
      <WppTypography slot="header" type="2xl-heading">
        {t('os.members_list.view_member.header')}
      </WppTypography>

      <Flex slot="body" direction="column" className={styles.modalBody} gap={32}>
        {isAzUserLoading || isAvatarLoading ? (
          <Flex className={styles.loader} justify="center" align="center">
            <WppSpinner size="m" />
          </Flex>
        ) : (
          !!azUser && (
            <ViewMemberSideModalContent
              user={azUser}
              avatar={avatar}
              isLayerAdminPage={isLayerAdminPage}
              onEdit={onEdit}
              onAddRole={onAddRole}
              onAddGroup={onAddGroup}
              onViewRole={onViewRole}
              onViewGroup={onViewGroup}
            />
          )
        )}
      </Flex>

      <Flex slot="actions" justify="end" align="center" gap={12}>
        {onEdit ? (
          <WppButton size="m" variant="secondary" onClick={onEdit}>
            {t('os.common.edit')}
          </WppButton>
        ) : (
          <WppButton size="m" variant="secondary" onClick={onBack}>
            <WppIconChevron direction="left" slot="icon-start" />
            {t('os.common.back_to', { entity: backButtonText })}
          </WppButton>
        )}

        <WppButton size="m" variant="primary" onClick={onClose}>
          {t('os.common.done')}
        </WppButton>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showViewMemberSideModal, hideModal: hideViewMemberSideModal } = createNiceModal(
  ViewMemberSideModal,
  'view-member-side-modal',
  [useSelectRoleSideModal, useAddGroupToMemberSideModal],
)
