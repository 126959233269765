import { useCallback, useRef } from 'react'

import { useFetchGenerateUserDetailsDownloadUrlsApi } from 'api/attachments/queryFetchers/useFetchGenerateUserDetailsDownloadUrlsApi'
import { useFetchRoleMembersApi } from 'api/roles/queryFetchers/useFetchRoleMembers'
import { TableInfiniteLoader } from 'components/common/table'
import { TableDefaults } from 'constants/table'
import { useLatestDistinct } from 'hooks/useLatestDistinct'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { RoleMember } from 'types/roles/roleMember'
import { excludeFalsy } from 'utils/common'

interface Params {
  search?: string
  hasDirectAssignment?: boolean
  hasGroupsAssignment?: boolean
  roleId: string
}

export const useRoleMembersLoader = ({ search, roleId, hasDirectAssignment, hasGroupsAssignment }: Params) => {
  const { currentTenant } = useCurrentTenantData()
  const avatarsCache = useRef<Record<string, string>>({})

  const hasDirectAssignmentStable = useLatestDistinct(hasDirectAssignment)
  const hasGroupsAssignmentStable = useLatestDistinct(hasGroupsAssignment)
  const roleIdStable = useLatestDistinct(roleId)

  const handleGenerateUserDetailsDownloadUrls = useFetchGenerateUserDetailsDownloadUrlsApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const handleFetchRoleMembersList = useFetchRoleMembersApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const loader: TableInfiniteLoader<RoleMember> = useCallback(
    async ({ startRow, endRow }) => {
      const itemsPerPage = endRow - startRow

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchRoleMembersList({
        tenantId: currentTenant.id,
        roleId: roleIdStable,
        itemsPerPage,
        search,
        hasDirectAssignment: hasDirectAssignmentStable,
        hasGroupsAssignment: hasGroupsAssignmentStable,
        page: endRow / itemsPerPage,
      })
      const avatarsKeys = data.map(({ avatarThumbnail }) => avatarThumbnail?.key).filter(excludeFalsy)

      if (avatarsKeys.length) {
        const { data } = await handleGenerateUserDetailsDownloadUrls({ keys: avatarsKeys })
        const portion = Object.fromEntries(data.map(({ key, signed_url }) => [key, signed_url]))

        avatarsCache.current = {
          ...avatarsCache.current,
          ...portion,
        }
      }

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [
      handleFetchRoleMembersList,
      currentTenant.id,
      roleIdStable,
      search,
      hasDirectAssignmentStable,
      hasGroupsAssignmentStable,
      handleGenerateUserDetailsDownloadUrls,
    ],
  )
  return { avatarsCache, loader }
}

export enum RolesListSegment {
  MEMBERS = 'members',
  ALL = 'all',
  GROUPS = 'groups',
}

export interface State {
  hasDirectAssignment: boolean
  hasGroupsAssignment: boolean
  search: string
  totalMembersCount: number
  isCriticalError: boolean
  currentSegment: RolesListSegment
  selectedMembers: RoleMember[]
}
