import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { TenantGroup } from 'types/groups/groups'
import { AZUser } from 'types/users/users'

interface EditMemberFormValues {
  groups: string[]
}
interface GetDefaultValuesParams {
  organizationsId: string
  groups: TenantGroup[]
}

export const getDefaultValues = ({ organizationsId, groups }: GetDefaultValuesParams): EditMemberFormValues => ({
  groups: groups.filter(group => group.createdOnAccountId === organizationsId).map(({ id }) => id),
})

interface MapValuesToPayloadParams {
  user: AZUser
  values: string[]
}

export const mapValuesToPayload = ({ user, values }: MapValuesToPayloadParams) => {
  const groupsToAdd = values.map(groupId => ({ groupId, userId: user.email }))

  return { create: groupsToAdd, delete: [] }
}

export const useAddMemberToGroupsValidationScheme = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        groups: zod.array(zod.string()).min(1, t('os.members_list.edit_member.validation.groups.required')),
      }),
    [t],
  )
}
