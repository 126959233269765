import { ThemeStyles } from '@platform-ui-kit/components-library'

import { AppInstanceFull } from '../appInstance'
import { MayBeNull } from '../common'
import { SelectedHierarchy } from '../hierarchy'
import { NavigationTree } from '../mapping'
import { PermissionsRecord } from '../permissions'
import { SelectedProjectData } from '../project'
import { Taxonomy } from '../taxonomy'
import { Tenant } from '../tenant'
import { UserDetails } from '../user'

/**
 * OS context data based on the appearance that application is launched with.
 */
export type OsContext = OsContextFullPage | OsContextTool | OsContextWidget

export interface OsContextFullPage extends OsCommonContext {
  appearance: OsContextAppearance.FullPage
  baseUrl: string
  hierarchy: SelectedHierarchy
}

export interface OsContextTool extends OsCommonContext {
  appearance: OsContextAppearance.Tool
  baseUrl: null
  workspace: null
  hierarchy: null
  project: null
  appInstance: null
  appCustomConfig: null
  additional: null
}

export interface OsContextWidget extends OsCommonContext {
  appearance: OsContextAppearance.Widget
  baseUrl: null
  workspace: null
  hierarchy: null
  project: null
  appInstance: null
  appCustomConfig: null
  additional: null
}

export interface OsCommonContext {
  /**
   * A type of appearance that application is launched with.
   * Supported appearances for each app are configured in DevHub.
   */
  appearance: OsContextAppearance

  /**
   * A url path without a leading slash.
   * Should be used as a basis for the app's router if it has one.
   * Only applicable for the FullPage appearance.
   *
   * Example: "application/123/tags-manager"
   */
  baseUrl: MayBeNull<string>

  /**
   * A tree of hierarchy nodes the app is launched on.
   * Includes a hidden hierarchy level if it's part of the tree.
   * Doesn't contain a root tenant node, only child hierarchy nodes.
   *
   * @deprecated Use `hierarchy` field instead.
   *
   * 'Workspace' now has a different meaning throughout the OS, it is equal to 'Tenant'.
   * So `workspace` field is deprecated to avoid confusion.
   *
   * This field will be removed at a later time.
   */
  workspace: MayBeNull<SelectedHierarchy>
  /**
   * A tree of hierarchy nodes the app is launched on.
   * Includes a hidden hierarchy level if it's part of the tree.
   * Always contains at least a root tenant node.
   */
  hierarchy: MayBeNull<SelectedHierarchy>
  /**
   * Project info will be filled if the app is launched within a project.
   */
  project: MayBeNull<SelectedProjectData>
  /**
   * Apps onboarded through DevHub will receive their instance data upon launch.
   */
  appInstance: MayBeNull<AppInstanceFull>
  /**
   * Native apps onboarded through DevHub will receive the data that was set in the 'Custom Context' config.
   */
  appCustomConfig: MayBeNull<Record<string, any>>
  /**
   * Apps may receive additional context data like launch configuration or advanced project context.
   */
  additional: MayBeNull<Record<string, any>>

  /**
   * Information about the tenant the app is launched in.
   */
  tenant: Tenant
  /**
   * List of user permissions.
   */
  permissions: PermissionsRecord[]
  /**
   * Information about the user who launched the app.
   */
  userDetails: UserDetails
  /**
   * Unpacked theme object with resolved color values.
   * May be used to provide external libraries with theme colors.
   */
  theme: ThemeStyles
  /**
   * A tree of hierarchy and project nodes the user has access to within a current tenant.
   * Includes a hidden hierarchy level if the tenant is configured accordingly.
   */
  navigationTree: NavigationTree
  /**
   * List of known taxonomy entities to be used in i18n.
   */
  taxonomy: Taxonomy
  /**
   * A page identifier present for specific pages, it is empty in all other cases.
   */
  activePage: MayBeNull<OsContextActivePage>
}

export enum OsContextAppearance {
  FullPage = 'FullPage',
  Tool = 'Tool',
  Widget = 'Widget',
}

export enum OsContextActivePage {
  Promo = 'PROMO_PAGE',
  Network = 'NETWORK_PAGE',
  Hubs = 'HUBS_PAGE',
  HubsAdmin = 'HUBS_ADMIN_PAGE',
  // Any pages related to the marketplace
  Marketplace = 'MARKETPLACE_PAGE',
  // Any pages related to DevHub
  DevHub = 'DEVHUB_PAGE',
  // Any pages related to Orchestration
  Orchestration = 'ORCHESTRATION_PAGE',
  // Any pages in OS Settings
  OsSettings = 'OS_SETTINGS_PAGE',
  LayerAdmin = 'LAYER_ADMIN_PAGE',
  UserProfile = 'USER_PROFILE_PAGE',
  Links = 'LINKS_PAGE',
  News = 'NEWS_PAGE',
  PageNotFound = 'NOT_FOUND_PAGE',
  PageForbidden = 'FORBIDDEN_PAGE',
}
