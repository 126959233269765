import qs from 'qs'

import { facadeApi } from 'api'
import { PaginationParams } from 'api/common/types'
import { RoleSource } from 'types/roles/roles'

type Params = PaginationParams<{
  tenantId: string
  search: string
}>

export interface RolesSourcesResponse {
  data: RoleSource[]
}

export const fetchRolesSourcesListApi = ({ tenantId, search, page, itemsPerPage }: Params) =>
  facadeApi.get<RolesSourcesResponse>(`/tenants/${tenantId}/roles/sources`, {
    params: {
      page,
      itemsPerPage,
      filter: {
        ...(!!search && { search }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
