import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse } from 'api/common/types'
import { TenantGroup } from 'types/groups/groups'

export enum GroupsSortBy {
  name = 'name',
  users = 'users',
  roles = 'roles',
}

interface Params {
  tenantId: string
  page?: number
  itemsPerPage?: number
  search?: string
  userId?: string[]
  roleId?: string[]
  sort?: string
}

export type GroupsListResponse = PaginatedResponse<TenantGroup>

export const fetchGroupsListApi = ({ page = 1, itemsPerPage, sort, search, roleId, userId, tenantId }: Params) =>
  facadeApi.get<GroupsListResponse>(`tenants/${tenantId}/groups`, {
    params: {
      page,
      itemsPerPage,
      sort,
      filter: {
        ...(!!search && { search }),
        ...(!!userId?.length && { user_id: userId }),
        ...(!!roleId?.length && { role_id: roleId }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
