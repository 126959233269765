import { MayBeNull } from '@wpp-open/core'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { tableActions } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import {
  hideViewGroupSideModal,
  showViewGroupSideModal,
} from 'pages/admin/members/groups/viewGroupSideModal/ViewGroupSideModal'
import {
  hideViewMemberSideModal,
  showViewMemberSideModal,
} from 'pages/admin/members/viewMemberSideModal/ViewMemberSideModal'
import { showAssignRoleToMembersGroupsSideModal } from 'pages/admin/roles/assignRoleToMembersGroupsSideModal/AssignRoleToMembersGroupsSideModal'
import { hideViewRoleSideModal, showViewRoleSideModal } from 'pages/admin/roles/viewRoleSideModal/ViewRoleSideModal'
import { queryClient } from 'providers/osQueryClient/utils'
import { RoleMember } from 'types/roles/roleMember'

export const handleReloadRoleMembersTable = async () => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.ROLE_MEMBERS] })
  tableActions.reload(TableKey.VIEW_ROLE_MEMBERS)
}

export const handleReloadRoleGroupsTable = async () => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.GROUPS_LIST] })
  tableActions.reload(TableKey.VIEW_ROLE_GROUPS)
}

export const useViewRoleModals = ({
  backButtonText,
  roleId,
  onBack,
}: {
  onBack?: () => void
  backButtonText?: string
  roleId: string
}) => {
  const { t } = useTranslation()

  const backToView = useCallback(
    () => showViewRoleSideModal({ roleId, backButtonText, onBack }),
    [backButtonText, roleId, onBack],
  )

  return useMemo(
    () => ({
      onAddGroup: () =>
        showAssignRoleToMembersGroupsSideModal({
          roleId,
          isGroupAdding: true,
          onOpen: hideViewRoleSideModal,
          onClose: backToView,
        }),
      onAddMember: () =>
        showAssignRoleToMembersGroupsSideModal({
          roleId,
          onOpen: hideViewRoleSideModal,
          onClose: backToView,
        }),
      onViewGroup: (groupId: string) => {
        showViewGroupSideModal({
          groupId,
          onOpen: hideViewRoleSideModal,
          ...(!onBack && {
            backButtonText: t('os.entities.role').toLocaleLowerCase(),
            onBack: () => {
              hideViewGroupSideModal()
              backToView()
            },
          }),
        })
      },
      onViewMember: (member: RoleMember) => {
        showViewMemberSideModal({
          member,
          onOpen: hideViewRoleSideModal,
          ...(!onBack && {
            backButtonText: t('os.entities.role').toLocaleLowerCase(),
            onBack: () => {
              hideViewMemberSideModal()
              backToView()
            },
          }),
        })
      },
    }),
    [backToView, onBack, roleId, t],
  )
}

export const deselectRoleMembers = () => tableActions.deselectAll(TableKey.VIEW_ROLE_MEMBERS)
export const deselectRoleGroups = () => tableActions.deselectAll(TableKey.VIEW_ROLE_GROUPS)

export enum ViewRoleSideModalTabs {
  MEMBERS = 'members',
  GROUPS = 'groups',
  PERMISSIONS = 'permissions',
}

export interface State {
  activeTab: string
  membersCount: MayBeNull<number>
  groupsCount: MayBeNull<number>
}
