import { MayBeNull, ProjectType } from '@wpp-open/core'

export interface Project {
  id: string
  name: string
  description: MayBeNull<string>
  contextWorkspace: MayBeNull<string>
  type: ProjectType
  processType: ProjectProcessType
  startDate: MayBeNull<string>
  endDate: MayBeNull<string>
  createdAt: string
  updatedAt: MayBeNull<string>
  status: ProjectStatus
  ownerEmail: string
  owner: MayBeNull<ProjectMember>
  members: ProjectMember[]
  owners: ProjectMember[]
}

export interface ProjectMember {
  id: string
  projectId: string
  email: string
  role: ProjectMemberRole
  firstname: MayBeNull<string>
  lastname: MayBeNull<string>
  agency: MayBeNull<string>
  jobTitle: MayBeNull<string>
  avatarUrl: MayBeNull<string>
}

export enum ProjectProcessType {
  Fluid = 'FLUID',
  Linear = 'LINEAR',
}

export enum ProjectStatus {
  Completed = 'COMPLETED',
  Active = 'ACTIVE',
  ToDo = 'TO_DO',
  InProgress = 'IN_PROGRESS',
  Archived = 'ARCHIVED',
}

export enum ProjectOwnership {
  All = 'ALL',
  My = 'MY',
  SharedWithMe = 'SHARED_WITH_ME',
  Member = 'MEMBER',
}

export enum ProjectMemberRole {
  Owner = 'OWNER',
  Contributor = 'CONTRIBUTOR',
  Viewer = 'VIEWER',
}
