import { FramedAppMethods, FramedAppParentMethods } from '@wpp-open/core'
import { connectToChild, Methods } from 'penpal'
import { ComponentPropsWithoutRef, useEffect, useRef } from 'react'

import { useAppsContext } from 'providers/apps/AppsContext'

type Props = ComponentPropsWithoutRef<'iframe'> & {
  isFullscreen: boolean
  appId: string
}

export const PenpalParentFrame = ({ isFullscreen, appId, ...iframeProps }: Props) => {
  const { getFullPageAppProps, getToolAppProps } = useAppsContext()
  const ref = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const { osApi, osContext } = isFullscreen
      ? getFullPageAppProps({ stableId: appId })
      : getToolAppProps({ stableId: appId })

    const parentMethods: Methods = {
      osApi: { ...osApi },
    } satisfies FramedAppParentMethods

    const connection = connectToChild<FramedAppMethods>({
      iframe: ref.current!,
      methods: parentMethods,
      debug: false,
    })

    const unsubscribeFn = osContext.subscribe(osContext => {
      connection.promise.then(child => {
        child.receiveOsContext(osContext)
      })
    })

    connection.promise.catch(error => {
      console.error(`Penpal parent context error for app '${appId}':`, error)
    })

    return () => {
      unsubscribeFn()
      connection.destroy()
    }
  }, [appId, getToolAppProps, getFullPageAppProps, isFullscreen])

  return <iframe ref={ref} {...iframeProps} />
}
