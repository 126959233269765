import { WppButton, WppCheckbox, WppTypography } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useApproveTenantAccessRequestsApi } from 'api/accessRequests/mutations/useApproveTenantAccessRequestsApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useDateWithTimeFormat } from 'hooks/useDateWithTimeFormat'
import { useUsernameFormat } from 'hooks/useUsernameFormat'
import styles from 'pages/admin/requests/tenantAccessRequests/approveTenantAccessRequestModal/ApproveTenantAccessRequestModal.module.scss'
import {
  handleReloadTenantAccessRequestsTables,
  isProcessedRequest,
} from 'pages/admin/requests/tenantAccessRequests/utils'
import { UsersInfoCard } from 'pages/admin/requests/usersInfoCard/UsersInfoCard'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { TenantAccessRequest } from 'types/requests/requests'
import { capitalize } from 'utils/common'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { routesManager } from 'utils/routesManager'

interface Props extends NiceModalWrappedProps {
  requests: TenantAccessRequest[]
  getAttachmentUrlByKey?: (key?: string) => string
  onSubmitted?: () => void
}

const ApproveTenantAccessRequestModal = ({
  requests,
  getAttachmentUrlByKey,
  isOpen,
  onSubmitted,
  onClose,
  onCloseComplete,
  id,
}: Props) => {
  const { enqueueToast } = useToast()
  const { t } = useTranslation()
  const { formatUsername } = useUsernameFormat()
  const { formatDate } = useDateWithTimeFormat()
  const [isRedirectChecked, setIsRedirectChecked] = useState(false)
  const navigate = useNavigate()

  const { mutateAsync: handleApproveTenantAccessRequests, isLoading } = useApproveTenantAccessRequestsApi()

  const isSingleRequest = requests.length === 1
  const { firstname, lastname } = requests[0].userData || {}
  const username = formatUsername(firstname, lastname)
  const isExternalUser = requests.some(({ userData }) => userData?.isExternal)

  const handleSubmit = async () => {
    try {
      await handleApproveTenantAccessRequests(requests.map(({ id }) => id))

      isSingleRequest
        ? queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.ACCESS_REQUEST, { id: requests[0].id }] })
        : queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.ACCESS_REQUESTS] })
      handleReloadTenantAccessRequestsTables()

      enqueueToast({
        type: 'success',
        message: capitalize(
          isSingleRequest
            ? t('os.requests.tenant_access_requests.toasts.approve')
            : t('os.requests.tenant_access_requests.toasts.approve_other', { count: requests.length }),
        ),
      })

      isRedirectChecked &&
        isSingleRequest &&
        navigate(routesManager.admin.members.root.url(), { state: { member: requests[0].userData } })
    } catch (error) {
      if (isProcessedRequest(error)) {
        enqueueToast({
          message: capitalize(t('os.common.errors.processed_request')),
          type: 'error',
        })
      } else {
        enqueueToast({
          message: capitalize(t('os.common.errors.general')),
          type: 'error',
        })
      }
    } finally {
      onSubmitted?.()
      onClose()
    }
  }

  const title = capitalize([t('os.requests.actions.approve'), t('os.requests.request_types.os_access')].join(' '))

  return (
    <Modal open={isOpen} onWppModalClose={onClose} onWppModalCloseComplete={onCloseComplete} size="m" data-testid={id}>
      <WppTypography type="xl-heading" slot="header">
        {title}
      </WppTypography>
      <Flex slot="body" direction="column" gap={16}>
        {isExternalUser && (
          <WppTypography type="s-strong" className={styles.textBody}>
            {t('os.requests.actions_modal.description_external')}
          </WppTypography>
        )}

        <WppTypography type="s-body" className={styles.textBody}>
          <Trans
            i18nKey="os.requests.actions_modal.description"
            values={{
              action: t('os.requests.actions.approve'),
              requestType: t('os.requests.request_types.os_access'),
              username: isSingleRequest ? username : t('os.entities.members_count', { count: requests.length }),
            }}
            components={[
              <b key="0">action</b>,
              <b key="1">requestType</b>,
              <b className={styles.bold} key="2">
                username
              </b>,
            ]}
          />
        </WppTypography>

        {!!getAttachmentUrlByKey && isSingleRequest && (
          <UsersInfoCard
            request={requests[0]}
            getAttachmentUrlByKey={getAttachmentUrlByKey}
            date={formatDate(requests[0].createdAt)}
          />
        )}
        {isSingleRequest && (
          <WppCheckbox
            labelConfig={{
              text: t('os.requests.redirect_checkbox_label'),
            }}
            required
            checked={isRedirectChecked}
            name="redirectToMemberDetails"
            onWppChange={({ detail: { checked } }) => setIsRedirectChecked(checked)}
            data-testid="redirect-checkbox"
          />
        )}
      </Flex>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
          {t('os.common.cancel')}
        </WppButton>
        <WppButton variant="primary" type="submit" loading={isLoading} onClick={handleSubmit} data-testid="apply">
          {capitalize(t('os.requests.actions.approve'))}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showApproveTenantAccessRequestModal } = createNiceModal(
  ApproveTenantAccessRequestModal,
  'approve-tenant-access-request-modal',
)
