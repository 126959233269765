import {
  WppButton,
  WppIconChevron,
  WppSkeleton,
  WppTab,
  WppTabs,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import { useRoleApi } from 'api/roles/queries/useRoleApi'
import { Flex } from 'components/common/flex/Flex'
import { RenderErrorBoundary, RenderErrorType } from 'components/renderError'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'
import { useNumberFormat } from 'hooks/useNumberFormat'
import { useAssignRoleToMembersGroupsSideModal } from 'pages/admin/roles/assignRoleToMembersGroupsSideModal/AssignRoleToMembersGroupsSideModal'
import { useIsTenantAdmin } from 'pages/admin/roles/utils'
import { RoleGroupsSection } from 'pages/admin/roles/viewRoleSideModal/roleGroupsSection/RoleGroupsSection'
import { RoleMembersSection } from 'pages/admin/roles/viewRoleSideModal/roleMembersSection/RoleMembersSection'
import { RolePermissionsSection } from 'pages/admin/roles/viewRoleSideModal/rolePermissionsSection/RolePermissionsSection'
import { State, useViewRoleModals, ViewRoleSideModalTabs } from 'pages/admin/roles/viewRoleSideModal/utils'
import styles from 'pages/admin/roles/viewRoleSideModal/ViewRoleSideModal.module.scss'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { isRoleCreatedOnRoot } from 'utils/roles'

interface Props extends NiceModalWrappedProps {
  backButtonText?: string
  roleId: string
  onBack?: () => void
}

const SideModalSkeleton = () => (
  <>
    <WppSkeleton height={28} width="90%" />
    <WppSkeleton height={22} width="70%" />
  </>
)

const ViewRoleSideModal = ({ isOpen, onClose, roleId, onBack, backButtonText, onCloseComplete, id }: Props) => {
  const { t } = useTranslation()
  const { formatNumber } = useNumberFormat()
  const { hasPermission } = useHasPermission()
  const isTenantAdmin = useIsTenantAdmin()
  const hasAzRolePermissionsListPermission = hasPermission(Permission.AZ_ROLE_PERMISSIONS_LIST)

  const { data: role, isLoading: isRoleLoading } = useRoleApi({
    params: { roleId },
    enabled: isOpen,
  })
  const { onViewMember, onViewGroup, onAddGroup, onAddMember } = useViewRoleModals({
    backButtonText,
    roleId,
    onBack,
  })

  const [{ membersCount, groupsCount, activeTab }, setState] = useSetState<State>({
    membersCount: null,
    groupsCount: null,
    activeTab: isTenantAdmin ? ViewRoleSideModalTabs.MEMBERS : ViewRoleSideModalTabs.GROUPS,
  })

  return (
    <SideModal
      size="xl"
      open={isOpen}
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={onCloseComplete}
      data-testid={id}
    >
      <WppTypography slot="header" type="2xl-heading">
        {t('os.roles.view_role.header')}
      </WppTypography>

      <Flex slot="body" direction="column" className={styles.fullHeight} gap={24}>
        <>
          <Flex direction="column" gap={4}>
            {!role || isRoleLoading ? (
              <SideModalSkeleton />
            ) : (
              <>
                <WppTypography type="l-strong" data-testid="role-name">
                  {role.name}
                </WppTypography>
                <WppTypography type="s-body" data-testid="role-description">
                  {role.description}
                </WppTypography>
                <WppTypography type="l-strong">{t('os.roles.view_role.os_application')}</WppTypography>
                <WppTypography type="s-body">
                  {isRoleCreatedOnRoot(role.available_only_in_account_name)
                    ? t('os.common.navigation.os')
                    : role.available_only_in_account_name}
                </WppTypography>
              </>
            )}
          </Flex>
          <WppTabs value={activeTab} onWppChange={({ detail }) => setState({ activeTab: detail.value })}>
            {isTenantAdmin && (
              <WppTab value={ViewRoleSideModalTabs.MEMBERS} data-testid="members-tab">
                {`${t('os.roles.view_role.tabs.members')} ${
                  membersCount === null ? '' : ' (' + formatNumber(membersCount) + ')'
                }`}
              </WppTab>
            )}
            <WppTab value={ViewRoleSideModalTabs.GROUPS}>
              {`${t('os.roles.view_role.tabs.groups')} ${
                groupsCount === null ? '' : ' (' + formatNumber(groupsCount) + ')'
              }`}
            </WppTab>
            {hasAzRolePermissionsListPermission && (
              <WppTab value={ViewRoleSideModalTabs.PERMISSIONS}>{t('os.roles.view_role.tabs.permissions')}</WppTab>
            )}
          </WppTabs>
          {!!role && (
            <RenderErrorBoundary catchErrors={[RenderErrorType.DataIsNotAvailable]}>
              <Flex
                className={clsx(styles.tab, {
                  [styles.hiddenSection]: activeTab !== ViewRoleSideModalTabs.GROUPS,
                })}
                direction="column"
              >
                <RoleGroupsSection
                  role={role}
                  onAddGroup={onAddGroup}
                  onViewGroup={onViewGroup}
                  onData={groupsCount => setState({ groupsCount })}
                />
              </Flex>

              {isTenantAdmin && (
                <Flex
                  className={clsx(styles.tab, {
                    [styles.hiddenSection]: activeTab !== ViewRoleSideModalTabs.MEMBERS,
                  })}
                  direction="column"
                >
                  <RoleMembersSection
                    role={role}
                    onAddMember={onAddMember}
                    onViewMember={onViewMember}
                    onData={membersCount => setState({ membersCount })}
                  />
                </Flex>
              )}

              {hasAzRolePermissionsListPermission && (
                <Flex
                  className={clsx(styles.tab, {
                    [styles.hiddenSection]: activeTab !== ViewRoleSideModalTabs.PERMISSIONS,
                  })}
                  direction="column"
                  data-testid="permissions_descriptions-list"
                >
                  <RolePermissionsSection role={role} />
                </Flex>
              )}
            </RenderErrorBoundary>
          )}
        </>
      </Flex>

      <Flex slot="actions" justify="end" align="center" gap={12}>
        {onBack && (
          <WppButton size="m" variant="secondary" onClick={onBack}>
            <WppIconChevron direction="left" slot="icon-start" />
            {t('os.common.back_to', { entity: backButtonText })}
          </WppButton>
        )}
        <WppButton size="m" variant="primary" onClick={onClose}>
          {t('os.common.done')}
        </WppButton>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showViewRoleSideModal, hideModal: hideViewRoleSideModal } = createNiceModal(
  ViewRoleSideModal,
  'view-role-side-modal',
  [useAssignRoleToMembersGroupsSideModal],
)
