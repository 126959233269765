import {
  WppActionButton,
  WppButton,
  WppDivider,
  WppIconChevron,
  WppIconTrash,
  WppTab,
  WppTabs,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useGroupApi } from 'api/groups/queries/useGroupApi'
import { Flex } from 'components/common/flex/Flex'
import { RenderErrorBoundary, RenderErrorType } from 'components/renderError'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useHierarchyAccountData } from 'pages/admin/hierarchy/hierarchySettings/utils'
import { useAddMembersToGroupSideModal } from 'pages/admin/members/addMembersToGroupSideModal/AddMembersToGroupSideModal'
import { showDeleteGroupModal } from 'pages/admin/members/groups/deleteGroupModal/DeleteGroupModal'
import { ExecutionContext, useViewGroupModals } from 'pages/admin/members/groups/viewGroupSideModal/useViewGroupModals'
import { GROUP_DETAIL } from 'pages/admin/members/groups/viewGroupSideModal/utils'
import { ViewGroupMembersSection } from 'pages/admin/members/groups/viewGroupSideModal/viewGroupMembersSection/ViewGroupMembersSection'
import { ViewGroupRolesSection } from 'pages/admin/members/groups/viewGroupSideModal/viewGroupRolesSection/ViewGroupRolesSection'
import styles from 'pages/admin/members/groups/viewGroupSideModal/ViewGroupSideModal.module.scss'
import { handleReloadMembersTable } from 'pages/admin/members/membersList/membersTable/utils'
import { useSelectRoleSideModal } from 'pages/admin/members/selectRoleSideModal/SelectRoleSideModal'
import { queryClient } from 'providers/osQueryClient/utils'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

export interface Props extends NiceModalWrappedProps {
  groupId: string
  backButtonText?: string
  shouldBackToEdit?: boolean
  onBack?: () => void
  onDelete?: (groupId: string) => void
  preOpenedTab?: GROUP_DETAIL
}

const ViewGroupSideModal = ({
  onClose,
  onCloseComplete,
  isOpen,
  groupId,
  backButtonText,
  id,
  onBack,
  onDelete,
  preOpenedTab,
}: Props) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState<GROUP_DETAIL>(preOpenedTab || GROUP_DETAIL.ROLES)
  const [rolesCount, setRolesCount] = useState(0)
  const [membersCount, setMembersCount] = useState(0)

  const [membersSearch, setMembersSearch] = useState('')
  const [rolesSearch, setRolesSearch] = useState('')
  const { data: group } = useGroupApi({
    params: { groupId },
    enabled: isOpen,
  })

  const { onEdit, onAddRole, onAddMember, onViewMember, onViewRole } = useViewGroupModals({
    executionContext: ExecutionContext.VIEW,
    groupId,
    backButtonText,
    group: group!,
    onBack,
  })
  const { getHierarchyAccountData } = useHierarchyAccountData()

  // TODO: move to appropriate place
  const onDeleteUserFromGroup = async () => {
    /* Invalidate user groups when delete performed in members table context */
    if (!onEdit) {
      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AZ_USER_BY_EMAIL] })
      // Default view
      await handleReloadMembersTable()
    }
  }

  return (
    <SideModal
      size="xl"
      open={isOpen}
      onWppSideModalCloseComplete={onCloseComplete}
      onWppSideModalClose={onClose}
      data-testid={id}
    >
      <WppTypography slot="header" type="2xl-heading">
        {t('os.groups.view_group.header')}
      </WppTypography>

      {!!group && (
        <Flex slot="body" direction="column" className={styles.modalBody}>
          <Flex direction="column" gap={4}>
            <WppTypography type="l-strong" data-testid="group-name">
              {group.name}
            </WppTypography>

            {!!group.description && (
              <WppTypography type="s-body" tag="p" data-testid="group-description" className={styles.description}>
                {group.description}
              </WppTypography>
            )}

            <WppTypography type="s-strong" data-testid="group-hierarchy">
              {`${t('os.groups.manage_group.hierarchy')}: ${getHierarchyAccountData(group.account_uid).title}`}
            </WppTypography>
          </Flex>

          <Flex className={styles.tabsWrapper}>
            <WppTabs
              size="m"
              value={activeTab}
              className={styles.tabs}
              onWppChange={event => setActiveTab(event.detail.value as GROUP_DETAIL)}
              data-testid="view-group-tabs"
            >
              <WppTab className={styles.tab} value={GROUP_DETAIL.ROLES} counter={rolesCount} data-testid="roles-tab">
                {t('os.groups.view_group.tabs.roles')}
              </WppTab>
              <WppTab
                className={styles.tab}
                value={GROUP_DETAIL.MEMBERS}
                counter={membersCount}
                data-testid="members-tab"
              >
                {t('os.groups.view_group.tabs.members')}
              </WppTab>
            </WppTabs>
            <WppDivider className={styles.tabsDivider} />
          </Flex>

          <RenderErrorBoundary catchErrors={[RenderErrorType.DataIsNotAvailable]}>
            {activeTab === GROUP_DETAIL.ROLES && (
              <ViewGroupRolesSection
                className={styles.tableSection}
                group={group!}
                onAddRole={onAddRole}
                onOpenViewRoleModal={onViewRole}
                setMembersSearch={setMembersSearch}
                rolesSearch={rolesSearch}
                setRolesSearch={setRolesSearch}
                onRolesLoad={setRolesCount}
              />
            )}

            {activeTab === GROUP_DETAIL.MEMBERS && (
              <ViewGroupMembersSection
                className={styles.tableSection}
                group={group!}
                onViewMember={onViewMember}
                onAddMember={onAddMember}
                onDeleteMember={onDeleteUserFromGroup}
                membersSearch={membersSearch}
                setMembersSearch={setMembersSearch}
                setRolesSearch={setRolesSearch}
                onMembersLoad={setMembersCount}
              />
            )}
          </RenderErrorBoundary>
        </Flex>
      )}
      <Flex slot="actions" justify="between" align="center">
        <WppActionButton
          variant="destructive"
          onClick={() => {
            showDeleteGroupModal({
              group: group!,
              onDelete: groupId => {
                if (onDelete) {
                  onDelete(groupId)
                }
                onClose()
              },
            })
          }}
        >
          <WppIconTrash slot="icon-start" />
          {t('os.groups.delete.submit')}
        </WppActionButton>
        <Flex gap={12}>
          {onBack ? (
            <WppButton size="m" variant="secondary" onClick={onBack}>
              <WppIconChevron direction="left" slot="icon-start" />
              {t('os.common.back_to', { entity: backButtonText })}
            </WppButton>
          ) : (
            <WppButton
              size="m"
              variant="secondary"
              onClick={() => {
                setMembersSearch('')
                setRolesSearch('')
                onEdit?.()
              }}
            >
              {t('os.common.edit')}
            </WppButton>
          )}

          <WppButton size="m" variant="primary" onClick={onClose}>
            {t('os.common.done')}
          </WppButton>
        </Flex>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showViewGroupSideModal, hideModal: hideViewGroupSideModal } = createNiceModal(
  ViewGroupSideModal,
  'view-group-side-modal',
  [useAddMembersToGroupSideModal, useSelectRoleSideModal],
)
