import { ListValue, LoadMoreHandler, SelectTypes } from '@platform-ui-kit/components-library'
import { WppListItem } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useInfiniteTenantRolesApi } from 'api/roles/infiniteQueries/useInfiniteTenantRolesApi'
import { FormSelect, FormSelectProps } from 'components/form/formSelect//FormSelect'
import { AutocompleteInfinite } from 'constants/autocomplete'
import { Delay } from 'constants/delay'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useStableCallback } from 'hooks/useStableCallback'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { TenantRole } from 'types/roles/tenantRole'

type Props<V extends ListValue> = Omit<FormSelectProps<TenantRole, V>, 'options'> & {
  accountId?: string
  onSearchChange?: (arg: string) => void
  type?: SelectTypes
  onSelectNone?: () => void
}

export const FormRolesSelect = <V extends ListValue>({
  accountId,
  type = 'single',
  onSelectNone,
  ...rest
}: Props<V>) => {
  const { currentTenant } = useCurrentTenantData()
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const { data, hasNextPage, fetchNextPage, isLoading } = useInfiniteTenantRolesApi({
    initialPageParam: {
      page: 1,
    },
    params: {
      search,
      itemsPerPage: AutocompleteInfinite.PageSize,
      tenantId: currentTenant.id,
      sort: 'name', // default sort
      ...(!!accountId && { accountId: [accountId] }),
    },
    staleTime: AutocompleteInfinite.StaleTime,
  })

  const setSearchDebounced = useDebounceFn((searchVal: string) => setSearch(searchVal.trim()), Delay.Search)

  const handleLoadMore: LoadMoreHandler = useStableCallback(
    () =>
      new Promise(resolve => {
        fetchNextPage().then(({ isFetched }) => {
          if (isFetched) {
            resolve()
          }
        })
      }),
  )

  const noneOption = () => {
    if (type === 'single' && !isLoading) {
      return (
        <WppListItem key="noValue" onWppChangeListItem={onSelectNone}>
          <span slot="label">{t('os.common.selects.none')}</span>
        </WppListItem>
      )
    }
  }

  return (
    <FormSelect
      {...rest}
      infinite
      infiniteLastPage={!hasNextPage}
      loadMore={handleLoadMore}
      options={data}
      actionOption={noneOption()}
      type={type}
      withFolder
      withSearch
      search={search}
      onWppSearchValueChange={search => setSearchDebounced(search.detail)}
      getOptionLabel={({ name }) => name}
      loading={isLoading}
      showSelectAllText={false}
    />
  )
}
