import { WppTypography, WppActionButton, WppIconExternalLink } from '@platform-ui-kit/components-library-react'
import { NavigationTree } from '@wpp-open/core'
import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { NavigationMenuColumnHeader } from 'components/navigationMenu/navigationMenuContent/navigationMenuColumnHeader/NavigationMenuColumnHeader'
import styles from 'components/navigationMenu/navigationMenuContent/NavigationMenuContent.module.scss'
import { NavigationMenuTreeListColumn } from 'components/navigationMenu/navigationMenuContent/navigationMenuTreeListColumn/NavigationMenuTreeListColumn'
import { SearchableTreeList } from 'components/treeList/searchableTreeList/SearchableTreeList'
import { TreeListItem } from 'components/treeList/treeListItem/TreeListItem'
import { DEFAULT_PLURAL_COUNT } from 'constants/i18n'
import { useHeaderNavigation } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { PointerNode } from 'utils/mapping/common'
import { getProjectsPointerNodes } from 'utils/mapping/navigationTree'
import { routesManager } from 'utils/routesManager'

export const ProjectsNavigationMenuColumn = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { closeNavigation } = useOsState()
  const { nodesMapping, activeNodeId } = useHeaderNavigation()

  const {
    navigationTree,
    currentTenant: { name: currentTenantName, id: currentTenantId },
  } = useCurrentTenantData()

  const projectsPointerNodes = useMemo(() => getProjectsPointerNodes(nodesMapping), [nodesMapping])

  const renderNode = ({ nodeId }: PointerNode<NavigationTree>) => {
    const { id, name, tenant } = navigationTree.projectsMapping[nodeId]
    const isInternal = currentTenantId === tenant.id

    const href = isInternal
      ? routesManager.systemApps.orchestration.project(id)
      : tenant.homeUrl + routesManager.systemApps.orchestration.project(id)

    return (
      <TreeListItem
        checked={nodeId === activeNodeId}
        onClick={e => {
          if (!e.currentTarget.checked) {
            window.open(href, isInternal ? '_self' : '_blank')
            closeNavigation()
          }
        }}
      >
        <WppTypography type="s-body" slot="label">
          {name}
        </WppTypography>
        <Flex align="center" slot="caption" gap={4}>
          <WppTypography type="xs-body" className={styles.itemCaptionTypography}>
            {isInternal ? currentTenantName : tenant.name}
          </WppTypography>
          {!isInternal && <WppIconExternalLink height={16} data-testid="external-link-icon" />}
        </Flex>
      </TreeListItem>
    )
  }

  const viewAllProjects = useCallback(() => {
    navigate(routesManager.systemApps.orchestration.projects())
    closeNavigation()
  }, [navigate, closeNavigation])

  const label = t('os.entities.project', { count: DEFAULT_PLURAL_COUNT })

  return (
    <NavigationMenuTreeListColumn data-testid="projects-navigation-menu-column">
      <NavigationMenuColumnHeader>
        <Flex justify="between" align="center">
          <WppTypography type="l-strong" data-testid="projects-navigation-menu-column-title">
            {label}
          </WppTypography>
          <WppActionButton
            onClick={viewAllProjects}
            variant="secondary"
            data-testid="projects-navigation-menu-column-view-all-button"
          >
            {t('os.common.view_all')}
          </WppActionButton>
        </Flex>
      </NavigationMenuColumnHeader>

      <SearchableTreeList
        pointerNodes={projectsPointerNodes}
        emptyState={
          <WppTypography type="s-midi" data-testid="no-projects-label">
            {t('os.navigation_menu.no_projects')}
          </WppTypography>
        }
        inputPlaceholder={t('os.navigation_menu.search_placeholder', {
          entity: label.toLocaleLowerCase(),
        })}
        renderNode={renderNode}
      />
    </NavigationMenuTreeListColumn>
  )
}
