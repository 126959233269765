import { AppContextUnsubscribeFn, AppContextListener, OpenCompactAppHandler, OsContextApi } from '@wpp-open/core'
import { useCallback, useMemo, useRef } from 'react'

import { MICRO_APP_DOM_ELEMENT_ID } from 'constants/apps'
import { AppsContextValue } from 'providers/apps/AppsContext'
import { useOpenApp, useTriggerError } from 'providers/apps/utils/apiHandlers'
import { useAuth } from 'providers/auth/AuthContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { BaseAppConfig } from 'types/apps/apps'
import { CompactAppCustomProps, FullscreenAppCustomProps } from 'types/common/singleSpa'
import { trackAnalytics } from 'utils/analytics'

export const useGetAppProps = ({
  handleSubscribeToFullPageContext,
  handleSubscribeToToolContext,
  openCompactApp,
}: {
  handleSubscribeToFullPageContext: (appId: string, listener: AppContextListener) => AppContextUnsubscribeFn
  handleSubscribeToToolContext: (appId: string, listener: AppContextListener) => AppContextUnsubscribeFn
  openCompactApp: OpenCompactAppHandler
}): Pick<AppsContextValue, 'getFullPageAppProps' | 'getToolAppProps'> => {
  const { jwt } = useAuth()
  const { openNavigation } = useOsState()
  const openApp = useOpenApp()
  const triggerError = useTriggerError()

  const tokenRef = useRef(jwt)
  tokenRef.current = jwt

  const getAccessToken = useCallback(() => tokenRef.current, [])

  const osApi: OsContextApi = useMemo(
    () => ({
      getAccessToken,
      analytics: {
        track: trackAnalytics,
      },
      navigation: {
        openMenu: openNavigation,
        openApp,
        openCompactApp,
        triggerError,
      },
    }),
    [getAccessToken, openApp, openCompactApp, openNavigation, triggerError],
  )

  const getFullPageAppProps = useCallback(
    ({ stableId }: Pick<BaseAppConfig, 'stableId'>): FullscreenAppCustomProps => ({
      domElementGetter: () => document.getElementById(MICRO_APP_DOM_ELEMENT_ID)!,
      osApi,
      osContext: {
        subscribe: listener => handleSubscribeToFullPageContext(stableId, listener),
      },
    }),
    [handleSubscribeToFullPageContext, osApi],
  )

  const getToolAppProps = useCallback(
    ({ stableId }: Pick<BaseAppConfig, 'stableId'>): CompactAppCustomProps => ({
      osApi,
      osContext: {
        subscribe: listener => handleSubscribeToToolContext(stableId, listener),
      },
    }),
    [handleSubscribeToToolContext, osApi],
  )

  return {
    getFullPageAppProps,
    getToolAppProps,
  }
}
