import { TreeType } from '@platform-ui-kit/components-library'
import { WppInput, WppToggle, WppTree, WppTypography } from '@platform-ui-kit/components-library-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import { FormTreeSkeleton } from 'components/form/formTree/FormTree'
import { SvgNoSearchResults } from 'components/svg/SvgNoSearchResults'
import { Delay } from 'constants/delay'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { searchTree } from 'pages/admin/members/viewMemberSideModal/viewMemberAccessSection/utils'
import styles from 'pages/admin/members/viewMemberSideModal/viewMemberAccessSection/ViewMemberAccessSection.module.scss'

interface State {
  treeData?: TreeType[]
  onlyAccessible: boolean
  isCriticalError: boolean
  searchValue?: string
}

const initialState: State = {
  isCriticalError: false,
  treeData: [],
  onlyAccessible: false,
}

interface Props {
  memberAccessTree?: TreeType[]
  isFetchingMemberAccessTree: boolean
}

export const ViewMemberAccessSection = ({ memberAccessTree, isFetchingMemberAccessTree }: Props) => {
  const { t } = useTranslation()
  const [{ onlyAccessible, treeData, searchValue }, setState] = useSetState<State>({
    ...initialState,
    treeData: memberAccessTree,
  })

  const setSearchDebounced = useDebounceFn((search?: string) => {
    setState({
      treeData:
        search || onlyAccessible ? searchTree(memberAccessTree!, onlyAccessible, search?.trim()) : memberAccessTree,
      searchValue: search,
    })
  }, Delay.Search)

  useEffect(() => {
    setState({
      treeData: memberAccessTree,
    })
  }, [memberAccessTree, setState])

  if (isFetchingMemberAccessTree) {
    return (
      <Flex className={styles.loader} justify="start" align="start">
        <FormTreeSkeleton />
      </Flex>
    )
  }

  return (
    <Flex className={styles.root} direction="column" gap={12}>
      {memberAccessTree?.length ? (
        <Flex gap={12} align="center">
          <WppInput
            size="s"
            type="search"
            className={styles.searchInput}
            onWppChange={({ detail }) => setSearchDebounced(detail.value)}
            placeholder={t('os.members_list.view_member.access.search.placeholder')}
            data-testid="navigation-search-input"
          />
          <WppToggle
            onWppChange={e => {
              setState({ onlyAccessible: e.detail.checked })
              setSearchDebounced(searchValue)
            }}
            labelConfig={{ text: t('os.members_list.view_member.access.search.assigned') }}
            required
          />
        </Flex>
      ) : null}
      {treeData?.length ? (
        <WppTree
          className={styles.tree}
          data={treeData}
          onWppChange={({ detail }) => {
            setState({
              treeData: detail.treeState,
            })
          }}
        />
      ) : (
        <Flex align="center" justify="center" direction="column" data-testid="no-results">
          <SvgNoSearchResults />
          <WppTypography type="m-strong">
            {t(
              searchValue
                ? 'os.members_list.view_member.access.emptySearch.main'
                : 'os.members_list.view_member.access.empty',
              { search: searchValue },
            )}
          </WppTypography>
          {searchValue && (
            <WppTypography type="s-body">{t('os.members_list.view_member.access.emptySearch.subtitle')}</WppTypography>
          )}
        </Flex>
      )}
    </Flex>
  )
}
