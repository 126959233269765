import { WppTypography, WppIconWarning, WppButton } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  onSave: () => void
}

export const AddToExternalGroupConfirmationModal = ({ isOpen, onClose, onSave, onCloseComplete, id }: Props) => {
  const { t } = useTranslation()

  const [{ loading: isSubmitting }, onAdd] = useAsyncFn(async () => {
    await onSave()
    onClose()
  }, [onClose, onSave])

  return (
    <Modal data-testid={id} open={isOpen} onWppModalClose={onClose} onWppModalCloseComplete={onCloseComplete}>
      <Flex slot="header" align="center" gap={9}>
        <WppIconWarning />
        <WppTypography type="xl-heading">{t('os.members_list.confirmation_external_group.title')}</WppTypography>
      </Flex>
      <WppTypography slot="body" type="s-body">
        {t('os.members_list.confirmation_external_group.text')}
      </WppTypography>
      <Flex slot="actions" justify="end" gap={12}>
        <WppButton variant="secondary" size="s" onClick={onClose} data-testid="cancel">
          {t('os.common.cancel')}
        </WppButton>
        <WppButton size="s" onClick={onAdd} loading={isSubmitting} data-testid="apply">
          {t('os.common.add')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showAddToExternalGroupConfirmationModal } = createNiceModal(
  AddToExternalGroupConfirmationModal,
  'add-to-external-group-confirmation-modal',
)
