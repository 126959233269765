import { Dispatch, SetStateAction, createContext, useContext } from 'react'

import { BaseAppConfig } from 'types/apps/apps'
import { CompactAppCustomProps, FullscreenAppCustomProps } from 'types/common/singleSpa'

export interface AppsContextValue {
  isFullscreenMicroAppActive: boolean
  setIsFullscreenMicroAppActive: Dispatch<SetStateAction<boolean>>
  getFullPageAppProps: (app: Pick<BaseAppConfig, 'stableId'>) => FullscreenAppCustomProps
  getToolAppProps: (app: Pick<BaseAppConfig, 'stableId'>) => CompactAppCustomProps
}

export const AppsContext = createContext<AppsContextValue>(null!)

export const useAppsContext = () => useContext(AppsContext)
