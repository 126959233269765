import { WppTypography, WppIconWarning, WppButton } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { useUpdateGroupsUsersApi } from 'api/groups/mutations/useUpdateGroupsUsersApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useStableCallback } from 'hooks/useStableCallback'
import styles from 'pages/admin/members/deleteGroupMemberModal/DeleteGroupMemberModal.module.scss'
import { handleReloadGroupsListTable } from 'pages/admin/members/groups/utils'
import { handleReloadGroupMembersTable } from 'pages/admin/members/groups/viewGroupSideModal/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { AzGroup } from 'types/groups/groups'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  member: { fullName: string; id: string; email: string }
  groups: AzGroup[]
  onDeleteMember: () => Promise<void>
}

export const DeleteGroupsMemberModal = ({
  isOpen,
  onClose,
  member,
  groups,
  onDeleteMember,
  onCloseComplete,
  id,
}: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleUpdateGroupsUsers, isLoading } = useUpdateGroupsUsersApi()

  const onDelete = useStableCallback(async () => {
    try {
      await handleUpdateGroupsUsers({
        create: [],
        delete: groups.map(({ uid }) => ({ userId: member.email, groupId: uid })),
      })
      await handleReloadGroupsListTable()
      await handleReloadGroupMembersTable()
      await onDeleteMember()
      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.GROUP_USERS] })

      enqueueToast({
        message: t('os.common.toasts.delete_from', {
          entity: t('os.entities.member').toLocaleLowerCase(),
          is: t('os.entities.is'),
          deleteFrom: groups.length > 1 ? t('os.entities.groups') : t('os.entities.group'),
        }),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('os.common.errors.error'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  })

  return (
    <Modal
      data-testid={id}
      open={isOpen}
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      disableOutsideClick={isLoading}
    >
      <Flex slot="header" align="center" gap={9}>
        <WppIconWarning />
        <WppTypography type="xl-heading">
          {t('os.members_list.view_member.groups.bulk_delete_modal.title')}
        </WppTypography>
      </Flex>

      <WppTypography slot="body" type="s-body">
        <Trans
          i18nKey="os.members_list.view_member.groups.bulk_delete_modal.description"
          values={{
            userName: member.fullName,
          }}
          components={[
            <b key="0" className={styles.bold}>
              userName
            </b>,
          ]}
        />
      </WppTypography>

      <Flex slot="actions" justify="end" gap={12}>
        <WppButton variant="secondary" size="s" onClick={onClose} data-testid="cancel">
          {t('os.common.cancel')}
        </WppButton>
        <WppButton size="s" variant="destructive" loading={isLoading} onClick={() => onDelete()} data-testid="apply">
          {t('os.common.remove')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showDeleteGroupsMemberModal } = createNiceModal(
  DeleteGroupsMemberModal,
  'delete-groups-member-modal',
)
