import { RowClickedEvent } from 'ag-grid-community'
import { useCallback } from 'react'

import { useFetchTenantAccessRequestsListApi } from 'api/accessRequests/queryFetchers/useFetchAccessRequestsListApi'
import { TableInfiniteLoader, tableActions } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { RequestsType } from 'constants/requests'
import { TableDefaults, TableKey } from 'constants/table'
import { useUserDetailsAttachments } from 'hooks/attachments/useUserDetailsAttachments'
import { useLatestDistinct } from 'hooks/useLatestDistinct'
import { useStableCallback } from 'hooks/useStableCallback'
import {
  hideRequestDetailsSideModal,
  showRequestDetailsSideModal,
} from 'pages/admin/requests/requestDetailsSideModal/RequestDetailsSideModal'
import { showApproveTenantAccessRequestModal } from 'pages/admin/requests/tenantAccessRequests/approveTenantAccessRequestModal/ApproveTenantAccessRequestModal'
import { showRejectTenantAccessRequestModal } from 'pages/admin/requests/tenantAccessRequests/rejectTenantAccessRequestModal/RejectTenantAccessRequestModal'
import { AccessRequestStatus } from 'pages/admin/requests/utils'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { queryClient } from 'providers/osQueryClient/utils'
import { TenantAccessRequest } from 'types/requests/requests'
import { excludeFalsy } from 'utils/common'
import { hasClosestInteractiveElement } from 'utils/dom'

interface LoaderParams {
  search?: string
  status: AccessRequestStatus[]
}

export const useTenantAccessRequestsLoader = ({ search, status }: LoaderParams) => {
  const { currentTenant } = useCurrentTenantData()

  const statusStable = useLatestDistinct(status)

  const { handleLoadAttachments, getAttachmentUrlByKey } = useUserDetailsAttachments({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const handleFetchAccessRequestsList = useFetchTenantAccessRequestsListApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const loader: TableInfiniteLoader<TenantAccessRequest> = useCallback(
    async ({ sortModel, startRow, endRow }) => {
      const [{ colId, sort }] = sortModel
      const itemsPerPage = endRow - startRow

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchAccessRequestsList({
        tenantId: currentTenant.id,
        search,
        status: statusStable,
        itemsPerPage,
        page: endRow / itemsPerPage,
        sort: sort === 'asc' ? colId : `-${colId}`,
      })

      const avatarsKeys = data.map(({ userData }) => userData?.avatarThumbnail?.key).filter(excludeFalsy)
      await handleLoadAttachments(avatarsKeys)

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [currentTenant.id, handleFetchAccessRequestsList, handleLoadAttachments, search, statusStable],
  )

  return { loader, getAttachmentUrlByKey }
}

export const handleReloadTenantAccessRequestsTables = async () => {
  await Promise.all([
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.ACCESS_REQUESTS] }),
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.ACCESS_REQUESTS_COUNT] }),
  ])
  tableActions.reload([TableKey.TENANT_ACCESS_PENDING_REQUESTS, TableKey.TENANT_ACCESS_COMPLETED_REQUESTS])
  tableActions.deselectAll(TableKey.TENANT_ACCESS_PENDING_REQUESTS)
}

export const isProcessedRequest = (error: unknown) =>
  (error as any)?.response?.data?.detail === "You can't change final status of access request"

export const useOnRowClick = (getAttachmentUrlByKey: (key?: string) => string) => {
  return useStableCallback(({ event, data }: RowClickedEvent<TenantAccessRequest>) => {
    if (!hasClosestInteractiveElement(event?.target as HTMLElement, ['[col-id=actions]', '[col-id=select]'])) {
      showRequestDetailsSideModal({
        requests: [data!],
        type: RequestsType.TENANT_ACCESS,
        getAttachmentUrlByKey,
        handleApprove: () => {
          showApproveTenantAccessRequestModal({
            requests: [data!],
            getAttachmentUrlByKey,
            onSubmitted: () => {
              hideRequestDetailsSideModal()
            },
          })
        },
        handleReject: () => {
          showRejectTenantAccessRequestModal({
            requests: [data!],
            getAttachmentUrlByKey,
            onSubmitted: () => {
              hideRequestDetailsSideModal()
            },
          })
        },
      })
    }
  })
}
