import { ListValue, LoadMoreHandler, SelectTypes } from '@platform-ui-kit/components-library'
import { WppListItem } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useInfiniteGroupsListApi } from 'api/groups/infiniteQueries/useInfiniteGroupsListApi'
import { FormSelect, FormSelectProps } from 'components/form/formSelect//FormSelect'
import { AutocompleteInfinite } from 'constants/autocomplete'
import { Delay } from 'constants/delay'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useStableCallback } from 'hooks/useStableCallback'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { TenantGroup } from 'types/groups/groups'

type Props<V extends ListValue> = Omit<FormSelectProps<TenantGroup, V>, 'options'> & {
  type?: SelectTypes
  onSelectNone?: () => void
  isOptionDisabled?: (group: TenantGroup) => boolean
}

export const FormGroupsSelect = <V extends ListValue>({
  type = 'single',
  isOptionDisabled,
  onSelectNone,
  ...rest
}: Props<V>) => {
  const { currentTenant } = useCurrentTenantData()
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const { data, hasNextPage, fetchNextPage, isLoading } = useInfiniteGroupsListApi({
    initialPageParam: {
      page: 1,
    },
    params: {
      search,
      tenantId: currentTenant.id,
      itemsPerPage: AutocompleteInfinite.PageSize,
    },
    staleTime: AutocompleteInfinite.StaleTime,
  })

  const setSearchDebounced = useDebounceFn((searchVal: string) => setSearch(searchVal.trim()), Delay.Search)

  const handleLoadMore: LoadMoreHandler = useStableCallback(
    () =>
      new Promise(resolve => {
        fetchNextPage().then(({ isFetched }) => {
          if (isFetched) {
            resolve()
          }
        })
      }),
  )

  const noneOption = () => {
    if (type === 'single' && !isLoading) {
      return (
        <WppListItem key="noValue" onWppChangeListItem={onSelectNone}>
          <span slot="label">{t('os.common.selects.none')}</span>
        </WppListItem>
      )
    }
  }

  return (
    <FormSelect
      {...rest}
      infinite
      infiniteLastPage={!hasNextPage}
      loadMore={handleLoadMore}
      options={data}
      actionOption={noneOption()}
      type={type}
      withFolder
      withSearch
      search={search}
      onWppSearchValueChange={search => setSearchDebounced(search.detail)}
      getOptionLabel={({ name }) => name}
      isOptionDisabled={isOptionDisabled}
      loading={isLoading}
      showSelectAllText={false}
      // TODO: replace with enableStaticOptions when CL will fix types in https://jira.uhub.biz/browse/WPPLONOP-26959
      enable-static-options
    />
  )
}
