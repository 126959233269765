import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { fetchRolesSourcesListApi } from 'api/roles/fetchers/fetchRolesSourcesListApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { AutocompleteInfinite } from 'constants/autocomplete'

export const useInfiniteRolesSourcesListApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.ROLES_SOURCES_INFINITE,
  fetcher: fetchRolesSourcesListApi,
  selector: res => res?.pages?.flatMap(page => page.data.data) || [],
  getNextPageParam: (lastPage, allPages) => {
    const total = allPages.reduce((acc, curr) => acc + curr.data.data.length, 0)
    const itemsPerPage = AutocompleteInfinite.PageSize

    return total % itemsPerPage === 0 || lastPage.data.data.length !== 0 ? { page: allPages.length + 1 } : undefined
  },
})
