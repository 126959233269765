import { Tenant, UserDetails } from '@wpp-open/core'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { HubsFilters } from 'api/hubs/fetchers/fetchUserHubsApi'
import { useUserHubsApi } from 'api/hubs/queries/useUserHubsApi'
import { useFetchUserHubsApi } from 'api/hubs/queryFetchers/useFetchUserHubsApi'
import { useNavigationAccessApi } from 'api/navigation/queries/useNavigationAccessApi'
import { useMemberRolesApi } from 'api/roles/queries/useMemberRolesApi'
import { useFetchMemberRolesApi } from 'api/roles/queryFetchers/useFetchMemberRolesApi'
import { tableActions, TableInfiniteLoader } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableDefaults, TableKey } from 'constants/table'
import { showAddGroupToMemberSideModal } from 'pages/admin/members/addGroupsToMemberSideModal/AddGroupsToMemberSideModal'
import {
  hideEditMemberSideModal,
  showEditMemberSideModal,
} from 'pages/admin/members/editMemberSideModal/EditMemberSideModal'
import {
  hideViewGroupSideModal,
  showViewGroupSideModal,
} from 'pages/admin/members/groups/viewGroupSideModal/ViewGroupSideModal'
import { useOpenSelectRoleSideModal } from 'pages/admin/members/utils'
import { processMappingIntoTree } from 'pages/admin/members/viewMemberSideModal/viewMemberAccessSection/utils'
import {
  hideViewMemberSideModal,
  showViewMemberSideModal,
} from 'pages/admin/members/viewMemberSideModal/ViewMemberSideModal'
import { hideViewRoleSideModal, showViewRoleSideModal } from 'pages/admin/roles/viewRoleSideModal/ViewRoleSideModal'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { queryClient } from 'providers/osQueryClient/utils'
import { AzGroup, GroupUser } from 'types/groups/groups'
import { UserHub } from 'types/hubs/hubs'
import { MemberRole } from 'types/roles/memberRole'
import { RoleMember } from 'types/roles/roleMember'
import { UserDetailsShort } from 'types/users/users'

interface AdaptMemberGroups {
  groups?: AzGroup[]
}

interface Params {
  memberEmail: string
}

export enum MemberSideModalTabs {
  ROLES = 'roles',
  GROUPS = 'groups',
  ACCESS = 'access',
  HUBS = 'hubs',
}

export const adaptMemberGroups = ({ groups }: AdaptMemberGroups) => {
  return [...(groups || [])].sort((a, b) => a.name.localeCompare(b.name))
}

export const handleReloadMemberRolesTable = async () => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MEMBER_ROLES] })
  tableActions.reload(TableKey.VIEW_MEMBER_ROLES)
}

export const handleReloadMemberAccess = async () => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.NAVIGATION_ACCESS] })
}

export const handleReloadUserByMail = async (email: string, isCurrentUser: boolean) => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.USER_BY_EMAIL, { email }] })

  if (isCurrentUser) {
    await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.CURRENT_USER] })
  }
}

export const useMemberRolesListLoader = ({ memberEmail }: Params) => {
  const { currentTenant } = useCurrentTenantData()

  const handleFetchMemberRolesList = useFetchMemberRolesApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const loader: TableInfiniteLoader<MemberRole> = useCallback(
    async ({ startRow, endRow }) => {
      const itemsPerPage = endRow - startRow

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchMemberRolesList({
        tenantId: currentTenant.id,
        memberEmail,
        itemsPerPage,
        page: endRow / itemsPerPage,
      })

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [currentTenant.id, memberEmail, handleFetchMemberRolesList],
  )

  return { loader }
}

export const useViewMemberModals = ({
  backButtonText,
  fullName,
  member,
  onBack,
  shouldBackToEdit,
  isLayerAdminPage,
}: {
  onBack?: () => void
  shouldBackToEdit?: boolean
  isLayerAdminPage?: boolean
  backButtonText?: string
  fullName: string
  member: UserDetailsShort | GroupUser | RoleMember
}) => {
  const { onAddRoleToMember } = useOpenSelectRoleSideModal()
  const { t } = useTranslation()

  const backToView = useCallback(
    () =>
      shouldBackToEdit
        ? showEditMemberSideModal({
            member,
            isLayerAdminPage,
          })
        : showViewMemberSideModal({
            backButtonText,
            member,
            onBack,
            isLayerAdminPage,
          }),
    [backButtonText, member, onBack, isLayerAdminPage, shouldBackToEdit],
  )

  return useMemo(
    () => ({
      onEdit: onBack
        ? undefined
        : () => {
            showEditMemberSideModal({ member, isLayerAdminPage, onOpen: hideViewMemberSideModal })
          },
      onAddRole: () => {
        onAddRoleToMember({
          email: member.email,
          fullName,
          onOpen: shouldBackToEdit ? hideEditMemberSideModal : hideViewMemberSideModal,
          onClose: backToView,
        })
      },
      onAddGroup: () => {
        showAddGroupToMemberSideModal({
          memberEmail: member.email,
          onOpen: shouldBackToEdit ? hideEditMemberSideModal : hideViewMemberSideModal,
          onClose: backToView,
        })
      },
      onViewGroup: (groupId: string) => {
        showViewGroupSideModal({
          groupId,
          onOpen: shouldBackToEdit ? hideEditMemberSideModal : hideViewMemberSideModal,
          ...(!onBack && {
            onBack: () => {
              hideViewGroupSideModal()
              backToView()
            },
            backButtonText: t('os.entities.member').toLocaleLowerCase(),
          }),
        })
      },
      onViewRole: (id: string) => {
        showViewRoleSideModal({
          roleId: id,
          onOpen: shouldBackToEdit ? hideEditMemberSideModal : hideViewMemberSideModal,
          ...(!onBack && {
            backButtonText: t('os.entities.member').toLocaleLowerCase(),
            onBack: () => {
              hideViewRoleSideModal()
              backToView()
            },
          }),
        })
      },
    }),
    [backToView, shouldBackToEdit, isLayerAdminPage, fullName, member, onAddRoleToMember, onBack, t],
  )
}

export const useMemberAccessTree = (userEmail: string, currentTenantId: string) => {
  const { data: navigationTreeAccess, isFetching: isFetchingMemberAccessTree } = useNavigationAccessApi({
    params: {
      tenantId: currentTenantId,
      email: userEmail,
    },
  })

  const memberAccessTree = useMemo(
    () => (navigationTreeAccess ? processMappingIntoTree(navigationTreeAccess?.mapping || {}) : undefined),
    [navigationTreeAccess],
  )

  return {
    memberAccessTree,
    isFetchingMemberAccessTree,
  }
}

export const useRolesCount = (memberEmail: string, tenantId: string) => {
  const { data: rolesCount } = useMemberRolesApi({
    params: {
      memberEmail,
      tenantId,
      itemsPerPage: 1,
      page: 1,
    },
  })

  return rolesCount?.paginator?.totalItems ?? 0
}

export const useHubsCount = (email: string, tenantId: string) => {
  const { data: hubsCount } = useUserHubsApi({
    params: {
      email,
      tenantId,
      page: 1,
    },
  })

  return hubsCount?.paginator?.totalItems ?? 0
}

export const useUserHubsLoader = (email: string, searchName?: string, filters?: HubsFilters) => {
  const { currentTenant } = useCurrentTenantData()

  const handleFetchHubsList = useFetchUserHubsApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const loader: TableInfiniteLoader<UserHub> = useCallback(
    async ({ startRow, endRow }) => {
      const itemsPerPage = endRow - startRow

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchHubsList({
        email,
        filters,
        searchName,
        tenantId: currentTenant.id,
        itemsPerPage,
        page: endRow / itemsPerPage,
      })

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [handleFetchHubsList, email, filters, searchName, currentTenant.id],
  )

  return { loader }
}

export const isCurrentDefaultHub = (hubId: string, user: UserDetails, currentTenant: Tenant) =>
  hubId === user.preferredHubPerTenant?.[currentTenant.id]

export const deselectMemberGroups = () => tableActions.deselectAll(TableKey.VIEW_MEMBER_GROUPS)
export const deselectMemberRoles = () => tableActions.deselectAll(TableKey.VIEW_MEMBER_ROLES)
