import { LoadMoreHandler } from '@platform-ui-kit/components-library'
import { ReactNode, useState, useRef, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useAsyncFn } from 'react-use'

import { UsersListSortBy } from 'api/users/fetchers/fetchUsersListApi'
import { useInfiniteUsersListApi } from 'api/users/infiniteQueries/useInfiniteUsersListApi'
import { useFetchUsersListByEmails } from 'api/users/queryFetchers/useFetchUsersListByEmails'
import { FormAutocomplete } from 'components/form/formAutocomplete/FormAutocomplete'
import { AvatarWithNameAndEmailOption } from 'components/form/formAutocomplete/optionRenderers/AvatarWithNameAndEmailOption'
import { FormAutocompleteInfiniteProps } from 'components/form/formAutocomplete/utils'
import { AutocompleteInfinite } from 'constants/autocomplete'
import { Delay } from 'constants/delay'
import { useUserDetailsAttachments } from 'hooks/attachments/useUserDetailsAttachments'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useStableCallback } from 'hooks/useStableCallback'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { UserDetailsShort } from 'types/users/users'
import { difference, excludeFalsy } from 'utils/common'
import { extractEmailsFromString } from 'utils/email'

type Props = Omit<FormAutocompleteInfiniteProps<UserDetailsShort>, 'renderOptionContent'> & {
  renderOptionContent?: (option: UserDetailsShort, avatarUrl: string) => ReactNode
  autoParseEmails?: boolean
}

const defaultRenderOptionContent = (
  { firstname, lastname, email, isExternal }: UserDetailsShort,
  avatarUrl: string,
) => (
  <AvatarWithNameAndEmailOption
    firstName={firstname}
    lastName={lastname}
    email={email}
    avatarUrl={avatarUrl}
    isExternal={isExternal}
  />
)

export const FormUsersListAutocomplete = ({
  renderOptionContent = defaultRenderOptionContent,
  autoParseEmails = true,
  name,
  multiple,
  ...rest
}: Props) => {
  const { currentTenant } = useCurrentTenantData()
  const [search, setSearch] = useState('')
  const { watch, setValue } = useFormContext()
  const ref = useRef<HTMLWppAutocompleteElement>(null)
  const avatarsLoadedRef = useRef(false)

  const { handleLoadAttachments, getAttachmentUrlByKey } = useUserDetailsAttachments({
    staleTime: AutocompleteInfinite.StaleTime,
  })

  const fetchUsersListByEmails = useFetchUsersListByEmails()

  const handleLoadUsersAvatars = useStableCallback(async (users: UserDetailsShort[]) => {
    const keys = users.map(({ avatarThumbnail }) => avatarThumbnail?.key).filter(excludeFalsy)
    await handleLoadAttachments(keys)
  })

  const currentValue: UserDetailsShort[] = watch(name)

  const performAutoParse = useStableCallback(async (emails: string[]) => {
    try {
      const { data } = await fetchUsersListByEmails({ emails, limit: 1000 })

      const newUsersRecipients = difference(data.data, currentValue, ({ id }) => id)

      if (newUsersRecipients.length) {
        await handleLoadUsersAvatars(newUsersRecipients)
        setValue(name, [...currentValue, ...newUsersRecipients], { shouldTouch: true })
        ref.current?.blur()
      }
    } catch (e) {
      if (process.env.DEV) {
        console.error(e)
      }
    }
  })

  const [{ loading: isParsing }, handleAutoParse] = useAsyncFn(performAutoParse, [performAutoParse])

  const setSearchDebounced = useDebounceFn((search: string) => {
    avatarsLoadedRef.current = false

    if (autoParseEmails) {
      const emails = extractEmailsFromString(search)

      if (emails.length) {
        handleAutoParse(emails)
      }
    }

    setSearch(search.trim())
  }, Delay.Search)

  const { data, hasNextPage, fetchNextPage, isLoading } = useInfiniteUsersListApi({
    initialPageParam: {
      offset: 0,
    },
    params: {
      workspaceId: currentTenant.azId,
      search,
      sortBy: UsersListSortBy.firstname,
      orderBy: 'asc',
      limit: AutocompleteInfinite.PageSize,
    },
    staleTime: AutocompleteInfinite.StaleTime,
    enabled: !isParsing && !!search,
  })

  useEffect(() => {
    if (!avatarsLoadedRef.current && data) {
      handleLoadUsersAvatars(data)
      avatarsLoadedRef.current = true
    }
  }, [data, handleLoadUsersAvatars])

  const handleLoadMore: LoadMoreHandler = useStableCallback(
    () =>
      new Promise(resolve => {
        fetchNextPage().then(async ({ isFetched, data }) => {
          if (isFetched) {
            const pageData = data?.pages?.at(-1)?.data.data || []
            await handleLoadUsersAvatars(pageData)

            resolve()
          }
        })
      }),
  )

  return (
    <FormAutocomplete
      ref={ref}
      {...rest}
      name={name}
      multiple={multiple}
      infinite
      infiniteLastPage={!hasNextPage}
      onWppSearchValueChange={({ detail }) => setSearchDebounced(detail)}
      loadMore={handleLoadMore}
      options={search ? data : []}
      loading={isLoading || isParsing}
      renderOptionContent={option => renderOptionContent(option, getAttachmentUrlByKey(option.avatarThumbnail?.key))}
    />
  )
}
