import { WppActionButton, WppTag, WppTypography } from '@platform-ui-kit/components-library-react'
import { UserDetails } from '@wpp-open/core'
import clsx from 'clsx'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import { useUpdateUsersPreferredHubApi } from 'api/groups/mutations/useUpdateUsersPreferredHubApi'
import { is5xxError } from 'api/utils'
import { AvatarWithNameCell } from 'components/cellRenderers/avatarWithName/AvatarWithNameCell'
import { AvatarWithInfoSkeleton } from 'components/cellRenderers/skeletons/avatarWithInfoSkeleton/AvatarWithInfoSkeleton'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, TableInfinite } from 'components/common/table'
import { CriticalError, RenderErrorBoundary, RenderErrorType } from 'components/renderError'
import { SvgNoRecords } from 'components/svg/network/SvgNoRecords'
import { TableKey } from 'constants/table'
import { useGetHubHierarchy } from 'hooks/useGetHubHierarchy'
import { useUserCanManageHubs } from 'hooks/useUserCanManageHubs'
import {
  handleReloadUserByMail,
  isCurrentDefaultHub,
  useUserHubsLoader,
} from 'pages/admin/members/viewMemberSideModal/utils'
import styles from 'pages/admin/members/viewMemberSideModal/ViewMemberSideModal.module.scss'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { useToast } from 'providers/toast/ToastProvider'
import { UserHub } from 'types/hubs/hubs'

export interface State {
  isCriticalError?: boolean
}

interface Props {
  user: UserDetails
}

const NoRowsOverlayComponent = () => {
  const { t } = useTranslation()

  return (
    <Flex direction="column" align="center" gap={12} className={styles.notFound}>
      <SvgNoRecords />
      <WppTypography type="m-strong" data-testid="no-member-hubs-found-text">
        {t('os.members_list.view_member.hubs.placeholder')}
      </WppTypography>
    </Flex>
  )
}

export const ViewMemberHubsSection = ({ user }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { managedHubs } = useUserCanManageHubs()
  const { loader } = useUserHubsLoader(user.email)
  const { getHubHierarchy } = useGetHubHierarchy()
  const { currentTenant } = useCurrentTenantData()
  const { userDetails } = useOtherTenantsAndUserData()
  const { mutateAsync: handleUpdateUsersPreferredHub, isLoading } = useUpdateUsersPreferredHubApi()

  const isCurrentUser = userDetails?.email === user.email
  const [{ isCriticalError }, setState] = useSetState<State>({
    isCriticalError: false,
  })

  const updatePreferredHub = useCallback(
    async (hubId: string) => {
      const { email: userEmail } = user
      await handleUpdateUsersPreferredHub({
        userPreferredHub: [
          {
            userEmail,
            hubId,
          },
        ],
      })
      handleReloadUserByMail(userEmail, isCurrentUser)
      enqueueToast({
        message: t('os.hubs.members.toasts.default_hub_toast'),
        type: 'success',
      })
    },
    [enqueueToast, handleUpdateUsersPreferredHub, isCurrentUser, t, user],
  )

  const columnDefs = useMemo<ColDef<UserHub>[]>(
    () => [
      {
        width: 320,
        colId: 'name',
        headerName: t('os.hubs.table.hub'),
        valueGetter: ({ data }) => data!.name,
        loadingCellRenderer: AvatarWithInfoSkeleton,
        cellRenderer: ({ data }) => {
          const { name, logoThumbnail, logoOriginal, id } = data!
          return (
            <Flex style={{ width: 300 }} align="center" gap={8}>
              <AvatarWithNameCell avatarThumbnail={logoThumbnail?.url || logoOriginal?.url || ''} userName={name} />
              {isCurrentDefaultHub(id, user, currentTenant) && (
                <WppTag data-testid="default-hub" label={t('os.common.home')} variant="neutral" />
              )}
            </Flex>
          )
        },
        tooltipValueGetter: ({ data }) => data?.name,
      },
      {
        flex: 1,
        colId: 'hierarchy',
        headerName: t('os.hubs.table.hierarchy'),
        valueGetter: ({ data }) => getHubHierarchy(data!.organizationUnitId),
      },
      {
        width: 120,
        colId: 'role',
        headerName: t('os.hubs.table.role'),
        valueGetter: ({ data }) => (data!.canManage ? t('os.hubs.table.admin') : t('os.hubs.table.viewer')),
      },
      {
        width: 140,
        colId: 'setAsHome',
        cellRenderer: ({ data }) =>
          data &&
          !isCurrentDefaultHub(data.id!, user, currentTenant) &&
          managedHubs.some(managedHub => managedHub.id === data.id) ? (
            <WppActionButton
              loading={isLoading}
              onClick={() => updatePreferredHub(data?.id!)}
              className={styles.setAsHomeButton}
              data-testid="set-as-home-button"
            >
              {t('os.hubs.table.setAsHome')}
            </WppActionButton>
          ) : null,
      },
    ],
    [currentTenant, getHubHierarchy, isLoading, t, updatePreferredHub, user, managedHubs],
  )

  return (
    <Flex direction="column" gap={12}>
      {isCriticalError && <CriticalError />}
      <RenderErrorBoundary catchErrors={[RenderErrorType.DataIsNotAvailable]}>
        <Flex direction="column" gap={12} data-testid="hubs-header-section">
          <TableInfinite
            tableKey={TableKey.VIEW_MEMBER_HUBS}
            className={clsx('member-hubs', styles.table)}
            columnDefs={columnDefs}
            noRowsOverlayComponent={NoRowsOverlayComponent}
            loader={loader}
            onLoadError={error => {
              if (is5xxError(error)) {
                setState({ isCriticalError: true })
              }
            }}
          />
        </Flex>
      </RenderErrorBoundary>
    </Flex>
  )
}
