import { AnalyticsAction } from '../analytics'
import { MayBeNull } from '../common'

export interface OsContextApi {
  getAccessToken: () => string
  navigation: {
    openMenu: OpenMenuHandler
    openApp: OpenAppHandler
    openCompactApp: OpenCompactAppHandler
    triggerError: TriggerErrorHandler
  }
  analytics: {
    track: (data: AnalyticsAction) => void
  }
}

export type OpenMenuHandler = (preselectedNodeAzId?: MayBeNull<string>) => void
export type OpenAppHandler = (
  appInstanceId: string,
  params?: {
    analytics?: {
      launchedFrom: string
    }
  },
) => void
export type OpenCompactAppHandler = (params: {
  name: string
  url: string
  overlayProps: Record<string, unknown>
}) => void

export type TriggerErrorHandler = (errorType: AppErrorType) => void

export enum AppErrorType {
  ForbiddenPage = 'Forbidden page',
  CriticalError = 'Critical error',
}
