import {
  HierarchyNodeType,
  MayBeNull,
  NavigationTree,
  OsCommonContext,
  OsContextAppearance,
  OsContextFullPage,
  OsContextTool,
  SelectedHierarchy,
  SelectedHierarchyItem,
} from '@wpp-open/core'
import { MutableRefObject, useMemo, useRef } from 'react'

import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { usePublicData } from 'providers/publicData/PublicDataContext'
import { WorkspaceHierarchyIds } from 'utils/workspace'

export interface ContextValues {
  fullPage: ContextValueFullPage
  fullPageRef: MutableRefObject<ContextValueFullPage>
  tool: OsContextTool
  toolRef: MutableRefObject<OsContextTool>
}

export interface ContextValueFullPage {
  appStableId: MayBeNull<string>
  context: OsContextFullPage
}

export const useContextValues = (): ContextValues => {
  const { appData } = useOsState()
  const { userDetails } = useOtherTenantsAndUserData()
  const { currentTenant, navigationTreeWithHiddenLevel, permissions } = useCurrentTenantData()
  const { resolvedTheme, currentTaxonomy } = usePublicData()
  const { activePage } = useOsRoot()
  const {
    app,
    activeWorkspaceId,
    activeHierarchyWithHiddenLevel,
    currentBaseUrl,
    project,
    projectItem,
    appCustomConfig,
    additionalContext,
    appInstance,
  } = appData

  const selectedWorkspace = useMemo(
    () =>
      resolveSelectedWorkspace({ activeWorkspaceId, activeHierarchyWithHiddenLevel, navigationTreeWithHiddenLevel }),
    [activeHierarchyWithHiddenLevel, activeWorkspaceId, navigationTreeWithHiddenLevel],
  )
  const selectedHierarchy = useMemo(
    () =>
      resolveSelectedHierarchy({ activeWorkspaceId, activeHierarchyWithHiddenLevel, navigationTreeWithHiddenLevel }),
    [activeHierarchyWithHiddenLevel, activeWorkspaceId, navigationTreeWithHiddenLevel],
  )

  const projectData = useMemo<OsCommonContext['project']>(
    () =>
      project && projectItem
        ? {
            id: project.id,
            name: project.name,
            type: project.type,
            itemId: String(projectItem.id),
            itemCompleteState: projectItem.task?.status ?? null,
          }
        : null,
    [project, projectItem],
  )

  const fullPage = useMemo<ContextValueFullPage>(
    () => ({
      appStableId: app?.stableId ?? null,
      context: {
        appearance: OsContextAppearance.FullPage,
        baseUrl: currentBaseUrl,
        workspace: selectedWorkspace,
        hierarchy: selectedHierarchy,
        project: projectData,
        appInstance,
        appCustomConfig,
        additional: additionalContext,
        tenant: currentTenant,
        permissions,
        userDetails,
        theme: resolvedTheme,
        navigationTree: navigationTreeWithHiddenLevel,
        taxonomy: currentTaxonomy,
        activePage,
      },
    }),
    [
      activePage,
      additionalContext,
      app?.stableId,
      appCustomConfig,
      appInstance,
      currentBaseUrl,
      currentTaxonomy,
      currentTenant,
      navigationTreeWithHiddenLevel,
      permissions,
      projectData,
      resolvedTheme,
      selectedHierarchy,
      selectedWorkspace,
      userDetails,
    ],
  )

  const tool = useMemo<OsContextTool>(
    () => ({
      appearance: OsContextAppearance.Tool,
      baseUrl: null,
      workspace: null,
      hierarchy: null,
      project: null,
      appInstance: null,
      appCustomConfig: null,
      additional: null,
      tenant: currentTenant,
      permissions,
      userDetails,
      theme: resolvedTheme,
      navigationTree: navigationTreeWithHiddenLevel,
      taxonomy: currentTaxonomy,
      activePage,
    }),
    [
      activePage,
      currentTaxonomy,
      currentTenant,
      navigationTreeWithHiddenLevel,
      permissions,
      resolvedTheme,
      userDetails,
    ],
  )

  const fullPageRef = useRef(fullPage)
  fullPageRef.current = fullPage

  const toolRef = useRef(tool)
  toolRef.current = tool

  return {
    fullPage,
    fullPageRef,
    tool,
    toolRef,
  }
}

/**
 * @deprecated Will be removed along with the `FullscreenAppContext.workspace` field
 */
export const resolveSelectedWorkspace = ({
  activeWorkspaceId,
  activeHierarchyWithHiddenLevel,
  navigationTreeWithHiddenLevel,
}: {
  activeWorkspaceId: MayBeNull<string>
  activeHierarchyWithHiddenLevel: WorkspaceHierarchyIds
  navigationTreeWithHiddenLevel: NavigationTree
}): MayBeNull<SelectedHierarchy> => {
  if (!activeWorkspaceId) {
    return null
  }

  return {
    azId: activeWorkspaceId,
    mapping: Object.fromEntries(
      activeHierarchyWithHiddenLevel.map((nodeAzId, index) => [
        nodeAzId,
        getHierarchyStructureItem({
          azId: nodeAzId,
          parentAzId: index === 0 ? undefined : activeHierarchyWithHiddenLevel[index - 1],
          navigationTreeWithHiddenLevel,
        }),
      ]),
    ),
  }
}

export const resolveSelectedHierarchy = ({
  activeWorkspaceId,
  activeHierarchyWithHiddenLevel,
  navigationTreeWithHiddenLevel,
}: {
  activeWorkspaceId: MayBeNull<string>
  activeHierarchyWithHiddenLevel: WorkspaceHierarchyIds
  navigationTreeWithHiddenLevel: NavigationTree
}): SelectedHierarchy => {
  const { rootId } = navigationTreeWithHiddenLevel
  const selectedRootAzId = activeWorkspaceId || rootId
  // Include tenant level in provided context
  const selectedHierarchyIds = [rootId, ...activeHierarchyWithHiddenLevel]

  return {
    azId: selectedRootAzId,
    mapping: Object.fromEntries(
      selectedHierarchyIds.map((nodeAzId, index) => [
        nodeAzId,
        getHierarchyStructureItem({
          azId: nodeAzId,
          parentAzId: index === 0 ? undefined : selectedHierarchyIds[index - 1],
          navigationTreeWithHiddenLevel,
        }),
      ]),
    ),
  }
}

const getHierarchyStructureItem = ({
  azId,
  parentAzId,
  navigationTreeWithHiddenLevel,
}: {
  azId: string
  parentAzId?: string
  navigationTreeWithHiddenLevel: NavigationTree
}): SelectedHierarchyItem => {
  const node = navigationTreeWithHiddenLevel.mapping[azId]

  return {
    azId,
    name: node.name,
    type: node.type as HierarchyNodeType,
    customTypeName: node.customTypeName,
    parentAzId,
  }
}
