import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { fetchGroupsListApi } from 'api/groups/fetchers/fetchGroupsListApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useInfiniteGroupsListApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.GROUPS_LIST_INFINITE,
  fetcher: fetchGroupsListApi,
  selector: res => res?.pages?.flatMap(page => page.data?.data) || [],
  getNextPageParam: ({
    data: {
      paginator: { page, totalPages },
    },
  }) => (page < totalPages ? { page: page + 1 } : undefined),
})
