import {
  WppActionButton,
  WppIconChevron,
  WppIconPeople,
  WppIconRemoveCircle,
  WppTooltip,
  WppTypography,
  WppInput,
  WppPill,
} from '@platform-ui-kit/components-library-react'
import { SelectionChangedEvent, CellClickedEvent } from 'ag-grid-community'
import clsx from 'clsx'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'
import { useSetState } from 'react-use'

import { GroupsSortBy } from 'api/groups/fetchers/fetchGroupsListApi'
import { EmptyStateWithAction } from 'components/common/emptyStateWithAction/EmptyStateWithAction'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, Table } from 'components/common/table'
import { Delay } from 'constants/delay'
import { TableKey } from 'constants/table'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useNumberFormat } from 'hooks/useNumberFormat'
import { useStableCallback } from 'hooks/useStableCallback'
import { useHierarchyAccountData } from 'pages/admin/hierarchy/hierarchySettings/utils'
import { showClearSelectionModal } from 'pages/admin/members/clearSelectionModal/ClearSelectionModal'
import { useCanManageGroup } from 'pages/admin/members/groups/utils'
import { deselectMemberGroups, deselectMemberRoles } from 'pages/admin/members/viewMemberSideModal/utils'
import styles from 'pages/admin/members/viewMemberSideModal/ViewMemberSideModal.module.scss'
import { AzGroup } from 'types/groups/groups'
import { routesManager } from 'utils/routesManager'

interface Props {
  groups: AzGroup[]
  onAddGroup: () => void
  onViewGroup: (groupId: string) => void
  onDelete: (group: AzGroup) => void
  onDeleteBulk: (groups: AzGroup[]) => void
  noResultsPlaceholder: boolean
}

export const ViewMemberGroupsSection = ({
  groups,
  onAddGroup,
  onDelete,
  onViewGroup,
  onDeleteBulk,
  noResultsPlaceholder,
}: Props) => {
  const { t } = useTranslation()
  const isRolesPage = !!useMatch(routesManager.admin.roles.url())
  const { formatNumber } = useNumberFormat()
  const { handler: canManageGroup } = useCanManageGroup()

  const { getHierarchyAccountData } = useHierarchyAccountData()

  const [{ search, selectedGroups }, setState] = useSetState<{
    search: string
    selectedGroups: AzGroup[]
  }>({
    search: '',
    selectedGroups: [],
  })

  const onSelectionChanged = useStableCallback(({ api }: SelectionChangedEvent<AzGroup>) => {
    setState({ selectedGroups: api.getSelectedRows() || [] })
  })

  const getRowData = useMemo(
    () =>
      groups.filter(
        ({ name, description }) =>
          name.toLowerCase().includes(search.toLowerCase()) || description.toLowerCase().includes(search.toLowerCase()),
      ),
    [groups, search],
  )

  const addGroups = useCallback(() => {
    deselectMemberGroups()
    deselectMemberRoles()
    onAddGroup()
  }, [onAddGroup])

  const handleSelectCellClick = useStableCallback(({ node }: CellClickedEvent<AzGroup>) => {
    if (canManageGroup(node.data?.account_uid)) {
      const isNodeSelected = node.isSelected()
      node.setSelected(!isNodeSelected)
    }
  })

  const isEmpty = !search && !groups.length

  const setSearchDebounced = useDebounceFn((value?: string) => {
    const search = value?.trim() || ''
    setState({ search })
  }, Delay.Search)

  const columnDefs = useMemo<ColDef<AzGroup>[]>(
    () => [
      {
        width: 50,
        colId: 'select',
        checkboxSelection: ({ data }) => !!canManageGroup(data?.account_uid),
        onCellClicked: handleSelectCellClick,
      },
      {
        flex: 1,
        headerName: t('os.roles.view_role.groups.table.name'),
        colId: GroupsSortBy.name,
        sort: 'asc',
        cellRenderer: ({ data }) => (
          <Flex direction="column" justify="center" className={styles.zeroMinWidth}>
            <WppTypography type="s-body" data-testid="group-name">
              {data!.name}
            </WppTypography>
            {!!data!.description && (
              <WppTooltip text={data!.description} className={styles.tooltipWidth}>
                <WppTypography type="xs-body" className={clsx(styles.descriptionColor, styles.zeroMinWidth)}>
                  {data!.description}
                </WppTypography>
              </WppTooltip>
            )}
          </Flex>
        ),
      },
      {
        flex: 1,
        headerName: t('os.entities.hierarchy'),
        colId: 'hierarchy',
        cellRenderer: ({ data }) => (
          <WppTypography type="s-body">{getHierarchyAccountData(data!.account_uid).title}</WppTypography>
        ),
      },
      {
        width: 60,
        colId: 'remove',
        cellRenderer: ({ data }) =>
          canManageGroup(data?.account_uid) ? (
            <WppTooltip text={t('os.members_list.view_member.groups.delete_tooltip')} config={{ placement: 'left' }}>
              <WppActionButton onClick={() => onDelete(data!)} data-testid="delete-group-button">
                <WppIconRemoveCircle />
              </WppActionButton>
            </WppTooltip>
          ) : (
            <></>
          ),
      },
      {
        width: 60,
        colId: 'view',
        cellRenderer: ({ data }) =>
          canManageGroup(data?.account_uid) ? (
            <WppTooltip text={t('os.members_list.view_member.groups.view_tooltip')} config={{ placement: 'left' }}>
              <WppActionButton
                onClick={() => {
                  if (!isRolesPage) {
                    onViewGroup(data!.uid)
                  }
                }}
              >
                <WppIconChevron direction="right" />
              </WppActionButton>
            </WppTooltip>
          ) : (
            <></>
          ),
      },
    ],
    [handleSelectCellClick, t, onDelete, isRolesPage, onViewGroup, getHierarchyAccountData, canManageGroup],
  )

  return (
    <Flex direction="column" gap={12}>
      <Flex justify={isEmpty ? 'end' : 'between'}>
        {!isEmpty && (
          <WppInput
            size="s"
            type="search"
            className={styles.groupsSearchInput}
            onWppChange={({ detail }) => setSearchDebounced(detail.value)}
            placeholder={t('os.groups.search.placeholder')}
            data-testid="groups-search-input"
          />
        )}
        <Flex gap={12} align="center">
          {!!selectedGroups.length && (
            <>
              <WppPill
                type="display"
                removable
                label={t('os.members_list.view_member.selected_entities_chip', {
                  entitiesCount: formatNumber(selectedGroups.length),
                })}
                onWppClose={() =>
                  showClearSelectionModal({
                    onSubmit: () => deselectMemberGroups(),
                  })
                }
                data-testid="member-groups-selected-pill"
              />
              <WppActionButton onClick={() => onDeleteBulk(selectedGroups)} data-testid="remove-member-groups-bulk">
                <WppIconRemoveCircle slot="icon-start" />
                {t('os.common.remove')}
              </WppActionButton>
            </>
          )}
          {!noResultsPlaceholder && (
            <WppActionButton onClick={addGroups} data-testid="member-add-empty">
              <WppIconPeople slot="icon-start" /> {t('os.members_list.table.empty_state.add_groups_button_label')}
            </WppActionButton>
          )}
        </Flex>
      </Flex>
      {noResultsPlaceholder || getRowData.length === 0 ? (
        <EmptyStateWithAction
          label={t('os.members_list.view_member.groups.not_assigned')}
          actionLabel={t('os.members_list.table.empty_state.add_group_button_label')}
          onAction={addGroups}
        />
      ) : (
        <Table
          tableKey={TableKey.VIEW_MEMBER_GROUPS}
          columnDefs={columnDefs}
          rowData={getRowData}
          className={clsx('member-groups', styles.table)}
          onSelectionChanged={onSelectionChanged}
          getRowId={({ data }) => data.uid}
          rowSelection="multiple"
          suppressRowClickSelection
        />
      )}
    </Flex>
  )
}
