import { facadeApi } from 'api'
import { skipEmptyParams } from 'api/common/utils'
import { GroupUser } from 'types/groups/groups'

interface Params {
  /** group `uid` */
  groupId: string
  search?: string
  limit?: number
  offset?: number
}

export const fetchGroupUsersApi = ({ groupId, search, offset, limit }: Params) =>
  facadeApi.get<GroupUser[]>(`/az/groups/${groupId}/users`, {
    params: skipEmptyParams({
      search,
      offset,
      limit,
    }),
  })
