import { WppTag, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import { join } from 'utils/common'

interface Props {
  firstName: string
  lastName: string
  email: string
  avatarUrl: string
  isExternal?: boolean
}

export const AvatarWithNameAndEmailOption = ({ firstName, lastName, email, avatarUrl, isExternal }: Props) => {
  const fullName = join([firstName, lastName], ' ')
  const { t } = useTranslation()

  return (
    <>
      <Avatar slot="left" size="s" name={fullName} src={avatarUrl} />
      <WppTypography slot="label" type="s-body">
        {fullName}
      </WppTypography>
      <WppTypography slot="caption" type="s-body">
        {email}
      </WppTypography>

      {isExternal && <WppTag slot="right" label={t('os.entities.external')} variant="neutral" />}
    </>
  )
}
