import { WppTypography, WppIconWarning, WppButton } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { useUpdateGroupsRolesApi } from 'api/groups/mutations/useUpdateGroupsRolesApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { handleReloadGroupsListTable } from 'pages/admin/members/groups/utils'
import { handleReloadGroupRolesTable } from 'pages/admin/members/groups/viewGroupSideModal/utils'
import styles from 'pages/admin/roles/Roles.module.scss'
import { handleReloadRolesTable } from 'pages/admin/roles/utils'
import {
  deselectRoleGroups,
  handleReloadRoleGroupsTable,
  handleReloadRoleMembersTable,
} from 'pages/admin/roles/viewRoleSideModal/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { TenantGroup } from 'types/groups/groups'
import { ShortTenantRole } from 'types/roles/tenantRole'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { getGroupRolesPayload } from 'utils/roles'

interface Props extends NiceModalWrappedProps {
  data: {
    group: TenantGroup
    role: ShortTenantRole
  }[]
}

const DeleteRoleGroupsModal = ({ isOpen, onClose, data, id, onCloseComplete }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleUpdateGroupsRoles, isLoading } = useUpdateGroupsRolesApi()

  const onDelete = async () => {
    try {
      await handleUpdateGroupsRoles({
        create: [],
        delete: data
          .map(({ role, group }) => role.uniqueAccounts.map(({ id }) => getGroupRolesPayload(group.id, role.id, id)))
          .flat(),
      })

      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.ROLE] })
      await handleReloadRolesTable()
      await handleReloadRoleGroupsTable()
      await handleReloadRoleMembersTable()
      await handleReloadGroupRolesTable()
      await handleReloadGroupsListTable()

      enqueueToast({
        message: t('os.common.toasts.delete_from', {
          entity: t('os.entities.role').toLocaleLowerCase(),
          is: t('os.entities.is'),
          deleteFrom: data.length > 1 ? t('os.entities.groups') : t('os.entities.group'),
        }),
        type: 'success',
      })
      deselectRoleGroups()
    } catch {
      enqueueToast({
        message: t('os.common.errors.error'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  }

  return (
    <Modal
      data-testid={id}
      open={isOpen}
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      disableOutsideClick={isLoading}
    >
      <Flex slot="header" align="center" gap={9}>
        <WppIconWarning />
        <WppTypography type="xl-heading">{t('os.roles.view_role.groups.bulk_delete_modal.title')}</WppTypography>
      </Flex>

      <WppTypography slot="body" type="s-body" className={styles.breakWord}>
        <Trans
          i18nKey="os.roles.view_role.groups.bulk_delete_modal.description"
          values={{
            roleName: data[0].role.name,
          }}
          components={[
            <span key="0" className={styles.bold}>
              roleName
            </span>,
          ]}
        />
      </WppTypography>

      <Flex slot="actions" justify="end" gap={12}>
        <WppButton variant="secondary" size="s" onClick={onClose} data-testid="cancel">
          {t('os.common.cancel')}
        </WppButton>
        <WppButton size="s" variant="destructive" loading={isLoading} onClick={onDelete} data-testid="apply">
          {t('os.common.remove')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showDeleteRoleGroupsModal } = createNiceModal(
  DeleteRoleGroupsModal,
  'delete-role-groups-modal',
)
