import { useMemo } from 'react'
import * as zod from 'zod'

export interface ManageHierarchyAccessFormDTO {
  navigation: { name: string; id: string }[]
}

export const useValidationSchema = () =>
  useMemo(
    () =>
      zod.object({
        navigation: zod.array(zod.object({ id: zod.string(), name: zod.string() })),
      }),
    [],
  )
