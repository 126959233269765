import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { useRejectHubAccessRequestApi } from 'api/accessRequests/mutations/useRejectHubAccessRequestApi'
import { Flex } from 'components/common/flex/Flex'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { Modal } from 'components/surface/modal/Modal'
import { useForm } from 'hooks/form/useForm'
import { useDateWithTimeFormat } from 'hooks/useDateWithTimeFormat'
import {
  CHARACTERS_LIMIT,
  useValidationSchema,
} from 'pages/admin/requests/hubAccessRequests/rejectHubAccessRequestModal/utils'
import { handleReloadHubAccessRequestsTables } from 'pages/admin/requests/hubAccessRequests/utils'
import styles from 'pages/admin/requests/requestDetailsSideModal/RequestDetailsSideModal.module.scss'
import { UsersInfoCard } from 'pages/admin/requests/usersInfoCard/UsersInfoCard'
import { useToast } from 'providers/toast/ToastProvider'
import { HubAccessRequest } from 'types/requests/requests'
import { capitalize } from 'utils/common'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  requests: HubAccessRequest[]
  getAttachmentUrlByKey: (key?: string) => string
  onSubmitted?: () => void
}

const RejectHubAccessRequestModal = ({
  requests,
  getAttachmentUrlByKey,
  isOpen,
  onSubmitted,
  onClose,
  onCloseComplete,
  id,
}: Props) => {
  const { enqueueToast } = useToast()
  const { t } = useTranslation()
  const { formatDate } = useDateWithTimeFormat()

  const form = useForm({
    validationSchema: useValidationSchema(),
    defaultValues: { rejectReason: '' },
  })
  const isSingleRequest = requests.length === 1
  const { mutateAsync: handleRejectHubAccessRequest } = useRejectHubAccessRequestApi()

  const { handleSubmit, formState } = form
  const { isValid, isSubmitting } = formState

  const onSubmit = handleSubmit(async ({ rejectReason }) => {
    try {
      await handleRejectHubAccessRequest({
        requestIds: requests.map(({ id }) => id),
        rejectReason,
        tenantId: requests[0].tenantId,
      })

      handleReloadHubAccessRequestsTables()

      enqueueToast({
        type: 'success',
        message: capitalize(
          isSingleRequest
            ? t('os.requests.hub_access_requests.actions_modal.toasts.reject')
            : t('os.requests.hub_access_requests.actions_modal.toasts.reject_other', { count: requests.length }),
        ),
      })
    } catch (error) {
      enqueueToast({
        message: capitalize(t('os.common.errors.general')),
        type: 'error',
      })
    } finally {
      onSubmitted?.()
      onClose()
    }
  })

  return (
    <FormProvider {...form}>
      <Modal
        open={isOpen}
        onWppModalClose={onClose}
        onWppModalCloseComplete={onCloseComplete}
        size="m"
        data-testid={id}
        formConfig={{ onSubmit }}
      >
        <WppTypography type="xl-heading" slot="header">
          {t('os.requests.hub_access_requests.actions_modal.reject_hub_access.title')}
        </WppTypography>
        <Flex direction="column" slot="body" gap={16}>
          <WppTypography type="s-body">
            {isSingleRequest ? (
              <Trans
                i18nKey="os.requests.hub_access_requests.actions_modal.reject_hub_access.description.single"
                values={{
                  hubName: requests[0].hubName,
                }}
                components={[
                  <span key="0" className={styles.bold}>
                    hubName
                  </span>,
                ]}
              />
            ) : (
              <Trans
                i18nKey="os.requests.hub_access_requests.actions_modal.reject_hub_access.description.other"
                values={{
                  count: t('os.entities.members_count', { count: requests.length }),
                }}
                components={[
                  <span key="0" className={styles.bold}>
                    count
                  </span>,
                ]}
              />
            )}
          </WppTypography>

          {isSingleRequest && (
            <UsersInfoCard
              request={requests[0]}
              getAttachmentUrlByKey={getAttachmentUrlByKey}
              date={formatDate(requests[0].createdAt)}
            />
          )}

          <FormTextareaInput
            required
            labelConfig={{
              text: t('os.requests.hub_access_requests.actions_modal.reject_hub_access.reject_reason.label'),
            }}
            charactersLimit={CHARACTERS_LIMIT}
            warningThreshold={CHARACTERS_LIMIT}
            name="rejectReason"
            placeholder={t('os.requests.hub_access_requests.actions_modal.reject_hub_access.reject_reason.placeholder')}
          />
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
            {t('os.common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" loading={isSubmitting} disabled={!isValid} data-testid="apply">
            {capitalize(t('os.requests.actions.reject'))}
          </WppButton>
        </Flex>
      </Modal>
    </FormProvider>
  )
}

export const { showModal: showRejectHubAccessRequestModal } = createNiceModal(
  RejectHubAccessRequestModal,
  'reject-hub-access-request-modal',
)
