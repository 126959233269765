import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { join } from 'utils/common'
import { getWorkspacePointerNodes, useNodesMapping } from 'utils/mapping/common'

export const useGetHubHierarchy = () => {
  const { t } = useTranslation()
  const { navigationHierarchyWithHiddenLevel, navigationTreeWithHiddenLevel } = useCurrentTenantData()
  const nodesMapping = useNodesMapping(navigationTreeWithHiddenLevel)

  const getHubHierarchy = useCallback(
    (id: string) => {
      const pointerNode = nodesMapping[id]

      if (!pointerNode) {
        return t('os.common.navigation.os')
      }

      const pointerNodes = getWorkspacePointerNodes({
        pointerNode,
        navigationHierarchy: navigationHierarchyWithHiddenLevel,
      })

      return (
        join(
          pointerNodes.map(({ node }) => node.name),
          ' / ',
        ) || t('os.common.navigation.os')
      )
    },
    [navigationHierarchyWithHiddenLevel, nodesMapping, t],
  )

  return { getHubHierarchy }
}
