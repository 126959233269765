import {
  AppInstanceAssignmentType,
  AppInstanceStatus,
  ChildHierarchyLevelType,
  DefaultChildHierarchyLevelType,
  DefaultHierarchyLevelType,
  HierarchyContainerNodeId,
  HierarchyLevel,
  RootHierarchyLevelType,
  TenantFlags,
} from '@wpp-open/core'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { useAppInstancesApi } from 'api/apps/queries/useAppInstancesApi'
import { AppInstancesDefaults } from 'constants/appInstances'
import { useStableCallback } from 'hooks/useStableCallback'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { HierarchyTree } from 'types/hierarchy/hierarchy'
import { NavigationHierarchyForm, NavigationHierarchyFormLevel } from 'types/navigation/navigation'
import { TenantFormEditDTO } from 'types/tenants/tenant'
import { capitalize, excludeFalsy } from 'utils/common'

const CUSTOM_TYPE_MIN_CHARACTERS = 1
const CUSTOM_TYPE_MAX_CHARACTERS = 64

export const MAX_HIERARCHY_LEVELS_COUNT = 5

export const HIERARCHY_OPTION_CUSTOM = 'Custom'
export type HierarchyOptionType = DefaultChildHierarchyLevelType | typeof HIERARCHY_OPTION_CUSTOM

export const hierarchyRadioOptions: HierarchyOptionType[] = [
  DefaultHierarchyLevelType.Client,
  DefaultHierarchyLevelType.Market,
  DefaultHierarchyLevelType.Brand,
  DefaultHierarchyLevelType.Region,
  DefaultHierarchyLevelType.Industry,
  HIERARCHY_OPTION_CUSTOM,
]

export const mapHierarchyFormToEditDTO = (
  form: NavigationHierarchyForm,
): Pick<TenantFormEditDTO, 'hierarchyLevels' | 'flags'> => ({
  flags: {
    isFirstLevelHidden: form.flagsIsFirstLevelHidden,
  },
  hierarchyLevels: [
    {
      type: DefaultHierarchyLevelType.Tenant,
      flags: {
        apps: {
          visible: form.osAppsVisible,
          publicAccess: form.osAppsPublicAccess,
        },
        level: {
          visible: true,
          publicAccess: true,
        },
      },
    },
    ...form.levels.map(level => ({
      type: level.type === HIERARCHY_OPTION_CUSTOM ? level.customTypeLabel.trim() : level.type!,
      flags: {
        apps: {
          visible: level.appsVisible,
          publicAccess: level.appsPublicAccess,
        },
        level: {
          visible: level.nodesVisible,
          publicAccess: level.nodesPublicAccess,
        },
      },
    })),
  ],
})

export const mapHierarchyToForm = ({
  flags,
  osLevel,
  hierarchyLevels,
}: {
  flags: TenantFlags
  osLevel: HierarchyLevel<RootHierarchyLevelType>
  hierarchyLevels: HierarchyLevel<ChildHierarchyLevelType>[]
}): NavigationHierarchyForm => ({
  flagsIsFirstLevelHidden: flags.isFirstLevelHidden,
  osAppsVisible: osLevel.flags.apps.visible,
  osAppsPublicAccess: osLevel.flags.apps.publicAccess,
  levels: hierarchyLevels.map(level => ({
    type: level.custom ? HIERARCHY_OPTION_CUSTOM : (level.type as DefaultChildHierarchyLevelType),
    customTypeLabel: level.custom ? level.type : '',
    nodesVisible: level.flags.level.visible,
    nodesPublicAccess: level.flags.level.publicAccess,
    appsVisible: level.flags.apps.visible,
    appsPublicAccess: level.flags.apps.publicAccess,
  })),
})

export const getLevelDefaultValues = (): NavigationHierarchyFormLevel => ({
  type: null,
  customTypeLabel: '',
  nodesVisible: true,
  nodesPublicAccess: false,
  appsVisible: true,
  appsPublicAccess: false,
})

// Maps hierarchy levels array to boolean array indicating whether there are any navigation nodes on that level
// E.g. [CLIENT, MARKET, BRAND] => [true, false, false]
export const getHasNodesOnLevels = ({ mapping }: HierarchyTree, depth: number): boolean[] => {
  const rootNodes = mapping[HierarchyContainerNodeId]?.children
  let currentDepth = 0

  const checkLevels = (parentNodes: string[] | undefined): boolean[] => {
    currentDepth++

    if (!parentNodes?.length) {
      return Array(depth - currentDepth + 1).fill(false)
    }

    const nodes = parentNodes
      .map(node => mapping[node].children)
      .filter(excludeFalsy)
      .flat()

    return currentDepth === depth ? [true] : [true, ...checkLevels(nodes)]
  }

  return checkLevels(rootNodes)
}

export const useIsFirstLevelHiddenDisabled = ({ mapping }: HierarchyTree) => {
  const rootNodes = mapping[HierarchyContainerNodeId]?.children
  const firstRootNodeId = rootNodes?.at(0)

  const hasFirstRootNode = !!firstRootNodeId
  const isMultipleRootNodes = rootNodes?.length > 1

  const { data: appInstances, isLoading } = useAppInstancesApi({
    params: {
      assignmentId: firstRootNodeId!,
      assignmentType: AppInstanceAssignmentType.Workspace,
      status: [
        AppInstanceStatus.Active,
        AppInstanceStatus.VersionDevelopment,
        AppInstanceStatus.VersionDeleted,
        AppInstanceStatus.VersionAvailabilityDisabled,
        AppInstanceStatus.VersionHierarchyLevelDisabled,
      ],
      itemsPerPage: 1,
    },
    gcTime: AppInstancesDefaults.HierarchySettingsGCTime,
    enabled: hasFirstRootNode && !isMultipleRootNodes,
  })

  return {
    isLoading,
    disabled: isMultipleRootNodes || appInstances.length > 0,
  }
}

export const useNavigationHierarchyValidationScheme = () => {
  const { t } = useTranslation()

  return useMemo(() => {
    const customTypeLengthErrorMessage = t('os.admin.hierarchy_settings.level.validation.custom_type.length', {
      min: CUSTOM_TYPE_MIN_CHARACTERS,
      max: CUSTOM_TYPE_MAX_CHARACTERS,
    })

    const baseLevel = zod.object({
      nodesVisible: zod.boolean(),
      nodesPublicAccess: zod.boolean(),
      appsVisible: zod.boolean(),
      appsPublicAccess: zod.boolean(),
    })
    const defaultLevel = baseLevel.extend({
      type: zod.nativeEnum(DefaultHierarchyLevelType, {
        invalid_type_error: t('os.admin.hierarchy_settings.level.validation.type.required'),
      }),
      customTypeLabel: zod.string(),
    })
    const customLevel = baseLevel.extend({
      type: zod.literal(HIERARCHY_OPTION_CUSTOM),
      customTypeLabel: zod
        .string()
        .trim()
        .min(CUSTOM_TYPE_MIN_CHARACTERS, customTypeLengthErrorMessage)
        .max(CUSTOM_TYPE_MAX_CHARACTERS, customTypeLengthErrorMessage)
        .regex(/^[a-zA-Z0-9-_ ]+$/g, t('os.admin.hierarchy_settings.level.validation.custom_type.characters')),
    })

    return zod.object({
      flagsIsFirstLevelHidden: zod.boolean(),
      osAppsVisible: zod.boolean(),
      osAppsPublicAccess: zod.boolean(),
      // .discriminatedUnion() doesn't work correctly with .nativeEnum() as type.
      // See: https://github.com/colinhacks/zod/issues/2106
      levels: zod.array(zod.union([defaultLevel, customLevel])).superRefine((levels, ctx) => {
        const existingCustomTypes: string[] = []

        levels.forEach(({ type, customTypeLabel }, index) => {
          const trimmedLabel = customTypeLabel.trim()

          if (type !== HIERARCHY_OPTION_CUSTOM || !trimmedLabel) {
            return
          }

          const lowerCaseLabel = trimmedLabel.toLocaleLowerCase()

          // The BE checks for default level types and their plural forms (English only)
          const defaultTypeLabelsLowerCase = Object.values(DefaultHierarchyLevelType)
            .map(levelType => levelType.toLocaleLowerCase())
            .flatMap(levelType => [
              levelType,
              levelType.at(-1) === 'y' ? `${levelType.slice(0, -1)}ies` : `${levelType}s`,
            ])

          if (defaultTypeLabelsLowerCase.includes(lowerCaseLabel)) {
            ctx.addIssue({
              path: [index, 'customTypeLabel'],
              code: zod.ZodIssueCode.custom,
              message: t('os.admin.hierarchy_settings.level.validation.custom_type.matches_default'),
            })
          }

          if (!existingCustomTypes.includes(lowerCaseLabel)) {
            existingCustomTypes.push(lowerCaseLabel)
            return
          }

          ctx.addIssue({
            path: [index, 'customTypeLabel'],
            code: zod.ZodIssueCode.custom,
            message: t('os.admin.hierarchy_settings.level.validation.custom_type.unique'),
          })
        })
      }),
    })
  }, [t])
}

export const useHierarchyAccountData = () => {
  const {
    navigationTree: { mapping, rootId },
  } = useCurrentTenantData()
  const getHierarchyAccountData = useStableCallback((accountId: string) => {
    const title = accountId === rootId ? capitalize(t('os.entities.workspace')) : mapping[accountId]?.name
    return { ...mapping[accountId], title }
  })
  return { getHierarchyAccountData }
}
