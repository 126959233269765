import { ListValue, LoadMoreHandler, SelectTypes } from '@platform-ui-kit/components-library'
import { WppListItem } from '@platform-ui-kit/components-library-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useInfiniteRolesSourcesListApi } from 'api/roles/infiniteQueries/useInfiniteRolesSourcesListApi'
import { FormSelect, FormSelectProps } from 'components/form/formSelect/FormSelect'
import { AutocompleteInfinite } from 'constants/autocomplete'
import { Delay } from 'constants/delay'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useStableCallback } from 'hooks/useStableCallback'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { RoleSource } from 'types/roles/roles'
import { isRoleCreatedOnRoot } from 'utils/roles'

type Props<V extends ListValue> = Omit<FormSelectProps<RoleSource, V>, 'options'> & {
  onSearchChange?: (arg: string) => void
  type?: SelectTypes
  onSelectNone?: () => void
}
// As ROOT account is renamed to Workspace - we need to replace search for 'workspace' input to root
// input value = 'workspace' -> queryParam search = 'root'
const replaceSearch = (search: string) => (search.toLowerCase() === 'workspace' ? 'root' : search)

export const FormRolesSourcesSelect = <V extends ListValue>({ type = 'single', onSelectNone, ...props }: Props<V>) => {
  const { t } = useTranslation()
  const { currentTenant } = useCurrentTenantData()
  const [search, setSearch] = useState('')

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteRolesSourcesListApi({
    initialPageParam: {
      page: 1,
    },
    params: {
      tenantId: currentTenant.id,
      search: replaceSearch(search),
      itemsPerPage: AutocompleteInfinite.PageSize,
    },
    staleTime: AutocompleteInfinite.StaleTime,
  })

  // We need the 'root' ('workspace') option to be always first in the list
  useEffect(() => {
    const rootIndex = data.findIndex(obj => obj.name === 'ROOT')
    if (rootIndex !== -1) {
      const rootElement = data.splice(rootIndex, 1)
      data.unshift(...rootElement)
    }
  }, [data])

  const setSearchDebounced = useDebounceFn((search: string) => setSearch(search.trim()), Delay.Search)

  const handleLoadMore: LoadMoreHandler = useStableCallback(
    () =>
      new Promise(resolve => {
        fetchNextPage().then(({ isFetched }) => {
          if (isFetched) {
            resolve()
          }
        })
      }),
  )

  const noneOption = () => {
    if (type === 'single' && !isLoading) {
      return (
        <WppListItem key="noValue" onWppChangeListItem={onSelectNone}>
          <span slot="label">{t('os.common.selects.none')}</span>
        </WppListItem>
      )
    }
  }

  return (
    <FormSelect
      {...props}
      infinite
      infiniteLastPage={!hasNextPage}
      loadMore={handleLoadMore}
      actionOption={noneOption()}
      options={data}
      type={type}
      withSearch
      withFolder
      search={search}
      getOptionValue={source => source}
      onWppSearchValueChange={search => setSearchDebounced(search.detail)}
      getOptionLabel={({ name }) => (isRoleCreatedOnRoot(name) ? 'Workspace' : name)}
      loading={isLoading}
      showSelectAllText={false}
    />
  )
}
