import { TreeType } from '@platform-ui-kit/components-library'
import { WppButton, WppInput, WppLabel, WppTypography } from '@platform-ui-kit/components-library-react'
import { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styles from 'src/pages/admin/roles/assignRoleToMembersGroupsSideModal/AssignRoleToMembersGroupsSideModal.module.scss'

import { useUpdateGroupsRolesApi } from 'api/groups/mutations/useUpdateGroupsRolesApi'
import { useRoleApi } from 'api/roles/queries/useRoleApi'
import { useUpdateUsersRolesApi } from 'api/users/mutations/useUpdateUsersRolesApi'
import { Flex } from 'components/common/flex/Flex'
import { FormAutocompleteSkeleton } from 'components/form/formAutocomplete/FormAutocomplete'
import { FormUsersListAutocomplete } from 'components/form/formAutocomplete/FormUsersListAutocomplete'
import { FormGroupsSelect } from 'components/form/formSelect/FormGroupsSelect'
import {
  FormTree,
  FormTreeSkeleton,
  getNavigationData,
  updateTreeById,
  updateTreeBySearch,
  updateTreeCloseNotSelected,
} from 'components/form/formTree/FormTree'
import { NoFoundSearchResult } from 'components/form/formTree/noFoundSearchResult/NoFoundSearchResult'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Delay } from 'constants/delay'
import { useForm } from 'hooks/form/useForm'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { showAddExternalUserConfirmationModal } from 'pages/admin/members/addExternalUserConfirmationModal/AddExternalUserConfirmationModal'
import { useCanManageGroup } from 'pages/admin/members/groups/utils'
import {
  getCreateDefaultFormValues,
  mapFormValuesToGroupsPayload,
  mapFormValuesToUsersPayload,
  useValidationSchema,
} from 'pages/admin/roles/assignRoleToMembersGroupsSideModal/utils'
import { handleReloadRolesTable } from 'pages/admin/roles/utils'
import { handleReloadRoleGroupsTable, handleReloadRoleMembersTable } from 'pages/admin/roles/viewRoleSideModal/utils'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { excludeFalsy } from 'utils/common'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { sortNavigationTree, useApplyPermissionsOnNavigationTree, useMapNavigationTree } from 'utils/roles'
import { hasExternalUser } from 'utils/user'

const SideModalSkeleton = () => (
  <>
    <FormAutocompleteSkeleton />
    <FormAutocompleteSkeleton />
    <FormAutocompleteSkeleton />
    <FormAutocompleteSkeleton />
    <FormTreeSkeleton />
  </>
)

interface Props extends NiceModalWrappedProps {
  roleId?: string
  isGroupAdding?: boolean
}

const AssignRoleToMembersGroupsSideModal = ({
  isOpen,
  onClose,
  roleId,
  isGroupAdding = false,
  id,
  onCloseComplete,
}: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { currentTenant } = useCurrentTenantData()
  const { mapNavigationTree } = useMapNavigationTree()
  const { applyPermissions, isLoading: isNavigationPermissionsLoading } = useApplyPermissionsOnNavigationTree()

  const sortedTree = mapNavigationTree().map(sortNavigationTree).map(applyPermissions())
  const { data: roleDetails, isLoading: isRoleDetailsLoading } = useRoleApi({
    params: {
      roleId: roleId!,
      accountId: currentTenant.azMeta.organizationsId,
    },
    enabled: isOpen,
  })

  const [treeData, setTreeData] = useState<TreeType[]>(sortedTree)
  useEffect(() => {
    !isNavigationPermissionsLoading && setTreeData(sortedTree)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNavigationPermissionsLoading])

  const [search, setSearch] = useState('')

  const setSearchDebounced = useDebounceFn((searchString?: string) => {
    const trimedSearchString = searchString?.trim() || ''
    trimedSearchString
      ? setTreeData(updateTreeBySearch(treeData, trimedSearchString))
      : setTreeData(updateTreeCloseNotSelected(treeData))

    setSearch(trimedSearchString)
  }, Delay.Tooltip)

  const form = useForm({
    defaultValues: getCreateDefaultFormValues(),
    validationSchema: useValidationSchema(isGroupAdding),
  })

  const { mutateAsync: handleUpdateGroupsRoles } = useUpdateGroupsRolesApi()
  const { mutateAsync: handleUpdateUsersRoles } = useUpdateUsersRolesApi()

  const {
    handleSubmit,
    watch,
    formState: { isValid, isSubmitting },
    setValue,
    trigger,
  } = form

  const [navigation, members] = watch(['navigation', 'members'])

  const onSubmit = handleSubmit(async values => {
    try {
      await Promise.all(
        [
          !!values.groups.length && handleUpdateGroupsRoles(mapFormValuesToGroupsPayload(values, roleDetails!)),
          !!values.members.length && handleUpdateUsersRoles(mapFormValuesToUsersPayload(values, roleDetails!)),
        ].filter(excludeFalsy),
      )

      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.ROLE_MEMBERS] })
      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.GROUPS_LIST] })
      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.ROLE] })
      await handleReloadRolesTable()

      isGroupAdding ? handleReloadRoleGroupsTable() : handleReloadRoleMembersTable()

      enqueueToast({
        message: values.members.length
          ? t('os.roles.manage_roles.toasts.assign_role_to_member', { count: values.members.length })
          : t('os.roles.manage_roles.toasts.assign_role_to_group', { count: values.groups.length }),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('os.common.errors.general'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  })
  const { handler: canManageGroup } = useCanManageGroup()

  const onSave = hasExternalUser(members) ? () => showAddExternalUserConfirmationModal({ onSave: onSubmit }) : onSubmit

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{
          onSubmit,
        }}
        disableOutsideClick
        onWppSideModalClose={onClose}
        open={isOpen}
        size="l"
        onWppSideModalCloseComplete={() => {
          setTreeData(sortedTree)
          onCloseComplete()
        }}
        data-testid={id}
      >
        <Flex slot="header" direction="column" gap={4}>
          <WppTypography type="2xl-heading" data-testid="assign-role-modal-title">
            {isGroupAdding
              ? t('os.roles.table.actions_column.add_role_to_groups', { roleName: roleDetails?.name })
              : t('os.roles.table.actions_column.assign_role_to_members', { roleName: roleDetails?.name })}
          </WppTypography>
          <WppTypography type="s-body" data-testid="assign-roles-modal-role-description">
            {roleDetails?.description}
          </WppTypography>
        </Flex>

        <Flex slot="body" direction="column" gap={24}>
          {isRoleDetailsLoading ? (
            <SideModalSkeleton />
          ) : (
            <>
              {isGroupAdding ? (
                <FormGroupsSelect
                  name="groups"
                  type="multiple"
                  getOptionValue={({ id }) => id}
                  required={isGroupAdding}
                  isOptionDisabled={({ createdOnAccountId }) => !canManageGroup(createdOnAccountId)}
                  labelConfig={{
                    text: t('os.roles.manage_roles.fields.groups.label'),
                    description: t('os.roles.manage_roles.fields.groups.label_description'),
                  }}
                  placeholder={t('os.roles.manage_roles.fields.groups.placeholder')}
                />
              ) : (
                <FormUsersListAutocomplete
                  required={!isGroupAdding}
                  multiple
                  name="members"
                  labelConfig={{
                    text: t('os.roles.manage_roles.fields.members.label'),
                    description: t('os.roles.assign_roles_to_members_groups.members_label_description'),
                  }}
                  placeholder={t('os.roles.manage_roles.fields.members.placeholder')}
                  getOptionLabel={({ email }) => email}
                  getOptionId={({ email }) => email}
                />
              )}

              {isNavigationPermissionsLoading ? (
                <SideModalSkeleton />
              ) : (
                <Flex direction="column" gap={8}>
                  <WppLabel
                    htmlFor="navigation"
                    config={{ text: t('os.roles.manage_roles.fields.hierarchy.label') }}
                    typography="s-strong"
                  />

                  {!!treeData.length && !!treeData[0].children?.length && (
                    <WppInput
                      size="s"
                      type="search"
                      className={styles.searchInput}
                      onWppChange={({ detail }) => setSearchDebounced(detail.value)}
                      placeholder={t('os.roles.manage_roles.fields.hierarchy.search')}
                    />
                  )}
                  <FormTree
                    name="navigation"
                    data={treeData}
                    multiple
                    onWppChange={({ detail }) => {
                      setTreeData(updateTreeById(treeData, detail.currentItem?.id!, detail.currentItem!))
                      setValue(
                        'navigation',
                        detail.reason === 'select'
                          ? getNavigationData({ currentItem: detail.currentItem!, navigation })
                          : navigation,
                      )
                      trigger('navigation')
                    }}
                  />
                  {treeData[0]?.hidden && <NoFoundSearchResult search={search} />}
                </Flex>
              )}
            </>
          )}
        </Flex>
        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
            {t('os.common.cancel')}
          </WppButton>
          <WppButton variant="primary" onClick={onSave} loading={isSubmitting} disabled={!isValid} data-testid="apply">
            {t('os.common.save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showAssignRoleToMembersGroupsSideModal, useModal: useAssignRoleToMembersGroupsSideModal } =
  createNiceModal(AssignRoleToMembersGroupsSideModal, 'assign-role-to-members-groups-sidemodal')
