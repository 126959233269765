import { AutocompleteOption } from '@platform-ui-kit/components-library/dist/types/components/wpp-autocomplete/types'
import { MayBeNull } from '@wpp-open/core'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

export interface Member {
  email: string
  firstname: MayBeNull<string>
  lastname: MayBeNull<string>
  isExternal?: boolean
}
export interface UsersFormValue {
  users: Member[]
  groups: AutocompleteOption[]
}
export const useMemberValidationScheme = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        users: zod
          .array(zod.object({ email: zod.string(), firstname: zod.any(), lastname: zod.any() }))
          .min(1, t('os.members.add_members_to_os.members.validation_message')),
      }),
    [t],
  )
}

export const mapValuesToPayload = ({ users, groups }: UsersFormValue) => ({
  create: users.flatMap(user => groups.map(group => ({ groupId: group.id, userId: user.email }))),
  delete: [],
})
