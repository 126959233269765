import { TypographyType } from '@platform-ui-kit/components-library/dist/types/components/wpp-typography/types'
import { WppTooltip, WppTypography } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'

import styles from 'components/cellRenderers/textWithTooltip/TextWithTooltipCell.module.scss'

interface Props {
  typographyType?: TypographyType
  tooltipProps: ComponentProps<typeof WppTooltip>
  cellText: string
}

export const TextWithTooltipCell = ({ typographyType = 's-body', tooltipProps, cellText }: Props) => (
  <WppTooltip {...tooltipProps} className={styles.tooltipWidth}>
    <WppTypography className={styles.typographyWidth} type={typographyType}>
      {cellText}
    </WppTypography>
  </WppTooltip>
)
