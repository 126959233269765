import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/notificationItemSideModal/NotificationItemSideModal.module.scss'
import { FeedContext } from 'types/feeds/feeds'

export const ProjectInviteSideModalContent = ({
  projectName,
  userName,
  projectLink,
}: {
  projectName: FeedContext['project_name']
  userName: FeedContext['project_owner_fullname']
  projectLink: FeedContext['project_overview_link']
}) => {
  const { t } = useTranslation()
  return (
    <Flex direction="column" gap={16}>
      <WppTypography type="s-body" data-testid="notification-item-content-project-invite">
        <Trans
          i18nKey="os.notification.access.project.invite"
          values={{
            userName: `<0>${userName}</0>`,
            projectName: `<1>${projectName}</1>`,
          }}
          components={[
            <WppTypography key="0" type="s-strong">
              userName
            </WppTypography>,
            <WppTypography key="0" type="s-strong">
              projectName
            </WppTypography>,
          ]}
        />
      </WppTypography>
      <WppTypography type="s-body" data-testid="notification-item-content-project-link">
        <Trans
          i18nKey="os.notification.access.project.projectLink"
          values={{
            link: `<0>${t('os.notification.access.project.here')}</0>`,
          }}
          components={[
            <Link className={styles.link} key="0" to={projectLink!}>
              projectLink
            </Link>,
          ]}
        />
      </WppTypography>
    </Flex>
  )
}
