import { MayBeNull } from '@wpp-open/core'

export enum EventName {
  ADMIN_NOTIFICATION_REQUEST = 'back_office_admin_notification_request',
  TENANT_ACCESS_REQUESTED_ADMIN = 'back_office_tenant_access_requested_admin',
  HIERARCHY_ACCESS_REQUESTED_ADMIN = 'back_office_hierarchy_access_requested_admin',
  HIERARCHY_ACCESS_REJECTED = 'back_office_hierarchy_access_rejected',
  HIERARCHY_ACCESS_APPROVED = 'back_office_hierarchy_access_approved',
  APPS_NOTIFICATION = 'apps_facade_api_apps_feed_notification',
  CALENDAR_UPCOMING_EVENT = 'calendar_api_upcoming_event',
  NEWS_CREATED_NOTIFICATION = 'news_feed_api_news_created',
  HUB_ACCESS_REQUESTED_ADMIN = 'back_office_hub_access_requested_admin',
  HUB_ACCESS_APPROVED = 'back_office_hub_access_approved',
  HUB_ACCESS_REJECTED = 'back_office_hub_access_rejected',
  HUB_ACCESS_GRANTED = 'back_office_hub_access_granted',
  PROJECT_MEMBER_INVITED = 'project_api_project_member_invited',
}

export interface FeedContext {
  tenant_id: string
  tenant_name: string
  user_email: string
  user_name: string
  request_id?: string
  request_reason?: string
  reject_reason?: string
  title?: string
  body?: string
  sender?: string
  news_item_id?: string
  event_id?: string
  starts_at?: string
  ends_at?: string
  location?: string
  link?: string
  color?: string
  hub_name?: string
  hub_id?: string
  project_owner_fullname?: string
  invitee_first_name?: string
  project_name?: string
  project_overview_link?: string
}

export interface Feed {
  id: string
  isRead: boolean
  createdAt: string
  recipientEmail: string
  context: FeedContext
  eventName: EventName
  categories: string[]
}

export interface FeedsStatusItem {
  snoozedUntil: MayBeNull<Date>
  tenantId: string
  existsNew: boolean
  lastSeenAt: MayBeNull<string>
}
