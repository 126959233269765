import { TreeType } from '@platform-ui-kit/components-library'
import { WppButton, WppIconChevron, WppInput, WppLabel, WppTypography } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { FormProvider, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styles from 'src/pages/admin/members/selectRoleSideModal/SelectRoleSideModal.module.scss'

import { Flex } from 'components/common/flex/Flex'
import { FormRolesSelect } from 'components/form/formSelect/FormRolesSelect'
import { FormRolesSourcesSelect } from 'components/form/formSelect/FormRolesSourcesSelect'
import {
  FormTree,
  getNavigationData,
  updateTreeById,
  updateTreeBySearch,
  updateTreeCloseNotSelected,
} from 'components/form/formTree/FormTree'
import { NoFoundSearchResult } from 'components/form/formTree/noFoundSearchResult/NoFoundSearchResult'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { Delay } from 'constants/delay'
import { NONE_SELECTED_VALUE } from 'constants/select'
import { useForm } from 'hooks/form/useForm'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { showAddToExternalGroupConfirmationModal } from 'pages/admin/members/addToExternalGroupConfirmationModal/AddToExternalGroupConfirmationModal'
import { SelectRoleFormDto, useValidationSchema } from 'pages/admin/members/selectRoleSideModal/utils'
import { AzGroup, TenantGroup } from 'types/groups/groups'
import { TenantRole } from 'types/roles/tenantRole'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { isExternalGroup } from 'utils/groups'
import { sortNavigationTree, useApplyPermissionsOnNavigationTree, useMapNavigationTree } from 'utils/roles'

interface Props extends NiceModalWrappedProps {
  onSubmit: (values: SelectRoleFormDto) => Promise<void> | void
  cancelButtonText?: string
  title?: string
  rolesAssignments?: TenantRole[]
  group?: AzGroup | TenantGroup
  roleFieldDescription?: string
}

const getDefaultFormValues = (): SelectRoleFormDto => ({
  role: NONE_SELECTED_VALUE,
  navigation: [],
  sources: NONE_SELECTED_VALUE,
})

export const SelectRoleSideModal = ({
  isOpen,
  onClose,
  onSubmit,
  cancelButtonText,
  title,
  rolesAssignments = [],
  group,
  id,
  onCloseComplete,
  roleFieldDescription,
}: Props) => {
  const { t } = useTranslation()

  const [treeData, setTreeData] = useState<TreeType[]>([])
  const [search, setSearch] = useState('')

  const setSearchDebounced = useDebounceFn((searchString?: string) => {
    const trimedSearchString = searchString?.trim() || ''
    trimedSearchString
      ? setTreeData(updateTreeBySearch(treeData, trimedSearchString))
      : setTreeData(updateTreeCloseNotSelected(treeData))

    setSearch(trimedSearchString)
  }, Delay.Tooltip)

  const { mapNavigationTree } = useMapNavigationTree()
  const { applyPermissions } = useApplyPermissionsOnNavigationTree()

  const defaultTree = mapNavigationTree().map(sortNavigationTree).map(applyPermissions())

  const form = useForm({
    defaultValues: getDefaultFormValues(),
    validationSchema: useValidationSchema(),
  })

  const {
    handleSubmit,
    trigger,
    formState: { isValid, isSubmitting },
    resetField,
    setValue,
    control,
  } = form

  const [role, sources, navigation] = useWatch({ name: ['role', 'sources', 'navigation'], control })

  const handleFormSubmit = handleSubmit(async values => {
    await onSubmit(values)
    onClose()
  })

  const onSave =
    group && isExternalGroup(group)
      ? () => showAddToExternalGroupConfirmationModal({ onSave: handleFormSubmit })
      : handleFormSubmit

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{
          onSubmit: handleFormSubmit,
        }}
        disableOutsideClick
        onWppSideModalClose={() => {
          setSearch('')
          setTreeData([])
          onClose()
        }}
        open={isOpen}
        size="l"
        onWppSideModalCloseComplete={onCloseComplete}
        data-testid={id}
      >
        <WppTypography slot="header" type="2xl-heading">
          {title || t('os.roles.manage_roles.header.select_role_title')}
        </WppTypography>

        <Flex slot="body" direction="column" gap={24}>
          <FormRolesSourcesSelect
            required
            name="sources"
            onSelectNone={() => resetField('sources')}
            labelConfig={{ text: t('os.roles.manage_roles.fields.os_application.label') }}
            placeholder={t('os.roles.manage_roles.fields.os_application.placeholder')}
            onWppChange={() => {
              resetField('role')
              resetField('navigation')
            }}
          />

          <FormRolesSelect
            withSearch
            name="role"
            required
            onSelectNone={() => resetField('role')}
            labelConfig={{
              text: t('os.entities.role'),
              ...(!!roleFieldDescription && { description: roleFieldDescription }),
            }}
            placeholder={t('os.roles.manage_roles.fields.role.placeholder')}
            getOptionValue={role => role}
            onWppChange={({ detail }) => {
              /* Reset navigation field values on any change */
              resetField('navigation')

              const role = detail.value as TenantRole
              if (role.id) {
                const assignmentRole = rolesAssignments.find(assignmentRole => assignmentRole.id === role.id)

                if (assignmentRole) {
                  const { uniqueAccounts } = assignmentRole

                  const treeWithPreselect = uniqueAccounts.reduce(
                    (accumulator, { id }) => updateTreeById(accumulator, id!, { selected: true }),
                    defaultTree,
                  )

                  setValue('navigation', uniqueAccounts)
                  setTreeData(treeWithPreselect)
                } else {
                  setTreeData(defaultTree)
                }
              }
            }}
            {...(sources !== NONE_SELECTED_VALUE && !!sources?.id && { accountId: sources.id })}
          />

          {!!role && role !== NONE_SELECTED_VALUE && (
            <WppTypography type="s-body" data-testid="select-role-modal-role-description">
              {role.description}
            </WppTypography>
          )}

          <Flex direction="column" gap={8}>
            <WppLabel
              htmlFor="navigation"
              config={{ text: t('os.roles.manage_roles.fields.hierarchy.label') }}
              typography="s-strong"
            />
            {role !== NONE_SELECTED_VALUE && role?.id ? (
              <>
                {!!treeData.length && !!treeData[0].children?.length && (
                  <WppInput
                    size="s"
                    type="search"
                    className={styles.searchInput}
                    onWppChange={({ detail }) => setSearchDebounced(detail.value)}
                    placeholder={t('os.roles.manage_roles.fields.hierarchy.search')}
                    data-testid="hierarchy-search-input"
                  />
                )}
                <FormTree
                  key={search}
                  name="navigation"
                  multiple
                  data={treeData}
                  search={search}
                  onWppChange={({ detail }) => {
                    setTreeData(updateTreeById(treeData, detail.currentItem?.id!, detail.currentItem!))
                    setValue(
                      'navigation',
                      detail.reason === 'select'
                        ? getNavigationData({ currentItem: detail.currentItem!, navigation })
                        : navigation,
                    )
                    trigger('navigation')
                  }}
                />
                {treeData[0]?.hidden && <NoFoundSearchResult search={search} />}
              </>
            ) : (
              <WppTypography type="s-body">{t('os.roles.manage_roles.fields.hierarchy.placeholder')}</WppTypography>
            )}
          </Flex>
        </Flex>
        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
            {cancelButtonText ? (
              <>
                <WppIconChevron direction="left" slot="icon-start" />
                {cancelButtonText}
              </>
            ) : (
              t('os.common.cancel')
            )}
          </WppButton>
          <WppButton
            variant="primary"
            onClick={() => onSave()}
            loading={isSubmitting}
            disabled={!isValid}
            data-testid="apply"
          >
            {t('os.common.save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showSelectRoleSideModal, useModal: useSelectRoleSideModal } = createNiceModal(
  SelectRoleSideModal,
  'select-role-sidemodal',
)
