import { WppButton, WppSkeleton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAzUserByEmailApi } from 'api/alphaZulu/queries/useAzUserByEmailApi'
import { useUpdateGroupsUsersApi } from 'api/groups/mutations/useUpdateGroupsUsersApi'
import { Flex } from 'components/common/flex/Flex'
import { FormGroupsSelect } from 'components/form/formSelect/FormGroupsSelect'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import styles from 'pages/admin/members/addGroupsToMemberSideModal/AddGroupsToMemberSideModal.module.scss'
import {
  getDefaultValues,
  mapValuesToPayload,
  useAddMemberToGroupsValidationScheme,
} from 'pages/admin/members/addGroupsToMemberSideModal/utils'
import { useCanManageGroup } from 'pages/admin/members/groups/utils'
import { handleReloadMembersTable } from 'pages/admin/members/membersList/membersTable/utils'
import { handleReloadMemberAccess, handleReloadMemberRolesTable } from 'pages/admin/members/viewMemberSideModal/utils'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { TenantGroup } from 'types/groups/groups'
import { difference, join } from 'utils/common'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  memberEmail: string
}

const AddGroupsToMemberSideModal = ({ isOpen, onClose, memberEmail, onCloseComplete, id }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleUpdateGroupsUsers } = useUpdateGroupsUsersApi()
  const { currentTenant } = useCurrentTenantData()
  const { handler: canManageGroup } = useCanManageGroup()

  const { data: azUser, isLoading: isAzUserLoading } = useAzUserByEmailApi({
    params: memberEmail,
    enabled: isOpen,
  })

  const userGroupMembership = azUser?.group_membership

  const form = useForm({
    defaultValues: useMemo(
      () =>
        getDefaultValues({
          organizationsId: currentTenant.azMeta.organizationsId,
          groups: [],
        }),
      [currentTenant.azMeta.organizationsId],
    ),
    validationSchema: useAddMemberToGroupsValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onSubmit = handleSubmit(async values => {
    try {
      const resultValues = difference(values.groups, userGroupMembership?.map(({ uid }) => uid) || [], id => id)

      await handleUpdateGroupsUsers(mapValuesToPayload({ user: azUser!, values: resultValues }))
      await Promise.all([handleReloadMembersTable(), handleReloadMemberRolesTable(), handleReloadMemberAccess()])
      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AZ_USER_BY_EMAIL] })

      enqueueToast({
        message: t('os.members.toasts.add', { count: resultValues.length }),
        type: 'success',
      })

      onClose()
    } catch {
      enqueueToast({
        message: t('os.common.errors.error'),
        type: 'error',
      })
    }
  })

  return (
    <FormProvider {...form}>
      <SideModal
        data-testid={id}
        formConfig={{ onSubmit }}
        open={isOpen}
        size="l"
        disableOutsideClick
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
      >
        <>
          <WppTypography slot="header" type="2xl-heading">
            {t('os.members.header.add_to_groups', {
              memberName: join([azUser?.first_name, azUser?.last_name], ' '),
            })}
          </WppTypography>

          <Flex slot="body" direction="column" gap={24}>
            {!isAzUserLoading ? (
              <FormGroupsSelect
                name="groups"
                type="multiple"
                isOptionDisabled={(group: TenantGroup) => !canManageGroup(group.createdOnAccountId)}
                getOptionValue={({ id }) => id}
                required
                labelConfig={{
                  text: t('os.members_list.edit_member.fields.groups.label'),
                  description: t('os.members_list.add_groups_to_member.groups_label_description'),
                }}
                placeholder={t('os.members_list.edit_member.fields.groups.placeholder')}
              />
            ) : (
              <WppSkeleton className={styles.skeleton} />
            )}
          </Flex>

          <Flex slot="actions" gap={12} justify="end">
            <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
              {t('os.common.cancel')}
            </WppButton>
            <WppButton
              variant="primary"
              type="submit"
              loading={isSubmitting}
              disabled={isAzUserLoading}
              data-testid="apply"
            >
              {t('os.common.save')}
            </WppButton>
          </Flex>
        </>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showAddGroupToMemberSideModal, useModal: useAddGroupToMemberSideModal } = createNiceModal(
  AddGroupsToMemberSideModal,
  'add-groups-to-member-side-modal',
)
