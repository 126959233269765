export const SvgNoRecordsPlaceholder = () => (
  <svg width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="60.5" cy="60" r="60" fill="#E0EBFF" />
    <path
      d="M100.869 60.6246C101.135 59.6375 102.597 59.6375 102.863 60.6246L103.002 61.142C103.099 61.5003 103.396 61.7768 103.773 61.8582L104.063 61.921C105.134 62.1525 105.134 63.6161 104.063 63.8476L103.773 63.9104C103.396 63.9917 103.099 64.2682 103.002 64.6265L102.863 65.1439C102.597 66.1311 101.135 66.1311 100.869 65.1439L100.73 64.6265C100.633 64.2682 100.336 63.9917 99.9591 63.9104L99.6689 63.8476C98.598 63.6161 98.598 62.1525 99.6689 61.921L99.9591 61.8582C100.336 61.7768 100.633 61.5003 100.73 61.142L100.869 60.6246Z"
      fill="#0A2FFF"
    />
    <ellipse cx="106.48" cy="53.8843" rx="1.49992" ry="1.5" fill="#85A3FF" />
    <path
      d="M56.2541 6.8676C56.5866 5.63365 58.4136 5.63365 58.7462 6.8676L58.9205 7.5143C59.0412 7.96223 59.4129 8.3078 59.8835 8.40955L60.2463 8.48798C61.5849 8.77742 61.5849 10.6069 60.2463 10.8963L59.8835 10.9747C59.4129 11.0765 59.0412 11.4221 58.9205 11.87L58.7462 12.5167C58.4136 13.7506 56.5866 13.7506 56.2541 12.5167L56.0798 11.87C55.9591 11.4221 55.5873 11.0765 55.1167 10.9747L54.754 10.8963C53.4153 10.6069 53.4153 8.77742 54.754 8.48798L55.1167 8.40955C55.5873 8.3078 55.9591 7.96223 56.0798 7.5143L56.2541 6.8676Z"
      fill="#85A3FF"
    />
    <circle cx="65.75" cy="15.2307" r="1.5" fill="#0A2FFF" />
    <circle cx="12.9619" cy="41.7693" r="1.5" fill="#C2D4FF" />
    <g opacity="0.5" filter="url(#filter0_f_25647_423)">
      <rect x="12.5" y="51.75" width="75" height="18.75" rx="4" fill="#0014CC" />
    </g>
    <rect x="12.5" y="49.5" width="75" height="21" rx="4" fill="white" />
    <rect x="35" y="54" width="21" height="4.5" rx="1" fill="#85A3FF" />
    <rect x="35" y="61.5" width="48" height="4.5" rx="1" fill="#E0EBFF" />
    <ellipse cx="23.75" cy="60.0001" rx="6.75" ry="6.75012" fill="#0014CC" />
    <g clipPath="url(#clip0_25647_423)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7515 56.3813C23.8794 56.3813 23.9962 56.4536 24.0534 56.5679L25.0066 58.4743L27.2716 58.8227C27.3973 58.8421 27.5014 58.9304 27.541 59.0513C27.5805 59.1722 27.5488 59.305 27.4589 59.395L25.8923 60.9615L26.2413 63.2299C26.2607 63.3557 26.2077 63.4816 26.1042 63.5557C26.0007 63.6298 25.8644 63.6393 25.7516 63.5805L23.7515 62.5369L21.7514 63.5805C21.6386 63.6393 21.5023 63.6298 21.3989 63.5557C21.2954 63.4816 21.2424 63.3557 21.2617 63.2299L21.6107 60.9615L20.0442 59.395C19.9542 59.305 19.9225 59.1722 19.9621 59.0513C20.0016 58.9304 20.1058 58.8421 20.2315 58.8227L22.4965 58.4743L23.4497 56.5679C23.5068 56.4536 23.6237 56.3813 23.7515 56.3813ZM23.7515 57.4735L23.0222 58.9323C22.9732 59.0302 22.8798 59.0982 22.7716 59.1149L20.9924 59.3886L22.2089 60.6051C22.285 60.6812 22.3202 60.7889 22.3039 60.8951L22.0302 62.6737L23.5954 61.8571C23.6932 61.806 23.8098 61.806 23.9076 61.8571L25.4728 62.6737L25.1992 60.8951C25.1828 60.7889 25.2181 60.6812 25.2941 60.6051L26.5106 59.3886L24.7314 59.1149C24.6233 59.0982 24.5299 59.0302 24.4809 58.9323L23.7515 57.4735Z"
        fill="#F8F9FB"
      />
    </g>
    <g opacity="0.5" filter="url(#filter1_f_25647_423)">
      <rect x="25.25" y="77.25" width="75" height="18.75" rx="4" fill="#0014CC" />
    </g>
    <rect x="25.25" y="75" width="75" height="21" rx="4" fill="white" />
    <rect x="47.75" y="79.5" width="21" height="4.5" rx="1" fill="#85A3FF" />
    <rect x="47.75" y="87" width="48" height="4.5" rx="1" fill="#E0EBFF" />
    <ellipse cx="36.5" cy="85.5001" rx="6.75" ry="6.75012" fill="#0014CC" />
    <g clipPath="url(#clip1_25647_423)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5015 81.8813C36.6294 81.8813 36.7462 81.9536 36.8034 82.0679L37.7566 83.9743L40.0216 84.3227C40.1473 84.3421 40.2514 84.4304 40.291 84.5513C40.3305 84.6722 40.2988 84.805 40.2089 84.895L38.6423 86.4615L38.9913 88.7299C39.0107 88.8557 38.9577 88.9816 38.8542 89.0557C38.7507 89.1298 38.6144 89.1393 38.5016 89.0805L36.5015 88.0369L34.5014 89.0805C34.3886 89.1393 34.2523 89.1298 34.1489 89.0557C34.0454 88.9816 33.9924 88.8557 34.0117 88.7299L34.3607 86.4615L32.7942 84.895C32.7042 84.805 32.6725 84.6722 32.7121 84.5513C32.7516 84.4304 32.8558 84.3421 32.9815 84.3227L35.2465 83.9743L36.1997 82.0679C36.2568 81.9536 36.3737 81.8813 36.5015 81.8813ZM36.5015 82.9735L35.7722 84.4323C35.7232 84.5302 35.6298 84.5982 35.5216 84.6149L33.7424 84.8886L34.9589 86.1051C35.035 86.1812 35.0702 86.2889 35.0539 86.3951L34.7802 88.1737L36.3454 87.3571C36.4432 87.306 36.5598 87.306 36.6576 87.3571L38.2228 88.1737L37.9492 86.3951C37.9328 86.2889 37.9681 86.1812 38.0441 86.1051L39.2606 84.8886L37.4814 84.6149C37.3733 84.5982 37.2799 84.5302 37.2309 84.4323L36.5015 82.9735Z"
        fill="#F8F9FB"
      />
    </g>
    <g opacity="0.5" filter="url(#filter2_f_25647_423)">
      <rect x="25.25" y="26.25" width="75" height="18.75" rx="4" fill="#0014CC" />
    </g>
    <rect x="25.25" y="24" width="75" height="21" rx="4" fill="white" />
    <rect x="47.75" y="28.5" width="21" height="4.5" rx="1" fill="#85A3FF" />
    <rect x="47.75" y="36" width="48" height="4.5" rx="1" fill="#E0EBFF" />
    <ellipse cx="36.5" cy="34.5001" rx="6.75" ry="6.75012" fill="#0014CC" />
    <g clipPath="url(#clip2_25647_423)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5015 30.8813C36.6294 30.8813 36.7462 30.9536 36.8034 31.0679L37.7566 32.9743L40.0216 33.3227C40.1473 33.3421 40.2514 33.4304 40.291 33.5513C40.3305 33.6722 40.2988 33.805 40.2089 33.895L38.6423 35.4615L38.9913 37.7299C39.0107 37.8557 38.9577 37.9816 38.8542 38.0557C38.7507 38.1297 38.6144 38.1393 38.5016 38.0805L36.5015 37.0369L34.5014 38.0805C34.3886 38.1393 34.2523 38.1297 34.1489 38.0557C34.0454 37.9816 33.9924 37.8557 34.0117 37.7299L34.3607 35.4615L32.7942 33.895C32.7042 33.805 32.6725 33.6722 32.7121 33.5513C32.7516 33.4304 32.8558 33.3421 32.9815 33.3227L35.2465 32.9743L36.1997 31.0679C36.2568 30.9536 36.3737 30.8813 36.5015 30.8813ZM36.5015 31.9735L35.7722 33.4323C35.7232 33.5302 35.6298 33.5982 35.5216 33.6149L33.7424 33.8886L34.9589 35.1051C35.035 35.1812 35.0702 35.2889 35.0539 35.3951L34.7802 37.1737L36.3454 36.3571C36.4432 36.306 36.5598 36.306 36.6576 36.3571L38.2228 37.1737L37.9492 35.3951C37.9328 35.2889 37.9681 35.1812 38.0441 35.1051L39.2606 33.8886L37.4814 33.6149C37.3733 33.5982 37.2799 33.5302 37.2309 33.4323L36.5015 31.9735Z"
        fill="#F8F9FB"
      />
    </g>
    <circle cx="60.5" cy="107.654" r="2.25" fill="#85A3FF" />
    <defs>
      <filter
        id="filter0_f_25647_423"
        x="2.5"
        y="41.75"
        width="95"
        height="38.75"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_25647_423" />
      </filter>
      <filter
        id="filter1_f_25647_423"
        x="15.25"
        y="67.25"
        width="95"
        height="38.75"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_25647_423" />
      </filter>
      <filter
        id="filter2_f_25647_423"
        x="15.25"
        y="16.25"
        width="95"
        height="38.75"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_25647_423" />
      </filter>
      <clipPath id="clip0_25647_423">
        <rect width="8.99989" height="8.99989" fill="white" transform="translate(19.2515 55.5)" />
      </clipPath>
      <clipPath id="clip1_25647_423">
        <rect width="8.99989" height="8.99989" fill="white" transform="translate(32.0015 81)" />
      </clipPath>
      <clipPath id="clip2_25647_423">
        <rect width="8.99989" height="8.99989" fill="white" transform="translate(32.0015 30)" />
      </clipPath>
    </defs>
  </svg>
)
