import { facadeApi } from 'api'
import { PaginatedResponse } from 'api/common/types'
import { MemberRole } from 'types/roles/memberRole'

interface Params {
  memberEmail: string
  tenantId: string
  page?: number
  itemsPerPage?: number
}

export type MemberRolesResponse = PaginatedResponse<MemberRole>

export const fetchMemberRolesApi = ({ memberEmail, tenantId, page, itemsPerPage }: Params) => {
  // API contract is case sensitive. For that reason params should be always in lower case
  const lowerCaseEmail = memberEmail.toLowerCase()
  return facadeApi.get<MemberRolesResponse>(`/tenants/${tenantId}/members/${lowerCaseEmail}/roles`, {
    params: {
      page,
      itemsPerPage,
    },
  })
}
