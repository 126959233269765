import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { UpdateGroupsRolesPayload } from 'api/groups/fetchers/updateGroupsRolesApi'
import { UpdateUsersRolesPayload } from 'api/users/fetchers/updateUsersRolesApi'
import { Role } from 'types/roles/roles'
import { UserDetailsShort } from 'types/users/users'

interface ManageRoleFormDTO {
  groups: string[]
  members: (UserDetailsShort | { email: string; firstname: string; lastname: string })[]
  navigation: { name: string; id: string }[]
}

export const mapFormValuesToUsersPayload = (formValues: ManageRoleFormDTO, role: Role): UpdateUsersRolesPayload => {
  return {
    create: formValues.navigation.flatMap(({ id }) =>
      formValues.members.map(({ email }) => ({
        user_id: email,
        role_id: role.id,
        account_id: id,
      })),
    ),
    delete: [],
  }
}

export const mapFormValuesToGroupsPayload = (formValues: ManageRoleFormDTO, role: Role): UpdateGroupsRolesPayload => {
  return {
    create: formValues.navigation.flatMap(({ id }) =>
      formValues.groups.map(group_id => ({
        group_id,
        role_id: role.id,
        account_id: id,
      })),
    ),
    delete: [],
  }
}

export const getCreateDefaultFormValues = (): ManageRoleFormDTO => ({
  groups: [],
  members: [],
  navigation: [],
})

export const useValidationSchema = (isGroupAdding: boolean) => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        groups: zod
          .array(zod.string())
          .min(Number(isGroupAdding), t('os.roles.manage_roles.fields.groups.validation_message')),
        members: zod
          .array(
            zod.object({
              email: zod.string(),
              firstname: zod.string(),
              lastname: zod.string(),
            }),
          )
          .min(Number(!isGroupAdding), t('os.roles.manage_roles.fields.members.validation_message')),
        navigation: zod
          .array(zod.object({ id: zod.string(), name: zod.string() }))
          .min(1, t('os.roles.manage_roles.fields.hierarchy.validation_message')),
      }),
    [isGroupAdding, t],
  )
}
