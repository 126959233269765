import { facadeApi } from 'api'

export interface UpdateGroupsUsersPayload {
  create: {
    groupId: string
    userId: string
  }[]
  delete: {
    groupId: string
    userId: string
  }[]
}

export const updateGroupsUsersApi = (payload: UpdateGroupsUsersPayload) => facadeApi.patch('/az/groups/users', payload)
