import { RowClickedEvent } from 'ag-grid-community'
import { useCallback } from 'react'

import { useFetchHubRequestsListApi } from 'api/accessRequests/queryFetchers/useFetchHubRequestsListApi'
import { tableActions, TableInfiniteLoader } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { RequestsType } from 'constants/requests'
import { TableDefaults, TableKey } from 'constants/table'
import { useUserDetailsAttachments } from 'hooks/attachments/useUserDetailsAttachments'
import { useLatestDistinct } from 'hooks/useLatestDistinct'
import { useStableCallback } from 'hooks/useStableCallback'
import { showApproveHubAccessRequestModal } from 'pages/admin/requests/hubAccessRequests/approveHubAccessRequestModal/ApproveHubAccessRequestModal'
import { showRejectHubAccessRequestModal } from 'pages/admin/requests/hubAccessRequests/rejectHubAccessRequestModal/RejectHubAccessRequestModal'
import {
  hideRequestDetailsSideModal,
  showRequestDetailsSideModal,
} from 'pages/admin/requests/requestDetailsSideModal/RequestDetailsSideModal'
import { AccessRequestStatus } from 'pages/admin/requests/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { HubAccessRequest } from 'types/requests/requests'
import { excludeFalsy } from 'utils/common'
import { hasClosestInteractiveElement } from 'utils/dom'

interface LoaderParams {
  search?: string
  status: AccessRequestStatus[]
}

export const useHubAccessRequestsLoader = ({ search, status }: LoaderParams) => {
  const statusStable = useLatestDistinct(status)

  const { handleLoadAttachments, getAttachmentUrlByKey } = useUserDetailsAttachments({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const handleFetchAccessRequestsList = useFetchHubRequestsListApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const loader: TableInfiniteLoader<HubAccessRequest> = useCallback(
    async ({ sortModel, startRow, endRow }) => {
      const [{ colId, sort }] = sortModel
      const itemsPerPage = endRow - startRow

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchAccessRequestsList({
        search,
        status: statusStable,
        itemsPerPage,
        page: endRow / itemsPerPage,
        sort: sort === 'asc' ? colId : `-${colId}`,
      })

      const avatarsKeys = data.map(({ userData }) => userData?.avatarThumbnail?.key).filter(excludeFalsy)
      await handleLoadAttachments(avatarsKeys)

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [handleFetchAccessRequestsList, handleLoadAttachments, search, statusStable],
  )

  return { loader, getAttachmentUrlByKey }
}

export const handleReloadHubAccessRequestsTables = async () => {
  await Promise.all([
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.HUB_REQUESTS] }),
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.HUB_REQUESTS_COUNT] }),
  ])
  tableActions.reload([TableKey.HUB_ACCESS_PENDING_REQUESTS])
  tableActions.deselectAll(TableKey.HUB_ACCESS_PENDING_REQUESTS)
}

export const useOnRowClick = (getAttachmentUrlByKey: (key?: string) => string) => {
  return useStableCallback(({ event, data }: RowClickedEvent<HubAccessRequest>) => {
    if (!hasClosestInteractiveElement(event?.target as HTMLElement, ['[col-id=actions]', '[col-id=select]'])) {
      showRequestDetailsSideModal({
        requests: [data!],
        getAttachmentUrlByKey,
        type: RequestsType.HUB_ACCESS,
        handleApprove: () => {
          showApproveHubAccessRequestModal({
            requests: [data!],
            getAttachmentUrlByKey,
            onSubmitted: () => {
              hideRequestDetailsSideModal()
            },
          })
        },
        handleReject: () => {
          showRejectHubAccessRequestModal({
            requests: [data!],
            getAttachmentUrlByKey,
            onSubmitted: () => {
              hideRequestDetailsSideModal()
            },
          })
        },
      })
    }
  })
}
