import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateGroupsRolesApi } from 'api/groups/mutations/useUpdateGroupsRolesApi'
import { useUpdateUsersRolesApi } from 'api/users/mutations/useUpdateUsersRolesApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Permission } from 'constants/permission'
import { NONE_SELECTED_VALUE } from 'constants/select'
import { canManage } from 'pages/admin/hierarchy/utils'
import { handleReloadMemberRolesEditTable } from 'pages/admin/members/editMemberSideModal/utils'
import { handleReloadGroupsListTable } from 'pages/admin/members/groups/utils'
import { handleReloadGroupRolesTable } from 'pages/admin/members/groups/viewGroupSideModal/utils'
import { handleReloadMembersTable } from 'pages/admin/members/membersList/membersTable/utils'
import { showSelectRoleSideModal } from 'pages/admin/members/selectRoleSideModal/SelectRoleSideModal'
import { handleReloadMemberAccess, handleReloadMemberRolesTable } from 'pages/admin/members/viewMemberSideModal/utils'
import { handleReloadRolesTable } from 'pages/admin/roles/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { AzGroup, TenantGroup } from 'types/groups/groups'
import { getGroupId, getMemberRolesPayload, mapGroupRolesPayloadToCreate } from 'utils/roles'

interface AddRoleToMember {
  email: string
  fullName: string
  onOpen?: () => void
  onClose?: () => void
}

interface AddRoleToGroup {
  group: AzGroup | TenantGroup
  onOpen?: () => void
  onClose?: () => void
}

export const useOpenSelectRoleSideModal = () => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleUpdateUsersRoles } = useUpdateUsersRolesApi()
  const { mutateAsync: handleUpdateGroupsRoles } = useUpdateGroupsRolesApi()

  return {
    onAddRoleToMember: useCallback(
      ({ email, fullName, onOpen, onClose }: AddRoleToMember) => {
        showSelectRoleSideModal({
          title: t('os.roles.header.assign_role_to', { fullName }),
          roleFieldDescription: t('os.members_list.view_member.select_role.role_label_description'),
          onSubmit: async ({ role, navigation }) => {
            if (!role || role === NONE_SELECTED_VALUE) {
              return
            }
            try {
              await handleUpdateUsersRoles({
                create: navigation.map(({ id }) => getMemberRolesPayload(email, role.id, id)),
                delete: [],
              })

              await Promise.all([
                handleReloadMemberRolesTable(),
                handleReloadRolesTable(),
                handleReloadMemberRolesEditTable(),
                handleReloadMemberAccess(),
              ])

              enqueueToast({
                message: t('os.roles.manage_roles.toasts.success_assign'),
                type: 'success',
              })
            } catch {
              enqueueToast({
                message: t('os.common.errors.general'),
                type: 'error',
              })
            }
          },
          onOpen,
          onClose,
        })
      },
      [enqueueToast, handleUpdateUsersRoles, t],
    ),
    onAddRoleToGroup: useCallback(
      ({ group, onOpen, onClose }: AddRoleToGroup) => {
        showSelectRoleSideModal({
          title: t('os.groups.actions.add_role_to_group', { groupName: group.name }),
          roleFieldDescription: t('os.groups.view_group.select_role.role_field_description'),
          group,
          onSubmit: async ({ role, navigation }) => {
            if (role && role !== NONE_SELECTED_VALUE) {
              try {
                await handleUpdateGroupsRoles({
                  create: mapGroupRolesPayloadToCreate([getGroupId(group)], [{ ...role, uniqueAccounts: navigation! }]),
                  delete: [],
                })

                await Promise.all([
                  handleReloadGroupRolesTable(),
                  handleReloadGroupsListTable(),
                  handleReloadMemberAccess(),
                ])

                enqueueToast({
                  message: t('os.groups.view_group.roles_table.toasts.success_assign'),
                  type: 'success',
                })
              } catch {
                enqueueToast({
                  message: t('os.common.errors.general'),
                  type: 'error',
                })
              }
            }
          },
          onClose,
          onOpen,
        })
      },
      [enqueueToast, handleUpdateGroupsRoles, t],
    ),
  }
}

export const handleGroupDelete = async (onEdit?: () => void) => {
  await handleReloadMemberRolesTable()
  /* Invalidate group users when delete performed in groups table context  */
  if (!onEdit) {
    await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.GROUP_USERS_LIST] })
  }

  await Promise.all([
    handleReloadMembersTable(),
    handleReloadMemberAccess(),
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AZ_USER_BY_EMAIL] }),
  ])
}
export const useCanManageHierarchy = canManage([Permission.OS_WORKSPACE_MANAGE], [Permission.OS_PREFIX])
