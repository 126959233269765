import { NavigationState } from '@platform-ui-kit/components-library'
import { WppTypography } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { ANALYTICS_EVENTS } from 'constants/analytics'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'
import { useUserCanManageHierarchy } from 'hooks/useUserCanManageHierarchy'
import { PageContent } from 'layout/pageContent/PageContent'
import { Subheader } from 'layout/subheader/Subheader'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { getAnalyticsEventPayload, trackAnalytics } from 'utils/analytics'
import { routesManager } from 'utils/routesManager'

const useNavigation = (): NavigationState[] => {
  const { t } = useTranslation()
  const { hasPermission } = useHasPermission()
  const { isUserCanManage: isUserCanManageHierarchy } = useUserCanManageHierarchy(true)

  const {
    userDetails: { isExternal: isExternalUser },
  } = useOtherTenantsAndUserData()

  return useMemo(
    () => [
      {
        label: t('os.admin.topbar.navigation.hubs'),
        value: 'hubs',
        path: routesManager.layerAdmin.hubs.url(),
      },
      {
        label: t('os.requests.segments.hub_access'),
        value: 'hub-access',
        path: routesManager.layerAdmin.hubAccess.root.url(),
      },
      ...(hasPermission(Permission.OS_WORKSPACE_MANAGE) || isUserCanManageHierarchy
        ? [
            {
              label: t('os.admin.topbar.hierarchy'),
              value: 'hierarchy',
              path: routesManager.layerAdmin.hierarchy.url(),
            },
          ]
        : []),
      ...(isExternalUser
        ? [
            {
              label: t('os.admin.topbar.groups'),
              value: 'members',
              path: routesManager.layerAdmin.members.groups.url(),
            },
          ]
        : [
            {
              label: t('os.admin.topbar.members'),
              value: 'members',
              path: routesManager.layerAdmin.members.root.url(),
            },
          ]),
      {
        label: t('os.entities.role_other'),
        value: 'roles',
        path: routesManager.layerAdmin.roles.url(),
      },

      {
        label: t('os.admin.topbar.apps'),
        value: 'apps',
        path: routesManager.layerAdmin.root.url(),
      },
    ],
    [t, hasPermission, isUserCanManageHierarchy, isExternalUser],
  )
}

export const LayerAdminPage = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const navigate = useNavigate()
  const navigation = useNavigation()
  const currentPage = matchPath(`${routesManager.layerAdmin.root.url()}/:tab/*`, pathname)?.params.tab || ''

  return (
    <>
      <Subheader
        value={currentPage}
        navigation={navigation}
        onWppChange={({ detail }) => {
          if (detail.label) {
            trackAnalytics({
              type: AnalyticsActionType.action,
              payload: getAnalyticsEventPayload(ANALYTICS_EVENTS.ADMIN.ACTIONS.OPEN_TAB, detail.label),
            })
          }
          navigate(detail.path!)
        }}
      >
        <WppTypography
          slot="app"
          type="m-strong"
          tag="h3"
          style={{ minWidth: '100px' }}
          data-testid="layer-admin-section-header"
        >
          {t('os.header.user_avatar_dropdown_menu.layer_admin_section_header')}
        </WppTypography>
      </Subheader>

      <PageContent>{children}</PageContent>
    </>
  )
}
