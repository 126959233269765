import { tableActions } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Permission } from 'constants/permission'
import { TableKey } from 'constants/table'
import { canManage } from 'pages/admin/hierarchy/utils'
import { queryClient } from 'providers/osQueryClient/utils'

export const handleReloadGroupsListTable = async () => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.GROUPS_LIST] })
  tableActions.reload(TableKey.GROUPS)
}

export const useCanManageGroup = canManage([Permission.AZ_MANAGE_ENTITIES_AND_ASSIGNMENTS], [Permission.AZ_PREFIX])
