import {
  WppActionButton,
  WppButton,
  WppDivider,
  WppIconBold,
  WppIconChevron,
  WppIconIndentDecrease,
  WppIconIndentIncrease,
  WppIconItalic,
  WppIconLocation,
  WppIconRedo,
  WppIconStrikeThrough,
  WppIconTrash,
  WppIconUnderline,
  WppIconUndo,
  WppIconVideoOn,
  WppToggle,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { addMinutes, isAfter, isBefore, isPast, isSameDay, set } from 'date-fns'
import { useMemo, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateCalendarWidgetEventApi } from 'api/calendars/mutations/useCreateCalendarWidgetEvent'
import { useUpdateCalendarWidgetEventApi } from 'api/calendars/mutations/useUpdateCalendarWidgetEvent'
import { Flex } from 'components/common/flex/Flex'
import { FormDatepicker } from 'components/form/formDatepicker/FormDatepicker'
import { FormRichtext } from 'components/form/formRichtext/FormRichtext'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { FormToggle } from 'components/form/formToggle/FormToggle'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useForm } from 'hooks/form/useForm'
import { useStableCallback } from 'hooks/useStableCallback'
import { CurrentTimeZoneLabel } from 'pages/admin/notifications/currentTimeZoneLabel/CurrentTimeZoneLabel'
import { showDeleteCalendarEventModal } from 'pages/home/systemWidgets/calendarWidget/deleteCalendarEventModal/DeleteCalendarEventModal'
import { ColorInput } from 'pages/home/systemWidgets/calendarWidget/manageEventSideModal/colorInput/ColorInput'
import styles from 'pages/home/systemWidgets/calendarWidget/manageEventSideModal/ManageEventSideModal.module.scss'
import {
  AddEventModalFormValues,
  defaultTime,
  DESCRIPTION_CHARACTERS_LIMIT,
  getDefaultValues,
  handleReloadCalendarEvents,
  setEventValues,
  useGetTimesListOptions,
  useRichtextConfig,
  useValidationSchema,
} from 'pages/home/systemWidgets/calendarWidget/manageEventSideModal/utils'
import { useHubId } from 'pages/hubs/utils'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useToast } from 'providers/toast/ToastProvider'
import { CalendarWidgetEventDTO } from 'types/widgets/widget'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  event?: CalendarWidgetEventDTO
  selectedDate?: Date
  calendarId?: string
}

export const ManageEventSideModal = ({
  id,
  isOpen,
  onClose,
  onCloseComplete,
  event,
  selectedDate,
  calendarId,
}: Props) => {
  const hubId = useHubId()
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { currentTenant } = useCurrentTenantData()
  const { mutateAsync: handleCreateCalendarWidgetEvent } = useCreateCalendarWidgetEventApi()
  const { mutateAsync: handleUpdateCalendarWidgetEvent } = useUpdateCalendarWidgetEventApi()
  const [predefinedTimes, setPredefinedTime] = useState<MayBeNull<Partial<AddEventModalFormValues>>>(null)

  const isEditMode = !!event
  const richtextConfig = useRichtextConfig()

  const [sendEventNotifications, setSendEventNotifications] = useState(event ? event.sendNotification : true)
  // If we are in edit mode and the end date is in the past, make the toggle disabled and false from the beginning
  const [disableEventNotifications, setDisableEventNotifications] = useState(
    false || isEditMode ? (isPast(new Date(event.endsAt)) ? true : false) : false,
  )

  const form = useForm({
    defaultValues: useMemo(() => getDefaultValues(event, selectedDate), [event, selectedDate]),
    validationSchema: useValidationSchema(),
  })

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
    watch,
    setValue,
    setValues,
    trigger,
    resetField,
  } = form
  const [color, startDate, endDate, startTime, endTime, allDay] = watch([
    'color',
    'startDate',
    'endDate',
    'startTime',
    'endTime',
    'allDay',
  ])

  const { startTimeListOptions, endTimeListOptions } = useGetTimesListOptions(startTime, startDate, endDate)

  const onCreateSubmit = async (values: AddEventModalFormValues) => {
    try {
      await handleCreateCalendarWidgetEvent({
        ...setEventValues(values),
        calendarId,
        hub_id: hubId,
        tenantId: currentTenant.id,
      })
      await handleReloadCalendarEvents()
      enqueueToast({
        message: t('os.home.calendar_widget.manage_event.toasts.add_success'),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('os.common.errors.general'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  }

  const onEditSubmit = async (values: AddEventModalFormValues) => {
    try {
      await handleUpdateCalendarWidgetEvent({
        ...setEventValues(values),
        id: event!.id,
        hub_id: hubId,
        tenantId: currentTenant.id,
      })
      await handleReloadCalendarEvents()
      enqueueToast({
        message: t('os.home.calendar_widget.manage_event.toasts.edit_success'),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('os.common.errors.general'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  }

  const handleStartDateChange = useStableCallback((date: Date) => {
    const modifiedStartTime = set(new Date(startTime), {
      year: date.getFullYear(),
      month: date.getMonth(),
      date: date.getDate(),
    })
    setValue('startTime', modifiedStartTime.toString())

    if (!isAfter(endDate, date)) {
      setValue('endDate', date)

      if (isSameDay(date, endDate) && isBefore(new Date(endTime), new Date(modifiedStartTime))) {
        setValue('endTime', modifiedStartTime.toString())
      }
    }
  })

  const handleEndDateChange = useStableCallback((date: Date) => {
    // TODO: Remove when CL would fix minDate/maxDate in WPPLONOP-19337
    if (isBefore(date, startDate)) {
      resetField('endDate', {
        defaultValue: startDate,
      })

      return
    }

    let modifiedEndTime = set(new Date(endTime), {
      year: date.getFullYear(),
      month: date.getMonth(),
      date: date.getDate(),
    })

    if (isSameDay(date, startDate) && isBefore(new Date(modifiedEndTime), new Date(startTime))) {
      modifiedEndTime = set(modifiedEndTime, {
        hours: new Date(startTime).getHours(),
        minutes: new Date(startTime).getMinutes(),
      })
    }

    setDisableEventNotifications(isPast(modifiedEndTime) ? true : false)

    setValues({
      endTime: modifiedEndTime.toString(),
      sendNotification: isPast(modifiedEndTime) ? false : allDay ? false : true,
    })
  })

  const handleToggleChange = useStableCallback(({ detail: { checked } }: CustomEvent) => {
    if (checked) {
      // sets 00:00 for start time and 23:59 for the end time and predefines time values
      setPredefinedTime({ startTime, endTime })
      setValues({
        startTime: set(new Date(startTime), defaultTime).toString(),
        endTime: addMinutes(
          set(new Date(endTime), { date: new Date(endTime).getDate() + 1, ...defaultTime }),
          -1,
        ).toString(),
        sendNotification: false,
      })
    } else {
      // sets default 00:00 time in the case time is not predefined
      setValues(
        predefinedTimes || {
          startTime: set(startDate, defaultTime).toString(),
          endTime: set(endDate, defaultTime).toString(),
        },
      )
      setValue('sendNotification', disableEventNotifications ? false : sendEventNotifications)
    }
  })

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{
          onSubmit: handleSubmit(isEditMode ? onEditSubmit : onCreateSubmit),
        }}
        data-testid={id}
        open={isOpen}
        size="m"
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        disableOutsideClick
      >
        <Flex slot="header" align="center">
          <WppActionButton onClick={onClose}>
            <WppIconChevron slot="icon-start" direction="left" className={styles.backIcon} />
          </WppActionButton>
          <WppTypography type="2xl-heading">
            {isEditMode
              ? t('os.home.calendar_widget.manage_event.header.edit')
              : t('os.home.calendar_widget.manage_event.header.add')}
          </WppTypography>
        </Flex>

        <Flex slot="body" direction="column" gap={28}>
          <FormTextInput
            name="name"
            labelConfig={{
              text: t('os.home.calendar_widget.manage_event.name.label'),
            }}
            placeholder={t('os.home.calendar_widget.manage_event.name.placeholder')}
            required
            className={styles.nameInput}
            data-testid="add-event-name-section"
          >
            <Flex slot="icon-start">
              <ColorInput
                color={color}
                onChange={currentColor => {
                  setValue('color', currentColor)
                  trigger('color')
                }}
              />
              <WppDivider className={styles.divider} />
            </Flex>
          </FormTextInput>

          <WppToggle
            labelConfig={{
              text: 'Send notification to hub members',
            }}
            onWppChange={e => {
              setSendEventNotifications(allDay || disableEventNotifications ? false : e.detail.checked)
              setValue('sendNotification', e.detail.checked)
            }}
            disabled={allDay || disableEventNotifications}
            checked={allDay || disableEventNotifications ? false : sendEventNotifications}
            name="sendNotification"
            required
            data-testid="send-notification-toggle"
          />

          <Flex direction="column" gap={12}>
            <Flex gap={4}>
              <WppTypography type="s-strong">{t('os.home.calendar_widget.manage_event.timezone_label')}</WppTypography>
              <CurrentTimeZoneLabel labelType="s-body" offsetType="s-body" />
            </Flex>

            <Flex gap={8}>
              <Flex gap={16} direction="column" className={styles.dateTimePickersWrapper}>
                <Flex gap={8}>
                  <FormDatepicker
                    name="startDate"
                    mode="date"
                    className={styles.datepicker}
                    required
                    onWppChange={({ detail: { date } }) => !!date && isDirty && handleStartDateChange(date as Date)}
                    data-testid="add-event-start-date-section"
                  />
                  {!allDay && (
                    <FormSelect
                      className={styles.timeSelect}
                      name="startTime"
                      type="single"
                      options={startTimeListOptions}
                      onWppChange={option => {
                        if (isAfter(new Date(option.detail.value), new Date(endTime))) {
                          setValue('endTime', new Date(option.detail.value).toString())
                        }
                      }}
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ id }) => id}
                      data-testid="add-event-start-time-section"
                    />
                  )}
                </Flex>
                <Flex gap={8}>
                  <FormDatepicker
                    name="endDate"
                    mode="date"
                    className={styles.datepicker}
                    required
                    minDate={startDate}
                    onWppChange={({ detail: { date } }) => !!date && isDirty && handleEndDateChange(date as Date)}
                    data-testid="add-event-end-date-section"
                  />
                  {!allDay && (
                    <FormSelect
                      className={styles.timeSelect}
                      name="endTime"
                      type="single"
                      options={endTimeListOptions}
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ id }) => id}
                      data-testid="add-event-end-time-section"
                    />
                  )}
                </Flex>
              </Flex>
              <Flex className={styles.toggleWrapper} align="center">
                <FormToggle
                  required
                  name="allDay"
                  labelConfig={{
                    text: t('os.home.calendar_widget.manage_event.all_day'),
                  }}
                  onWppChange={handleToggleChange}
                  data-testid="all-day-toggle"
                />
              </Flex>
            </Flex>
          </Flex>

          <FormTextInput
            name="location"
            labelConfig={{
              text: t('os.home.calendar_widget.manage_event.location.label'),
            }}
            placeholder={t('os.home.calendar_widget.manage_event.location.placeholder')}
            data-testid="add-event-location-section"
          >
            <WppIconLocation slot="icon-start" aria-label="Location icon" />
          </FormTextInput>

          <FormTextInput
            name="videoConferencingUrl"
            labelConfig={{
              text: t('os.home.calendar_widget.manage_event.video_link.label'),
            }}
            placeholder={t('os.home.calendar_widget.manage_event.video_link.placeholder')}
            data-testid="add-event-video-link-section"
          >
            <WppIconVideoOn slot="icon-start" aria-label="Video on icon" />
          </FormTextInput>

          <Flex direction="column" gap={24}>
            {/* TODO Temporary workaround to load icons https://jira.uhub.biz/browse/WPPLONOP-21371 */}
            <div className={styles.hidden}>
              <WppIconItalic />
              <WppIconBold />
              <WppIconUnderline />
              <WppIconStrikeThrough />
              <WppIconIndentIncrease />
              <WppIconIndentDecrease />
              <WppIconUndo />
              <WppIconRedo />
            </div>
            <FormRichtext
              name="description"
              className={styles.richtext}
              modules={richtextConfig}
              labelConfig={{
                text: t('os.home.calendar_widget.manage_event.description.label'),
              }}
              placeholder={t('os.home.calendar_widget.manage_event.description.placeholder')}
              charactersLimit={DESCRIPTION_CHARACTERS_LIMIT}
              warningThreshold={DESCRIPTION_CHARACTERS_LIMIT}
              data-testid="add-event-description-section"
            />
          </Flex>
        </Flex>

        <Flex slot="actions" justify={isEditMode ? 'between' : 'end'}>
          {isEditMode && (
            <WppActionButton
              onClick={() =>
                showDeleteCalendarEventModal({
                  event,
                  onClose: () => {
                    onClose()
                  },
                })
              }
            >
              <WppIconTrash slot="icon-start" />
              {t('os.common.delete')}
            </WppActionButton>
          )}
          <Flex gap={12}>
            <WppButton onClick={onClose} variant="secondary" size="s">
              {t('os.common.cancel')}
            </WppButton>
            <WppButton size="s" variant="primary" type="submit" loading={isSubmitting} disabled={!isValid}>
              {isEditMode
                ? t('os.home.calendar_widget.manage_event.manage_button.edit')
                : t('os.home.calendar_widget.manage_event.manage_button.add')}
            </WppButton>
          </Flex>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showManageEventSideModal } = createNiceModal(
  ManageEventSideModal,
  'calendar-widget-add-event-modal',
)
