import { AzGroup, TenantGroup } from 'types/groups/groups'

const EXTERNAL_GROUP_NAME = 'OS External Users'

export const mapAzGroupToTenantGroup = (azGroup: AzGroup): TenantGroup => ({
  id: azGroup.uid,
  createdOnAccountId: azGroup.account_uid,
  customData: azGroup.custom_data,
  name: azGroup.name,
  description: azGroup.description,
  uniqueRolesCount: 0,
  uniqueUsersCount: 0,
})

export const isExternalGroup = (group: AzGroup | TenantGroup) => group?.name === EXTERNAL_GROUP_NAME
