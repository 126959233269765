import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/notificationItemSideModal/NotificationItemSideModal.module.scss'
import { WPP_OPEN_SUPPORT_EMAIL } from 'constants/common'
import { mailto } from 'utils/links'

export const HubRejectedSideModalContent = ({ hubName, rejectReason }: { hubName: string; rejectReason: string }) => {
  const { t } = useTranslation()

  return (
    <Flex direction="column" gap={8}>
      <WppTypography type="s-body" data-testid="notification-item-content-hub-rejected">
        <Trans
          i18nKey="os.notification.access.rejected_access"
          values={{
            type: `<0>${t('os.notification.access.hub.admin_title', { hubName })}</0>`,
          }}
          components={[
            <WppTypography key="0" type="s-strong">
              type
            </WppTypography>,
          ]}
        />
      </WppTypography>
      <WppTypography type="s-body" data-testid="notification-item-content-hub-rejected-description">
        {t('os.notification.access.hub.rejected_access', { hubName })}
        <br />
        {rejectReason}
      </WppTypography>
      <WppTypography type="xs-body" className={styles.greyTypography} data-testid="notification-item-support">
        <Trans
          i18nKey="os.notification.access.support"
          values={{
            supportEmail: WPP_OPEN_SUPPORT_EMAIL,
          }}
          components={[
            <a key="0" href={mailto(WPP_OPEN_SUPPORT_EMAIL)}>
              supportEmail
            </a>,
          ]}
        />
      </WppTypography>
    </Flex>
  )
}
