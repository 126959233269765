import { WppTag, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import styles from 'components/cellRenderers/avatarWithName/AvatarWithNameCell.module.scss'
import { Avatar } from 'components/common/avatar/Avatar'
import { ClipboardText } from 'components/common/clipboardText/ClipboardText'
import { Flex } from 'components/common/flex/Flex'
import { MeTag } from 'components/common/meTag/MeTag'

export interface Props {
  isCurrentUser?: boolean
  avatarThumbnail: string
  userName: string
  isCopiable?: boolean
  copyTooltip?: string
  isExternal?: boolean
}

export const AvatarWithNameCell = ({
  isCurrentUser = false,
  avatarThumbnail,
  userName,
  isCopiable = false,
  copyTooltip,
  isExternal = false,
}: Props) => {
  const { t } = useTranslation()
  return (
    <Flex className={styles.flexBaseWidth} align="center">
      <Avatar className={styles.avatar} size="s" src={avatarThumbnail} name={userName} />

      <Flex className={styles.flexBaseWidth} align="center" gap={4}>
        {isCopiable ? (
          <ClipboardText
            text={userName}
            tooltipMessage={copyTooltip || t('os.common.copy_to_clipboard')}
            showEndTag={isExternal}
            textEndTag={t('os.entities.external')}
          >
            <Flex gap={4} align="center">
              {userName}
              {isCurrentUser && <MeTag />}
            </Flex>
          </ClipboardText>
        ) : (
          <>
            <WppTypography className={styles.flexBaseWidth} type="s-body">
              {userName}
            </WppTypography>
            {isCurrentUser && <MeTag />}
            {isExternal && <WppTag className={styles.external} label={t('os.entities.external')} variant="neutral" />}
          </>
        )}
      </Flex>
    </Flex>
  )
}
