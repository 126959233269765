import {
  WppActionButton,
  WppIconRemoveCircle,
  WppInput,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppIconUserAdd,
  WppPill,
  WppIconChevron,
  WppTooltip,
} from '@platform-ui-kit/components-library-react'
import { SelectionChangedEvent, CellClickedEvent } from 'ag-grid-community'
import clsx from 'clsx'
import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'
import { useSetState } from 'react-use'

import { is5xxError } from 'api/utils'
import { AvatarWithNameCell } from 'components/cellRenderers/avatarWithName/AvatarWithNameCell'
import { AvatarWithInfoSkeleton } from 'components/cellRenderers/skeletons/avatarWithInfoSkeleton/AvatarWithInfoSkeleton'
import { ClipboardText } from 'components/common/clipboardText/ClipboardText'
import { EmptyStateWithAction } from 'components/common/emptyStateWithAction/EmptyStateWithAction'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, TableInfinite } from 'components/common/table'
import { CriticalError } from 'components/renderError'
import { Delay } from 'constants/delay'
import { TableKey } from 'constants/table'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useNumberFormat } from 'hooks/useNumberFormat'
import { useStableCallback } from 'hooks/useStableCallback'
import { showClearSelectionModal } from 'pages/admin/members/clearSelectionModal/ClearSelectionModal'
import { showDeleteRoleMemberModal } from 'pages/admin/roles/deleteRoleMemberModal/DeleteRoleMemberModal'
import { showDeleteRoleMembersModal } from 'pages/admin/roles/deleteRoleMembersModal/DeleteRoleMembersModal'
import styles from 'pages/admin/roles/viewRoleSideModal/roleMembersSection/RoleMembersSection.module.scss'
import {
  RolesListSegment,
  State,
  useRoleMembersLoader,
} from 'pages/admin/roles/viewRoleSideModal/roleMembersSection/utils'
import { deselectRoleGroups, deselectRoleMembers } from 'pages/admin/roles/viewRoleSideModal/utils'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { RoleMember } from 'types/roles/roleMember'
import { Role } from 'types/roles/roles'
import { join } from 'utils/common'
import { routesManager } from 'utils/routesManager'

interface Props {
  role: Role
  onViewMember: (member: RoleMember) => void
  onAddMember: () => void
  onData: (membersCount: number) => void
}

export const RoleMembersSection = ({ role, onViewMember, onAddMember, onData }: Props) => {
  const { formatNumber } = useNumberFormat()
  const { t } = useTranslation()
  const { userDetails } = useOtherTenantsAndUserData()

  const onViewMemberStable = useStableCallback(onViewMember)

  const isGroupsPage = !!useMatch(routesManager.admin.members.groups.url())

  const [
    {
      hasDirectAssignment,
      hasGroupsAssignment,
      search,
      currentSegment,
      selectedMembers,
      totalMembersCount,
      isCriticalError,
    },
    setState,
  ] = useSetState<State>({
    hasDirectAssignment: false,
    hasGroupsAssignment: false,
    search: '',
    totalMembersCount: -1,
    selectedMembers: [],
    isCriticalError: false,
    currentSegment: RolesListSegment.ALL,
  })

  const { avatarsCache, loader } = useRoleMembersLoader({
    search,
    roleId: role.id,
    hasDirectAssignment,
    hasGroupsAssignment,
  })

  const onSelectionChanged = useStableCallback(({ api }: SelectionChangedEvent<RoleMember>) => {
    setState({ selectedMembers: api.getSelectedRows() || [] })
  })

  const handleSelectCellClick = useStableCallback(({ node }: CellClickedEvent<RoleMember>) => {
    const isNodeSelected = node.isSelected()
    node.setSelected(!isNodeSelected)
  })

  const setSearchDebounced = useDebounceFn(
    (search?: string) => setState({ search: search?.trim() || '', totalMembersCount: -1 }),
    Delay.Search,
  )

  const assignToMembers = useCallback(() => {
    deselectRoleGroups()
    deselectRoleMembers()
    onAddMember()
  }, [onAddMember])

  const columnDefs = useMemo<ColDef<RoleMember>[]>(
    () => [
      {
        width: 50,
        colId: 'select',
        checkboxSelection: true,
        hide: currentSegment !== RolesListSegment.MEMBERS,
        onCellClicked: handleSelectCellClick,
      },
      {
        flex: 1,
        headerName: t('os.roles.view_role.groups.table.name'),
        colId: 'name',
        loadingCellRenderer: AvatarWithInfoSkeleton,
        cellRenderer: ({ data }) => {
          const { firstName, lastName, email, avatarThumbnail, isExternal } = data!
          const userName = join([firstName, lastName], ' ')
          const isCurrentUser = email === userDetails.email
          const thumbnail = avatarThumbnail ? avatarsCache.current[avatarThumbnail.key] || '' : ''

          return (
            <AvatarWithNameCell
              isCurrentUser={isCurrentUser}
              avatarThumbnail={thumbnail}
              userName={userName}
              isCopiable
              isExternal={isExternal}
              copyTooltip={t('os.common.copy_entity_to_clipboard', {
                entity: t('os.members_list.table.tooltips.member_name'),
              })}
            />
          )
        },
      },
      {
        flex: 1,
        colId: 'email',
        headerName: t('os.roles.view_role.groups.table.email'),
        cellRenderer: ({ data }) => (
          <ClipboardText
            text={data!.email}
            tooltipMessage={t('os.common.copy_entity_to_clipboard', {
              entity: t('os.members_list.table.tooltips.member_email'),
            })}
          >
            {data!.email}
          </ClipboardText>
        ),
      },
      {
        width: 60,
        colId: 'remove',
        hide: currentSegment === RolesListSegment.GROUPS,
        cellRenderer: ({ data }) => (
          <WppTooltip
            config={{ placement: 'left' }}
            text={
              !data!.assignmentsDirect.length
                ? t('os.roles.view_role.members.actions.groups_remove_tooltip')
                : t('os.roles.view_role.members.actions.remove')
            }
          >
            <WppActionButton
              disabled={!data!.assignmentsDirect.length}
              variant="secondary"
              onClick={() => {
                deselectRoleMembers()
                showDeleteRoleMemberModal({
                  member: data!,
                  role: role!,
                })
              }}
            >
              <WppIconRemoveCircle slot="icon-start" />
            </WppActionButton>
          </WppTooltip>
        ),
      },
      {
        width: 60,
        colId: 'view',
        cellRenderer: ({ data }) =>
          userDetails.isExternal ? null : (
            <WppTooltip text={t('os.roles.table.actions_column.view_member')} config={{ placement: 'left' }}>
              <WppActionButton
                disabled={isGroupsPage}
                onClick={() => {
                  deselectRoleMembers()
                  onViewMemberStable(data!)
                }}
              >
                <WppIconChevron direction="right" />
              </WppActionButton>
            </WppTooltip>
          ),
      },
    ],
    [
      currentSegment,
      handleSelectCellClick,
      t,
      userDetails.email,
      userDetails.isExternal,
      avatarsCache,
      role,
      isGroupsPage,
      onViewMemberStable,
    ],
  )
  return (
    <>
      {isCriticalError && <CriticalError />}

      <Flex gap={12} direction="column" className={styles.membersTable}>
        <Flex justify="between">
          <Flex gap={12} align="center">
            <WppInput
              size="s"
              type="search"
              className={styles.searchInput}
              onWppChange={({ detail }) => setSearchDebounced(detail.value)}
              placeholder={t('os.members_list.search_placeholder')}
              data-testid="members-search-input-on-role-modal"
            />
            <WppSegmentedControl
              size="s"
              value={currentSegment}
              onWppChange={({ detail: { value } }) => {
                const segment = value as RolesListSegment
                setState({
                  currentSegment: segment,
                  hasGroupsAssignment: segment === RolesListSegment.GROUPS,
                  hasDirectAssignment: segment === RolesListSegment.MEMBERS,
                  totalMembersCount: -1,
                })
              }}
              data-testid="role-members-segment"
            >
              <WppSegmentedControlItem value={RolesListSegment.ALL}>
                {t('os.roles.table.columns.all')}
              </WppSegmentedControlItem>

              <WppSegmentedControlItem value={RolesListSegment.MEMBERS}>
                {t('os.roles.view_role.members.segments.direct_assignment')}
              </WppSegmentedControlItem>

              <WppSegmentedControlItem value={RolesListSegment.GROUPS}>
                {t('os.roles.view_role.members.segments.group_affiliation')}
              </WppSegmentedControlItem>
            </WppSegmentedControl>
          </Flex>
          <Flex gap={12} align="center">
            {!!selectedMembers.length && (
              <>
                <WppPill
                  type="display"
                  removable
                  label={t('os.members.header.selected_entities_chip', {
                    entitiesCount: formatNumber(selectedMembers.length),
                  })}
                  onWppClose={() =>
                    showClearSelectionModal({
                      onSubmit: () => deselectRoleMembers(),
                    })
                  }
                />
                <WppActionButton onClick={() => showDeleteRoleMembersModal({ role, members: selectedMembers })}>
                  <WppIconRemoveCircle slot="icon-start" />
                  {t('os.common.remove')}
                </WppActionButton>
              </>
            )}
            {!selectedMembers.length && totalMembersCount > 0 && (
              <WppActionButton onClick={assignToMembers}>
                <WppIconUserAdd slot="icon-start" />
                {t('os.roles.table.actions_column.assign_to_members')}
              </WppActionButton>
            )}
          </Flex>
        </Flex>

        {totalMembersCount === 0 ? (
          <EmptyStateWithAction
            label={
              search
                ? t('os.roles.table.placeholder.no_search_records', { search })
                : t('os.roles.table.placeholder.no_members_associated')
            }
            actionLabel={!search && t('os.roles.table.actions_column.assign_to_member')}
            onAction={assignToMembers}
          />
        ) : (
          <TableInfinite
            tableKey={TableKey.VIEW_ROLE_MEMBERS}
            className={clsx('role-members', styles.table)}
            columnDefs={columnDefs}
            getRowId={({ data }) => data.id}
            onSelectionChanged={onSelectionChanged}
            loader={loader}
            onLoadSuccess={({ totalRowsCount }) => {
              setState({ totalMembersCount: totalRowsCount })
              onData(totalRowsCount)
            }}
            onLoadError={error => {
              if (is5xxError(error)) {
                setState({ isCriticalError: true })
              }
            }}
          />
        )}
      </Flex>
    </>
  )
}
