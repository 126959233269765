import { WppTab, WppTabs, WppTypography, WppSkeleton, WppTag } from '@platform-ui-kit/components-library-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserByEmailApi } from 'api/users/queries/useUserByEmailApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { MeTag } from 'components/common/meTag/MeTag'
import { useCountries } from 'hooks/useCountries'
import { useNumberFormat } from 'hooks/useNumberFormat'
import { useStableCallback } from 'hooks/useStableCallback'
import { useHierarchyAccountData } from 'pages/admin/hierarchy/hierarchySettings/utils'
import { showDeleteGroupMemberModal } from 'pages/admin/members/deleteGroupMemberModal/DeleteGroupMemberModal'
import { showDeleteGroupsMemberModal } from 'pages/admin/members/deleteGroupsMemberModal/DeleteGroupsMemberModal'
import { handleGroupDelete } from 'pages/admin/members/utils'
import {
  adaptMemberGroups,
  MemberSideModalTabs,
  useMemberAccessTree,
  useRolesCount,
  useHubsCount,
} from 'pages/admin/members/viewMemberSideModal/utils'
import { ViewMemberAccessSection } from 'pages/admin/members/viewMemberSideModal/viewMemberAccessSection/ViewMemberAccessSection'
import { ViewMemberGroupsSection } from 'pages/admin/members/viewMemberSideModal/viewMemberGroupsSection/ViewMemberGroupsSection'
import { ViewMemberHubsSection } from 'pages/admin/members/viewMemberSideModal/viewMemberHubsSection/ViewMemberHubsSection'
import { ViewMemberRolesSection } from 'pages/admin/members/viewMemberSideModal/viewMemberRolesSection/ViewMemberRolesSection'
import styles from 'pages/admin/members/viewMemberSideModal/ViewMemberSideModal.module.scss'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { DownloadInfo } from 'types/attachments/attachments'
import { AZUser } from 'types/users/users'
import { join } from 'utils/common'
import { mailto } from 'utils/links'

interface Props {
  user: AZUser
  avatar?: DownloadInfo
  onEdit?: () => void
  onViewRole: (roleId: string) => void
  onAddRole: () => void
  onAddGroup: () => void
  onViewGroup: (groupId: string) => void
  isLayerAdminPage: boolean
}

export const ViewMemberSideModalContent = ({
  user,
  avatar,
  onEdit,
  onViewRole,
  onAddRole,
  onAddGroup,
  onViewGroup,
  isLayerAdminPage = false,
}: Props) => {
  const { userDetails: currentUserDetails } = useOtherTenantsAndUserData()
  const [activeTab, setActiveTab] = useState<string>(MemberSideModalTabs.ACCESS)
  const { formatNumber } = useNumberFormat()
  const { t } = useTranslation()
  const { currentTenant } = useCurrentTenantData()
  const { normalizedCountries } = useCountries()
  const { getHierarchyAccountData } = useHierarchyAccountData()

  const { memberAccessTree, isFetchingMemberAccessTree } = useMemberAccessTree(user.email, currentTenant.id)
  const totalRoles = useRolesCount(user.email, currentTenant.id)
  const totalHubs = useHubsCount(user.email, currentTenant.id)

  const { first_name, last_name, group_membership, email } = user
  const { data: userDetails, isLoading: userDetailsIsLoading } = useUserByEmailApi({
    params: {
      email,
    },
  })

  const userName = join([first_name, last_name], ' ')
  const groups = adaptMemberGroups({
    groups: group_membership,
  })
  const filteredGroups = useMemo(
    () => groups.filter(({ account_uid }) => getHierarchyAccountData(account_uid).title),
    [groups, getHierarchyAccountData],
  )

  const onViewRoleStable = useStableCallback(onViewRole)

  return (
    <>
      <Flex align="center">
        <Avatar size="l" className={styles.avatar} src={avatar?.signed_url || ''} name={userName} />
        <Flex direction="column">
          <Flex gap={4} align="center">
            <WppTypography type="l-strong">{userName}</WppTypography>
            {email === currentUserDetails.email && <MeTag />}
          </Flex>
          <a href={mailto(email)}>
            <WppTypography type="s-body">{email}</WppTypography>
          </a>
          <Flex className={styles.userDetails}>
            {userDetailsIsLoading ? (
              <>
                <WppSkeleton width="30%" height={22} />
                <WppSkeleton width="30%" height={22} />
              </>
            ) : (
              userDetails && (
                <Flex direction="column">
                  <WppTypography type="s-body">{`${t('os.members_list.view_member.agency_label')} ${
                    userDetails.agency
                  }`}</WppTypography>
                  <WppTypography type="s-body">{`${t('os.members_list.view_member.country_label')} ${
                    userDetails.countryAlpha2Code ? normalizedCountries[userDetails.countryAlpha2Code]?.name : '-'
                  }`}</WppTypography>
                </Flex>
              )
            )}
          </Flex>
        </Flex>
        {userDetails?.isExternal && (
          <WppTag className={styles.external} label={t('os.entities.external')} variant="neutral" />
        )}
      </Flex>

      <WppTabs
        value={activeTab}
        onWppChange={({ detail }) => setActiveTab(detail.value)}
        data-testid="view-member-modal-tabs"
      >
        <WppTab className={styles.memberTab} value={MemberSideModalTabs.ACCESS}>
          {t('os.members_list.view_member.access.header')}
        </WppTab>
        <WppTab className={styles.memberTab} value={MemberSideModalTabs.GROUPS}>
          {t('os.members_list.view_member.groups.header', { countGroups: formatNumber(filteredGroups.length) })}
        </WppTab>
        {!isLayerAdminPage && (
          <WppTab className={styles.memberTab} value={MemberSideModalTabs.ROLES}>
            {t('os.members_list.view_member.roles.header', { countRoles: totalRoles ? formatNumber(totalRoles) : 0 })}
          </WppTab>
        )}
        <WppTab className={styles.memberTab} value={MemberSideModalTabs.HUBS}>
          {t('os.members_list.view_member.hubs.header', { countHubs: totalHubs ? formatNumber(totalHubs) : 0 })}
        </WppTab>
      </WppTabs>

      {activeTab === MemberSideModalTabs.ACCESS && (
        <ViewMemberAccessSection
          memberAccessTree={memberAccessTree}
          isFetchingMemberAccessTree={isFetchingMemberAccessTree}
        />
      )}
      {!isLayerAdminPage && activeTab === MemberSideModalTabs.ROLES && (
        <ViewMemberRolesSection
          onAddRole={onAddRole}
          onViewRole={onViewRoleStable}
          user={user}
          isLayerAdminPage={isLayerAdminPage}
          noResultsPlaceholder={totalRoles === 0}
        />
      )}
      {activeTab === MemberSideModalTabs.GROUPS && (
        <ViewMemberGroupsSection
          groups={filteredGroups}
          onAddGroup={onAddGroup}
          onViewGroup={onViewGroup}
          onDelete={group => {
            const { first_name, last_name, id } = user
            showDeleteGroupMemberModal({
              group,
              member: { fullName: join([first_name, last_name], ' '), id },
              onDeleteMember: () => handleGroupDelete(onEdit),
            })
          }}
          onDeleteBulk={groups => {
            const { first_name, last_name, id, email } = user
            showDeleteGroupsMemberModal({
              groups,
              member: { fullName: join([first_name, last_name], ' '), id, email },
              onDeleteMember: () => handleGroupDelete(onEdit),
            })
          }}
          noResultsPlaceholder={filteredGroups.length === 0}
        />
      )}
      {activeTab === MemberSideModalTabs.HUBS && userDetails && <ViewMemberHubsSection user={userDetails} />}
    </>
  )
}
