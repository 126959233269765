import { AppContextListener, OsContextTool } from '@wpp-open/core'
import PubSub from 'pubsub-js'
import { MutableRefObject, useCallback, useEffect } from 'react'
import { NOT_MOUNTED, SingleSpaCustomEventDetail } from 'single-spa'

import { OS_ASSISTANT_APP_CODE } from 'components/osAssistant/OsAssistant'
import { ContextValueFullPage } from 'providers/apps/utils/contextValue'
import { unregisterAllMicroApps } from 'utils/singleSpa'

const formatFullPageAppId = (appId: string) => `FULL_PAGE:${appId}`
const formatToolAppId = (appId: string) => `TOOL:${appId}`

export const useSubscriptionEffects = ({
  fullPageSubscribersRef,
  fullPageContextValue,
  toolSubscribersRef,
  toolContextValue,
}: {
  fullPageSubscribersRef: MutableRefObject<Set<string>>
  fullPageContextValue: ContextValueFullPage
  toolSubscribersRef: MutableRefObject<Set<string>>
  toolContextValue: OsContextTool
}) => {
  // Publish FullPage app context changes
  useEffect(() => {
    fullPageSubscribersRef.current.forEach(formattedAppId => {
      const safeContextValue = structuredClone(fullPageContextValue.context)
      const formattedContextAppId =
        fullPageContextValue.appStableId && formatFullPageAppId(fullPageContextValue.appStableId)

      // Skip publishing outdated context
      if (formattedAppId === formattedContextAppId || formattedAppId === formatFullPageAppId(OS_ASSISTANT_APP_CODE)) {
        PubSub.publish(formattedAppId, safeContextValue)
      }
    })
  }, [fullPageContextValue, fullPageSubscribersRef])

  // Publish Tool app context changes
  useEffect(() => {
    toolSubscribersRef.current.forEach(formattedAppId => {
      const safeContextValue = structuredClone(toolContextValue)

      PubSub.publish(formattedAppId, safeContextValue)
    })
  }, [toolContextValue, toolSubscribersRef])

  // Cleanup app subscriptions for apps that are no longer mounted
  useEffect(() => {
    const handleUnsubscribeFullPageAppOnUnmount = ({ detail }: CustomEvent<SingleSpaCustomEventDetail>) => {
      detail.appsByNewStatus[NOT_MOUNTED].forEach(appId => {
        const formattedAppId = formatFullPageAppId(appId)

        PubSub.unsubscribe(formattedAppId)
        fullPageSubscribersRef.current.delete(formattedAppId)
      })
    }

    window.addEventListener('single-spa:before-app-change', handleUnsubscribeFullPageAppOnUnmount)

    const fullPageSubscriberNames = fullPageSubscribersRef.current
    const toolSubscriberNames = toolSubscribersRef.current

    // Cleanup is called only when we enter public routes
    return () => {
      fullPageSubscriberNames.forEach(formattedAppId => {
        PubSub.unsubscribe(formattedAppId)
      })
      toolSubscriberNames.forEach(formattedAppId => {
        PubSub.unsubscribe(formattedAppId)
      })

      window.removeEventListener('single-spa:before-app-change', handleUnsubscribeFullPageAppOnUnmount)

      // As we cancel app subscriptions, we also unregister the apps
      unregisterAllMicroApps()
    }
  }, [toolSubscribersRef, fullPageSubscribersRef])
}

export const useHandleSubscribeToFullPageContext = ({
  fullPageSubscribersRef,
  fullPageContextValueRef,
}: {
  fullPageSubscribersRef: MutableRefObject<Set<string>>
  fullPageContextValueRef: MutableRefObject<ContextValueFullPage>
}) => {
  return useCallback(
    (appId: string, listener: AppContextListener) => {
      const formattedAppId = formatFullPageAppId(appId)
      const subscriberToken = PubSub.subscribe(formattedAppId, (msg, value) => {
        listener(value)
      })
      const safeContextValue = structuredClone(fullPageContextValueRef.current.context)
      const contextAppId = fullPageContextValueRef.current.appStableId

      // Initial publish when an app subscribes to the context. Skip publishing outdated context.
      if (appId === contextAppId || appId === OS_ASSISTANT_APP_CODE) {
        PubSub.publish(formattedAppId, safeContextValue)
      }
      fullPageSubscribersRef.current.add(formattedAppId)

      return () => {
        PubSub.unsubscribe(subscriberToken)

        const wasLast = PubSub.countSubscriptions(formattedAppId) === 0

        if (wasLast) {
          fullPageSubscribersRef.current.delete(formattedAppId)
        }
      }
    },
    [fullPageContextValueRef, fullPageSubscribersRef],
  )
}

export const useHandleSubscribeToToolContext = ({
  toolSubscribersRef,
  toolContextValueRef,
}: {
  toolSubscribersRef: MutableRefObject<Set<string>>
  toolContextValueRef: MutableRefObject<OsContextTool>
}) => {
  return useCallback(
    (appId: string, listener: AppContextListener) => {
      const formattedAppId = formatToolAppId(appId)
      const subscriberToken = PubSub.subscribe(formattedAppId, (msg, value) => {
        listener(value)
      })
      const safeContextValue = structuredClone(toolContextValueRef.current)

      // Initial publish when an app subscribes to the context
      PubSub.publish(formattedAppId, safeContextValue)
      toolSubscribersRef.current.add(formattedAppId)

      return () => {
        PubSub.unsubscribe(subscriberToken)

        const wasLast = PubSub.countSubscriptions(formattedAppId) === 0

        if (wasLast) {
          toolSubscribersRef.current.delete(formattedAppId)
        }
      }
    },
    [toolContextValueRef, toolSubscribersRef],
  )
}
