import styles from 'components/svg/common.module.scss'

export const SvgNewNoRecords = (props: JSX.IntrinsicElements['svg']) => (
  <svg width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="60.5" cy="60" r="60" className={styles.fillPrimary100} />
    <path
      d="M100.868 60.6251C101.135 59.638 102.596 59.638 102.862 60.6251L103.002 61.1425C103.098 61.5008 103.396 61.7773 103.772 61.8587L104.062 61.9214C105.133 62.153 105.133 63.6165 104.062 63.8481L103.772 63.9108C103.396 63.9922 103.098 64.2687 103.002 64.627L102.862 65.1444C102.596 66.1316 101.135 66.1316 100.868 65.1444L100.729 64.627C100.632 64.2687 100.335 63.9922 99.9586 63.9108L99.6684 63.8481C98.5975 63.6165 98.5975 62.153 99.6684 61.9214L99.9586 61.8587C100.335 61.7773 100.632 61.5008 100.729 61.1425L100.868 60.6251Z"
      className={styles.fillPrimary400}
    />
    <ellipse cx="106.48" cy="53.8848" rx="1.49992" ry="1.5" className={styles.fillPrimary300} />
    <path
      d="M56.2541 6.86785C56.5866 5.63389 58.4136 5.6339 58.7462 6.86785L58.9205 7.51454C59.0412 7.96247 59.4129 8.30805 59.8835 8.40979L60.2463 8.48822C61.5849 8.77766 61.5849 10.6071 60.2463 10.8965L59.8835 10.975C59.4129 11.0767 59.0412 11.4223 58.9205 11.8702L58.7462 12.5169C58.4136 13.7509 56.5866 13.7509 56.2541 12.5169L56.0798 11.8702C55.9591 11.4223 55.5873 11.0767 55.1167 10.975L54.754 10.8965C53.4153 10.6071 53.4153 8.77766 54.754 8.48822L55.1167 8.40979C55.5873 8.30805 55.9591 7.96247 56.0798 7.51454L56.2541 6.86785Z"
      className={styles.fillPrimary300}
    />
    <circle cx="65.75" cy="15.2305" r="1.5" className={styles.fillPrimary400} />
    <circle cx="12.9619" cy="41.7695" r="1.5" className={styles.fillPrimary200} />
    <g opacity="0.5" filter="url(#filter0_f_17240_6687)">
      <rect x="12.5" y="51.75" width="75" height="18.75" rx="4" className={styles.fillPrimary500} />
    </g>
    <rect x="12.5" y="49.5" width="75" height="21" rx="4" className={styles.fillWhite} />
    <rect x="35" y="54" width="21" height="4.5" rx="1" className={styles.fillPrimary300} />
    <rect x="35" y="61.5" width="48" height="4.5" rx="1" className={styles.fillPrimary100} />
    <ellipse cx="23.75" cy="60.0001" rx="6.75" ry="6.75012" className={styles.fillPrimary500} />
    <g clipPath="url(#clip0_17240_6687)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7515 56.3809C23.8794 56.3809 23.9962 56.4531 24.0534 56.5674L25.0066 58.4738L27.2716 58.8223C27.3973 58.8416 27.5014 58.93 27.541 59.0508C27.5805 59.1717 27.5488 59.3045 27.4589 59.3945L25.8923 60.961L26.2413 63.2295C26.2607 63.3552 26.2077 63.4812 26.1042 63.5552C26.0007 63.6293 25.8644 63.6388 25.7516 63.58L23.7515 62.5365L21.7514 63.58C21.6386 63.6388 21.5023 63.6293 21.3989 63.5552C21.2954 63.4812 21.2424 63.3552 21.2617 63.2295L21.6107 60.961L20.0442 59.3945C19.9542 59.3045 19.9225 59.1717 19.9621 59.0508C20.0016 58.93 20.1058 58.8416 20.2315 58.8223L22.4965 58.4738L23.4497 56.5674C23.5068 56.4531 23.6237 56.3809 23.7515 56.3809ZM23.7515 57.473L23.0222 58.9318C22.9732 59.0297 22.8798 59.0978 22.7716 59.1144L20.9924 59.3881L22.2089 60.6047C22.285 60.6807 22.3202 60.7884 22.3039 60.8946L22.0302 62.6732L23.5954 61.8566C23.6932 61.8055 23.8098 61.8055 23.9076 61.8566L25.4728 62.6732L25.1992 60.8946C25.1828 60.7884 25.2181 60.6807 25.2941 60.6047L26.5106 59.3881L24.7314 59.1144C24.6233 59.0978 24.5299 59.0297 24.4809 58.9318L23.7515 57.473Z"
        className={styles.fillPrimary100}
      />
    </g>
    <g opacity="0.5" filter="url(#filter1_f_17240_6687)">
      <rect x="25.25" y="77.25" width="75" height="18.75" rx="4" className={styles.fillPrimary500} />
    </g>
    <rect x="25.25" y="75" width="75" height="21" rx="4" className={styles.fillWhite} />
    <rect x="47.75" y="79.5" width="21" height="4.5" rx="1" className={styles.fillPrimary300} />
    <rect x="47.75" y="87" width="48" height="4.5" rx="1" className={styles.fillPrimary100} />
    <ellipse cx="36.5" cy="85.5001" rx="6.75" ry="6.75012" className={styles.fillPrimary500} />
    <g clipPath="url(#clip1_17240_6687)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5015 81.8809C36.6294 81.8809 36.7462 81.9531 36.8034 82.0674L37.7566 83.9738L40.0216 84.3223C40.1473 84.3416 40.2514 84.43 40.291 84.5508C40.3305 84.6717 40.2988 84.8045 40.2089 84.8945L38.6423 86.461L38.9913 88.7295C39.0107 88.8552 38.9577 88.9812 38.8542 89.0552C38.7507 89.1293 38.6144 89.1388 38.5016 89.08L36.5015 88.0365L34.5014 89.08C34.3886 89.1388 34.2523 89.1293 34.1489 89.0552C34.0454 88.9812 33.9924 88.8552 34.0117 88.7295L34.3607 86.461L32.7942 84.8945C32.7042 84.8045 32.6725 84.6717 32.7121 84.5508C32.7516 84.43 32.8558 84.3416 32.9815 84.3223L35.2465 83.9738L36.1997 82.0674C36.2568 81.9531 36.3737 81.8809 36.5015 81.8809ZM36.5015 82.973L35.7722 84.4318C35.7232 84.5297 35.6298 84.5978 35.5216 84.6144L33.7424 84.8881L34.9589 86.1047C35.035 86.1807 35.0702 86.2884 35.0539 86.3946L34.7802 88.1732L36.3454 87.3566C36.4432 87.3055 36.5598 87.3055 36.6576 87.3566L38.2228 88.1732L37.9492 86.3946C37.9328 86.2884 37.9681 86.1807 38.0441 86.1047L39.2606 84.8881L37.4814 84.6144C37.3733 84.5978 37.2799 84.5297 37.2309 84.4318L36.5015 82.973Z"
        className={styles.fillPrimary100}
      />
    </g>
    <g opacity="0.5" filter="url(#filter2_f_17240_6687)">
      <rect x="25.25" y="26.25" width="75" height="18.75" rx="4" className={styles.fillPrimary500} />
    </g>
    <rect x="25.25" y="24" width="75" height="21" rx="4" className={styles.fillWhite} />
    <rect x="47.75" y="28.5" width="21" height="4.5" rx="1" className={styles.fillPrimary300} />
    <rect x="47.75" y="36" width="48" height="4.5" rx="1" className={styles.fillPrimary100} />
    <ellipse cx="36.5" cy="34.5001" rx="6.75" ry="6.75012" className={styles.fillPrimary500} />
    <g clipPath="url(#clip2_17240_6687)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5015 30.8809C36.6294 30.8809 36.7462 30.9531 36.8034 31.0674L37.7566 32.9738L40.0216 33.3223C40.1473 33.3416 40.2514 33.43 40.291 33.5508C40.3305 33.6717 40.2988 33.8045 40.2089 33.8945L38.6423 35.461L38.9913 37.7295C39.0107 37.8552 38.9577 37.9811 38.8542 38.0552C38.7507 38.1293 38.6144 38.1388 38.5016 38.08L36.5015 37.0365L34.5014 38.08C34.3886 38.1388 34.2523 38.1293 34.1489 38.0552C34.0454 37.9811 33.9924 37.8552 34.0117 37.7295L34.3607 35.461L32.7942 33.8945C32.7042 33.8045 32.6725 33.6717 32.7121 33.5508C32.7516 33.43 32.8558 33.3416 32.9815 33.3223L35.2465 32.9738L36.1997 31.0674C36.2568 30.9531 36.3737 30.8809 36.5015 30.8809ZM36.5015 31.973L35.7722 33.4318C35.7232 33.5297 35.6298 33.5978 35.5216 33.6144L33.7424 33.8881L34.9589 35.1047C35.035 35.1807 35.0702 35.2884 35.0539 35.3946L34.7802 37.1732L36.3454 36.3566C36.4432 36.3055 36.5598 36.3055 36.6576 36.3566L38.2228 37.1732L37.9492 35.3946C37.9328 35.2884 37.9681 35.1807 38.0441 35.1047L39.2606 33.8881L37.4814 33.6144C37.3733 33.5978 37.2799 33.5297 37.2309 33.4318L36.5015 31.973Z"
        className={styles.fillPrimary100}
      />
    </g>
    <circle cx="60.5" cy="107.654" r="2.25" className={styles.fillPrimary300} />
    <defs>
      <filter
        id="filter0_f_17240_6687"
        x="2.5"
        y="41.75"
        width="95"
        height="38.75"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_17240_6687" />
      </filter>
      <filter
        id="filter1_f_17240_6687"
        x="15.25"
        y="67.25"
        width="95"
        height="38.75"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_17240_6687" />
      </filter>
      <filter
        id="filter2_f_17240_6687"
        x="15.25"
        y="16.25"
        width="95"
        height="38.75"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_17240_6687" />
      </filter>
      <clipPath id="clip0_17240_6687">
        <rect width="8.99989" height="8.99989" className={styles.fillWhite} transform="translate(19.252 55.5)" />
      </clipPath>
      <clipPath id="clip1_17240_6687">
        <rect width="8.99989" height="8.99989" className={styles.fillWhite} transform="translate(32.002 81)" />
      </clipPath>
      <clipPath id="clip2_17240_6687">
        <rect width="8.99989" height="8.99989" className={styles.fillWhite} transform="translate(32.002 30)" />
      </clipPath>
    </defs>
  </svg>
)
