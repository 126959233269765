import { useMemo } from 'react'

import { tableActions } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Permission } from 'constants/permission'
import { TableKey } from 'constants/table'
import { useHasPermission } from 'hooks/useHasPermission'
import { queryClient } from 'providers/osQueryClient/utils'
import { TenantRole } from 'types/roles/tenantRole'

export const handleReloadRolesTable = async () => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.ROLES_LIST] })
  tableActions.reload(TableKey.ROLES)
}

export const getRolesDescriptions = (roles?: TenantRole[]) =>
  roles?.length
    ? roles
        .map(({ description }) => description)
        .filter(Boolean)
        .join(', ')
    : ''

export const useIsTenantAdmin = () => {
  const { hasPermission } = useHasPermission()
  return useMemo(() => hasPermission(Permission.OS_WORKSPACE_MANAGE), [hasPermission])
}
