import { useCallback } from 'react'

import { useFetchGroupsListApi } from 'api/groups/queryFetchers/useFetchGroupsListApi'
import { TableInfiniteLoader } from 'components/common/table'
import { TableDefaults } from 'constants/table'
import { useLatestDistinct } from 'hooks/useLatestDistinct'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { TenantGroup } from 'types/groups/groups'

interface Params {
  search?: string
  userId?: string[]
  roleId?: string[]
}

export const useGroupsListLoader = ({ search, roleId, userId }: Params) => {
  const { currentTenant } = useCurrentTenantData()
  const userIdStable = useLatestDistinct(userId)
  const roleIdStable = useLatestDistinct(roleId)

  const handleFetchGroupsList = useFetchGroupsListApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const loader: TableInfiniteLoader<TenantGroup> = useCallback(
    async ({ startRow, endRow, sortModel }) => {
      const itemsPerPage = endRow - startRow
      const [{ colId, sort }] = sortModel

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchGroupsList({
        tenantId: currentTenant.id,
        search,
        itemsPerPage,
        page: endRow / itemsPerPage,
        sort: sort === 'asc' ? colId : `-${colId}`,
        userId: userIdStable,
        roleId: roleIdStable,
      })

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [currentTenant.id, handleFetchGroupsList, roleIdStable, search, userIdStable],
  )

  return { loader }
}
