import { facadeApi } from 'api'
import { Role } from 'types/roles/roles'

interface Params {
  roleId: string
  accountId?: string
}
export const fetchRoleApi = ({ roleId, accountId }: Params) =>
  facadeApi.get<Role>(`/az/roles/${roleId}`, {
    params: { account_id: accountId },
  })
