import { WppTypography, WppIconWarning, WppButton } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { useUpdateGroupsRolesApi } from 'api/groups/mutations/useUpdateGroupsRolesApi'
import { useRoleApi } from 'api/roles/queries/useRoleApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import styles from 'pages/admin/members/deleteGroupRoleModal/DeleteGroupRoleModal.module.scss'
import { handleReloadGroupRolesEditTable } from 'pages/admin/members/groups/manageGroupSideModal/utils'
import { handleReloadGroupsListTable } from 'pages/admin/members/groups/utils'
import { handleReloadGroupRolesTable } from 'pages/admin/members/groups/viewGroupSideModal/utils'
import { useGroupsAccountsMap } from 'pages/admin/roles/viewRoleSideModal/roleGroupsSection/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { AzGroup } from 'types/groups/groups'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { mapGroupRolesPayloadToDelete } from 'utils/roles'

interface Props extends NiceModalWrappedProps {
  roleName: string
  roleId: string
  group: AzGroup
}

const DeleteGroupRoleModal = ({ id, isOpen, onClose, onCloseComplete, roleName, roleId, group }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleUpdateGroupsRoles, isLoading } = useUpdateGroupsRolesApi()

  const { data: roleFull, isLoading: isRoleLoading } = useRoleApi({
    params: { roleId },
    enabled: isOpen,
  })

  const groupsAccountsMap = useGroupsAccountsMap(roleFull)

  const onRemove = async () => {
    try {
      await handleUpdateGroupsRoles({
        create: [],
        delete: mapGroupRolesPayloadToDelete(group, {
          id: roleId,
          name: roleName,
          uniqueAccounts: groupsAccountsMap[group.uid] || [],
        }),
      })

      await handleReloadGroupRolesTable()
      await handleReloadGroupsListTable()
      await handleReloadGroupRolesEditTable()

      enqueueToast({
        message: t('os.common.toasts.delete_from', {
          entity: t('os.entities.role').toLocaleLowerCase(),
          is: t('os.entities.is'),
          deleteFrom: t('os.entities.group'),
        }),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('os.common.errors.error'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  }

  return (
    <Modal
      open={isOpen}
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      disableOutsideClick={isLoading || isRoleLoading}
      data-testid={id}
    >
      <Flex slot="header" align="center" gap={9}>
        <WppIconWarning />
        <WppTypography type="xl-heading">{t('os.groups.view_group.roles_table.delete_modal.title')}</WppTypography>
      </Flex>

      <WppTypography slot="body" type="s-body">
        <Trans
          i18nKey="os.groups.view_group.roles_table.delete_modal.description"
          values={{
            roleName,
            groupName: group.name,
          }}
          components={[
            <b key="0" className={styles.bold}>
              roleName
            </b>,
            <b key="1" className={styles.bold}>
              groupName
            </b>,
          ]}
        />
      </WppTypography>

      <Flex slot="actions" justify="end" gap={12}>
        <WppButton variant="secondary" size="s" onClick={onClose} data-testid="cancel">
          {t('os.common.cancel')}
        </WppButton>
        <WppButton
          size="s"
          variant="destructive"
          loading={isLoading || isRoleLoading}
          onClick={onRemove}
          data-testid="apply"
        >
          {t('os.common.remove')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showDeleteGroupRoleModal } = createNiceModal(DeleteGroupRoleModal, 'delete-group-role-modal')
