import { WppSkeleton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useUserPermissionsApi } from 'api/alphaZulu/queries/useRolePermissionsApi'
import { Flex } from 'components/common/flex/Flex'
import { SvgNoRecords } from 'components/svg/network/SvgNoRecords'
import styles from 'pages/admin/roles/viewRoleSideModal/rolePermissionsSection/RolePermissionsSection.module.scss'
import { Role } from 'types/roles/roles'

interface Props {
  role: Role
}
export const RolePermissionsSection = ({ role }: Props) => {
  const { t } = useTranslation()
  const { data, isLoading } = useUserPermissionsApi({ params: { roleUid: role.id } })

  const permissions = data.filter(({ description }) => !!description)

  return isLoading ? (
    <Flex direction="column" gap={5}>
      <WppSkeleton height={22} width="50%" />
      <WppSkeleton height={22} width="50%" />
      <WppSkeleton height={22} width="50%" />
      <WppSkeleton height={22} width="50%" />
      <WppSkeleton height={22} width="50%" />
    </Flex>
  ) : permissions.length ? (
    <ul className={styles.list}>
      {permissions.map(({ description, id }) => {
        return (
          <li key={id}>
            <WppTypography type="s-body">{description}</WppTypography>
          </li>
        )
      })}
    </ul>
  ) : (
    <Flex direction="column" align="center" gap={12}>
      <SvgNoRecords />

      <WppTypography type="m-strong">{t('os.roles.view_role.permissions.no_permissions_found')}</WppTypography>
    </Flex>
  )
}
