import {
  AddMembersSideModal,
  ModalProps,
} from 'pages/admin/members/addMembersToGroupSideModal/AddMembersToGroupSideModal'
import { createNiceModal } from 'utils/createNiceModal'

const AddMembersOnAddGroupSideModal = ({ onClose, onSubmit, ...rest }: ModalProps) => {
  const handleSubmit: ModalProps['onSubmit'] = async values => {
    await onSubmit(values)
    onClose()
  }
  return <AddMembersSideModal onSubmit={handleSubmit} onClose={onClose} {...rest} />
}
export const { showModal: showAddMembersSideModal } = createNiceModal(
  AddMembersOnAddGroupSideModal,
  'add-members-side-modal',
)
