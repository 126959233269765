import { WppTypography, WppIconWarning, WppButton } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useUpdateGroupsUsersApi } from 'api/groups/mutations/useUpdateGroupsUsersApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { useStableCallback } from 'hooks/useStableCallback'
import { handleReloadGroupsListTable } from 'pages/admin/members/groups/utils'
import {
  deselectGroupMembers,
  handleReloadGroupMembersTable,
} from 'pages/admin/members/groups/viewGroupSideModal/utils'
import styles from 'pages/admin/members/Members.module.scss'
import { useToast } from 'providers/toast/ToastProvider'
import { AzGroup, GroupUser } from 'types/groups/groups'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  users: GroupUser[]
  group: AzGroup
}

const DeleteGroupMembersModal = ({ isOpen, onClose, users, group, onCloseComplete, id }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleUpdateGroupsUsers, isLoading } = useUpdateGroupsUsersApi()

  const onDelete = useStableCallback(async () => {
    try {
      await handleUpdateGroupsUsers({
        create: [],
        delete: users.map(({ email }) => {
          return {
            userId: email,
            groupId: group.uid,
          }
        }),
      })
      await handleReloadGroupsListTable()
      await handleReloadGroupMembersTable()
      enqueueToast({
        message: t('os.common.toasts.delete_from', {
          entity: t('os.entities.member', { count: users.length }).toLocaleLowerCase(),
          is: t('os.entities.is', { count: users.length }),
          deleteFrom: t('os.entities.group'),
        }),
        type: 'success',
      })
      await deselectGroupMembers()
    } catch {
      enqueueToast({
        message: t('os.common.errors.error'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  })

  return (
    <Modal
      data-testid={id}
      open={isOpen}
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      disableOutsideClick={isLoading}
    >
      <Flex slot="header" align="center" gap={9}>
        <WppIconWarning />
        <WppTypography type="xl-heading">
          {t('os.groups.view_group.members_table.delete_users_modal.title')}
        </WppTypography>
      </Flex>

      <WppTypography slot="body" type="s-strong" className={styles.wordBreak}>
        {t('os.groups.view_group.members_table.delete_users_modal.description', {
          groupName: group.name,
          members: t('os.entities.member', { count: users.length }).toLocaleLowerCase(),
        })}
      </WppTypography>

      <Flex slot="actions" justify="end" gap={12}>
        <WppButton variant="secondary" size="s" onClick={onClose} data-testid="cancel">
          {t('os.common.cancel')}
        </WppButton>
        <WppButton size="s" variant="destructive" loading={isLoading} onClick={onDelete} data-testid="apply">
          {t('os.common.remove')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showDeleteGroupMembersModal } = createNiceModal(
  DeleteGroupMembersModal,
  'delete-group-members-modal',
)
