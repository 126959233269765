import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse } from 'api/common/types'
import { RoleMember } from 'types/roles/roleMember'

interface Params {
  roleId: string
  tenantId: string
  search?: string
  page?: number
  itemsPerPage?: number
  hasDirectAssignment?: boolean
  hasGroupsAssignment?: boolean
}

export type RoleMembersResponse = PaginatedResponse<RoleMember>

export const fetchRoleMembersApi = ({
  roleId,
  tenantId,
  search,
  page,
  itemsPerPage,
  hasDirectAssignment,
  hasGroupsAssignment,
}: Params) =>
  facadeApi.get<RoleMembersResponse>(`/tenants/${tenantId}/roles/${roleId}/members`, {
    params: {
      page,
      itemsPerPage,
      filter: {
        ...(!!search && { search }),
        hasDirectAssignment,
        hasGroupsAssignment,
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'brackets', encode: true }),
    },
  })
