import qs from 'qs'

import { userDetailsApi } from 'api'
import { PaginatedResponseOld, SortOrder } from 'api/common/types'
import { UserDetailsShort } from 'types/users/users'

export enum UsersListSortBy {
  office_location = 'office_location',
  department = 'department',
  job_title = 'job_title',
  firstname = 'firstname',
  email = 'email',
}

export interface UsersListFilters {
  country?: string[]
  jobTitle?: string[]
  department?: string[]
}

export interface Params {
  emails: string[]
  limit?: number
  offset?: number
  search?: string
  sortBy?: UsersListSortBy
  orderBy?: SortOrder
  filters?: UsersListFilters
}

export type UsersListResponse = PaginatedResponseOld<UserDetailsShort>

export const fetchUsersListByEmailsApi = ({ emails, offset, limit, search, orderBy, sortBy, filters = {} }: Params) =>
  userDetailsApi.post<UsersListResponse>(
    '/users/search',
    {
      emails,
    },
    {
      params: {
        offset,
        limit,
        sortBy,
        orderBy,
        filter: {
          ...(!!search && { search }),
          jobTitle: filters.jobTitle?.join(', ') || '',
          country: filters.country?.join(', ') || '',
          department: filters.department?.join(', ') || '',
        },
      },
      paramsSerializer: {
        serialize: params => qs.stringify(params, { arrayFormat: 'brackets', encode: true }),
      },
    },
  )
