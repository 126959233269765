import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useUpdateGroupsUsersApi } from 'api/groups/mutations/useUpdateGroupsUsersApi'
import { Flex } from 'components/common/flex/Flex'
import { FormUsersListAutocomplete } from 'components/form/formAutocomplete/FormUsersListAutocomplete'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { DEFAULT_PLURAL_COUNT } from 'constants/i18n'
import { useForm } from 'hooks/form/useForm'
import { showAddExternalUserConfirmationModal } from 'pages/admin/members/addExternalUserConfirmationModal/AddExternalUserConfirmationModal'
import {
  useMemberValidationScheme,
  mapValuesToPayload,
  UsersFormValue,
} from 'pages/admin/members/addMembersToGroupSideModal/utils'
import { handleReloadGroupsListTable } from 'pages/admin/members/groups/utils'
import { handleReloadGroupMembersTable } from 'pages/admin/members/groups/viewGroupSideModal/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { TenantGroup } from 'types/groups/groups'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { hasExternalUser } from 'utils/user'

interface Props extends NiceModalWrappedProps {
  group: TenantGroup
}

export interface ModalProps extends NiceModalWrappedProps {
  onSubmit: (values: Pick<UsersFormValue, 'users'>) => Promise<void> | void
  group?: TenantGroup
}
export const AddMembersSideModal = ({ isOpen, onClose, onCloseComplete, onSubmit, group, id }: ModalProps) => {
  const { t } = useTranslation()

  const form = useForm({
    defaultValues: { users: [] },
    validationSchema: useMemberValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = form

  const handleFormSubmit = handleSubmit(async values => {
    await onSubmit(values)
    onClose()
  })

  const [users] = watch(['users'])

  const onSave = hasExternalUser(users)
    ? () => showAddExternalUserConfirmationModal({ onSave: handleFormSubmit })
    : handleFormSubmit

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{
          onSubmit: handleFormSubmit,
        }}
        data-testid={id}
        open={isOpen}
        size="l"
        disableOutsideClick
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
      >
        {group?.name ? (
          <WppTypography slot="header" type="2xl-heading">
            {t('os.groups.header.add_member', {
              groupName: group.name,
            })}
          </WppTypography>
        ) : (
          <WppTypography slot="header" type="2xl-heading">
            {t('os.groups.header.add_member_to_new_group')}
          </WppTypography>
        )}

        <Flex slot="body" direction="column" gap={24}>
          <FormUsersListAutocomplete
            required
            multiple
            name="users"
            getOptionId={({ email }) => email}
            getOptionLabel={({ email }) => email}
            labelConfig={{
              text: t('os.entities.member', { count: DEFAULT_PLURAL_COUNT }),
              description: t('os.members.add_members_to_group.members_label_description'),
            }}
            placeholder={t('os.members.add_members_to_os.members.placeholder')}
          />
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
            {t('os.common.cancel')}
          </WppButton>
          <WppButton variant="primary" onClick={() => onSave()} loading={isSubmitting} data-testid="apply">
            {t('os.common.save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

const AddMembersToGroupSideModal = ({ isOpen, onClose, group, onCloseComplete, id }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleUpdateGroupsUsers } = useUpdateGroupsUsersApi()
  const onSubmit = async (values: Pick<UsersFormValue, 'users'>) => {
    try {
      const payload = mapValuesToPayload({
        users: values.users,
        groups: [{ id: group.id, label: group.name }],
      })
      await handleUpdateGroupsUsers(payload)
      await handleReloadGroupsListTable()
      await handleReloadGroupMembersTable()

      enqueueToast({
        message: t('os.members.add_members_to_os.toasts.add', { count: values.users.length }),
        type: 'success',
      })
      onClose()
    } catch {
      enqueueToast({
        message: t('os.common.errors.error'),
        type: 'error',
      })
    }
  }

  return (
    <AddMembersSideModal
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={onCloseComplete}
      group={group}
      onSubmit={onSubmit}
    />
  )
}

export const { showModal: showAddMembersToGroupSideModal, useModal: useAddMembersToGroupSideModal } = createNiceModal(
  AddMembersToGroupSideModal,
  'add-members-to-group-side-modal',
)

export const { showModal: showAddMembersSideModal } = createNiceModal(AddMembersSideModal, 'add-members-side-modal')
