import { UserDetails } from '@wpp-open/core'
import { TFunction } from 'i18next'
import * as zod from 'zod'

import { UpdateGroupsUsersPayload } from 'api/groups/fetchers/updateGroupsUsersApi'
import { tableActions } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { queryClient } from 'providers/osQueryClient/utils'
import { AzGroup, GroupForm, GroupFormCreateDTO, GroupFormEditData, GroupUser } from 'types/groups/groups'
import { difference } from 'utils/common'

export const NAME_MAX_CHARACTERS = 100
export const DESCRIPTION_MAX_CHARACTERS = 400

export const enum MembersSegment {
  New = 'new',
  Assigned = 'assigned',
}

export const mapGroupFormToCreateDTO = (group: GroupForm): GroupFormCreateDTO => ({
  account_uid: group.account_uid,
  name: group.name,
  description: group.description,
  custom_data: {},
})

export const mapGroupFormToEditDTO = (
  groupForm: GroupForm,
  initialMembers: GroupUser[],
  groupId: string,
): GroupFormEditData => {
  const createDiff = difference(groupForm.members || [], initialMembers, ({ email }) => email)
  const deleteDiff = difference(initialMembers, groupForm.members || [], ({ email }) => email)

  return {
    group: {
      name: groupForm.name,
      description: groupForm.description,
      custom_data: {},
    },
    members: {
      create: createDiff.map(({ email }) => ({ userId: email, groupId })),
      delete: deleteDiff.map(({ email }) => ({ userId: email, groupId })),
    },
  }
}

export const mapMembersToGroupUsersDTO = (
  members: Pick<UserDetails, 'email'>[],
  groupId: string,
): UpdateGroupsUsersPayload => ({
  create: members.map(({ email }) => ({ userId: email, groupId })),
  delete: [],
})

export const mapGroupToForm = (group: AzGroup, groupUsers: GroupUser[]): GroupForm => ({
  name: group.name,
  description: group.description,
  account_uid: group.account_uid,
  members: groupUsers.map(({ email, first_name, last_name }) => ({
    email,
    firstname: first_name ?? '',
    lastname: last_name ?? '',
  })),
})

export const getGroupScheme = (t: TFunction) =>
  zod.object({
    name: zod
      .string()
      .trim()
      .min(1, t('os.groups.manage_group.name.required_message'))
      .max(
        NAME_MAX_CHARACTERS,
        t('os.groups.manage_group.name.max_length_message', { maxCharacters: NAME_MAX_CHARACTERS }),
      ),
    description: zod
      .string()
      .trim()
      .min(1, t('os.groups.manage_group.description.required_message'))
      .max(
        DESCRIPTION_MAX_CHARACTERS,
        t('os.groups.manage_group.description.max_length_message', { maxCharacters: DESCRIPTION_MAX_CHARACTERS }),
      ),
    members: zod.array(zod.object({ email: zod.string() })),
    account_uid: zod.string(),
  })

export const handleReloadGroupRolesEditTable = async () => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.ROLES_LIST] })
  tableActions.reload(TableKey.GROUP_ROLES)
}
