import { TreeType } from '@platform-ui-kit/components-library'
import { HierarchyContainerNodeId } from '@wpp-open/core'

import { NavigationTreeMappingAccess, NavigationTreeNodeAccess } from 'api/navigation/fetchers/fetchNavigationAccessApi'

export const processNavigationNode = (node: NavigationTreeNodeAccess, mapping: NavigationTreeMappingAccess) => {
  const currentNode: TreeType = {
    id: node.azId || '',
    title: node.name,
    children: [],
    selected: false,
  }
  if (node.children.length) {
    currentNode.children = node.children.map(child => {
      return processNavigationNode(mapping[child], mapping)
    })
    currentNode.children.sort((a, b) => a.title?.localeCompare(b.title))
  }
  if (node.accessible) {
    currentNode.open = !!(currentNode.children?.length && node.accessible)
  } else {
    currentNode.iconStart = {
      icon: 'wpp-icon-lock-on',
      name: 'Not accessible',
    }
    currentNode.disabled = true
  }
  return currentNode
}

export const processMappingIntoTree = (mapping: NavigationTreeMappingAccess) => {
  return processNavigationNode(mapping[HierarchyContainerNodeId], mapping).children
}

export const searchTree = (
  tree: TreeType[],
  onlyAccessible: boolean = false,
  searchTerm: string | null = null,
): TreeType[] => {
  const searchByTerm = (node: TreeType, response: boolean): boolean => {
    const result = searchTerm ? node.title?.toLowerCase().includes(searchTerm.toLowerCase()) : response
    node.selected = !!(response && result && searchTerm)
    return response && result
  }
  const searchAccessible = (node: TreeType): boolean => (onlyAccessible ? !node.disabled : true)
  const searchChildren = (node: TreeType, response: boolean): boolean => {
    if (node.children?.length) {
      const newChildren = applySearchTypes(node.children)
      const result = !!newChildren.length
      node.children = newChildren
      node.open = !!searchTerm && result
      return result || response
    }
    return response
  }

  const searchTypes: ((node: TreeType, response: boolean) => boolean)[] = [
    searchAccessible,
    searchByTerm,
    searchChildren,
  ]

  const applySearchTypes = (tree: TreeType[]) => {
    return tree.filter(node => {
      const result = searchTypes.reduce<boolean>((acc, search) => {
        return search(node, acc)
      }, true)
      node.hidden = !result
      return result
    })
  }

  return applySearchTypes(structuredClone(tree))
}
