import { MayBeNull, SupportService, UserDetails } from '@wpp-open/core'
import { useEffect, useMemo } from 'react'

import { useIntercomData } from 'api/users/queries/useIntercomData'
import { useOsSupportService } from 'providers/osSupportService/OsSupportServiceContext'
import { join } from 'utils/common'

export const openIntercomWidget = () => {
  window.Intercom?.('show')
}

export const newIntercomMessage = () => {
  window.Intercom?.('showNewMessage')
}

export const clearIntercomUserData = () => {
  window.Intercom?.('shutdown')
}

export const useIntercomUser = (userDetails: MayBeNull<UserDetails>) => {
  const { enabled, active } = useOsSupportService(SupportService.Intercom)
  const { data: userHash } = useIntercomData({ enabled })

  const params = useMemo(() => {
    if (userDetails && userHash) {
      const { email, createdAt: created_at, firstname, lastname } = userDetails

      return {
        email,
        created_at,
        name: join([firstname, lastname], ' '),
        user_hash: userHash,
        page_url: window.location.href,
      }
    }

    return null
  }, [userDetails, userHash])

  useEffect(() => {
    if (enabled && active && params) {
      window.Intercom?.('update', {
        ...params,
        hide_default_launcher: false,
      })
    } else {
      window.Intercom?.('update', {
        hide_default_launcher: !enabled,
      })
    }
  }, [enabled, active, params])

  useEffect(() => clearIntercomUserData, [])
}
