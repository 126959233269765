import {
  WppActionButton,
  WppButton,
  WppDivider,
  WppIconDownload,
  WppIconFavourites,
  WppIconFavouritesFilled,
  WppIconLink,
  WppIconTrash,
  WppSpinner,
  WppTag,
  WppToggle,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useFileApi } from 'api/files/queries/useFileApi'
import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useStableCallback } from 'hooks/useStableCallback'
import { showDeleteFileItemsModal } from 'pages/files/deleteFileItemsModal/DeleteFileItemsModal'
import styles from 'pages/files/fileDetailsModal/FileDetailsModal.module.scss'
import { FileNameInput } from 'pages/files/fileDetailsModal/FileNameInput'
import { useAllowInAiAssistant } from 'pages/files/fileDetailsModal/utils'
import {
  getFileExtensionWithoutDot,
  getFileNameWithoutExtension,
  useCopyFileLink,
  useDownloadFile,
  useToggleIsFavoriteFile,
  useUrlParams,
} from 'pages/files/utils'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { formatBytes } from 'utils/files'

export interface Props extends NiceModalWrappedProps {
  fileId: string
  isAdmin: boolean
}

export const FileDetailsModal = ({ id, fileId, onClose, onCloseComplete, isOpen, isAdmin }: Props) => {
  const { t } = useTranslation()
  const {
    data: file,
    isLoading: isLoadingFile,
    isError: isFileLoadError,
  } = useFileApi({
    params: { id: fileId },
  })
  const { userDetails } = useOtherTenantsAndUserData()
  const { onAllowInAiAssistant, isAllowingInAiAssistant } = useAllowInAiAssistant()
  const { deleteFileId, setFileNotFound } = useUrlParams()
  const download = useDownloadFile()
  const isLoading = isLoadingFile
  const extension = getFileExtensionWithoutDot(file?.name) ?? ''
  const name = getFileNameWithoutExtension(file?.name) ?? ''
  const { toggleIsFavorite, isTogglingIsFavorite } = useToggleIsFavoriteFile()
  const copyFile = useCopyFileLink()
  const isFileOwner = file?.createdBy === userDetails.email
  const canEdit = isAdmin || isFileOwner

  useEffect(() => {
    if (isFileLoadError) {
      setFileNotFound()
      onClose()
    }
  }, [isFileLoadError, onClose, setFileNotFound])

  const handleCloseComplete = useStableCallback(() => {
    onCloseComplete()
    deleteFileId()
  })

  const handleCopy = useStableCallback(() => {
    copyFile(file!)
  })

  const handleDelete = useStableCallback(() => {
    showDeleteFileItemsModal({ items: [file!], onDelete: onClose })
  })

  const handleToggleFavorite = useStableCallback(async () => {
    await toggleIsFavorite(fileId, file!.isFavorite, true)
  })

  return (
    <SideModal
      open={isOpen}
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={handleCloseComplete}
      data-testid={id}
      size="m"
    >
      <WppTypography slot="header" type="2xl-heading" data-testid="file-details-modal-title">
        {t('os.files.file_details_modal.title')}
      </WppTypography>
      <Flex slot="body" className={styles.modalBody} direction="column" gap={24}>
        {isLoading || !file ? (
          <Flex className={styles.loading} justify="center" align="center" data-testid="spinner">
            <WppSpinner size="m" />
          </Flex>
        ) : (
          <Flex direction="column" gap={12}>
            {canEdit ? (
              <FileNameInput
                name={name}
                id={file.id}
                extension={extension}
                data-testid="file-details-modal-name-input"
              />
            ) : (
              <WppTypography type="xl-heading" data-testid="file-details-name">
                {name}
              </WppTypography>
            )}
            <Flex gap={16}>
              <WppTypography type="s-body" data-testid="file-details-extension" className={styles.info}>
                {extension.toUpperCase()}
              </WppTypography>
              <WppTypography type="s-body" data-testid="file-details-size" className={styles.info}>
                {formatBytes(file.metadata.size || 0)}
              </WppTypography>
              {file.allowInAiAssistant && !canEdit && (
                <WppTooltip text={t('os.files.file_details_modal.ai_tooltip')}>
                  <WppTag
                    data-testid="file-details-ai-tag"
                    label={t('os.files.file_details_modal.ai_label')}
                    variant="neutral"
                  />
                </WppTooltip>
              )}
              {canEdit && (
                <WppTooltip
                  text={
                    file.allowInAiAssistant
                      ? t('os.files.file_details_modal.remove_from_ai')
                      : t('os.files.file_details_modal.allow_in_ai')
                  }
                >
                  <WppToggle
                    data-testid="file-ai-toggle"
                    labelConfig={{
                      text: t('os.files.file_details_modal.ai_label'),
                    }}
                    onWppChange={() => onAllowInAiAssistant(file.id, !file.allowInAiAssistant)}
                    checked={file.allowInAiAssistant}
                    required
                    disabled={isAllowingInAiAssistant}
                  />
                </WppTooltip>
              )}
            </Flex>

            <Flex gap={16}>
              <WppTooltip text={t('os.files.actions.copy_link')}>
                <WppActionButton
                  variant="secondary"
                  onClick={handleCopy}
                  data-testid="file-details-copy-to-clipboard-button"
                  className={styles.actionButton}
                >
                  <WppIconLink />
                </WppActionButton>
              </WppTooltip>

              <WppTooltip text={t('os.files.actions.download')}>
                <WppActionButton
                  variant="secondary"
                  onClick={() => download(fileId, file.name)}
                  data-testid="file-details-download-button"
                  className={styles.actionButton}
                >
                  <WppIconDownload />
                </WppActionButton>
              </WppTooltip>

              <WppTooltip text={file!.isFavorite ? t('os.files.actions.unfavourite') : t('os.files.actions.favourite')}>
                <WppActionButton
                  variant="secondary"
                  onClick={handleToggleFavorite}
                  data-testid="file-details-favorite-button"
                  className={styles.actionButton}
                  loading={isTogglingIsFavorite}
                >
                  {file!.isFavorite ? <WppIconFavouritesFilled /> : <WppIconFavourites />}
                </WppActionButton>
              </WppTooltip>

              {canEdit && (
                <WppTooltip text={t('os.files.actions.delete')}>
                  <WppActionButton
                    variant="secondary"
                    data-testid="file-details-delete-button"
                    className={styles.actionButton}
                    onClick={handleDelete}
                  >
                    <WppIconTrash />
                  </WppActionButton>
                </WppTooltip>
              )}
            </Flex>

            <WppDivider />

            <Flex direction="column" className={styles.details}>
              <WppTypography type="s-strong" data-testid="added-by-label">
                {t('os.files.file_details_modal.added_by')}
              </WppTypography>
              <WppTypography type="s-body">{file.createdBy}</WppTypography>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex slot="actions" justify="end" gap={12}>
        <WppButton variant="primary" size="m" onClick={onClose}>
          {t('os.common.done')}
        </WppButton>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showFileDetailsModal } = createNiceModal(FileDetailsModal, 'file-details-modal')
