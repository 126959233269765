import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { fetchRolesListApi } from 'api/roles/fetchers/fetchRolesListApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useInfiniteTenantRolesApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.TENANT_ROLES_INFINITE,
  fetcher: fetchRolesListApi,
  selector: res => res?.pages?.flatMap(page => page.data?.data) || [],
  getNextPageParam: ({
    data: {
      paginator: { page, totalPages },
    },
  }) => (page < totalPages ? { page: page + 1 } : undefined),
})
