import { facadeApi } from 'api'
import { AssignRolePayload } from 'types/roles/tenantRole'

export interface GroupsPayload {
  group_id: string
  role_id: string
  account_id: string
}

export type UpdateGroupsRolesPayload = AssignRolePayload<GroupsPayload>

export const updateGroupsRolesApi = (payload: UpdateGroupsRolesPayload) => facadeApi.patch('/az/groups/roles', payload)
