import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { RequestsType } from 'constants/requests'
import { useDateWithTimeFormat } from 'hooks/useDateWithTimeFormat'
import styles from 'pages/admin/requests/requestDetailsSideModal/RequestDetailsSideModal.module.scss'
import { RequestStatusTag } from 'pages/admin/requests/requestStatus/RequestStatusTag'
import { UsersInfoCard } from 'pages/admin/requests/usersInfoCard/UsersInfoCard'
import { AccessRequestStatus } from 'pages/admin/requests/utils'
import { HierarchyAccessRequest, HubAccessRequest, SignUpRequest, TenantAccessRequest } from 'types/requests/requests'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  type: RequestsType
  requests: (TenantAccessRequest | HubAccessRequest | HierarchyAccessRequest | SignUpRequest)[]
  getAttachmentUrlByKey?: (key?: string) => string
  onSubmitSuccess?: () => void
  handleApprove: () => void
  handleReject: () => void
}

const RequestDetailsSideModal = ({
  isOpen,
  onClose,
  requests,
  type,
  getAttachmentUrlByKey,
  handleApprove,
  handleReject,
  id,
  onCloseComplete,
}: Props) => {
  const { t } = useTranslation()
  const { formatDate } = useDateWithTimeFormat()

  return (
    <SideModal
      disableOutsideClick
      onWppSideModalClose={onClose}
      open={isOpen}
      size="m"
      onWppSideModalCloseComplete={onCloseComplete}
      data-testid={id}
    >
      <WppTypography slot="header" type="2xl-heading">
        {t('os.requests.request_details.title')}
      </WppTypography>

      <Flex slot="body" direction="column" gap={24}>
        {type === RequestsType.HUB_ACCESS && (
          <Flex direction="column" gap={4}>
            <WppTypography type="s-strong" className={styles.label}>
              {t('os.requests.request_details.hub.hub_name')}
            </WppTypography>
            <WppTypography type="s-body">{(requests[0] as HubAccessRequest).hubName || '-'}</WppTypography>
          </Flex>
        )}

        <UsersInfoCard
          request={requests[0]}
          getAttachmentUrlByKey={getAttachmentUrlByKey}
          date={formatDate(requests[0].createdAt)}
        />

        {![AccessRequestStatus.NEW, AccessRequestStatus.PENDING].includes(requests[0].status) && (
          <>
            <Flex direction="column" gap={8}>
              <WppTypography type="s-strong" className={styles.label}>
                {t('os.requests.request_details.processed_by')}
              </WppTypography>
              <Flex justify="between" align="center">
                <Flex direction="column" gap={4}>
                  <WppTypography type="s-body">{requests[0].updatedBy}</WppTypography>
                  <WppTypography type="s-body" className={styles.date}>
                    {formatDate(requests[0].updatedAt!)}
                  </WppTypography>
                </Flex>
                <RequestStatusTag isApproved={requests[0].status === AccessRequestStatus.APPROVED} />
              </Flex>
            </Flex>
            {requests[0].rejectReason && (
              <Flex direction="column" gap={8}>
                <WppTypography type="s-strong" className={styles.label}>
                  {t('os.requests.request_details.reject_reason')}
                </WppTypography>
                <WppTypography type="s-body">{requests[0].rejectReason}</WppTypography>
              </Flex>
            )}
          </>
        )}
      </Flex>

      <Flex slot="actions" gap={12} justify="end">
        {[AccessRequestStatus.NEW, AccessRequestStatus.PENDING].includes(requests[0].status) && (
          <>
            <WppButton variant="destructive" onClick={handleReject} data-testid="cancel">
              {t('os.requests.request_details.actions.reject')}
            </WppButton>
            <WppButton variant="primary" onClick={handleApprove} data-testid="apply">
              {t('os.requests.request_details.actions.approve')}
            </WppButton>
          </>
        )}
        {![AccessRequestStatus.NEW, AccessRequestStatus.PENDING].includes(requests[0].status) && (
          <WppButton variant="primary" onClick={onClose} data-testid="apply">
            {t('os.common.close')}
          </WppButton>
        )}
      </Flex>
    </SideModal>
  )
}

export const { showModal: showRequestDetailsSideModal, hideModal: hideRequestDetailsSideModal } = createNiceModal(
  RequestDetailsSideModal,
  'request-details-sidemodal',
)
