import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { fetchUsersListApi } from 'api/users/fetchers/fetchUsersListApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useInfiniteUsersListApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.USERS_LIST_INFINITE,
  fetcher: fetchUsersListApi,
  selector: res => res?.pages?.flatMap(page => page.data?.data) || [],
  getNextPageParam: ({
    data: {
      pagination: { offset, limit, total },
    },
  }) => (total <= offset + limit ? undefined : { offset: offset + limit }),
})
