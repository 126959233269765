import {
  WppActionButton,
  WppIconChevron,
  WppIconPeople,
  WppIconRemoveCircle,
  WppInput,
  WppPill,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { SelectionChangedEvent, CellClickedEvent } from 'ag-grid-community'
import clsx from 'clsx'
import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'
import { useSetState } from 'react-use'

import { GroupsSortBy } from 'api/groups/fetchers/fetchGroupsListApi'
import { is5xxError } from 'api/utils'
import { TextWithTooltipCell } from 'components/cellRenderers/textWithTooltip/TextWithTooltipCell'
import { EmptyStateWithAction } from 'components/common/emptyStateWithAction/EmptyStateWithAction'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, TableInfinite } from 'components/common/table'
import { CriticalError } from 'components/renderError'
import { Delay } from 'constants/delay'
import { TableKey } from 'constants/table'
import { useGroupsListLoader } from 'hooks/loaders/useGroupListLoader'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useNumberFormat } from 'hooks/useNumberFormat'
import { useStableCallback } from 'hooks/useStableCallback'
import { useHierarchyAccountData } from 'pages/admin/hierarchy/hierarchySettings/utils'
import { useCanManageGroup } from 'pages/admin/members/groups/utils'
import { showClearSelectionModal } from 'pages/admin/roles/clearSelectionModal/ClearSelectionModal'
import { showDeleteRoleGroupModal } from 'pages/admin/roles/deleteRoleGroupModal/DeleteRoleGroupModal'
import { showDeleteRoleGroupsModal } from 'pages/admin/roles/deleteRoleGroupsModal/DeleteRoleGroupsModal'
import styles from 'pages/admin/roles/viewRoleSideModal/roleGroupsSection/RoleGroupsSection.module.scss'
import { useGroupsAccountsMap } from 'pages/admin/roles/viewRoleSideModal/roleGroupsSection/utils'
import { deselectRoleGroups, deselectRoleMembers } from 'pages/admin/roles/viewRoleSideModal/utils'
import { TenantGroup } from 'types/groups/groups'
import { Role } from 'types/roles/roles'
import { routesManager } from 'utils/routesManager'

interface Props {
  role: Role
  onAddGroup: () => void
  onViewGroup: (groupId: string) => void
  onData: (groupsCount: number) => void
}

export const RoleGroupsSection = ({ role, onAddGroup, onViewGroup, onData }: Props) => {
  const { t } = useTranslation()
  const { formatNumber } = useNumberFormat()

  const { getHierarchyAccountData } = useHierarchyAccountData()

  const groupsAccountsMap = useGroupsAccountsMap(role)
  const isMembersPage = !!useMatch(routesManager.admin.members.root.url())

  const [{ isCriticalError, search, selectedGroups, totalGroupsCount }, setState] = useSetState<{
    isCriticalError: boolean
    search: string
    selectedGroups: TenantGroup[]
    totalGroupsCount: number
  }>({
    isCriticalError: false,
    search: '',
    selectedGroups: [],
    totalGroupsCount: -1,
  })

  const { loader } = useGroupsListLoader({ roleId: [role.id], search })

  const onSelectionChanged = useStableCallback(({ api }: SelectionChangedEvent<TenantGroup>) => {
    setState({ selectedGroups: api.getSelectedRows() || [] })
  })

  const handleSelectCellClick = useStableCallback(({ node }: CellClickedEvent<TenantGroup>) => {
    const isNodeSelected = node.isSelected()
    node.setSelected(!isNodeSelected)
  })

  const isEmpty = !search && !totalGroupsCount

  const setSearchDebounced = useDebounceFn(
    (search?: string) => setState({ search: search?.trim() || '', totalGroupsCount: -1 }),
    Delay.Search,
  )
  const { handler: canManageGroup } = useCanManageGroup()

  const assignToMembers = useCallback(() => {
    deselectRoleGroups()
    deselectRoleMembers()
    onAddGroup()
  }, [onAddGroup])

  const groupRolesColumnDefs = useMemo<ColDef<TenantGroup>[]>(
    () => [
      {
        width: 50,
        colId: 'select',
        checkboxSelection: true,
        onCellClicked: handleSelectCellClick,
      },
      {
        flex: 1,
        headerName: t('os.roles.view_role.groups.table.name'),
        colId: GroupsSortBy.name,
        sort: 'asc',
        cellRenderer: ({ data }) => (
          <Flex direction="column" justify="center" className={styles.zeroMinWidth}>
            <WppTypography type="s-body"> {data!.name} </WppTypography>
            {!!data!.description && (
              <WppTooltip text={data!.description} className={styles.tooltipWidth}>
                <WppTypography type="xs-body" className={clsx(styles.descriptionColor, styles.zeroMinWidth)}>
                  {data!.description}
                </WppTypography>
              </WppTooltip>
            )}
          </Flex>
        ),
      },
      {
        width: 120,
        headerName: t('os.roles.view_role.groups.table.hierarchy'),
        colId: 'hierarchy',
        cellRenderer: ({ data }) => {
          return <WppTypography type="s-body">{getHierarchyAccountData(data!.createdOnAccountId).title}</WppTypography>
        },
      },
      {
        width: 120,
        headerName: t('os.roles.view_role.groups.table.assignment'),
        colId: 'assignment',
        cellRenderer: ({ data }) => {
          return (
            <TextWithTooltipCell
              tooltipProps={{
                text: groupsAccountsMap[data!.id]?.map(acc => acc.name).join(', '),
                config: { placement: 'left', delay: [Delay.Tooltip, null] },
              }}
              cellText={t('os.roles.view_role.groups.table.location', {
                count: groupsAccountsMap[data!.id].length,
              })}
            />
          )
        },
      },
      {
        width: 60,
        colId: 'remove',
        cellRenderer: ({ data }) =>
          canManageGroup(data?.createdOnAccountId) ? (
            <WppTooltip
              text={t('os.roles.view_role.groups.actions_column.delete_tooltip')}
              config={{ placement: 'left' }}
            >
              <WppActionButton
                onClick={() => {
                  showDeleteRoleGroupModal({
                    group: data!,
                    role: {
                      id: role!.id,
                      name: role!.name,
                      uniqueAccounts: groupsAccountsMap[data!.id],
                    },
                  })
                }}
                data-testid="delete-group-button"
              >
                <WppIconRemoveCircle />
              </WppActionButton>
            </WppTooltip>
          ) : null,
      },
      {
        width: 60,
        colId: 'view',
        cellRenderer: ({ data }) =>
          canManageGroup(data?.createdOnAccountId) ? (
            <WppTooltip
              text={t('os.roles.view_role.groups.actions_column.view_tooltip')}
              config={{ placement: 'left' }}
            >
              <WppActionButton
                onClick={() => {
                  if (!isMembersPage) {
                    onViewGroup(data!.id)
                  }
                }}
              >
                <WppIconChevron direction="right" />
              </WppActionButton>
            </WppTooltip>
          ) : null,
      },
    ],
    [
      groupsAccountsMap,
      role,
      t,
      isMembersPage,
      onViewGroup,
      handleSelectCellClick,
      getHierarchyAccountData,
      canManageGroup,
    ],
  )

  return (
    <>
      {isCriticalError && <CriticalError />}

      <Flex direction="column" gap={8}>
        <Flex justify="between">
          {!isEmpty && (
            <WppInput
              size="s"
              type="search"
              className={styles.searchInput}
              onWppChange={({ detail }) => setSearchDebounced(detail.value)}
              placeholder={t('os.groups.search.placeholder')}
            />
          )}
          <Flex gap={12} align="center">
            {!!selectedGroups.length && (
              <>
                <WppPill
                  type="display"
                  removable
                  label={t('os.roles.view_role.selected_entities_chip', {
                    entitiesCount: formatNumber(selectedGroups.length),
                  })}
                  onWppClose={() =>
                    showClearSelectionModal({
                      onSubmit: () => deselectRoleGroups(),
                    })
                  }
                  data-testid="selected-pill"
                />
                <WppActionButton
                  onClick={() =>
                    showDeleteRoleGroupsModal({
                      data: selectedGroups.map(group => ({
                        group,
                        role: {
                          id: role!.id,
                          name: role!.name,
                          uniqueAccounts: groupsAccountsMap[group.id],
                        },
                      })),
                    })
                  }
                  data-testid="bulk-remove-groups-button"
                >
                  <WppIconRemoveCircle slot="icon-start" />
                  {t('os.common.remove')}
                </WppActionButton>
              </>
            )}
            {!selectedGroups.length && totalGroupsCount > 0 && (
              <WppActionButton onClick={assignToMembers}>
                <WppIconPeople slot="icon-start" />
                {t('os.roles.table.actions_column.add_to_groups')}
              </WppActionButton>
            )}
          </Flex>
        </Flex>
        {totalGroupsCount === 0 ? (
          <EmptyStateWithAction
            label={t('os.roles.view_role.groups.no_groups_associated')}
            actionLabel={t('os.roles.table.actions_column.add_to_group')}
            onAction={assignToMembers}
          />
        ) : (
          <TableInfinite
            tableKey={TableKey.VIEW_ROLE_GROUPS}
            className={clsx('role-groups', styles.table)}
            columnDefs={groupRolesColumnDefs}
            isRowSelectable={({ data }) => {
              return canManageGroup(data?.createdOnAccountId)
            }}
            rowClass={clsx([{ [styles.disabledRow]: isMembersPage }])}
            onSelectionChanged={onSelectionChanged}
            loader={loader}
            onLoadSuccess={({ totalRowsCount }) => {
              setState({ totalGroupsCount: totalRowsCount })
              onData(totalRowsCount)
            }}
            onLoadError={error => {
              if (is5xxError(error)) {
                setState({ isCriticalError: true })
              }
            }}
          />
        )}
      </Flex>
    </>
  )
}
