import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { showAddMembersToGroupSideModal } from 'pages/admin/members/addMembersToGroupSideModal/AddMembersToGroupSideModal'
import {
  hideManageGroupSideModal,
  showManageGroupSideModal,
} from 'pages/admin/members/groups/manageGroupSideModal/ManageGroupSideModal'
import { GROUP_DETAIL } from 'pages/admin/members/groups/viewGroupSideModal/utils'
import {
  hideViewGroupSideModal,
  showViewGroupSideModal,
} from 'pages/admin/members/groups/viewGroupSideModal/ViewGroupSideModal'
import { useOpenSelectRoleSideModal } from 'pages/admin/members/utils'
import {
  hideViewMemberSideModal,
  showViewMemberSideModal,
} from 'pages/admin/members/viewMemberSideModal/ViewMemberSideModal'
import { hideViewRoleSideModal, showViewRoleSideModal } from 'pages/admin/roles/viewRoleSideModal/ViewRoleSideModal'
import { AzGroup, GroupUser } from 'types/groups/groups'
import { mapAzGroupToTenantGroup } from 'utils/groups'

export enum ExecutionContext {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}
interface HookProps {
  group: AzGroup
  groupId: string
  backButtonText?: string
  onBack?: () => void
  executionContext: ExecutionContext
}

export const useViewGroupModals = ({ executionContext, group, groupId, backButtonText, onBack }: HookProps) => {
  const { t } = useTranslation()
  const { onAddRoleToGroup } = useOpenSelectRoleSideModal()

  const backToView = useCallback(
    (preOpenedTab?: GROUP_DETAIL) => showViewGroupSideModal({ groupId, backButtonText, onBack, preOpenedTab }),
    [groupId, backButtonText, onBack],
  )

  return useMemo(
    () => ({
      onEdit: onBack
        ? undefined
        : () => {
            showManageGroupSideModal({ groupId, onOpen: hideViewGroupSideModal })
          },
      onAddMember: () => {
        showAddMembersToGroupSideModal({
          group: mapAzGroupToTenantGroup(group),
          onClose: executionContext === ExecutionContext.VIEW ? backToView : undefined,
          onOpen: () => {
            hideViewGroupSideModal()
          },
        })
      },
      onAddRole: () => {
        onAddRoleToGroup({
          group,
          onClose: backToView,
          onOpen: () => {
            hideViewGroupSideModal()
            hideManageGroupSideModal()
          },
        })
      },
      onViewMember: (member: GroupUser) => {
        showViewMemberSideModal({
          member,
          onOpen: () => {
            hideViewGroupSideModal()
            hideManageGroupSideModal()
          },
          ...(!onBack && {
            onBack: () => {
              hideViewMemberSideModal()
              backToView(GROUP_DETAIL.MEMBERS)
            },
            backButtonText: t('os.entities.group'),
          }),
        })
      },
      onViewRole: (roleId: string) => {
        showViewRoleSideModal({
          roleId,
          onOpen: () => {
            hideViewGroupSideModal()
            hideManageGroupSideModal()
          },
          ...(!onBack && {
            backButtonText: t('os.entities.group'),
            onBack: () => {
              hideViewRoleSideModal()

              if (groupId) {
                backToView()
              } else {
                showManageGroupSideModal()
              }
            },
          }),
        })
      },
    }),
    [onBack, groupId, group, backToView, onAddRoleToGroup, executionContext, t],
  )
}
