import { facadeApi } from 'api'

interface Params {
  /** group `uid` */
  groupId: string
  userId: string
}

export const deleteGroupUserApi = ({ groupId, userId }: Params) =>
  facadeApi.delete(`/az/groups/${groupId}/users/${userId}`)
