import {
  WppActionButton,
  WppIconRemoveCircle,
  WppPill,
  WppInput,
  WppAvatar,
  WppIconChevron,
  WppTooltip,
  WppIconUserAdd,
} from '@platform-ui-kit/components-library-react'
import { SelectionChangedEvent, CellClickedEvent } from 'ag-grid-community'
import clsx from 'clsx'
import { useMemo, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import { EmailCell } from 'components/cellRenderers/email/EmailCell'
import { ClipboardText } from 'components/common/clipboardText/ClipboardText'
import { EmptyStateWithAction } from 'components/common/emptyStateWithAction/EmptyStateWithAction'
import { Flex } from 'components/common/flex/Flex'
import { MeTag } from 'components/common/meTag/MeTag'
import { ColDef, TableInfinite } from 'components/common/table'
import { Delay } from 'constants/delay'
import { TableKey } from 'constants/table'
import { useGroupUsersListLoader } from 'hooks/loaders/useGroupUsersListLoader'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useNumberFormat } from 'hooks/useNumberFormat'
import { useStableCallback } from 'hooks/useStableCallback'
import { showClearSelectionModal } from 'pages/admin/members/clearSelectionModal/ClearSelectionModal'
import { showDeleteGroupMemberModal } from 'pages/admin/members/deleteGroupMemberModal/DeleteGroupMemberModal'
import { showDeleteGroupMembersModal } from 'pages/admin/members/deleteGroupMembersModal/DeleteGroupMembersModal'
import { deselectGroupMembers } from 'pages/admin/members/groups/viewGroupSideModal/utils'
import styles from 'pages/admin/members/groups/viewGroupSideModal/viewGroupMembersSection/ViewGroupMembersSection.module.scss'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { AzGroup, GroupUser } from 'types/groups/groups'
import { join } from 'utils/common'

interface State {
  totalRowsCount: number
}

interface Props {
  className?: string
  group: AzGroup
  onAddMember: (group: AzGroup) => void
  onViewMember: (member: GroupUser) => void
  onDeleteMember: () => Promise<void>
  membersSearch?: string
  setMembersSearch: (arg: string) => void
  setRolesSearch: (arg: string) => void
  onMembersLoad: (count: number) => void
}

export const ViewGroupMembersSection = ({
  className,
  group,
  onDeleteMember,
  onAddMember,
  onViewMember,
  membersSearch,
  setMembersSearch,
  setRolesSearch,
  onMembersLoad,
}: Props) => {
  const { t } = useTranslation()
  const { formatNumber } = useNumberFormat()
  const { userDetails } = useOtherTenantsAndUserData()

  const { loader } = useGroupUsersListLoader({ search: membersSearch, groupId: group.uid })

  const [{ totalRowsCount }, setState] = useSetState<State>({
    totalRowsCount: -1,
  })
  const [selectedUsers, setSelectedUsers] = useState<GroupUser[]>([])

  const onSelectionChanged = useStableCallback(({ api }: SelectionChangedEvent<GroupUser>) => {
    setSelectedUsers(api.getSelectedRows())
  })

  const onView = useStableCallback(onViewMember)

  const setSearchDebounced = useDebounceFn((search?: string) => {
    setMembersSearch(search?.trim() || '')
    setState({ totalRowsCount: -1 })
  }, Delay.Search)

  const handleAddMemberClick = useCallback(() => {
    setMembersSearch('')
    setRolesSearch('')
    deselectGroupMembers()
    onAddMember(group)
  }, [onAddMember, setMembersSearch, setRolesSearch, group])

  const columnDefs = useMemo<ColDef<GroupUser>[]>(
    () => [
      {
        width: 50,
        colId: 'select',
        checkboxSelection: true,
        onCellClicked: ({ node }: CellClickedEvent<GroupUser>) => {
          const isNodeSelected = node.isSelected()
          node.setSelected(!isNodeSelected)
        },
      },
      {
        flex: 1,
        headerName: t('os.groups.view_group.members_table.columns.name'),
        colId: 'name',
        cellRenderer: ({ data }) => {
          const { first_name, last_name, email, isExternal } = data!
          const fullName = join([first_name, last_name], ' ')

          return (
            <ClipboardText
              textClassName={styles.clipboard}
              text={fullName}
              tooltipMessage={t('os.common.copy_entity_to_clipboard', {
                entity: t('os.members_list.table.tooltips.member_name'),
              })}
              showEndTag={isExternal}
              textEndTag={t('os.entities.external')}
            >
              <Flex>
                <WppAvatar name={fullName} size="s" className={styles.avatar} />
              </Flex>
              <Flex gap={4} align="center" className={styles.fullNameWrapper}>
                <Flex className={styles.fullName}>{fullName}</Flex>
                {email === userDetails.email && <MeTag />}
              </Flex>
            </ClipboardText>
          )
        },
      },
      {
        flex: 1,
        headerName: t('os.groups.view_group.members_table.columns.email'),
        colId: 'email',
        cellRenderer: ({ data }) => (
          <EmailCell
            email={data!.email}
            isCopiable
            copyTooltip={t('os.common.copy_entity_to_clipboard', {
              entity: t('os.members_list.table.tooltips.member_email'),
            })}
          />
        ),
        tooltipValueGetter: ({ data }) => data!.email,
      },
      {
        width: 52,
        colId: 'delete',
        cellRenderer: ({ data }) => (
          <WppTooltip text={t('os.groups.view_group.members_table.delete_users_modal.title')}>
            <WppIconRemoveCircle
              className={styles.removeMember}
              onClick={() => {
                const { first_name, last_name, id } = data!
                showDeleteGroupMemberModal({
                  member: { fullName: join([first_name, last_name], ' '), id },
                  group,
                  onDeleteMember,
                })
                deselectGroupMembers()
              }}
            />
          </WppTooltip>
        ),
      },
      {
        width: 52,
        colId: 'view',
        cellRenderer: ({ data }) =>
          userDetails.isExternal ? null : (
            <WppTooltip text={t('os.groups.view_group.members_table.actions_column.view_member')}>
              <WppIconChevron className={styles.viewMember} direction="right" onClick={() => onView(data!)} />
            </WppTooltip>
          ),
      },
    ],
    [t, userDetails.isExternal, userDetails.email, group, onDeleteMember, onView],
  )

  return (
    <Flex direction="column" gap={8} className={className}>
      <Flex justify="between" align="end">
        <Flex direction="column">
          {(totalRowsCount > 1 || !!membersSearch) && (
            <WppInput
              size="s"
              type="search"
              value={membersSearch}
              className={styles.searchInput}
              onWppChange={({ detail }) => setSearchDebounced(detail.value)}
              placeholder={t('os.groups.view_group.search_placeholder')}
              data-testid="search-groups"
            />
          )}
        </Flex>

        <Flex gap={12}>
          {!!selectedUsers.length && (
            <>
              <WppPill
                type="display"
                removable
                label={t('os.members.header.selected_entities_chip', {
                  entitiesCount: formatNumber(selectedUsers.length),
                })}
                onWppClose={() =>
                  showClearSelectionModal({
                    onSubmit: deselectGroupMembers,
                  })
                }
              />
              <WppActionButton
                onClick={() => {
                  showDeleteGroupMembersModal({ users: selectedUsers, group })
                }}
              >
                <WppIconRemoveCircle slot="icon-start" />
                {t('os.groups.view_group.members_table.remove')}
              </WppActionButton>
            </>
          )}
          {!selectedUsers.length && totalRowsCount > 0 && (
            <WppActionButton onClick={handleAddMemberClick} data-testid="member-add-empty">
              <WppIconUserAdd slot="icon-start" />
              {t('os.groups.view_group.members_table.add_members')}
            </WppActionButton>
          )}
        </Flex>
      </Flex>
      {totalRowsCount === 0 ? (
        <EmptyStateWithAction
          label={t('os.roles.table.placeholder.no_added_members')}
          actionLabel={t('os.groups.view_group.members_table.add_members')}
          onAction={handleAddMemberClick}
        />
      ) : (
        <TableInfinite
          tableKey={TableKey.VIEW_GROUP_MEMBERS}
          className={clsx(styles.table, 'members-list')}
          columnDefs={columnDefs}
          onSelectionChanged={onSelectionChanged}
          getRowId={({ data }) => data.id}
          loader={loader}
          onLoadSuccess={({ totalRowsCount }) => {
            setState({ totalRowsCount })
            onMembersLoad(totalRowsCount)
          }}
        />
      )}
    </Flex>
  )
}
