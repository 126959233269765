import { useCallback, useMemo } from 'react'

import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'

export const useNumberFormat = () => {
  const {
    userDetails: { numberLocale },
  } = useOtherTenantsAndUserData()

  const intlInstance = useMemo(() => new Intl.NumberFormat(numberLocale, { useGrouping: true }), [numberLocale])

  return {
    formatNumber: useCallback((numberToFormat: number): string => intlInstance.format(numberToFormat), [intlInstance]),
  }
}
