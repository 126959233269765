import { useMemo } from 'react'

import { Role } from 'types/roles/roles'

type GroupsAccountsmap = Record<string, { id: string; name: string }[]>

export const useGroupsAccountsMap = (role?: Role): GroupsAccountsmap =>
  useMemo(() => {
    const groups_roles_accounts = role ? role.groups_roles_accounts : []
    return groups_roles_accounts.reduce<GroupsAccountsmap>((acc, curr) => {
      const { group_id, account_id, account_name } = curr
      const newAccount = { id: account_id, name: account_name }
      if (group_id in acc) {
        const groupAccounts = acc[group_id]

        return { ...acc, [group_id]: [...groupAccounts, newAccount] }
      }
      return { ...acc, [group_id]: [newAccount] }
    }, {})
  }, [role])
