import { TreeType } from '@platform-ui-kit/components-library'
import { MayBeNull } from '@wpp-open/core'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useAsyncFn, useCopyToClipboard } from 'react-use'

import { useMePermissionByAccountApi } from 'api/alphaZulu/queries/useMePermissionByAccountApi'
import { useUpdateFileApi } from 'api/files/mutations/useUpdateFileApi'
import { useFetchFilesSearchApi } from 'api/files/queryFetchers/useFetchFilesSearchApi'
import { useHubsApi } from 'api/hubs/queries/useHubsApi'
import { tableActions, TableInfiniteLoader } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Delay } from 'constants/delay'
import { PageSize } from 'constants/pageSize'
import { Permission } from 'constants/permission'
import { TableKey } from 'constants/table'
import { useStableCallback } from 'hooks/useStableCallback'
import { FilesFormat, FilesListUrlParams } from 'pages/files/constants'
import { showFileDetailsModal } from 'pages/files/fileDetailsModal/FileDetailsModal'
import { useFilesOptions } from 'pages/files/filesOptions/FilesOptionsContext'
import { FileCategory, FilesListLoaderParams, FileType, OwnerFilterValue } from 'pages/files/types'
import { useAuth } from 'providers/auth/AuthContext'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { queryClient } from 'providers/osQueryClient/utils'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { useToast } from 'providers/toast/ToastProvider'
import { FileItem, FileItemType, FileTreeItem } from 'types/files/files'

export const createTree = (folders?: MayBeNull<FileTreeItem[]>, selectedId?: string): TreeType[] => {
  const isParentOfSelected = (node: FileTreeItem) => {
    if (node.id === selectedId) {
      return true
    }
    return node.children ? node.children.some(isParentOfSelected) : false
  }

  const createNode = (folder: FileTreeItem) => {
    return {
      id: folder.id,
      title: folder.name,
      iconStart: { icon: 'wpp-icon-folder', name: 'folder' },
      open: isParentOfSelected(folder),
      children: createTree(folder.children, selectedId),
      selected: selectedId === folder.id,
    }
  }
  return (folders || []).map(createNode)
}

export const useFilesListLoader = ({
  search,
  assignmentType,
  assignmentId,
  parentId,
  isFavorite,
  ownerFilter,
  category,
}: FilesListLoaderParams) => {
  const { currentTenant } = useCurrentTenantData()
  const { userDetails } = useOtherTenantsAndUserData()

  const handleFetchHubFiles = useFetchFilesSearchApi()

  const loader: TableInfiniteLoader<FileItem> = useCallback(
    async ({ sortModel }) => {
      const [{ colId, sort }] = sortModel

      const { data } = await handleFetchHubFiles({
        tenantId: currentTenant.id,
        assignmentId,
        parentId,
        name: search,
        sortDirection: sort,
        sortField: colId,
        assignmentType,
        isFavorite,
        recursive: isFavorite || !!search || ownerFilter === OwnerFilterValue.MY,
        createdBy: ownerFilter === OwnerFilterValue.MY ? userDetails.email : undefined,
        type: ownerFilter === OwnerFilterValue.MY ? FileItemType.REGULAR : undefined,
        category: category === FileCategory.ALL ? undefined : category,
      })

      return {
        data,
        totalRowsCount: data.length,
      }
    },
    [
      assignmentId,
      assignmentType,
      category,
      currentTenant.id,
      handleFetchHubFiles,
      isFavorite,
      ownerFilter,
      parentId,
      search,
      userDetails.email,
    ],
  )

  return { loader }
}

export const handleReloadFiles = async () => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.FILES_SEARCH] })
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.FILES_TREE] })
  tableActions.reload(TableKey.FILES)
}

export const getFileNameWithoutExtension = (fileName?: string) => {
  if (!fileName) return undefined

  const match = fileName.match(/^(.*?)(\.[^.]*$|$)/)
  return match ? match[1] : undefined
}

export const getFileExtension = (fileName?: string) => {
  const fileExtensionWithoutDot = getFileExtensionWithoutDot(fileName)
  return fileExtensionWithoutDot ? `.${fileExtensionWithoutDot}` : undefined
}

export const getFileExtensionWithoutDot = (fileName?: string) => {
  if (!fileName) return undefined

  const match = fileName.match(/\.([0-9a-z_-]+)([?#]|$)/i)
  return match ? match[1] : undefined
}

export const FileFormatMapByCategory: Record<string, FileType> = Object.fromEntries(
  Object.entries(FilesFormat).flatMap(([category, extensions]) =>
    extensions.map(extension => [extension, category as FileType]),
  ),
)

export const useUrlParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const fileId = searchParams.get(FilesListUrlParams.FILE_ID)
  const fileNotFound = searchParams.get(FilesListUrlParams.FILE_NOT_FOUND)
  const folderNotFound = searchParams.get(FilesListUrlParams.FOLDER_NOT_FOUND)
  const deleteParam = useStableCallback((param: string) =>
    setSearchParams(prev => {
      prev.delete(param)
      return prev
    }),
  )
  const setParam = useStableCallback((param: string, value: string) =>
    setSearchParams(prev => {
      prev.set(param, value)
      return prev
    }),
  )
  const setFileId = useStableCallback((value: string) => setParam(FilesListUrlParams.FILE_ID, value))
  const setFileNotFound = useStableCallback(() => setParam(FilesListUrlParams.FILE_NOT_FOUND, 'true'))
  const setFolderNotFound = useStableCallback(() => setParam(FilesListUrlParams.FOLDER_NOT_FOUND, 'true'))
  const deleteFileId = useStableCallback(() => deleteParam(FilesListUrlParams.FILE_ID))
  const deleteFileNotFound = useStableCallback(() => deleteParam(FilesListUrlParams.FILE_NOT_FOUND))
  const deleteFolderNotFound = useStableCallback(() => deleteParam(FilesListUrlParams.FOLDER_NOT_FOUND))

  return {
    fileId,
    fileNotFound,
    folderNotFound,
    setFileId,
    setFileNotFound,
    setFolderNotFound,
    deleteFileId,
    deleteFileNotFound,
    deleteFolderNotFound,
  }
}

export const useCopyFileLink = () => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const [, copyToClipboard] = useCopyToClipboard()

  return useStableCallback((file: FileItem) => {
    const { id, parentId, assignmentId } = file
    copyToClipboard(`${window.location.origin}/files/hubs/${assignmentId}/folders/${parentId}?fileId=${id}`)
    enqueueToast({ type: 'success', message: t('os.files.file_link_copied') })
  })
}

export const useDownloadFile = () => {
  const { currentTenant } = useCurrentTenantData()
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { jwt } = useAuth()
  const getJwt = useStableCallback(() => jwt)

  return useCallback(
    (fileId: string, fileName?: string) => {
      if (!fileName) return

      try {
        const href = `/api/file-manager/v1/tenants/${
          currentTenant.id
        }/files/${fileId}/presigned-download?accessToken=${getJwt()}`
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(href)

        enqueueToast({
          message: t('os.files.file_downloading_started'),
          type: 'success',
        })
      } catch (e) {
        enqueueToast({
          message: t('os.common.errors.error'),
          type: 'error',
        })
      }
    },
    [currentTenant.id, enqueueToast, getJwt, t],
  )
}

export const useOpenFileDetailsModal = () => {
  const { fileId } = useUrlParams()
  const { isAdmin } = useFilesOptions()

  useEffect(() => {
    if (fileId) {
      showFileDetailsModal({ fileId, isAdmin })
    }
  }, [isAdmin, fileId])
}

export const useShowFileNotFoundNotification = () => {
  const { fileNotFound, deleteFileNotFound } = useUrlParams()

  useEffect(() => {
    if (fileNotFound) {
      const timeout = setTimeout(() => {
        deleteFileNotFound()
      }, Delay.ToastLong)

      return () => clearTimeout(timeout)
    }
  }, [fileNotFound, deleteFileNotFound])
}

export const useShowFolderNotFoundNotification = () => {
  const { folderNotFound, deleteFolderNotFound } = useUrlParams()

  useEffect(() => {
    if (folderNotFound) {
      const timeout = setTimeout(() => {
        deleteFolderNotFound()
      }, Delay.ToastLong)

      return () => clearTimeout(timeout)
    }
  }, [folderNotFound, deleteFolderNotFound])
}

export const useToggleIsFavoriteFile = () => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleUpdateFile } = useUpdateFileApi()

  const [{ loading }, toggleIsFavorite] = useAsyncFn(
    async (id: string, isFavorite: boolean, reloadFile?: boolean) => {
      try {
        await handleUpdateFile({ data: { isFavorite: !isFavorite }, id })
        await handleReloadFiles()
        if (reloadFile) {
          await handleReloadFile(id)
        }

        enqueueToast({
          message: isFavorite ? t('os.files.toasts.remove_favorite') : t('os.files.toasts.added_favorite'),
          type: 'success',
        })
      } catch {
        enqueueToast({
          message: t('os.common.errors.general'),
          type: 'error',
        })
      }
    },
    [enqueueToast, handleUpdateFile, t],
  )

  return { toggleIsFavorite, isTogglingIsFavorite: loading }
}

export const useToggleIsFavoriteFiles = () => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleUpdateFile } = useUpdateFileApi()

  const [{ loading }, toggleIsFavoriteFiles] = useAsyncFn(
    async (ids: string[], isFavorite: boolean) => {
      try {
        await Promise.all(ids.map(id => handleUpdateFile({ data: { isFavorite }, id })))
        await handleReloadFiles()
        tableActions.deselectAll(TableKey.FILES)
        enqueueToast({
          message:
            ids.length === 1
              ? t(isFavorite ? 'os.files.toasts.file_added_favorite' : 'os.files.toasts.file_removed_favorite')
              : t(isFavorite ? 'os.files.toasts.files_added_favorite' : 'os.files.toasts.files_removed_favorite', {
                  amount: ids.length,
                }),
          type: 'success',
        })
      } catch {
        enqueueToast({
          message: t('os.common.errors.general'),
          type: 'error',
        })
      }
    },
    [enqueueToast, handleUpdateFile, t],
  )

  return { toggleIsFavoriteFiles, isTogglingIsFavoriteFiles: loading }
}

export const useToggleIsPinnedFile = () => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleUpdateFile } = useUpdateFileApi()

  const [{ loading }, toggleIsPinned] = useAsyncFn(
    async (id: string, isPinned: boolean, reloadFile?: boolean) => {
      try {
        await handleUpdateFile({ data: { isPinned: !isPinned }, id })
        await handleReloadFiles()
        if (reloadFile) {
          await handleReloadFile(id)
        }

        enqueueToast({
          message: isPinned ? t('os.files.toasts.file_unpinned') : t('os.files.toasts.file_pinned'),
          type: 'success',
        })
      } catch {
        enqueueToast({
          message: t('os.common.errors.general'),
          type: 'error',
        })
      }
    },
    [enqueueToast, handleUpdateFile, t],
  )

  return { toggleIsPinned, isTogglingIsPinned: loading }
}

export const handleReloadFile = async (id: string) => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.FILE, { id }] })
}

export const useHubsWithAccessToFiles = () => {
  const { currentTenant } = useCurrentTenantData()
  const { isLoading: isHubsLoading, data: hubs } = useHubsApi({
    params: {
      itemsPerPage: PageSize.All,
      tenantId: currentTenant.id,
    },
  })

  const accountIds = hubs?.map(({ azId }) => azId) || []

  const { data: permissionRecords, isLoading: isPermissionsLoading } = useMePermissionByAccountApi({
    params: { account_ids: accountIds },
    enabled: accountIds.length > 0,
  })

  const isLoading = isHubsLoading || isPermissionsLoading

  const canAccessAzIds = permissionRecords
    ?.map(({ account_id, permissions }) => ({
      account_id,
      canAccess: permissions?.includes(Permission.OS_FILE_MANAGER_ACCESS),
    }))
    .filter(({ canAccess }) => canAccess)
    .map(({ account_id }) => account_id)

  const hubsWithAccessToFiles = hubs?.filter(({ azId }) => canAccessAzIds?.includes(azId)) || []

  return {
    isLoading,
    hubsWithAccessToFiles,
  }
}
