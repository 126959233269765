import { facadeApi } from 'api'

export interface UpdateUsersPreferredHubApi {
  userPreferredHub: {
    hubId: string
    userEmail: string
  }[]
}

export const updateUsersPreferredHubApi = (payload: UpdateUsersPreferredHubApi) =>
  facadeApi.patch('/users/hubs', payload)
