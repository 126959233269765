import { PropsWithChildren, useRef, useState } from 'react'

import { AppsContext } from 'providers/apps/AppsContext'
import { CompactAppOverlay } from 'providers/apps/compactAppOverlay/CompactAppOverlay'
import { useContextValues } from 'providers/apps/utils/contextValue'
import { useGetAppProps } from 'providers/apps/utils/customProps'
import { useOverlay } from 'providers/apps/utils/overlays'
import {
  useHandleSubscribeToToolContext,
  useHandleSubscribeToFullPageContext,
  useSubscriptionEffects,
} from 'providers/apps/utils/subscription'

export const AppsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isFullscreenMicroAppActive, setIsFullscreenMicroAppActive] = useState(false)
  const fullPageSubscribersRef = useRef(new Set<string>())
  const toolSubscribersRef = useRef(new Set<string>())

  const { fullPage, fullPageRef, tool, toolRef } = useContextValues()
  const { overlay, openCompactApp, closeCompactApp, apps } = useOverlay()

  // Handle publish on context changes and unsubscription cleanup
  useSubscriptionEffects({
    fullPageSubscribersRef,
    fullPageContextValue: fullPage,
    toolSubscribersRef,
    toolContextValue: tool,
  })

  const handleSubscribeToFullPageContext = useHandleSubscribeToFullPageContext({
    fullPageSubscribersRef,
    fullPageContextValueRef: fullPageRef,
  })

  const handleSubscribeToToolContext = useHandleSubscribeToToolContext({
    toolSubscribersRef,
    toolContextValueRef: toolRef,
  })

  const { getFullPageAppProps, getToolAppProps } = useGetAppProps({
    handleSubscribeToFullPageContext,
    handleSubscribeToToolContext,
    openCompactApp,
  })

  return (
    <AppsContext.Provider
      value={{
        isFullscreenMicroAppActive,
        setIsFullscreenMicroAppActive,
        getFullPageAppProps,
        getToolAppProps,
      }}
    >
      {overlay && (
        <CompactAppOverlay
          key={overlay.id}
          app={apps[overlay.appStableId]}
          overlayProps={overlay.overlayProps}
          close={() => closeCompactApp()}
        />
      )}

      {children}
    </AppsContext.Provider>
  )
}
