import {
  WppActionButton,
  WppIconChevron,
  WppIconInfo,
  WppIconOwner,
  WppIconRemoveCircle,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import { ColDef, Table } from 'components/common/table'
import { CriticalError } from 'components/renderError'
import { SvgNewNoRecords } from 'components/svg/network/SvgNewNoRecords'
import { DEFAULT_PLURAL_COUNT } from 'constants/i18n'
import { NONE_SELECTED_VALUE } from 'constants/select'
import { useNumberFormat } from 'hooks/useNumberFormat'
import styles from 'pages/admin/members/groups/viewGroupSideModal/viewGroupMembersSection/ViewGroupMembersSection.module.scss'
import { showSelectRoleSideModal } from 'pages/admin/members/selectRoleSideModal/SelectRoleSideModal'
import { TenantGroup } from 'types/groups/groups'
import { TenantRole } from 'types/roles/tenantRole'
import { isRoleCreatedOnRoot } from 'utils/roles'

interface Props {
  className?: string
  group?: TenantGroup
  setRoles: (arg: TenantRole[]) => void
  onOpenViewRoleModal: (id: string) => void
}

export const CreateGroupRolesSection = ({ className, setRoles, onOpenViewRoleModal }: Props) => {
  const { formatNumber } = useNumberFormat()
  const { t } = useTranslation()

  const [{ isCriticalError, rolesAssignments }, setState] = useSetState<{
    isCriticalError: boolean
    rolesAssignments: TenantRole[]
  }>({
    isCriticalError: false,
    rolesAssignments: [],
  })

  const handleDeleteAssignedRole = useCallback(
    (roleId: string) => {
      const filteredAssignments = rolesAssignments.filter(role => role.id !== roleId)
      setState({ rolesAssignments: filteredAssignments })
    },
    [rolesAssignments, setState],
  )

  const columnDefs = useMemo<ColDef<TenantRole>[]>(
    () => [
      {
        flex: 1,
        headerName: t('os.roles.table.columns.account_name'),
        colId: 'account_name',
        sortable: true,
        sort: 'asc',
        cellClass: styles.truncateText,
        valueGetter: ({ data }) =>
          isRoleCreatedOnRoot(data?.createdOnAccountName) ? t('os.common.navigation.os') : data!.createdOnAccountName,
        tooltipValueGetter: ({ data }) =>
          isRoleCreatedOnRoot(data?.createdOnAccountName) ? t('os.common.navigation.os') : data!.createdOnAccountName,
      },
      {
        flex: 1,
        colId: 'name',
        sortable: true,
        headerName: t('os.entities.role'),
        cellClass: styles.truncateText,
        valueGetter: ({ data }) => data!.name,
        tooltipValueGetter: ({ data }) => data!.name,
      },
      {
        width: 52,
        colId: 'delete',
        cellRenderer: ({ data }) => (
          <WppTooltip text={t('os.groups.view_group.roles_table.delete_modal.title')}>
            <WppIconRemoveCircle className={styles.removeMember} onClick={() => handleDeleteAssignedRole(data!.id)} />
          </WppTooltip>
        ),
      },
      {
        width: 52,
        colId: 'information',
        cellRenderer: ({ data }) => (
          <WppTooltip text={t('os.groups.view_group.roles_table.actions_column.view_role')}>
            <WppIconChevron
              className={styles.viewMember}
              direction="right"
              onClick={() => onOpenViewRoleModal(data!.id)}
            />
          </WppTooltip>
        ),
      },
    ],
    [t, handleDeleteAssignedRole, onOpenViewRoleModal],
  )

  const handleOpenSelectRoleSideModal = () => {
    showSelectRoleSideModal({
      cancelButtonText: t('os.common.back_to', { entity: t('os.entities.group') }),
      rolesAssignments,
      onSubmit: async ({ role, navigation }) => {
        if (!role || role === NONE_SELECTED_VALUE) {
          return
        }
        const filteredRolesAssignments = rolesAssignments.filter(roleAssignments => roleAssignments.id !== role.id)
        const resultRoles = [...filteredRolesAssignments, { ...role, uniqueAccounts: navigation! }]
        setState({
          rolesAssignments: resultRoles,
        })
        setRoles(resultRoles)
      },
    })
  }

  return (
    <>
      {isCriticalError && <CriticalError />}

      <Flex direction="column" gap={8} className={className}>
        <Flex direction="column" data-testid="roles-header-section">
          <Flex align="center" justify="between">
            <Flex gap={6} align="center">
              <WppTypography type="m-strong">{t('os.entities.role', { count: DEFAULT_PLURAL_COUNT })}</WppTypography>
              <WppTypography type="s-body" className={styles.entitiesCounter} data-testid="create-roles-counter">
                ({formatNumber(rolesAssignments.length)})
              </WppTypography>
              <WppTooltip config={{ placement: 'right' }} text={t('os.groups.manage_group.roles.label_description')}>
                <WppIconInfo />
              </WppTooltip>
            </Flex>

            <WppActionButton onClick={handleOpenSelectRoleSideModal}>
              <WppIconOwner slot="icon-start" />
              {t('os.roles.manage_roles.fields.assign_role')}
            </WppActionButton>
          </Flex>

          <WppTypography type="s-body" className={styles.subheader} data-testid="roles-subheader">
            {t('os.roles.table.sub_headers.group_roles')}
          </WppTypography>
        </Flex>

        <Table
          columnDefs={columnDefs}
          noRowsOverlayComponent={() => (
            <Flex direction="column" align="center" gap={12} data-testid="add-group-roles-empty-state">
              <SvgNewNoRecords />
              <WppTypography type="m-strong">{t('os.roles.table.placeholder.no_roles_found')}</WppTypography>
            </Flex>
          )}
          rowData={rolesAssignments}
          className={clsx('create-group-roles', styles.table)}
        />
      </Flex>
    </>
  )
}
