import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { useDeleteGroupApi } from 'api/groups/mutations/useDeleteGroupApi'
import { is422Error } from 'api/utils'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import styles from 'pages/admin/members/groups/deleteGroupModal/DeleteGroupModal.module.scss'
import { handleReloadGroupsListTable } from 'pages/admin/members/groups/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { TenantGroup, AzGroup } from 'types/groups/groups'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  group: TenantGroup | AzGroup
  onDelete: (groupId: string) => void
}

export const DeleteGroupModal = ({ isOpen, group, onClose, onDelete, id, onCloseComplete }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleDeleteGroup, isLoading } = useDeleteGroupApi()
  const groupId = 'uid' in group ? group.uid : group.id

  const handleDelete = async () => {
    try {
      await handleDeleteGroup(groupId)

      onDelete(groupId)
      await handleReloadGroupsListTable()

      enqueueToast({
        message: t('os.common.toasts.delete', {
          entity: t('os.entities.group'),
        }),
        type: 'success',
      })
    } catch (error) {
      enqueueToast({
        message: t(is422Error(error) ? 'os.groups.delete.error_message' : 'os.common.errors.general'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  }

  return (
    <Modal
      data-testid={id}
      open={isOpen}
      onWppModalCloseComplete={onCloseComplete}
      onWppModalClose={onClose}
      disableOutsideClick={isLoading}
    >
      <WppTypography slot="header" type="xl-heading">
        {t('os.groups.delete.header')}
      </WppTypography>

      <WppTypography slot="body" type="s-body">
        <Trans
          i18nKey="os.groups.delete.description"
          values={{ name: group?.name || '' }}
          components={[
            <span key="0" className={styles.bold}>
              group
            </span>,
          ]}
        />
      </WppTypography>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} variant="secondary" size="s" data-testid="cancel">
          {t('os.common.cancel')}
        </WppButton>
        <WppButton onClick={handleDelete} size="s" variant="destructive" loading={isLoading} data-testid="apply">
          {t('os.groups.delete.submit')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showDeleteGroupModal } = createNiceModal(DeleteGroupModal, 'delete-group-modal')
