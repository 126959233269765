import { NavigationTreeNode, ProjectsMapping } from '@wpp-open/core'

import { facadeApi } from 'api'

interface Accessible {
  accessible: boolean
}

export type NavigationTreeNodeAccess = NavigationTreeNode & Accessible

export type NavigationTreeMappingAccess = Record<string, NavigationTreeNodeAccess>

export interface NavigationTreeAccess {
  rootId: string
  projectsMapping: ProjectsMapping
  mapping: NavigationTreeMappingAccess
}

export const fetchNavigationAccessApi = ({ tenantId, email }: { tenantId: string; email: string }) => {
  return facadeApi.get<NavigationTreeAccess>(`v2/tenants/${tenantId}/navigation-access-tree/users/${email}`)
}
