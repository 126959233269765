import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import styles from 'components/cellRenderers/email/EmailCell.module.scss'
import { ClipboardText } from 'components/common/clipboardText/ClipboardText'
import { Flex } from 'components/common/flex/Flex'
import { mailto } from 'utils/links'

export interface Props {
  email: string
  isCopiable?: boolean
  copyTooltip?: string
}

export const EmailCell = ({ email, isCopiable = false, copyTooltip }: Props) => {
  const { t } = useTranslation()

  return (
    <Flex className={styles.link} as="a" align="center" href={mailto(email)}>
      {isCopiable ? (
        <ClipboardText text={email} tooltipMessage={copyTooltip || t('os.common.copy_to_clipboard')}>
          {email}
        </ClipboardText>
      ) : (
        <WppTypography className={styles.label} type="s-body">
          {email}
        </WppTypography>
      )}
    </Flex>
  )
}
