import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useRejectHierarchyAccessRequestApi } from 'api/accessRequests/mutations/useRejectHierarchyAccessRequestApi'
import { Flex } from 'components/common/flex/Flex'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { Modal } from 'components/surface/modal/Modal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useDateWithTimeFormat } from 'hooks/useDateWithTimeFormat'
import {
  CHARACTERS_LIMIT,
  useValidationSchema,
} from 'pages/admin/requests/hierarchyAccessRequests/rejectHierarchyAccessRequestModal/utils'
import {
  handleReloadHierarchyAccessRequestsTables,
  isProcessedRequest,
} from 'pages/admin/requests/hierarchyAccessRequests/utils'
import { UsersInfoCard } from 'pages/admin/requests/usersInfoCard/UsersInfoCard'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { HierarchyAccessRequest } from 'types/requests/requests'
import { capitalize } from 'utils/common'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  requests: HierarchyAccessRequest[]
  getAttachmentUrlByKey: (key?: string) => string
  onSubmitted?: () => void
}

const RejectHierarchyAccessRequestModal = ({
  requests,
  getAttachmentUrlByKey,
  onSubmitted,
  isOpen,
  onClose,
  onCloseComplete,
  id,
}: Props) => {
  const { enqueueToast } = useToast()
  const { t } = useTranslation()
  const { formatDate } = useDateWithTimeFormat()

  const form = useForm({
    validationSchema: useValidationSchema(),
    defaultValues: { rejectReason: '' },
  })

  const { mutateAsync: handleRejectHierarchyAccessRequest } = useRejectHierarchyAccessRequestApi()

  const { handleSubmit, formState } = form
  const { isValid, isSubmitting } = formState

  const count = requests.length
  const isSingle = count === 1

  const onSubmit = handleSubmit(async ({ rejectReason }) => {
    try {
      await handleRejectHierarchyAccessRequest({
        ids: requests.map(({ id }) => id),
        rejectReason,
      })

      queryClient.invalidateQueries({
        queryKey: isSingle
          ? [ApiQueryKeys.HIERARCHY_REQUEST, { id: requests[0].id }]
          : [ApiQueryKeys.HIERARCHY_REQUESTS],
      })
      handleReloadHierarchyAccessRequestsTables()

      enqueueToast({
        type: 'success',
        message: isSingle
          ? capitalize(t('os.requests.hierarchy_access_requests.toasts.reject'))
          : capitalize(t('os.requests.hierarchy_access_requests.toasts.reject_other', { count })),
      })
    } catch (error) {
      if (isProcessedRequest(error)) {
        enqueueToast({
          message: capitalize(t('os.common.errors.processed_request')),
          type: 'error',
        })
      } else {
        enqueueToast({
          message: capitalize(t('os.common.errors.general')),
          type: 'error',
        })
      }
    } finally {
      onSubmitted?.()
      onClose()
    }
  })

  return (
    <FormProvider {...form}>
      <Modal
        open={isOpen}
        onWppModalClose={onClose}
        onWppModalCloseComplete={onCloseComplete}
        size="m"
        data-testid={id}
        formConfig={{ onSubmit }}
      >
        <WppTypography type="xl-heading" slot="header">
          {t('os.requests.hierarchy_access_requests.actions_modal.reject_hierarchy.title')}
        </WppTypography>
        <Flex direction="column" slot="body" gap={16}>
          {isSingle ? (
            <UsersInfoCard
              request={requests[0]}
              getAttachmentUrlByKey={getAttachmentUrlByKey}
              date={formatDate(requests[0].createdAt)}
            />
          ) : (
            <WppTypography type="s-strong">
              {t('os.requests.hierarchy_access_requests.actions_modal.reject_hierarchy.description', {
                count,
              })}
            </WppTypography>
          )}

          <FormTextareaInput
            required
            labelConfig={{
              text: t('os.requests.hierarchy_access_requests.actions_modal.reject_hierarchy.reject_reason.label'),
            }}
            charactersLimit={CHARACTERS_LIMIT}
            warningThreshold={CHARACTERS_LIMIT}
            name="rejectReason"
            placeholder={t(
              'os.requests.hierarchy_access_requests.actions_modal.reject_hierarchy.reject_reason.placeholder',
            )}
          />
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
            {t('os.common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" loading={isSubmitting} disabled={!isValid} data-testid="apply">
            {capitalize(t('os.requests.actions.reject'))}
          </WppButton>
        </Flex>
      </Modal>
    </FormProvider>
  )
}

export const { showModal: showRejectHierarchyAccessRequestModal } = createNiceModal(
  RejectHierarchyAccessRequestModal,
  'reject-hierarchy-access-request-modal',
)
