import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { useApproveHubAccessRequestApi } from 'api/accessRequests/mutations/useApproveHubAccessRequestApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { useDateWithTimeFormat } from 'hooks/useDateWithTimeFormat'
import { handleReloadHubAccessRequestsTables } from 'pages/admin/requests/hubAccessRequests/utils'
import styles from 'pages/admin/requests/Requests.module.scss'
import { UsersInfoCard } from 'pages/admin/requests/usersInfoCard/UsersInfoCard'
import { useToast } from 'providers/toast/ToastProvider'
import { HubAccessRequest } from 'types/requests/requests'
import { capitalize } from 'utils/common'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  requests: HubAccessRequest[]
  getAttachmentUrlByKey: (key?: string) => string
  onSubmitted?: () => void
}

const ApproveHubAccessRequestModal = ({
  requests,
  getAttachmentUrlByKey,
  isOpen,
  onSubmitted,
  onClose,
  onCloseComplete,
  id,
}: Props) => {
  const { enqueueToast } = useToast()
  const { t } = useTranslation()
  const { formatDate } = useDateWithTimeFormat()
  const isSingleRequest = requests.length === 1

  const { mutateAsync: handleApproveHubAccessRequest, isLoading } = useApproveHubAccessRequestApi()

  const onSubmit = async () => {
    try {
      await handleApproveHubAccessRequest({ ids: requests.map(({ id }) => id), tenantId: requests[0].tenantId })

      handleReloadHubAccessRequestsTables()

      enqueueToast({
        type: 'success',
        message: capitalize(
          isSingleRequest
            ? t('os.requests.hub_access_requests.actions_modal.toasts.approve')
            : t('os.requests.hub_access_requests.actions_modal.toasts.approve_other', {
                count: requests.length,
              }),
        ),
      })
    } catch (error) {
      enqueueToast({
        message: capitalize(t('os.common.errors.general')),
        type: 'error',
      })
    } finally {
      onSubmitted?.()
      onClose()
    }
  }
  const isExternalUser = requests.some(({ userData }) => userData?.isExternal)

  return (
    <Modal open={isOpen} onWppModalClose={onClose} onWppModalCloseComplete={onCloseComplete} size="m" data-testid={id}>
      <WppTypography type="xl-heading" slot="header">
        {t('os.requests.hub_access_requests.actions_modal.approve_hub_access.title')}
      </WppTypography>

      <Flex slot="body" direction="column" gap={16}>
        {isExternalUser && (
          <WppTypography type="s-strong" data-testid="approve-hub-access-description-external">
            {t('os.requests.hub_access_requests.actions_modal.approve_hub_access.description_external')}
          </WppTypography>
        )}

        <WppTypography type="s-body" data-testid="approve-hub-access-description">
          {isSingleRequest ? (
            <Trans
              i18nKey="os.requests.hub_access_requests.actions_modal.approve_hub_access.description.single"
              values={{
                hubName: requests[0].hubName,
              }}
              components={[
                <span key="0" className={styles.bold}>
                  hubName
                </span>,
              ]}
            />
          ) : (
            <Trans
              i18nKey="os.requests.hub_access_requests.actions_modal.approve_hub_access.description.other"
              values={{
                count: t('os.entities.members_count', { count: requests.length }),
              }}
              components={[
                <span key="0" className={styles.bold}>
                  count
                </span>,
              ]}
            />
          )}
        </WppTypography>

        {isSingleRequest && (
          <UsersInfoCard
            request={requests[0]}
            getAttachmentUrlByKey={getAttachmentUrlByKey}
            date={formatDate(requests[0].createdAt)}
          />
        )}
      </Flex>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
          {t('os.common.cancel')}
        </WppButton>
        <WppButton variant="primary" onClick={onSubmit} loading={isLoading} data-testid="apply">
          {capitalize(t('os.requests.hub_access_requests.actions_modal.approve_hub_access.approve'))}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showApproveHubAccessRequestModal } = createNiceModal(
  ApproveHubAccessRequestModal,
  'assign-hub-access-request-modal',
)
