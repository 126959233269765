import { MayBeNull } from '@wpp-open/core'
import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponseOld, SortOrder } from 'api/common/types'
import { UserDetailsShort } from 'types/users/users'

export enum UsersListSortBy {
  office_location = 'office_location',
  department = 'department',
  job_title = 'job_title',
  firstname = 'firstname',
  email = 'email',
  countryAlpha2Code = 'country_alpha2_code',
}

export interface UsersListFilters {
  country?: string[]
  jobTitle?: string[]
  department?: string[]
  countryAlpha2Code?: string[]
  external?: MayBeNull<boolean>
}

export interface Params {
  workspaceId: string
  limit?: number
  offset?: number
  search?: string
  sortBy?: UsersListSortBy
  orderBy?: SortOrder
  filters?: UsersListFilters
}

export type UsersListResponse = PaginatedResponseOld<UserDetailsShort>

export const fetchUsersListApi = ({
  workspaceId,
  offset = 0,
  limit,
  search,
  orderBy = 'asc',
  sortBy,
  filters = {},
}: Params) =>
  facadeApi.get<UsersListResponse>('/users', {
    headers: {
      workspace_id: workspaceId,
    },
    params: {
      offset,
      limit,
      sortBy,
      orderBy,
      filter: {
        ...(!!search && { search }),
        ...(filters.external !== null && { isExternal: filters.external }),
        jobTitle: filters.jobTitle?.join(', ') || '',
        country: filters.country?.join(', ') || '',
        department: filters.department?.join(', ') || '',
        countryAlpha2Code: filters.countryAlpha2Code?.join(', ') || '',
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'brackets', encode: true }),
    },
  })
