import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { NONE_SELECTED_VALUE } from 'constants/select'
import { RoleSource } from 'types/roles/roles'
import { TenantRole } from 'types/roles/tenantRole'

export interface SelectRoleFormDto {
  role: typeof NONE_SELECTED_VALUE | TenantRole
  sources: typeof NONE_SELECTED_VALUE | RoleSource
  navigation: { name: string; id: string }[]
}

export const useValidationSchema = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod
        .object({
          sources: zod.any(),
          role: zod.custom<TenantRole>(),
          navigation: zod
            .array(zod.object({ id: zod.string(), name: zod.string() }))
            .min(1, t('os.roles.manage_roles.fields.hierarchy.validation_message')),
        })
        .refine(({ role }) => !!role?.id, {
          message: t('os.roles.manage_roles.fields.role.validation_message'),
          path: ['role'],
        }),
    [t],
  )
}
