import { WppButton, WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react'
import { useMemo, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAzUserByEmailApi } from 'api/alphaZulu/queries/useAzUserByEmailApi'
import { useGenerateUserDetailsDownloadUrlsApi } from 'api/attachments/queries/useGenerateUserDetailsDownloadUrlsApi'
import { useUpdateGroupsUsersApi } from 'api/groups/mutations/useUpdateGroupsUsersApi'
import { useUpdateUsersRolesApi } from 'api/users/mutations/useUpdateUsersRolesApi'
import { useUserByEmailApi } from 'api/users/queries/useUserByEmailApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useForm } from 'hooks/form/useForm'
import { showDeleteGroupMemberModal } from 'pages/admin/members/deleteGroupMemberModal/DeleteGroupMemberModal'
import { showDeleteGroupsMemberModal } from 'pages/admin/members/deleteGroupsMemberModal/DeleteGroupsMemberModal'
import { EditMemberGroupsSection } from 'pages/admin/members/editMemberSideModal/editMemberGroupsSection/EditMemberGroupsSection'
import styles from 'pages/admin/members/editMemberSideModal/EditMemberSideModal.module.scss'
import { EditMemberSideModalRoleSection } from 'pages/admin/members/editMemberSideModal/EditMemberSideModalRoleSection'
import {
  getDefaultValues,
  mapValuesToPayload,
  editMemberValidationScheme,
  handleReloadSideEditRolesTable,
} from 'pages/admin/members/editMemberSideModal/utils'
import { handleReloadMembersTable } from 'pages/admin/members/membersList/membersTable/utils'
import { isMemberWithAvatar } from 'pages/admin/members/membersList/utils'
import { handleGroupDelete } from 'pages/admin/members/utils'
import {
  adaptMemberGroups,
  handleReloadMemberRolesTable,
  useViewMemberModals,
} from 'pages/admin/members/viewMemberSideModal/utils'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useToast } from 'providers/toast/ToastProvider'
import { GroupUser } from 'types/groups/groups'
import { RoleMember } from 'types/roles/roleMember'
import { TenantRole } from 'types/roles/tenantRole'
import { UserDetailsShort } from 'types/users/users'
import { join } from 'utils/common'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { mapAzGroupToTenantGroup } from 'utils/groups'
import { mapMemberRolesPayloadToCreate } from 'utils/roles'

interface Props extends NiceModalWrappedProps {
  member: UserDetailsShort | GroupUser | RoleMember
  isLayerAdminPage?: boolean
}

const EditMemberSideModal = ({ isOpen, member, onClose, onCloseComplete, id, isLayerAdminPage = false }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { currentTenant } = useCurrentTenantData()

  const { mutateAsync: handleUpdateGroupsUsers } = useUpdateGroupsUsersApi()
  const { mutateAsync: handleUpdateUsersRoles } = useUpdateUsersRolesApi()

  const { data: azUser, isLoading: isAzUserLoading } = useAzUserByEmailApi({
    params: member.email,
    enabled: isOpen,
  })

  const isAvatarAvailable = isMemberWithAvatar(member)

  const { data: user } = useUserByEmailApi({
    params: { email: member.email },
    enabled: isOpen && !isAvatarAvailable,
  })
  const groups = adaptMemberGroups({
    groups: azUser?.group_membership,
  })

  const { onAddGroup, onViewGroup, onEdit } = useViewMemberModals({
    member,
    isLayerAdminPage,
    shouldBackToEdit: true,
    fullName: join([azUser?.first_name, azUser?.last_name], ' '),
  })

  const avatarThumbnail = isAvatarAvailable ? member.avatarThumbnail : user?.avatarThumbnail

  const { data: avatar, isLoading: isAvatarLoading } = useGenerateUserDetailsDownloadUrlsApi({
    params: { keys: [avatarThumbnail?.key!] },
    enabled: isOpen && !!avatarThumbnail?.key,
  })

  const [rolesAssignments, setRolesAssignments] = useState<TenantRole[]>([])

  const form = useForm({
    defaultValues: useMemo(
      () =>
        getDefaultValues({
          organizationsId: currentTenant.azMeta.organizationsId,
          groups: azUser?.group_membership.map(mapAzGroupToTenantGroup) || [],
        }),
      [currentTenant.azMeta.organizationsId, azUser?.group_membership],
    ),
    validationSchema: editMemberValidationScheme,
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onSubmit = handleSubmit(async values => {
    try {
      await handleUpdateUsersRoles({
        create: mapMemberRolesPayloadToCreate(
          [{ email: azUser?.email!, lastname: azUser?.last_name!, firstname: azUser?.first_name! }],
          rolesAssignments,
        ),
        delete: [],
      })
      await handleUpdateGroupsUsers(
        mapValuesToPayload({ organizationsId: currentTenant.azMeta.organizationsId, user: azUser!, values }),
      )

      await Promise.all([handleReloadMembersTable(), handleReloadSideEditRolesTable(), handleReloadMemberRolesTable()])

      enqueueToast({
        message: t('os.common.toasts.update', { entity: t('os.entities.member').toLocaleLowerCase() }),
        type: 'success',
      })

      onEditModalClose()
    } catch {
      enqueueToast({
        message: t('os.common.errors.error'),
        type: 'error',
      })
    }
  })

  const onEditModalClose = () => {
    setRolesAssignments([])
    onClose()
  }

  const fullName = join([azUser?.first_name, azUser?.last_name], ' ')

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{ onSubmit }}
        size="l"
        open={isOpen}
        disableOutsideClick
        onWppSideModalClose={onEditModalClose}
        onWppSideModalCloseComplete={onCloseComplete}
        data-testid={id}
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('os.members_list.edit_member.header')}
        </WppTypography>

        <Flex slot="body" direction="column" className={styles.modalBody} gap={24}>
          {isAzUserLoading || isAvatarLoading ? (
            <Flex className={styles.loader} justify="center" align="center">
              <WppSpinner size="m" />
            </Flex>
          ) : (
            !!azUser && (
              <>
                <Flex justify="between">
                  <Flex align="center">
                    <Avatar size="l" className={styles.avatar} src={avatar[0]?.signed_url || ''} name={fullName} />
                    <Flex direction="column">
                      <WppTypography type="l-strong">{fullName}</WppTypography>
                      <WppTypography type="s-body">{member.email}</WppTypography>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex direction="column" gap={24}>
                  <EditMemberGroupsSection
                    groups={groups}
                    onAddGroup={onAddGroup}
                    onViewGroup={onViewGroup}
                    onDelete={group => {
                      const { first_name, last_name, id } = azUser
                      showDeleteGroupMemberModal({
                        group,
                        member: { fullName: join([first_name, last_name], ' '), id },
                        onDeleteMember: () => handleGroupDelete(onEdit),
                      })
                    }}
                    onDeleteBulk={groups => {
                      const { first_name, last_name, id, email } = azUser
                      showDeleteGroupsMemberModal({
                        groups,
                        member: { fullName: join([first_name, last_name], ' '), id, email },
                        onDeleteMember: () => handleGroupDelete(onEdit),
                      })
                    }}
                  />

                  {!isLayerAdminPage && (
                    <EditMemberSideModalRoleSection
                      member={member}
                      azUser={azUser}
                      isLayerAdminPage={isLayerAdminPage}
                      rolesAssignments={rolesAssignments}
                      updateRolesAssignment={roles => {
                        setRolesAssignments(roles)
                      }}
                    />
                  )}
                </Flex>
              </>
            )
          )}
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onEditModalClose} data-testid="cancel">
            {t('os.common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" loading={isSubmitting} disabled={!azUser} data-testid="apply">
            {t('os.common.save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showEditMemberSideModal, hideModal: hideEditMemberSideModal } = createNiceModal(
  EditMemberSideModal,
  'edit-member-side-modal',
)
