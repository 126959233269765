import { tableActions } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { queryClient } from 'providers/osQueryClient/utils'

export enum GROUP_DETAIL {
  ROLES = 'roles',
  MEMBERS = 'members',
}

export const handleReloadGroupRolesTable = async () => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.ROLES_LIST] })
  tableActions.reload(TableKey.VIEW_GROUP_ROLES)
}

export const handleReloadGroupMembersTable = async () => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.GROUP_USERS_LIST] })
  tableActions.reload(TableKey.VIEW_GROUP_MEMBERS)
}

export const deselectGroupMembers = () => tableActions.deselectAll(TableKey.VIEW_GROUP_MEMBERS)
