import { useCallback, useMemo } from 'react'

import { RequestsType } from 'constants/requests'
import { TableDefaults } from 'constants/table'
import { useUserDetailsAttachments } from 'hooks/attachments/useUserDetailsAttachments'
import { showAssignHierarchyAccessRequestModal } from 'pages/admin/requests/hierarchyAccessRequests/assignHierarchyAccessRequestModal/AssignHierarchyAccessRequestModal'
import { showRejectHierarchyAccessRequestModal } from 'pages/admin/requests/hierarchyAccessRequests/rejectHierarchyAccessRequestModal/RejectHierarchyAccessRequestModal'
import { showApproveHubAccessRequestModal } from 'pages/admin/requests/hubAccessRequests/approveHubAccessRequestModal/ApproveHubAccessRequestModal'
import { showRejectHubAccessRequestModal } from 'pages/admin/requests/hubAccessRequests/rejectHubAccessRequestModal/RejectHubAccessRequestModal'
import {
  hideRequestDetailsSideModal,
  showRequestDetailsSideModal,
} from 'pages/admin/requests/requestDetailsSideModal/RequestDetailsSideModal'
import { showApproveTenantAccessRequestModal } from 'pages/admin/requests/tenantAccessRequests/approveTenantAccessRequestModal/ApproveTenantAccessRequestModal'
import { showRejectTenantAccessRequestModal } from 'pages/admin/requests/tenantAccessRequests/rejectTenantAccessRequestModal/RejectTenantAccessRequestModal'
import { HierarchyAccessRequest, HubAccessRequest, TenantAccessRequest } from 'types/requests/requests'

export enum AccessRequestStatus {
  NEW = 'new',
  PENDING = 'pending',
  REJECTED = 'rejected',
  APPROVED = 'approved',
}

interface Props {
  request: HierarchyAccessRequest | HubAccessRequest | TenantAccessRequest
  type: RequestsType
}

export const useAccessRequesDetailsModal = ({ request, type }: Props) => {
  const { getAttachmentUrlByKey } = useUserDetailsAttachments({
    staleTime: TableDefaults.LoaderStaleTime,
  })
  const onSubmitted = () => hideRequestDetailsSideModal()
  const generalOptions = useMemo(
    () => ({ requests: [request], getAttachmentUrlByKey }),
    [request, getAttachmentUrlByKey],
  )

  const getModalHandler = useCallback(() => {
    switch (type) {
      case RequestsType.HIERARCHY_ACCESS:
        return {
          handleApprove: () => showAssignHierarchyAccessRequestModal({ ...generalOptions, onSubmitted }),
          handleReject: () => showRejectHierarchyAccessRequestModal({ ...generalOptions, onSubmitted }),
        }
      case RequestsType.TENANT_ACCESS:
        return {
          handleApprove: () => showApproveTenantAccessRequestModal({ ...generalOptions, onSubmitted }),
          handleReject: () => showRejectTenantAccessRequestModal({ ...generalOptions, onSubmitted }),
        }
      case RequestsType.HUB_ACCESS:
        return {
          handleApprove: () =>
            showApproveHubAccessRequestModal({
              requests: [request] as HubAccessRequest[],
              getAttachmentUrlByKey,
              onSubmitted,
            }),
          handleReject: () =>
            showRejectHubAccessRequestModal({
              requests: [request] as HubAccessRequest[],
              getAttachmentUrlByKey,
              onSubmitted,
            }),
        }
      default:
        throw new Error(`Invalid request type: ${type}`)
    }
  }, [type, generalOptions, getAttachmentUrlByKey, request])

  const requestDetailsSideModalHandler = useCallback(() => {
    showRequestDetailsSideModal({
      type,
      ...generalOptions,
      ...getModalHandler(),
    })
  }, [generalOptions, type, getModalHandler])

  return { requestDetailsSideModalHandler }
}
