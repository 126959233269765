import { useMemo } from 'react'

import { useMePermissionByAccountsAndPermissionsApi } from 'api/alphaZulu/queries/useMePermissionByAccountsAndPermissionsApi'
import { useHierarchyTreeApi } from 'api/navigation/queries/useHierarchyTreeApi'
import { useWorkspaceAccountIds } from 'hooks/useWorkspaceAccountIds'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'

export const canManage = (perms: string[], perm_prefixes: string[]) => () => {
  const {
    currentTenant: { id, azId },
  } = useCurrentTenantData()

  const { isLoading, data: hierarchyTree } = useHierarchyTreeApi({
    params: {
      tenantId: id,
    },
  })
  const accountIds = useWorkspaceAccountIds(hierarchyTree)
  const { data: permissions, isLoading: isPermissionsLoading } = useMePermissionByAccountsAndPermissionsApi({
    params: {
      account_ids: [...accountIds, azId],
      perms,
      perm_prefixes,
    },
    enabled: !!hierarchyTree,
  })

  const set = useMemo(() => new Set(permissions.map(({ account_id }) => account_id)), [permissions])
  return {
    isLoading: isPermissionsLoading,
    handler: useMemo(
      () => (accountId: string | undefined) =>
        !(isLoading && isPermissionsLoading) && Boolean(accountId && set.has(accountId)),
      [set, isLoading, isPermissionsLoading],
    ),
  }
}
