import {
  WppActionButton,
  WppTooltip,
  WppTypography,
  WppIconCopy,
  WppTag,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useCopyToClipboard } from 'react-use'

import styles from 'components/common/clipboardText/ClipboardText.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { useToast } from 'providers/toast/ToastProvider'

export type ClipboardProps = ComponentPropsWithoutRef<typeof WppTypography> & {
  text: string
  toastMessage?: string
  tooltipMessage?: string
  'data-testid'?: string
  buttonClassName?: string
  textClassName?: string
  showEndTag?: boolean
  textEndTag?: string
}

export const ClipboardText = ({
  className,
  text,
  toastMessage,
  tooltipMessage,
  children,
  buttonClassName,
  textClassName,
  showEndTag,
  textEndTag,
  'data-testid': dataTestId = 'clipboard',
  ...rest
}: ClipboardProps) => {
  const [, copyToClipboard] = useCopyToClipboard()
  const { t } = useTranslation()
  const { enqueueToast } = useToast()

  const handleCopyClick = useCallback(
    (event: React.MouseEvent<HTMLWppActionButtonElement>) => {
      event.preventDefault()
      copyToClipboard(text)
      enqueueToast({
        message: toastMessage || t('os.common.toasts.copied_to_clipboard'),
        type: 'success',
      })
    },
    [copyToClipboard, enqueueToast, t, text, toastMessage],
  )

  return (
    <Flex
      className={clsx(className, styles.root)}
      gap={7}
      align="center"
      justify="between"
      inline
      data-testid={dataTestId}
    >
      <WppTypography type="s-body" className={clsx(textClassName, styles.text)} {...rest}>
        {children}
      </WppTypography>
      <>
        {showEndTag && (
          <WppTag className={styles.endTag} label={textEndTag} variant="neutral" data-testid="user-name-end-tag" />
        )}
      </>
      <WppTooltip text={tooltipMessage || t('os.common.copy_to_clipboard')}>
        <WppActionButton
          className={clsx(buttonClassName, styles.button)}
          onClick={handleCopyClick}
          data-testid="clipboard-button"
        >
          <WppIconCopy />
        </WppActionButton>
      </WppTooltip>
    </Flex>
  )
}
