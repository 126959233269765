import { useCallback } from 'react'

import { useFetchRolesListApi } from 'api/roles/queryFetchers/useFetchRolesListApi'
import { TableInfiniteLoader } from 'components/common/table'
import { TableDefaults } from 'constants/table'
import { useLatestDistinct } from 'hooks/useLatestDistinct'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { TenantRole } from 'types/roles/tenantRole'

interface Params {
  search?: string
  groupId?: string[]
  memberId?: string[]
  accountId?: string[]
}

export const useRolesListLoader = ({ search, groupId, memberId, accountId }: Params) => {
  const { currentTenant } = useCurrentTenantData()
  const groupIdStable = useLatestDistinct(groupId)
  const memberIdStable = useLatestDistinct(memberId)
  const accountIdStable = useLatestDistinct(accountId)

  const handleFetchRolesList = useFetchRolesListApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const loader: TableInfiniteLoader<TenantRole> = useCallback(
    async ({ startRow, endRow, sortModel }) => {
      const size = endRow - startRow
      const [{ colId, sort }] = sortModel

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchRolesList({
        tenantId: currentTenant.id,
        groupId: groupIdStable,
        userId: memberIdStable,
        accountId: accountIdStable,
        itemsPerPage: size,
        search,
        sort: sort === 'asc' ? colId : `-${colId}`,
        page: endRow / size,
      })

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [currentTenant.id, groupIdStable, search, handleFetchRolesList, memberIdStable, accountIdStable],
  )

  return { loader }
}
