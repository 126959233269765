import { WppTypography, WppIconWarning, WppButton } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useUpdateUsersRolesApi } from 'api/users/mutations/useUpdateUsersRolesApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { handleReloadSideEditRolesTable } from 'pages/admin/members/editMemberSideModal/utils'
import { handleReloadMembersTable } from 'pages/admin/members/membersList/membersTable/utils'
import { handleReloadMemberRolesTable } from 'pages/admin/members/viewMemberSideModal/utils'
import styles from 'pages/admin/roles/Roles.module.scss'
import { handleReloadRolesTable } from 'pages/admin/roles/utils'
import { deselectRoleMembers, handleReloadRoleMembersTable } from 'pages/admin/roles/viewRoleSideModal/utils'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useToast } from 'providers/toast/ToastProvider'
import { MemberRole } from 'types/roles/memberRole'
import { RoleMember } from 'types/roles/roleMember'
import { Role } from 'types/roles/roles'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { mapMembersRolesPayloadToDelete } from 'utils/roles'

interface Props extends NiceModalWrappedProps {
  members: RoleMember[]
  role: MemberRole | Role
}

const DeleteRoleMembersModal = ({ isOpen, onClose, members, role, id, onCloseComplete }: Props) => {
  const { t } = useTranslation()
  const { currentTenant } = useCurrentTenantData()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleUpdateUsersRoles, isLoading } = useUpdateUsersRolesApi()

  const onDelete = async () => {
    try {
      await handleUpdateUsersRoles({
        create: [],
        delete: mapMembersRolesPayloadToDelete(members, role, currentTenant.azMeta.organizationsId),
      })

      await Promise.all([
        handleReloadSideEditRolesTable(),
        handleReloadRoleMembersTable(),
        handleReloadRolesTable(),
        handleReloadMemberRolesTable(),
        handleReloadMembersTable(),
      ])

      enqueueToast({
        message: t('os.common.toasts.delete_from', {
          entity: t('os.entities.role').toLocaleLowerCase(),
          is: t('os.entities.is'),
          deleteFrom: t('os.entities.member', { count: members.length }).toLocaleLowerCase(),
        }),
        type: 'success',
      })
      deselectRoleMembers()
    } catch {
      enqueueToast({
        message: t('os.common.errors.error'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  }

  return (
    <Modal
      data-testid={id}
      open={isOpen}
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      disableOutsideClick={isLoading}
    >
      <Flex slot="header" align="center" gap={8}>
        <WppIconWarning />
        <WppTypography type="xl-heading">
          {t('os.roles.view_role.members.delete_users_modal.title', {
            members: t('os.entities.member', { count: members.length }).toLocaleLowerCase(),
          })}
        </WppTypography>
      </Flex>

      <WppTypography slot="body" type="s-strong" className={styles.breakWord}>
        {t('os.roles.view_role.members.delete_users_modal.description', {
          roleName: role.name,
          members: t('os.entities.member', { count: members.length }).toLocaleLowerCase(),
        })}
      </WppTypography>

      <Flex slot="actions" justify="end" gap={12}>
        <WppButton variant="secondary" size="s" onClick={onClose} data-testid="cancel">
          {t('os.common.cancel')}
        </WppButton>
        <WppButton size="s" variant="destructive" loading={isLoading} onClick={onDelete} data-testid="apply">
          {t('os.common.remove')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showDeleteRoleMembersModal } = createNiceModal(
  DeleteRoleMembersModal,
  'delete-role-members-modal',
)
