import {
  WppActionButton,
  WppIconChevron,
  WppIconInfo,
  WppIconOwner,
  WppIconRemoveCircle,
  WppInput,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import { is5xxError } from 'api/utils'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, Table, TableInfinite } from 'components/common/table'
import { RenderErrorBoundary, RenderErrorType, CriticalError } from 'components/renderError'
import { SvgNewNoRecords } from 'components/svg/network/SvgNewNoRecords'
import { Delay } from 'constants/delay'
import { DEFAULT_PLURAL_COUNT } from 'constants/i18n'
import { NONE_SELECTED_VALUE } from 'constants/select'
import { TableKey } from 'constants/table'
import { useRolesListLoader } from 'hooks/loaders/useRolesListLoader'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useNumberFormat } from 'hooks/useNumberFormat'
import { showDeleteGroupRoleModal } from 'pages/admin/members/deleteGroupRoleModal/DeleteGroupRoleModal'
import { RolesSegment } from 'pages/admin/members/editMemberSideModal/utils'
import styles from 'pages/admin/members/groups/viewGroupSideModal/viewGroupMembersSection/ViewGroupMembersSection.module.scss'
import { showSelectRoleSideModal } from 'pages/admin/members/selectRoleSideModal/SelectRoleSideModal'
import { AzGroup } from 'types/groups/groups'
import { TenantRole } from 'types/roles/tenantRole'
import { isRoleCreatedOnRoot } from 'utils/roles'

interface Props {
  className?: string
  group: AzGroup
  setRoles: (arg: TenantRole[]) => void
  onOpenViewRoleModal: (id: string) => void
}

interface State {
  rolesCount: number
  isCriticalError: boolean
  rolesAssignments: TenantRole[]
  currentSegment: RolesSegment
}

const initialState: State = {
  rolesCount: 0,
  isCriticalError: false,
  rolesAssignments: [],
  currentSegment: RolesSegment.Assigned,
}

export const EditGroupRolesSection = ({ className, group, setRoles, onOpenViewRoleModal }: Props) => {
  const { formatNumber } = useNumberFormat()
  const [rolesSearch, setRolesSearch] = useState('')
  const { t } = useTranslation()

  const groupId = group.uid

  const { loader } = useRolesListLoader({ search: rolesSearch, groupId: [groupId!] })

  const [{ rolesCount, isCriticalError, rolesAssignments, currentSegment }, setState] = useSetState<State>(initialState)

  const noRowsOverlayComponent = useCallback(
    () => (
      <Flex direction="column" align="center" gap={12}>
        <SvgNewNoRecords />
        <WppTypography type="m-strong">{t('os.roles.table.placeholder.no_roles_found')}</WppTypography>
      </Flex>
    ),
    [t],
  )

  const handleDeleteAssignedRole = useCallback(
    (roleId: string) => {
      const filteredAssignments = rolesAssignments.filter(role => role.id !== roleId)
      setState({ rolesAssignments: filteredAssignments })
    },
    [rolesAssignments, setState],
  )

  const handleRoleDelete = useCallback(
    async (role: TenantRole) => {
      if (currentSegment === RolesSegment.New) {
        handleDeleteAssignedRole(role.id)
        rolesAssignments.length === 1 &&
          setState({
            currentSegment: RolesSegment.Assigned,
          })
        return
      }
      const { id, name } = role

      showDeleteGroupRoleModal({
        group,
        roleName: name,
        roleId: id,
      })
    },
    [currentSegment, handleDeleteAssignedRole, rolesAssignments.length, group, setState],
  )

  const columnDefs = useMemo<ColDef<TenantRole>[]>(
    () => [
      {
        flex: 1,
        headerName: t('os.roles.table.columns.account_name'),
        colId: 'account_name',
        sort: 'asc',
        sortable: true,
        valueGetter: ({ data }) =>
          isRoleCreatedOnRoot(data?.createdOnAccountName) ? t('os.common.navigation.os') : data!.createdOnAccountName,
        tooltipValueGetter: ({ data }) =>
          isRoleCreatedOnRoot(data?.createdOnAccountName) ? t('os.common.navigation.os') : data!.createdOnAccountName,
      },
      {
        flex: 1,
        colId: 'name',
        sortable: true,
        headerName: t('os.entities.role'),
        valueGetter: ({ data }) => data!.name,
        tooltipValueGetter: ({ data }) => data!.name,
      },
      {
        width: 52,
        colId: 'delete',
        cellRenderer: ({ data }) => (
          <WppTooltip text={t('os.groups.view_group.roles_table.delete_modal.title')}>
            <WppIconRemoveCircle className={styles.removeMember} onClick={() => handleRoleDelete(data!)} />
          </WppTooltip>
        ),
      },
      {
        width: 52,
        colId: 'information',
        cellRenderer: ({ data }) => (
          <WppTooltip text={t('os.groups.view_group.roles_table.actions_column.view_role')}>
            <WppIconChevron
              className={styles.viewMember}
              direction="right"
              onClick={() => onOpenViewRoleModal(data!.id)}
            />
          </WppTooltip>
        ),
      },
    ],
    [t, handleRoleDelete, onOpenViewRoleModal],
  )

  const handleOpenSelectRoleSideModal = () => {
    setRolesSearch('')
    showSelectRoleSideModal({
      cancelButtonText: t('os.common.back_to', { entity: t('os.entities.group') }),
      rolesAssignments,
      group,
      onSubmit: async ({ role, navigation }) => {
        if (!role || role === NONE_SELECTED_VALUE) {
          return
        }
        const filteredRolesAssignments = rolesAssignments.filter(roleAssignments => roleAssignments.id !== role.id)
        const resultRoles = [...filteredRolesAssignments, { ...role, uniqueAccounts: navigation! }]
        setState({
          rolesAssignments: resultRoles,
          currentSegment: resultRoles.length ? RolesSegment.New : RolesSegment.Assigned,
        })
        setRoles(resultRoles)
      },
    })
  }

  const setSearchDebounced = useDebounceFn((search?: string) => setRolesSearch(search?.trim() || ''), Delay.Search)

  return (
    <>
      {isCriticalError && <CriticalError />}

      <Flex direction="column" gap={8} className={className}>
        <Flex data-testid="roles-header-section" justify="between" align="end" gap={4}>
          <Flex direction="column" gap={8}>
            <Flex gap={6} align="center">
              <WppTypography type="m-strong">{t('os.entities.role', { count: DEFAULT_PLURAL_COUNT })}</WppTypography>
              <WppTypography type="s-body" className={styles.entitiesCounter} data-testid="create-roles-counter">
                ({formatNumber(currentSegment === RolesSegment.Assigned ? rolesCount : rolesAssignments.length)})
              </WppTypography>
              <WppTooltip config={{ placement: 'right' }} text={t('os.groups.manage_group.roles.label_description')}>
                <WppIconInfo />
              </WppTooltip>
            </Flex>

            <WppTypography type="s-body" className={styles.subheader} data-testid="roles-subheader">
              {t('os.roles.table.sub_headers.group_roles')}
            </WppTypography>
            {(rolesCount > 1 || !!rolesSearch) && currentSegment === RolesSegment.Assigned && (
              <WppInput
                size="s"
                type="search"
                value={rolesSearch}
                className={styles.searchInput}
                onWppChange={({ detail }) => setSearchDebounced(detail.value)}
                placeholder={t('os.groups.view_group.search_role_placeholder')}
                data-testid="search-roles"
              />
            )}
          </Flex>
          <WppActionButton onClick={handleOpenSelectRoleSideModal}>
            <WppIconOwner slot="icon-start" />
            {t('os.roles.manage_roles.fields.assign_role')}
          </WppActionButton>
        </Flex>

        {!!rolesAssignments.length && (
          <WppSegmentedControl
            size="s"
            value={currentSegment}
            onWppChange={({ detail: { value } }) => {
              const segment = value as RolesSegment
              setRolesSearch('')
              setState({
                currentSegment: segment,
              })
            }}
          >
            <WppSegmentedControlItem value={RolesSegment.Assigned}>
              {t('os.roles.table.columns.assigned')}
            </WppSegmentedControlItem>
            <WppSegmentedControlItem value={RolesSegment.New}>
              {t('os.roles.table.columns.new')}
            </WppSegmentedControlItem>
          </WppSegmentedControl>
        )}
        {currentSegment === RolesSegment.New && (
          <Table
            columnDefs={columnDefs}
            noRowsOverlayComponent={noRowsOverlayComponent}
            rowData={rolesAssignments}
            className={clsx('edit-new-group-roles', styles.table)}
          />
        )}
        {currentSegment === RolesSegment.Assigned && (
          <RenderErrorBoundary catchErrors={[RenderErrorType.DataIsNotAvailable]}>
            <TableInfinite
              className={clsx('edit-group-roles', styles.table)}
              loader={loader}
              columnDefs={columnDefs}
              noRowsOverlayComponent={noRowsOverlayComponent}
              tableKey={TableKey.GROUP_ROLES}
              onLoadSuccess={({ totalRowsCount }) => setState({ rolesCount: totalRowsCount })}
              onLoadError={error => {
                if (is5xxError(error)) {
                  setState({ isCriticalError: true })
                }
              }}
            />
          </RenderErrorBoundary>
        )}
      </Flex>
    </>
  )
}
