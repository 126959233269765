import { MayBeNull } from '@wpp-open/core'
import { t } from 'i18next'
import { createContext, useContext } from 'react'

import { useDeleteFeedApi } from 'api/feeds/mutations/useDeleteFeedApi'
import { useUpdateFeedsApi } from 'api/feeds/mutations/useUpdateFeedsApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useStableCallback } from 'hooks/useStableCallback'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { EventName, Feed } from 'types/feeds/feeds'
import { difference } from 'utils/common'

export const enum NotificationSegment {
  ALL = 'all',
  OS = 'os',
  PROJECTS = 'projects',
  APPS = 'apps',
}

export const isToday = (date: string) => {
  const dateToCompare = new Date(date)
  const today = new Date()
  return (
    dateToCompare.getDate() == today.getDate() &&
    dateToCompare.getMonth() == today.getMonth() &&
    dateToCompare.getFullYear() == today.getFullYear()
  )
}

interface FeedsListProps {
  feeds: Feed[]
  lastSeenAt?: MayBeNull<string>
}

export const useModifyFeed = () => {
  const { enqueueToast } = useToast()
  const { mutateAsync: handleDeleteFeed } = useDeleteFeedApi()
  const { mutateAsync: handleUpdateFeeds } = useUpdateFeedsApi()

  const handleFeedsDataReload = useStableCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.USER_FEEDS_INFINITE] })
    await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.FEEDS_STATUSES] })
  })

  const deleteFeed = useStableCallback(async (id: string, tenantId: string) => {
    try {
      await handleDeleteFeed({ tenantId, id })
      await handleFeedsDataReload()
      enqueueToast({
        message: t('os.notification.toast.delete_success_one'),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('os.common.errors.general'),
        type: 'error',
      })
    }
  })

  const readFeed = useStableCallback(async (id: string, tenantId: string, isRead: boolean) => {
    await handleUpdateFeeds({ tenantId, ids: [id], updates: { isRead } })
    await handleFeedsDataReload()
  })

  return {
    deleteFeed,
    readFeed,
  }
}

export const getFeedsLists = ({ lastSeenAt, feeds }: FeedsListProps) => {
  const filteredFeeds = feeds.filter(({ eventName }) => Object.values(EventName).includes(eventName))
  if (!lastSeenAt) {
    return { newItems: filteredFeeds, todayItems: [], earlierItems: [] }
  } else {
    const newItems = filteredFeeds.filter(
      (item: Feed) => new Date(item.createdAt).getTime() > new Date(lastSeenAt).getTime() && !item.isRead,
    )

    const todayItems = difference(filteredFeeds, newItems, ({ id }) => id).filter(({ createdAt }) => isToday(createdAt))

    const earlierItems = difference(filteredFeeds, [...newItems, ...todayItems], ({ id }) => id)

    return { newItems, todayItems, earlierItems }
  }
}

export const NotificationFeedsStateContext = createContext<{
  selectedFeeds: string[]
  handleFeedSelect: (id: string) => void
}>(null!)

export const useNotificationFeedsState = () => useContext(NotificationFeedsStateContext)
