import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'
import * as zod from 'zod'

import { useUpdateFileApi } from 'api/files/mutations/useUpdateFileApi'
import { handleReloadFile } from 'pages/files/utils'
import { useToast } from 'providers/toast/ToastProvider'

const MAX_CHARACTERS = 128

export const useValidationScheme = (extension: string) => {
  const { t } = useTranslation()
  const maxNameLength = MAX_CHARACTERS - extension.length - 1
  return useMemo(
    () =>
      zod.object({
        name: zod
          .string()
          .min(
            1,
            t('os.common.errors.min_max_length', {
              entity: t('os.files.file_details_modal.file_name'),
              min: 1,
              max: maxNameLength,
            }),
          )
          .max(
            maxNameLength,
            t('os.common.errors.min_max_length', {
              entity: t('os.files.file_details_modal.file_name'),
              min: 1,
              max: maxNameLength,
            }),
          ),
      }),
    [maxNameLength, t],
  )
}

export const useAllowInAiAssistant = () => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleFileUpdate } = useUpdateFileApi()

  const [{ loading: isAllowingInAiAssistant }, onAllowInAiAssistant] = useAsyncFn(
    async (fileId: string, allowInAiAssistant: boolean) => {
      try {
        await handleFileUpdate({ id: fileId, data: { allowInAiAssistant } })
        await handleReloadFile(fileId)
        enqueueToast({
          message: allowInAiAssistant
            ? t('os.files.toasts.file_is_allowed_in_ai_assigned')
            : t('os.files.toasts.file_is_removed_from_ai_assigned'),
          type: 'success',
        })
      } catch {
        enqueueToast({
          message: t('os.common.errors.error'),
          type: 'error',
        })
      }
    },
    [enqueueToast, handleFileUpdate, t],
  )

  return { isAllowingInAiAssistant, onAllowInAiAssistant }
}
