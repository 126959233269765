import {
  WppActionButton,
  WppIconOwner,
  WppIconRemoveCircle,
  WppTypography,
  WppTooltip,
  WppIconChevron,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'
import { useSetState } from 'react-use'

import { is5xxError } from 'api/utils'
import { TextWithTooltipCell } from 'components/cellRenderers/textWithTooltip/TextWithTooltipCell'
import { EmptyStateWithAction } from 'components/common/emptyStateWithAction/EmptyStateWithAction'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, TableInfinite } from 'components/common/table'
import { CriticalError, RenderErrorBoundary, RenderErrorType } from 'components/renderError'
import { Delay } from 'constants/delay'
import { TableKey } from 'constants/table'
import { useMemberRolesListLoader } from 'pages/admin/members/viewMemberSideModal/utils'
import styles from 'pages/admin/members/viewMemberSideModal/ViewMemberSideModal.module.scss'
import { showDeleteRoleMemberModal } from 'pages/admin/roles/deleteRoleMemberModal/DeleteRoleMemberModal'
import { MemberRole } from 'types/roles/memberRole'
import { AZUser } from 'types/users/users'
import { isRoleCreatedOnRoot } from 'utils/roles'
import { routesManager } from 'utils/routesManager'

export interface State {
  isCriticalError?: boolean
}

interface Props {
  onViewRole: (roleId: string) => void
  onAddRole: () => void
  user: AZUser
  isLayerAdminPage: boolean
  noResultsPlaceholder: boolean
}

export const ViewMemberRolesSection = ({
  onAddRole,
  user,
  onViewRole,
  isLayerAdminPage,
  noResultsPlaceholder,
}: Props) => {
  const { t } = useTranslation()
  const { loader } = useMemberRolesListLoader({ memberEmail: user.email })
  const isGroupsPage = !!useMatch(routesManager.admin.members.groups.url())

  const [{ isCriticalError }, setState] = useSetState<State>({
    isCriticalError: false,
  })

  const noRowsOverlay = useMemo(
    () => () => (
      <EmptyStateWithAction
        label={t('os.roles.table.placeholder.not_assigned')}
        actionLabel={t('os.roles.header.assign_role')}
        onAction={onAddRole}
      />
    ),
    [t, onAddRole],
  )

  const memberRolesColumnDefs = useMemo<ColDef<MemberRole>[]>(
    () => [
      {
        flex: 1,
        headerName: t('os.common.entity'),
        colId: 'account_name',
        valueGetter: ({ data }) =>
          isRoleCreatedOnRoot(data!.createdOnAccountName) ? t('os.common.navigation.os') : data!.createdOnAccountName,
        tooltipValueGetter: ({ data }) => data!.createdOnAccountName,
      },
      {
        flex: 1,
        headerName: t('os.entities.role'),
        colId: 'role',
        cellRenderer: ({ data }) => {
          return (
            <Flex direction="column" justify="center" className={styles.zeroMinWidth}>
              <WppTypography type="s-body">{data!.name}</WppTypography>
              {!!data!.description && (
                <WppTooltip text={data!.description} className={styles.tooltipWidth}>
                  <WppTypography type="xs-body" className={clsx(styles.descriptionColor, styles.zeroMinWidth)}>
                    {data!.description}
                  </WppTypography>
                </WppTooltip>
              )}
            </Flex>
          )
        },
      },
      {
        width: 120,
        headerName: t('os.roles.table.columns.hierarchy'),
        colId: 'hierarchy',
        cellRenderer: ({ data }) => {
          return (
            <TextWithTooltipCell
              tooltipProps={{
                text: data!.uniqueAccounts.map(acc => acc.name).join(', '),
                config: { placement: 'left', delay: [Delay.Tooltip, null] },
              }}
              cellText={t('os.roles.table.rows.hierarchy', { count: data!.uniqueAccounts.length })}
            />
          )
        },
      },
      {
        width: 60,
        colId: 'remove',
        cellRenderer: ({ data }) =>
          isLayerAdminPage ? (
            <></>
          ) : (
            <WppTooltip
              text={t('os.members_list.view_member.roles.actions_column.remove_role')}
              config={{ placement: 'left' }}
            >
              <WppActionButton
                disabled={!data?.userAssignedDirect.length}
                onClick={() =>
                  showDeleteRoleMemberModal({
                    member: user,
                    role: data!,
                  })
                }
              >
                <WppIconRemoveCircle />
              </WppActionButton>
            </WppTooltip>
          ),
      },
      {
        width: 60,
        colId: 'view',
        cellRenderer: ({ data }) => (
          <WppTooltip
            text={t('os.members_list.view_member.roles.actions_column.view_role')}
            config={{ placement: 'left' }}
          >
            <WppActionButton onClick={() => onViewRole(data!.id)} disabled={isGroupsPage}>
              <WppIconChevron direction="right" />
            </WppActionButton>
          </WppTooltip>
        ),
      },
    ],
    [t, isGroupsPage, user, onViewRole, isLayerAdminPage],
  )

  return (
    <Flex direction="column" gap={12}>
      {isCriticalError && <CriticalError />}
      <RenderErrorBoundary catchErrors={[RenderErrorType.DataIsNotAvailable]}>
        <Flex direction="column" gap={12} data-testid="roles-header-section">
          {!isLayerAdminPage && !noResultsPlaceholder && (
            <Flex justify="end">
              <WppActionButton onClick={onAddRole}>
                <WppIconOwner slot="icon-start" /> {t('os.roles.header.assign_role')}
              </WppActionButton>
            </Flex>
          )}
        </Flex>
        {noResultsPlaceholder ? (
          <EmptyStateWithAction
            label={t('os.roles.table.placeholder.not_assigned')}
            actionLabel={t('os.roles.header.assign_role')}
            onAction={onAddRole}
          />
        ) : (
          <TableInfinite
            tableKey={TableKey.VIEW_MEMBER_ROLES}
            className={clsx('member-roles', styles.table)}
            columnDefs={memberRolesColumnDefs}
            noRowsOverlayComponent={noRowsOverlay}
            loader={loader}
            onLoadError={error => {
              if (is5xxError(error)) {
                setState({ isCriticalError: true })
              }
            }}
          />
        )}
      </RenderErrorBoundary>
    </Flex>
  )
}
