import { WppButton, WppIconWarning, WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { useUpdateUsersRolesApi } from 'api/users/mutations/useUpdateUsersRolesApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { handleReloadSideEditRolesTable } from 'pages/admin/members/editMemberSideModal/utils'
import { handleReloadMembersTable } from 'pages/admin/members/membersList/membersTable/utils'
import { handleReloadMemberAccess, handleReloadMemberRolesTable } from 'pages/admin/members/viewMemberSideModal/utils'
import styles from 'pages/admin/roles/Roles.module.scss'
import { handleReloadRolesTable } from 'pages/admin/roles/utils'
import { handleReloadRoleMembersTable } from 'pages/admin/roles/viewRoleSideModal/utils'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useToast } from 'providers/toast/ToastProvider'
import { MemberRole } from 'types/roles/memberRole'
import { RoleMember } from 'types/roles/roleMember'
import { Role } from 'types/roles/roles'
import { AZUser } from 'types/users/users'
import { join } from 'utils/common'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { mapMemberRolesPayloadToDelete } from 'utils/roles'

interface Props extends NiceModalWrappedProps {
  member: AZUser | RoleMember
  role: MemberRole | Role
}

const DeleteRoleMemberModal = ({ isOpen, onClose, member, role, id, onCloseComplete }: Props) => {
  const { t } = useTranslation()
  const { currentTenant } = useCurrentTenantData()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleUpdateUsersRoles, isLoading } = useUpdateUsersRolesApi()

  const fullName = join(
    'firstName' in member ? [member.firstName, member.lastName] : [member.first_name, member.last_name],
    ' ',
  )

  const onDelete = async () => {
    try {
      await handleUpdateUsersRoles({
        create: [],
        delete: mapMemberRolesPayloadToDelete(member, role, currentTenant.azMeta.organizationsId),
      })
      await Promise.all([
        handleReloadSideEditRolesTable(),
        handleReloadRoleMembersTable(),
        handleReloadRolesTable(),
        handleReloadMemberRolesTable(),
        handleReloadMembersTable(),
        handleReloadMemberAccess(),
      ])

      enqueueToast({
        message: t('os.common.toasts.delete_from', {
          entity: t('os.entities.role').toLocaleLowerCase(),
          is: t('os.entities.is'),
          deleteFrom: t('os.entities.member').toLocaleLowerCase(),
        }),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('os.common.errors.error'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  }

  return (
    <Modal
      data-testid={id}
      open={isOpen}
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      disableOutsideClick={isLoading}
    >
      <Flex slot="header" align="center" gap={8}>
        <WppIconWarning />
        <WppTypography type="xl-heading">{t('os.roles.view_role.members.delete_modal.title')}</WppTypography>
      </Flex>

      <WppTypography slot="body" type="s-body" className={styles.breakWord}>
        <Trans
          i18nKey="os.roles.view_role.members.delete_modal.description"
          values={{
            userName: fullName,
            roleName: role.name,
          }}
          components={[
            <b key="0" className={styles.bold}>
              roleName
            </b>,
            <b key="1" className={styles.bold}>
              userName
            </b>,
          ]}
        />
      </WppTypography>

      <Flex slot="actions" justify="end" gap={12}>
        <WppButton variant="secondary" size="s" onClick={onClose} data-testid="cancel">
          {t('os.common.cancel')}
        </WppButton>
        <WppButton size="s" variant="destructive" loading={isLoading} onClick={onDelete} data-testid="apply">
          {t('os.common.remove')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showDeleteRoleMemberModal } = createNiceModal(
  DeleteRoleMemberModal,
  'delete-role-member-modal',
)
