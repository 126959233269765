import { useCallback } from 'react'

import { useFetchGroupUsersApi } from 'api/groups/queryFetchers/useFetchGroupUsersApi'
import { useUserExternalStatusApi } from 'api/users/queries/useUserExternalStatus'
import { TableInfiniteLoader } from 'components/common/table'
import { TableDefaults } from 'constants/table'
import { GroupUser } from 'types/groups/groups'

interface Params {
  groupId: string
  search?: string
}

export const useGroupUsersListLoader = ({ search, groupId }: Params) => {
  const handleFetchGroupsList = useFetchGroupUsersApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const handleFetchUsersExternalStatus = useUserExternalStatusApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const loader: TableInfiniteLoader<GroupUser> = useCallback(async () => {
    const { data: members } = await handleFetchGroupsList({
      search,
      groupId,
    })

    const {
      data: { data: statuses },
    } = await handleFetchUsersExternalStatus({ emails: members?.length ? members.map(user => user.email) : null })

    const mappedStatuses = new Map(
      statuses.map((user: { email: string; isExternal: boolean }) => [user.email, user.isExternal]),
    )

    /* ../check-external API returns external status only for users who are exist in user-details.
    Users are in AZ but not in user-details service we mark as internal.
    */
    for (const member of members) {
      member['isExternal'] = mappedStatuses.has(member.email) ? Boolean(mappedStatuses.get(member.email)) : false
    }

    return {
      data: members,
      totalRowsCount: members.length,
    }
  }, [groupId, handleFetchGroupsList, search, handleFetchUsersExternalStatus])

  return { loader }
}
