import { useMatch } from 'react-router-dom'

import { UsersListFilters } from 'api/users/fetchers/fetchUsersListApi'
import { GroupUser } from 'types/groups/groups'
import { RoleMember } from 'types/roles/roleMember'
import { UserDetailsShort } from 'types/users/users'
import { routesManager } from 'utils/routesManager'

export enum MembersTab {
  MEMBERS = 'members',
  GROUPS = 'groups',
}

export type MembersListFilters = Required<
  Pick<UsersListFilters, 'countryAlpha2Code' | 'department' | 'jobTitle' | 'external'>
>

export const useActiveMembersTab = () => {
  const match = useMatch(routesManager.admin.members.groups.url())

  return match ? MembersTab.GROUPS : MembersTab.MEMBERS
}

export const isMemberWithAvatar = (member: UserDetailsShort | GroupUser | RoleMember): member is UserDetailsShort =>
  'avatarThumbnail' in member
