import * as zod from 'zod'

import { tableActions } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { queryClient } from 'providers/osQueryClient/utils'
import { TenantGroup } from 'types/groups/groups'
import { AZUser } from 'types/users/users'
import { difference } from 'utils/common'
import { mapAzGroupToTenantGroup } from 'utils/groups'

interface EditMemberFormValues {
  groups: string[]
}

interface GetDefaultValuesParams {
  organizationsId: string
  groups: TenantGroup[]
}

export const getDefaultValues = ({ organizationsId, groups }: GetDefaultValuesParams): EditMemberFormValues => ({
  groups: groups.filter(group => group.createdOnAccountId === organizationsId).map(({ id }) => id),
})

export const editMemberValidationScheme = zod.object({
  groups: zod.array(zod.string()),
})

interface MapValuesToPayloadParams {
  organizationsId: string
  user: AZUser
  values: EditMemberFormValues
}

export const mapValuesToPayload = ({ organizationsId, user, values }: MapValuesToPayloadParams) => {
  const { groups: initialGroups } = getDefaultValues({
    organizationsId,
    groups: user.group_membership.map(mapAzGroupToTenantGroup),
  })

  const createDiff = difference(values.groups || [], initialGroups, id => id).map(groupId => ({
    groupId,
    userId: user.email,
  }))
  const deleteDiff = difference(initialGroups, values.groups || [], id => id).map(groupId => ({
    groupId,
    userId: user.email,
  }))

  return { create: createDiff, delete: deleteDiff }
}

export const handleReloadMemberRolesEditTable = async () => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MEMBER_ROLES_EDIT] })
  tableActions.reload(TableKey.EDIT_MEMBER_ROLES)
}

export const handleReloadSideEditRolesTable = async () => {
  await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MEMBER_ROLES] })
  tableActions.reload(TableKey.EDIT_MEMBER_ROLES)
}

export const enum RolesSegment {
  New = 'new',
  Assigned = 'assigned',
}
