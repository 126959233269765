import {
  WppActionButton,
  WppIconRemoveCircle,
  WppTypography,
  WppInput,
  WppAvatar,
  WppTooltip,
  WppIconUserAdd,
  WppSegmentedControl,
  WppSegmentedControlItem,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import { EmailCell } from 'components/cellRenderers/email/EmailCell'
import { ClipboardText } from 'components/common/clipboardText/ClipboardText'
import { Flex } from 'components/common/flex/Flex'
import { MeTag } from 'components/common/meTag/MeTag'
import { ColDef, Table, TableInfinite } from 'components/common/table'
import { RenderErrorBoundary, RenderErrorType } from 'components/renderError'
import { SvgNewNoRecords } from 'components/svg/network/SvgNewNoRecords'
import { Delay } from 'constants/delay'
import { TableKey } from 'constants/table'
import { useGroupUsersListLoader } from 'hooks/loaders/useGroupUsersListLoader'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useNumberFormat } from 'hooks/useNumberFormat'
import { showAddMembersSideModal } from 'pages/admin/members/addMembersToGroupSideModal/AddMembersToGroupSideModal'
import { Member } from 'pages/admin/members/addMembersToGroupSideModal/utils'
import { showDeleteGroupMemberModal } from 'pages/admin/members/deleteGroupMemberModal/DeleteGroupMemberModal'
import { MembersSegment } from 'pages/admin/members/groups/manageGroupSideModal/utils'
import styles from 'pages/admin/members/groups/viewGroupSideModal/viewGroupMembersSection/ViewGroupMembersSection.module.scss'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { AzGroup, GroupUser } from 'types/groups/groups'
import { join } from 'utils/common'
import { mapAzGroupToTenantGroup } from 'utils/groups'

interface Props {
  className?: string
  group: AzGroup
  onDeleteMember: () => Promise<void>
  membersSearch?: string
  setMembersSearch: (arg: string) => void
  setUsers: (users: (GroupUser | Member)[]) => void
}

interface State {
  usersCount: number
  users: (GroupUser | Member)[]
  currentSegment: MembersSegment
}

const initialState: State = {
  usersCount: 0,
  users: [],
  currentSegment: MembersSegment.Assigned,
}

export const EditGroupMembersSection = ({
  className,
  group,
  onDeleteMember,
  setUsers,
  membersSearch,
  setMembersSearch,
}: Props) => {
  const { t } = useTranslation()
  const { formatNumber } = useNumberFormat()
  const { userDetails } = useOtherTenantsAndUserData()
  const { loader } = useGroupUsersListLoader({ search: membersSearch, groupId: group.uid })

  const [{ users, usersCount, currentSegment }, setState] = useSetState<State>(initialState)

  const setSearchDebounced = useDebounceFn((search?: string) => setMembersSearch(search?.trim() || ''), Delay.Search)

  const handleAddMemberClick = () => {
    setMembersSearch('')

    showAddMembersSideModal({
      group: mapAzGroupToTenantGroup(group),
      onSubmit: async ({ users: addedUsers }) => {
        if (!addedUsers) {
          return
        }

        const assignedUsers = [...users, ...addedUsers]
        setState({
          users: assignedUsers,
          currentSegment: addedUsers.length ? MembersSegment.New : MembersSegment.Assigned,
        })
        setUsers(assignedUsers)
      },
    })
  }

  const noRowsOverlayComponent = useCallback(
    () => (
      <Flex direction="column" align="center" gap={2} data-testid="member-section-empty-state">
        <SvgNewNoRecords />
        {membersSearch ? (
          <>
            <div className={styles.truncateContainer}>
              <WppTypography className={styles.truncateItem} type="l-strong" data-testid="no-results-found">
                {t('os.common.empty_state.no_search_results_title', { search: membersSearch })}
              </WppTypography>
            </div>

            <WppTypography type="s-body">{t('os.common.empty_state.no_search_results_description')}</WppTypography>
          </>
        ) : (
          <>
            <WppTypography type="m-strong">{t('os.roles.table.placeholder.no_members_found')}</WppTypography>
          </>
        )}
      </Flex>
    ),
    [t, membersSearch],
  )

  const handleDeleteAssignedMember = useCallback(
    (email: string) => {
      const filteredAssignments = users.filter(member => member.email !== email)
      setState({ users: filteredAssignments })
      setUsers(filteredAssignments)
    },
    [users, setState, setUsers],
  )

  const handleMemberDelete = useCallback(
    async (member: GroupUser | Member) => {
      if (currentSegment === MembersSegment.New) {
        handleDeleteAssignedMember(member.email)
        users.length === 1 &&
          setState({
            currentSegment: MembersSegment.Assigned,
          })
        return
      }

      const { first_name, last_name, id } = member as GroupUser
      showDeleteGroupMemberModal({
        member: { fullName: join([first_name, last_name], ' '), id },
        group,
        onDeleteMember,
      })
    },
    [currentSegment, handleDeleteAssignedMember, users.length, group, setState, onDeleteMember],
  )

  const columnDefs = useMemo<ColDef<GroupUser | Member>[]>(
    () => [
      {
        flex: 1,
        headerName: t('os.groups.manage_group.members_table.columns.name'),
        colId: 'name',
        cellRenderer: ({ data }) => {
          const { first_name, last_name, email, isExternal } = data! as GroupUser
          const { firstname, lastname } = data! as Member
          const fullName = join([first_name || firstname, last_name || lastname], ' ')

          return (
            <ClipboardText
              text={fullName}
              showEndTag={isExternal}
              textClassName={styles.clipboard}
              textEndTag={t('os.entities.external')}
              tooltipMessage={t('os.common.copy_entity_to_clipboard', {
                entity: t('os.members_list.table.tooltips.member_name'),
              })}
            >
              <Flex>
                <WppAvatar name={fullName} size="s" className={styles.avatar} />
              </Flex>
              <Flex gap={4} align="center" className={styles.fullNameWrapper}>
                <Flex className={styles.fullName}>{fullName}</Flex>
                {email === userDetails.email && <MeTag />}
              </Flex>
            </ClipboardText>
          )
        },
      },
      {
        flex: 1,
        headerName: t('os.groups.manage_group.members_table.columns.email'),
        colId: 'email',
        cellRenderer: ({ data }) => (
          <EmailCell
            email={data!.email}
            isCopiable
            copyTooltip={t('os.common.copy_entity_to_clipboard', {
              entity: t('os.members_list.table.tooltips.member_email'),
            })}
          />
        ),
        tooltipValueGetter: ({ data }) => data!.email,
      },
      {
        width: 52,
        colId: 'delete',
        cellRenderer: ({ data }) => (
          <WppTooltip text={t('os.groups.manage_group.members_table.delete_modal.title')}>
            <WppIconRemoveCircle
              className={styles.removeMember}
              onClick={() => {
                return handleMemberDelete(data!)
              }}
            />
          </WppTooltip>
        ),
      },
    ],
    [t, handleMemberDelete, userDetails.email],
  )

  return (
    <>
      <Flex align="center" gap={8} direction="row">
        <WppTypography type="m-strong" data-testid="members-header-section-title">
          {t('os.groups.manage_group.members_table.header')}
        </WppTypography>
        <WppTypography type="s-body" className={styles.entitiesCounter} data-testid="create-members-counter">
          ({formatNumber(currentSegment === MembersSegment.Assigned ? usersCount : users.length)})
        </WppTypography>
      </Flex>
      <Flex direction="column" gap={8} className={className}>
        <Flex data-testid="members-header-section" justify="between" align="end">
          <Flex direction="column">
            {currentSegment === MembersSegment.Assigned && (
              <WppInput
                size="s"
                type="search"
                value={membersSearch}
                data-testid="search-members"
                className={styles.searchInput}
                onWppChange={({ detail }) => setSearchDebounced(detail.value)}
                placeholder={t('os.groups.manage_group.search_placeholder')}
              />
            )}
          </Flex>

          <Flex gap={12}>
            <WppActionButton onClick={handleAddMemberClick} data-testid="member-add-empty">
              <WppIconUserAdd slot="icon-start" />
              {t('os.groups.manage_group.members_table.add_members')}
            </WppActionButton>
          </Flex>
        </Flex>

        {!!users.length && (
          <WppSegmentedControl
            size="s"
            value={currentSegment}
            onWppChange={({ detail: { value } }) => {
              const segment = value as MembersSegment
              setMembersSearch('')
              setState({
                currentSegment: segment,
              })
            }}
          >
            <WppSegmentedControlItem value={MembersSegment.Assigned}>
              {t('os.roles.table.columns.assigned')}
            </WppSegmentedControlItem>
            <WppSegmentedControlItem value={MembersSegment.New}>
              {t('os.roles.table.columns.new')}
            </WppSegmentedControlItem>
          </WppSegmentedControl>
        )}
        {currentSegment === MembersSegment.New && (
          <Table
            columnDefs={columnDefs}
            noRowsOverlayComponent={noRowsOverlayComponent}
            rowData={users as GroupUser[]}
            className={clsx('edit-new-group-members', styles.table, 'members-list')}
          />
        )}
        {currentSegment === MembersSegment.Assigned && (
          <RenderErrorBoundary catchErrors={[RenderErrorType.DataIsNotAvailable]}>
            <TableInfinite
              loader={loader}
              columnDefs={columnDefs}
              tableKey={TableKey.VIEW_GROUP_MEMBERS}
              getRowId={({ data }) => (data as GroupUser).id}
              noRowsOverlayComponent={noRowsOverlayComponent}
              onLoadSuccess={({ totalRowsCount }) => {
                setState({ usersCount: totalRowsCount })
              }}
              className={clsx('edit-group-members', styles.table, 'members-list')}
            />
          </RenderErrorBoundary>
        )}
      </Flex>
    </>
  )
}
